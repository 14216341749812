import { Api } from "../../../../utils/api"

export const RolesApi = {
    get: async () => {
        try {
            let res = await Api.get({
                path: '/userRoles'
            })
            if(res.success) {
                return res.data.roles 
            }
            return null 
        } catch(e) {
            return null 
        }
    }
}