import { useContext, useEffect, useState } from "react";
import ReactModal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import AppImages from "../../../assets/images";
import DataContext from "../../../contexts/DataContext";
import { Api } from "../../../utils/api";
import { RequestStatus } from "../../../utils/enums";
import Button from "../../basic/Button";
import SimpleModal from "../../basic/SimpleModal";
import DashboardTemplate from "../../common/DashboardTemplate";
import { RolesApi } from "./api/RolesApi";
import { userRolesActions, userRoleThunk } from "./user_roles_slice";

function ListRoles() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
    const dataCtx = useContext(DataContext)
    const [roles, setRoles] = useState(dataCtx.roles.data ?? [])
    const roleReducer = useSelector(state => state.userRoles)
    const [data, setData] = useState(roleReducer.data ?? [])
    const [showableData, setShowableData] = useState(data ?? [])
    const [deleteableItem, setDeletableItem] = useState(null);

    useEffect(() => {
        if(roleReducer.fetchDataRequestStatus === RequestStatus.IDLE) {
            dispatch(userRoleThunk.getAll()) 
        }
      }, [roleReducer.data])
    
      useEffect(() => {
        setData(roleReducer.data ?? [])
        setShowableData(roleReducer.data ?? [])
        dataCtx.roles.update(roleReducer.data ?? [])
    }, [roleReducer.data])

    return (  
        <DashboardTemplate>
            {/* Delete modal */}
            <SimpleModal
                isModalOpen={deleteableItem !== null}
                onClick={() => {
                    console.log(deleteableItem?.id);
                    setDeletableItem(null);
                    dispatch(userRolesActions.deleteRole({ roleId: deleteableItem?.id }))    
                    Api.delete({
                        path: `/userRoles/${deleteableItem?.id}`
                    }).then(response => {
                        console.log(response)
                    })
                }}
                text="Are you sure to delete this role?"
                onClose={() => {
                    setDeletableItem(null);
                }}
            />

            <div>
                <div className="flex justify-between items-center mb-8">
                    <div>
                        <h1 className="font-bold text-[20px] text-black">Roles and Permissions</h1>
                    </div>
                    <div>
                        <Button 
                            text={'Add Role'}
                            style={{
                                fontSize: 'text-[14px]',
                                custom: 'py-2 px-8'
                            }}
                            onClick={e => navigate('/roles/add')}
                        />
                    </div>
                </div>

                <table className="w-full">
                    <thead>
                        <tr className="border-b-2">
                            <th className="text-left text-[12px] font-semibold pb-6 w-[10%]">ID</th>
                            <th className="text-left text-[12px] font-semibold pb-6 w-[20%]">Role Name</th>
                            <th className="text-left text-[12px] font-semibold pb-6 w-[70%]">Covid Response</th>
                        </tr>
                    </thead>
                    <tbody>
                        { dataCtx.roles.isLoading && 
                            <tr className="relative h-[200px]">
                                <td colSpan={3} className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
                                    <img src={AppImages.loading} alt="Loading" className="w-[50px]" />
                                </td>
                            </tr> 
                        }
                        { showableData && showableData.map((item, index) => (
                            <tr className="border-b-2" key={index}>
                                <td className="py-4 text-[13px]">{item.id}</td>
                                <td className="py-4 text-[13px]">{item.name}</td>
                                <td className="py-4">
                                    <div className="flex">
                                        <div 
                                            className="bg-primary p-2 rounded-lg mr-2 cursor-pointer hover:bg-black"
                                            onClick={e => {
                                                dispatch(userRolesActions.setActiveRole({ role: item }))
                                                navigate(`/roles/${item.id}/view`)
                                            }}
                                        >
                                            <img src={AppImages.eyeWhite} alt="" />
                                        </div>
                                        <div 
                                            className="bg-primary p-2 rounded-lg mr-2 cursor-pointer hover:bg-black"
                                            onClick={e => {
                                                dispatch(userRolesActions.setActiveRole({ role: item }))
                                                navigate(`/roles/${item.id}/edit`)
                                            }} 
                                        >
                                            <img src={AppImages.editWhite} alt="" />
                                        </div>
                                        <div 
                                            className="bg-primary p-2 rounded-lg mr-2 cursor-pointer hover:bg-black"
                                            onClick={e =>{
                                                setDeletableItem(item)
                                            }}
                                        >
                                            <img src={AppImages.deleteWhite} alt="" />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        )) }
                    </tbody>
                </table>

            </div>
        </DashboardTemplate>
    );
}

export default ListRoles;