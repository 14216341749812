import axios from "axios";
import { useContext } from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactLoading from "react-loading";
import { Link, useNavigate, useParams } from "react-router-dom";
import AppImages from "../../../../assets/images";
import AuthContext from "../../../../contexts/AuthContext";
import { Api } from "../../../../utils/api";
import { RequestStatus } from "../../../../utils/enums";
import SimpleModal from "../../../basic/SimpleModal";
import ListingTemplate from "../ListingTemplate";
import { employeeActions, employeeThunk } from "../reducers/employee_slice";
import ReactToPrint from "react-to-print";
import DownloadPdf from "./Print";
import { useRef } from "react";
import { forwardRef } from "react";
import Chart from "react-google-charts";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import DownloadPdf1 from "./Print1";
import FirebaseService from "../../../../services/FirebaseService";
import Modal from "./Modal";
// import { encode, decode } from "base-64";
// import ReactToPrint from "react-to-print";

const GharanaVoterList = forwardRef(({ children }, ref) => {
  const navigate = useNavigate();
  const ths = [{ title: "Block Code" }, { title: "Age" }, { title: "View" }];
  const tdClasses = "text-[17px] border-[1px] border-[#0d0d10fd] py-4 px-2";
  const wordsToReplace = [
    "مکان",
    "سال",
    "نمبر",
    "مین",
    "محلہ",
    "روڈ",
    "پارک",
    "،",
    "کالونی ",
    "لاہور ",
    "کی",
    "ضلع",
    "/",
    "ٹاور",
    "خالصہ",
    "خلاصہ",
  ];
  const dispatch = useDispatch();
  const authCtx = useContext(AuthContext);
  const employeeReducer = useSelector((state) => state.employees);
  // console.log("Employee", employeeReducer.data)
  const [data, setData] = useState("");
  const [data1, setData1] = useState();
  const [rawData, setRawData] = useState([])
  const [showableData, setShowableData] = useState(data ?? []);

  const fields = {
    blockCode: null,
    minage: null,
    maxage: null,
  };
  const errors = {
    blockCode: null,
    minage: null,
    maxage: null,
  };
  const [formFields, setFormFields] = useState(fields);
  const [data2, setData2] = useState("");
  let na = authCtx.user.sector;
  let { blockcode } = useParams();
//   let {ghnumber} = useParams();
  //  let na = authCtx.user.sector;
  // let na = authCtx.user.sector;
  const [page, setPage] = useState(1);
  // console.log("Pageee",page)
  useEffect(() => {
    let na = authCtx.user.sector;

    let page = 1;
    // console.log("ID", blockcode, na);
    const fetchData = async () => {
      const result = await axios(
        `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/api_func_002?bc=${blockcode}&sector=${authCtx.user.sector}`
      );
        // setData(result.data.data.)
      setData(result.data.data.data);
      setRawData(result.data.data.data);
      setData1(result.data.data.facts);
    //   let wholedata = result.data.data;
    //   console.log("WholeData", wholedata);
    //   let sortdata = wholedata.sort((a, b) => b.age - a.age);
    //   console.log("SortDataaaaaaaaaaaaaaaaaaa", sortdata);
    //   const resultData = wholedata.filter((obj) => obj.gharana_no === "");
    };

    fetchData();
  }, []);
  console.log("dataaaa",data)
  console.log("Dataaa1",data1)

  const newData = async () => {
    const result = await axios(
      `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/voter-list-blockCode?sector=${na}&page_no=${page}&block_code=${blockcode}`
    );

    setData(result.data.voters);
  };
   function ImageSlice({ item }) {
     var yIncr = 0;
     if (item?.rowY) {
         if (authCtx.user.sector === "PP 172") {
           if (
             // item.block_code === 185570302 ||
             // item.block_code === 185560303 ||
             // item.block_code === 185570402
             item.meta === "100"
           ) {
             yIncr = -10;
           } else {
             if (item?.name === "" || item?.address === "") {
               yIncr = -40;
             } else {
               yIncr = 65;
             }
           }
         } else if (["PP 208", "PP 14"].includes(authCtx.user.sector)) {
           if (item?.name === "" || item?.address === "") {
             yIncr = -40;
           } else {
             yIncr = 65;
           }
         } else if (["PP 207"].includes(authCtx.user.sector)) {
           if (item?.name === "" || item?.address === "") {
             yIncr = -30;
           } else {
             yIncr = 70;
           }
         } else if (["NA 120"].includes(authCtx.user.sector)) {
           yIncr = 50;
         } else if (["NA 127"].includes(authCtx.user.sector)) {
           yIncr = -10;
         } else if (["PP 59"].includes(authCtx.user.sector)) {
           yIncr = -20;
         } else {
           yIncr = -10;
         }
     }

     return (
       <>
         {authCtx.user.sector === "PP 152" ? (
           <img
             src={`https://res.cloudinary.com/election-experts/image/fetch/c_crop,h_55,x_0,w_4000,y_${
               parseInt(item?.rowY) + yIncr
             }/${encodeURIComponent(item?.firebase_url)}`}
             alt=""
             srcSet=""
             className=" w-full h-[40px]"
           />
         ) : (
           <img
             src={`https://res.cloudinary.com/election-experts/image/fetch/c_crop,h_80,x_0,w_4000,y_${
               parseInt(item?.rowY) + yIncr
             }/${encodeURIComponent(item?.firebase_url)}`}
             alt=""
             srcSet=""
             className=" w-full h-[40px]"
           />
         )}
       </>
     );
   }

  const [message, showmessage] = useState("");
   const [isModalOpen, setModalOpen] = useState(false);

   const openModal = () => {
     setModalOpen(true);
   };

   const closeModal = () => {
     setModalOpen(false);
   };

  return (
    <>
      {/* <div> */}
      <ListingTemplate ref={ref}>
        {/* </div> */}
        <div className="flex flex-row" id="nottoshow">
          {/* <div className="text-[18px] font-bold">Sector</div>
        <div className=" text-[15px] ">{na}</div>
          <div className="ml-1 text-[15px] ">/</div>
          <div
            className="ml-1 text-[15px]"
            onClick={(e) => {
              // navigate("/team/list");
            }}
          >
            {blockcode}
          </div>
          <div className="ml-1 text-[15px] ">/</div>
          <div className="ml-1 text-[15px]  text-gray-400">Voter List</div> */}
        </div>
        <div id="nottoshowsss">
          {/* <BlockcodeAgeGraph blockCode={blockcode} /> */}
        </div>

        <div id="nottoshowss">
          {/* {authCtx.user.sector == "PP 14" || "PP 172" ? (
            // <button onClick={openModal}>Open Modal</button>
            <button
              onClick={openModal}
              className="border-[1px] border-gray-400 rounded-md px-1 hover:bg-slate-200"
            >
              Download PDF
            </button>
          ) : ( */}
          <DownloadPdf
            rootElementId={"pagetodownload"}
            show={"nottoshow"}
            shows={"nottoshows"}
            showss={"nottoshowss"}
            showsss={"nottoshowsss"}
            onClick={async () => {
              let res = await FirebaseService.addLog({
                tag: "GharanaListDownload",
                platform: "web",
                role: "manager",
                blockcode: blockcode,
                sector: authCtx.user.sector,
              });
              console.log("firebase response", res);
              showmessage(
                "Please wait for a minute We are creating PDF of this data"
              );
            }}
          />
          {/* )} */}
          {message === "" ? (
            <div></div>
          ) : (
            <div className="text-[18px] text-red-400">{message}</div>
          )}
        </div>
        <div id="pagetodownload">
          <div className="w-full px-7 my-3 font-noori font-bold" dir="rtl">
            <div className="w-full flex flex-row">
              <div className="w-[25%] border-[1px] border-black bg-slate-200">
                قومی حلقہ
              </div>
              <div className="w-[25%] border-[1px] px-1 border-black">
                {authCtx.user.sector === "PP 207" ||
                authCtx.user.sector === "PP 208"
                  ? "NA 146"
                  : authCtx.user.sector}
              </div>
              <div className="w-[25%] border-[1px] border-black bg-slate-200">
                صوبائی حلقہ
              </div>
              <div className="w-[25%] border-[1px] px-1 border-black">
                {authCtx.user.sector}
              </div>
            </div>
            <div className="w-full flex flex-row">
              <div className="w-[25%] border-[1px] border-black bg-slate-200">
                یونین کونسل نمبر
              </div>
              <div className="w-[25%] border-[1px] border-black px-1"></div>
              <div className="w-[25%] border-[1px] border-black bg-slate-200">
                یونین کونسل نام
              </div>
              <div className="w-[25%] border-[1px] px-1 border-black">
                {/* {authCtx.user.sector === "PP 14"
                  ? "راولپنڈی الیکشن"
                  : "لاہور الیکشن"} */}
              </div>
            </div>
            <div className="w-full flex flex-row">
              <div className="w-[25%] border-[1px] border-black bg-slate-200">
                پولنگ سٹیشن نمبر قومی حلقہ
              </div>
              <div className="w-[25%] border-[1px] border-black px-1"></div>
              <div className="w-[25%] border-[1px] border-black bg-slate-200">
                پولنگ سٹیشن نمبر قومی حلقہ
              </div>
              <div className="w-[25%] border-[1px] px-1 border-black"></div>
            </div>
            <div className="w-full flex flex-row">
              <div className="w-[25%] border-[1px] border-black bg-slate-200">
                پولنگ سٹیشن
              </div>
              <div className="w-[75%] border-[1px] px-1 border-black">
                {/* گورنمنٹ ہائی سکول لاہور */}
              </div>
            </div>
            <div className="w-full flex flex-row">
              <div className="w-[25%] border-[1px] border-black bg-slate-200">
                بلاک کوڈ
              </div>
              <div className="w-[75%] border-[1px] px-1 border-black">
                {blockcode}
              </div>
            </div>
          </div>
          <div className="w-full px-7 my-3 font-noori font-bold" dir="rtl">
            <div className="w-full flex flex-row">
              <div className="w-[25%] border-[1px] px-4 border-black bg-slate-200">
                گھرانہ وار ووٹرو کا خلاصہ
              </div>
              <div className="w-[15%] border-[1px] px-4 border-black bg-slate-200">
                گھرانے
              </div>
              <div className="w-[15%] border-[1px] px-4 border-black bg-slate-200">
                مرد
              </div>
              <div className="w-[15%] border-[1px] px-4 border-black bg-slate-200">
                خواتین
              </div>
              <div className="w-[15%] border-[1px] px-4 border-black bg-slate-200">
                کل
              </div>
              <div className="w-[15%] border-[1px] px-4 border-black bg-slate-200"></div>
            </div>
            <div className="w-full flex flex-row">
              <div className="w-[25%] border-[1px] px-4 border-black">
                دس افراد یا اس سے زیادہ
              </div>
              {/* {data1.map((item, index) => (console.log("itemmmmmmmmm",item)))} */}
              <div className="w-[15%] border-[1px] px-4 border-black">
                {data1?.ten.total_gharanas}
              </div>
              <div className="w-[15%] border-[1px] px-4 border-black ">
                {data1?.ten.males}
              </div>
              <div className="w-[15%] border-[1px] px-4 border-black ">
                {data1?.ten.females}
              </div>
              <div className="w-[15%] border-[1px] px-4 border-black ">
                {data1?.ten.total_members}
              </div>
              <div className="w-[15%] flex justify-center items-center border-[1px] px-4 border-black ">
                <div
                  className="bg-slate-200 rounded-md py-[2px] text-[17px] px-2 cursor-pointer my-[2px] hover:bg-slate-300"
                  onClick={() => {
                    const gharanaNos = data1.ten.gharana_numbers.map(
                      (g) => g.gharana_no
                    );
                    const tenPlusData = rawData?.filter((item) =>
                      gharanaNos.includes(item.gharana_no)
                    );
                    setData(tenPlusData);
                  }}
                >
                  View
                </div>
              </div>
            </div>
            <div className="w-full flex flex-row">
              <div className="w-[25%] border-[1px] px-4 border-black">
                5 سے 9 افراد
              </div>
              <div className="w-[15%] border-[1px] px-4 border-black">
                {data1?.FivetoNine.total_gharanas}
              </div>
              <div className="w-[15%] border-[1px] px-4 border-black ">
                {data1?.FivetoNine.males}
              </div>
              <div className="w-[15%] border-[1px] px-4 border-black ">
                {data1?.FivetoNine.females}
              </div>
              <div className="w-[15%] border-[1px] px-4 border-black ">
                {data1?.FivetoNine.total_members}
              </div>
              <div className="w-[15%] flex justify-center items-center border-[1px] px-4 border-black ">
                <div
                  className="bg-slate-200 rounded-md py-[2px] text-[17px] px-2 cursor-pointer my-[2px] hover:bg-slate-300"
                  onClick={() => {
                    const gharanaNos = data1.FivetoNine.gharana_numbers.map(
                      (g) => g.gharana_no
                    );
                    const FivetoNine = rawData?.filter((item) =>
                      gharanaNos.includes(item.gharana_no)
                    );
                    setData(FivetoNine);
                  }}
                >
                  View
                </div>
              </div>
            </div>
            <div className="w-full flex flex-row">
              <div className="w-[25%] border-[1px] px-4 border-black">
                1 سے 4 افراد
              </div>
              <div className="w-[15%] border-[1px] px-4 border-black">
                {data1?.OnetoFour.total_gharanas}
              </div>
              <div className="w-[15%] border-[1px] px-4 border-black ">
                {data1?.OnetoFour.males}
              </div>
              <div className="w-[15%] border-[1px] px-4 border-black ">
                {data1?.OnetoFour.females}
              </div>
              <div className="w-[15%] border-[1px] px-4 border-black ">
                {data1?.OnetoFour.total_members}
              </div>
              <div className="w-[15%] flex justify-center items-center border-[1px] px-4 border-black ">
                <div
                  className="bg-slate-200 rounded-md py-[2px] text-[17px] px-2 cursor-pointer my-[2px] hover:bg-slate-300"
                  onClick={() => {
                    const gharanaNos = data1.OnetoFour.gharana_numbers.map(
                      (g) => g.gharana_no
                    );
                    const OnetoFour = rawData?.filter((item) =>
                      gharanaNos.includes(item.gharana_no)
                    );
                    setData(OnetoFour);
                  }}
                >
                  View
                </div>
              </div>
            </div>
            <div className="w-full flex flex-row">
              <div className="w-[25%] border-[1px] px-4 border-black">کل</div>
              <div className="w-[15%] border-[1px] px-4 border-black">
                {data1?.OnetoFour.total_gharanas +
                  data1?.FivetoNine.total_gharanas +
                  data1?.ten.total_gharanas}
              </div>
              <div className="w-[15%] border-[1px] px-4 border-black ">
                {data1?.OnetoFour.males +
                  data1?.FivetoNine.males +
                  data1?.ten.males}
              </div>
              <div className="w-[15%] border-[1px] px-4 border-black ">
                {" "}
                {data1?.OnetoFour.females +
                  data1?.FivetoNine.females +
                  data1?.ten.females}
              </div>
              <div className="w-[15%] border-[1px] px-4 border-black ">
                {" "}
                {data1?.OnetoFour.total_members +
                  data1?.FivetoNine.total_members +
                  data1?.ten.total_members}
              </div>
              <div className="w-[15%] flex justify-center items-center border-[1px] px-4 border-black ">
                <div
                  className="bg-slate-200 rounded-md py-[2px] text-[17px] px-2 cursor-pointer my-[2px] hover:bg-slate-300"
                  onClick={() => {
                    setData(rawData);
                  }}
                >
                  View
                </div>
              </div>
            </div>
          </div>
          {authCtx.user.party === "PTI" ? (
            <div className="flex justify-center">
              <img
                className=" rounded-full bg-gray-400 w-[10%] object-contain"
                src={authCtx.user.party_image}
                alt="Rounded avatar"
              />
            </div>
          ) : (
            ""
          )}
          {
          // authCtx.user.sector === "NA 127" ||
          authCtx.user.sector == "PP 161" ? (
            <table className="w-full my-6 px-7">
              <thead>
                <tr className="font-noori">
                  {/* {authCtx.user.package !== "1" ? ( */}
                  <th
                    className="text-[15px] font-semibold border-b-[1px] border-[#2A2D4314] py-4 text-right px-2"
                    dir="rtl"
                  >
                    فون
                  </th>
                  {/* ) : null} */}
                  <th
                    className="text-[15px] font-semibold border-b-[1px] border-[#2A2D4314] py-4 text-right "
                    dir="rtl"
                  >
                    صنف
                  </th>
                  <th
                    className="text-[15px] font-semibold border-b-[1px] border-[#2A2D4314] py-4 text-right px-2"
                    dir="rtl"
                  >
                    پتہ
                  </th>
                  <th
                    className="text-[15px] font-semibold border-b-[1px] border-[#2A2D4314] py-4 text-right "
                    dir="rtl"
                  >
                    عمر
                  </th>

                  <th
                    className="text-[15px] font-semibold border-b-[1px] border-[#2A2D4314] py-4 text-right "
                    dir="rtl"
                  >
                    شناختی کارڈ نمبر
                  </th>

                  <th
                    className="text-[15px] font-semibold border-b-[1px] border-[#2A2D4314] py-4 text-right"
                    dir="rtl"
                  >
                    نام
                  </th>
                  <th
                    className="text-[15px] font-semibold border-b-[1px] border-[#2A2D4314] py-4 text-right "
                    dir="rtl"
                  >
                    گھرانہ نمبر
                  </th>

                  <th
                    className="text-[15px] font-semibold border-b-[1px] border-[#2A2D4314] py-4 text-right "
                    dir="rtl"
                  >
                    سلسلہ نمبر
                  </th>
                </tr>
              </thead>
              <tbody>
                {/* {data1 === null} */}
                {data ? (
                  data.map((item, index) => (
                    <tr key={index}>
                      {authCtx.user.package !== "1" ? (
                        <td
                          className="text-[17px] border-b-[1px] pr-3 border-[#2A2D4314] py-4 w-[150px]"
                          dir="rtl"
                        >
                          {item.voter_phone
                            ? (typeof item.voter_phone === "string" &&
                              item.voter_phone !== "null"
                                ? JSON.parse(item.voter_phone)
                                : Array.isArray(item.voter_phone)
                                ? item.voter_phone
                                : []
                              )
                                .filter(
                                  (item, index, self) =>
                                    self.indexOf(item) === index
                                )
                                .map((phone) => (
                                  <div className="mr-2">0{phone}</div>
                                ))
                            : "--"}
                        </td>
                      ) : null}

                      <td
                        className="text-[17px] border-b-[1px] border-[#2A2D4314] pr-4 py-4 w-[150px] font-noori"
                        dir="rtl"
                      >
                        {/*  */}
                        {item.gender === "male"
                          ? "مرد"
                          : item.gender === "female"
                          ? "عورت"
                          : ""}
                      </td>
                      <td
                        className="text-[17px] border-b-[1px] border-[#2A2D4314] pl-2 py-4 w-[170px] font-noori"
                        dir="rtl"
                      >
                        {item.address?.length > 100
                          ? item.address?.replace(/سال/gi, "").substr(0, 100) +
                            ".."
                          : item.address?.replace(/سال/gi, "")}
                      </td>
                      <td
                        className="text-[17px] border-b-[1px] px-1 border-[#2A2D4314] py-4 w-[100px] font-noori"
                        dir="rtl"
                      >
                        {item.age}
                      </td>

                      <td
                        className="text-[17px] border-b-[1px] px-1 border-[#2A2D4314] py-4 w-[200px] font-noori"
                        dir="rtl"
                      >
                        {item.cnic}
                      </td>

                      <td
                        className="text-[17px] border-b-[1px] px-2 border-[#2A2D4314] py-4 w-[150px] font-noori"
                        dir="rtl"
                      >
                        {item.name?.length > 44
                          ? item.name
                              ?.replace(/[^\W]/gi, "")
                              .replace(/[-*]/g, " ")
                              .replace(
                                new RegExp(wordsToReplace.join("|"), "gi"),
                                ""
                              )
                              .substr(0, 44) + ""
                          : item.name
                              ?.replace(/[^\W]/gi, "")
                              .replace(/[-*]/g, " ")
                              .replace(
                                new RegExp(wordsToReplace.join("|"), "gi"),
                                ""
                              )}
                      </td>
                      <td
                        className="text-[17px] border-b-[1px] border-[#2A2D4314] py-4 w-[130px]"
                        dir="rtl"
                      >
                        {item.gharana_no === "" ? 1 : item.gharana_no}
                      </td>
                      <td
                        className="text-[17px] border-b-[1px] px-1 border-[#2A2D4314] py-4 w-[150px] font-noori"
                        dir="rtl"
                      >
                        {item.silsila_no === "" ? 1 : item.silsila_no}
                      </td>
                    </tr>
                  ))
                ) : (
                  <ReactLoading
                    color={"gray"}
                    height={"40px"}
                    width={"70px"}
                    className="my-2 justify-center"
                  />
                )}
              </tbody>
            </table>
          ) : (
            <table className=" my-6 w-full">
              <thead>
                <tr className="font-noori bg-slate-300">
                  {/* {authCtx.user.package !== "1" ? (
                  <th
                    className="text-[15px] font-semibold border-[4px] border-[#0d0d10fd] py-1 text-center px-2"
                    dir="rtl"
                  >
                    فون
                  </th>
                ) : null} */}
                  {/* <th
                  className="text-[15px] font-semibold  border-[4px] px-2 border-[#0d0d10fd] py-1 text-center "
                  dir="rtl"
                >
                  جنس
                </th> */}
                  <th
                    className="text-[15px] font-semibold border-[4px] border-[#0d0d10fd] py-1 text-center px-2"
                    dir="rtl"
                  >
                    پتہ
                  </th>
                  <th
                    className="text-[15px] font-semibold border-[4px] px-2 border-[#0d0d10fd] py-1 text-center "
                    dir="rtl"
                  >
                    عمر
                  </th>

                  <th
                    className="text-[15px] font-semibold border-[4px] px-2 border-[#0d0d10fd] py-1 text-center "
                    dir="rtl"
                  >
                    شناختی کارڈ نمبر
                  </th>
                  <th
                    className="text-[15px] font-semibold border-[4px] px-2 border-[#0d0d10fd] py-1 text-center "
                    dir="rtl"
                  >
                    والد کا نام
                  </th>

                  <th
                    className="text-[15px] font-semibold border-[4px] px-2 border-[#0d0d10fd] py-1 text-center"
                    dir="rtl"
                  >
                    نام
                    {/* <div className="flex flex-row justify-around">
                    <div>سلسلہ</div>
                    <div>خاندان</div>
                    <div></div>
                    <div className="">والد کا نام</div>
                  </div> */}
                  </th>
                  <th
                    className="text-[15px] font-semibold border-[4px] px-2 border-[#0d0d10fd] py-1 text-center "
                    dir="rtl"
                  >
                    گھرانہ
                  </th>

                  <th
                    className="text-[15px] font-semibold border-[4px] px-2 border-[#0d0d10fd]  py-1 text-center "
                    dir="rtl"
                  >
                    سلسلہ
                  </th>
                </tr>
              </thead>
              <tbody>
                {/* {data1 === null} */}
                {data ? (
                  data.map((item, index) => (
                    <>
                      <tr>
                        <td
                          className="text-[11px] border-t-[1px] border-l-[1px] border-r-[1px] px-1 border-[#0d0d10fd] py-1 w-[70px] font-noori"
                          dir="rtl"
                          colSpan={7}
                        > <>
                          {item?.type === "manual" ? (
                            <div
                              className="px-1 flex flex-row text-[25px]  py-2"
                              dir="rtl"
                            >
                              <div className="font-bold text-right font-noori">
                                نام
                              </div>
                              <div className="font-noori mr-4">
                                {/* {data2?.response?.name} */}
                                {
                                  item?.name?.length > 44
                                    ? item?.name
                                        ?.replace(/[^\W]/gi, "")
                                        .replace(/[-*]/g, " ")
                                        // .replace(
                                        //   new RegExp(
                                        //     wordsToReplace.join("|"),
                                        //     "gi"
                                        //   ),
                                        // ""
                                        // )
                                        .substr(0, 44) + ""
                                    : item?.name
                                        ?.replace(/[^\W]/gi, "")
                                        .replace(/[-*]/g, " ")
                                  // .replace(
                                  //   new RegExp(
                                  //     wordsToReplace.join("|"),
                                  //     "gi"
                                  //   ),
                                  //   ""
                                  // )
                                }
                              </div>
                            </div>
                          ) : (
                          <ImageSlice item={item} />)}</>
                          {/* {authCtx.user.sector === "PP 207" ? (
                          <img
                            src={`https://res.cloudinary.com/election-experts/image/fetch/c_crop,h_80,x_0,w_4000,y_${
                              parseInt(item?.rowY) + 70
                            }/${encodeURIComponent(item?.firebase_url)}`}
                            alt=""
                            srcSet=""
                            className=" w-[900px]"
                          />
                        ) : (
                          <>
                            {item?.name === "" || item?.address === "" ? (
                              <img
                                src={`https://res.cloudinary.com/election-experts/image/fetch/c_crop,h_100,x_0,w_4000,y_${
                                  parseInt(item?.rowY) - 40
                                }/${encodeURIComponent(item?.firebase_url)}`}
                                alt=""
                                srcSet=""
                                className="w-[900px]"
                              />
                            ) : (
                              <img
                                src={`https://res.cloudinary.com/election-experts/image/fetch/c_crop,h_100,x_0,w_4000,y_${
                                  parseInt(item?.rowY) + 65
                                }/${encodeURIComponent(item?.firebase_url)}`}
                                alt=""
                                srcSet=""
                                className="w-[900px]"
                              />
                            )}
                          </>
                        )} */}
                        </td>
                      </tr>
                      <tr key={index}>
                        {authCtx.user.package !== "1" ? (
                          <td
                            className="text-[17px] border-b-[1px] border-l-[1px] border-r-[1px] pr-3 border-[#0d0d10fd] p1-4 "
                            dir="rtl"
                            // rowSpan={2}
                            colSpan={7}
                          >
                            <span>
                              <span className="mx-3 text-right">
                                {item?.cnic}
                              </span>
                              {authCtx.user.sector !== "PK 82" ? (
                                <>
                                  <strong> | </strong>
                                  <strong dir="rtl">فون :</strong>
                                  <span>
                                    {item.voter_phone
                                      ? (typeof item.voter_phone === "string" &&
                                        item.voter_phone !== "null"
                                          ? JSON.parse(item.voter_phone)
                                          : Array.isArray(item.voter_phone)
                                          ? item.voter_phone
                                          : []
                                        )
                                          .filter(
                                            (item, index, self) =>
                                              self.indexOf(item) === index
                                          )
                                          .map((phone) => (
                                            <span className="mr-2">
                                              0{phone},
                                            </span>
                                          ))
                                      : "--"}
                                  </span>
                                </>
                              ) : (
                                ""
                              )}
                            </span>
                          </td>
                        ) : null}
                      </tr>
                    </>
                  ))
                ) : (
                  <ReactLoading
                    color={"gray"}
                    height={"40px"}
                    width={"70px"}
                    className="my-2 justify-center"
                  />
                )}
              </tbody>
            </table>
          )}

          <Modal isOpen={isModalOpen} onClose={closeModal}>
            <div className="text-[20px] font-bold">
              You can not download more pdfs
            </div>
            <div className="text-[15px] text-gray-700 font-bold">
              For more information please contact us.
            </div>
          </Modal>
        </div>
      </ListingTemplate>
    </>
  );
});

const BlockcodeAgeGraph = ({ blockCode }) => {
  const authCtx = useContext(AuthContext);
  const [ageGraphData, setAgeGraphData] = useState(null);

  useEffect(() => {
    getDataAgeWise().then((res) => {
      if (res.status === 200) {
        const d = [];
        res.report
          .sort((a, b) => a.age - b.age)
          .map((item) => {
            d.push([item.age, item.count]);
          });
        setAgeGraphData(d);
      }
    });
  }, []);

  const getDataAgeWise = async () => {
    let res = await axios(
      `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/age-report?sector=${authCtx.user.sector}&block_code=${blockCode}`
    );
    return res.data;
  };

  return (
    <div>
      {ageGraphData && (
        <>
          <Chart
            chartType="Bar"
            data={[["Age", "Count"], ...ageGraphData]}
            options={{
              hAxis: {
                title: "Count",
              },
              vAxis: {
                title: "Age",
              },
            }}
            width={"100%"}
            height={"200px"}
          />
        </>
      )}
    </div>
  );
};

export default GharanaVoterList;
