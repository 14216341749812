import axios from "axios";

import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import AuthContext from "../../../../contexts/AuthContext";
import Button from "../../../basic/Button";
import TextInputField from "../../../basic/TextInputField";
import ListingTemplate from "../ListingTemplate";
// import AppImages from "../../../assets/images";
// import AuthContext from "../../../contexts/AuthContext";
// import { Api } from "../../../utils/api";
// import { isEmail } from "../../../utils/helpers";
// import Button from "../../basic/Button";
// import TextInputField from "../../basic/TextInputField";
// import AuthTemplate from "../../common/AuthTemplate";
// import {
//   customerActions,
//   customerThunk,
// } from "../Organization/reducers/customer_slice";
// import { AuthApi } from "./api/AuthApi";

function AddPollingScheme() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //   const authCtx = useContext(AuthContext);
  const authCtx = useContext(AuthContext);
  let na = authCtx.user.sector;
  console.log("Authcontext", authCtx);
  const [formFields, setFormFields] = useState({
    sn: "",
    ward: "",
    gt: "",
    psa: "",
    bca: "",
    bc: "",
    psau: "",
    mb: "",
    fb: "",
    tb: "",
  });
  const [formErrors, setFormErrors] = useState({
    sn: null,
    ward: null,
    gt: null,
    psa: null,
    bca: null,
    bc: null,
    psau: null,
    mb: null,
    fb: null,
    tb: null,
  });
  const [showLoader, setShowLoader] = useState(false);
  const [showableData, setShowableData] = useState([]);
  const [data, setData] = useState("");
  const [message, setMessage] = useState("");
  useEffect(() => {
    axios
      .get(
        `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/geo-location-polling-station?sector=${authCtx.user?.sector}`
      )
      .then((res) => {
        console.log(res.data);
        if (res.data.status === 200) {
          //   setData(res.data.polling_scheme);
          setShowableData(res.data.polling_scheme);
        }
      });
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("FormFields", formFields);
    let na = authCtx.user.sector;
    let id = authCtx.user.id;
    let party = authCtx.user.party;
    let packagee = authCtx.user.package;
    const result = await axios.post(
      `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/add-polling-scheme?sn=${formFields.sn}&ward=${formFields.ward}&gt=${formFields.gt}&psa=${formFields.psa}&bcs=${formFields.bca}&bc=${formFields.bc}&psau=${formFields.psau}&mb=${formFields.mb}&fb=${formFields.fb}&tb=${formFields.tb}`
    );
    const url = `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/add-polling-scheme?sn=${formFields.sn}&ward=${formFields.ward}&gt=${formFields.gt}&psa=${formFields.psa}&phone=${formFields.phone}&ht=${authCtx.user.sector}&pe=${formFields.previousexperience}&comment=${formFields.comment}&pb=${formFields.pollingboth}`;
    console.log("URL", url);
    console.log("Resultttttttttt", result);
    setData(result.data);
    setMessage(result.data.message);
  };
  console.log("Dataaaa", data);
  const selecttype = [
    {
      value: "1",
      text: "User",
    },
    {
      value: "2",
      text: "Worker",
    },
  ];
  return (
    // <AuthTemplate>
    <ListingTemplate>
      <form
        onSubmit={handleSubmit}
        className="max-w-md mx-auto my-5 gap-2 h-[70vh] justify-between flex flex-col"
      >
        <div>
          <TextInputField
            type={"number"}
            style={{
              width: "w-full",
              labelFontSize: "text-[20px]",
              inputFontSize: "text-[18px]",
            }}
            label="Serial Number"
            onChange={(e) => {
              setFormErrors((old) => ({ ...old, sn: null }));
              setFormFields((old) => ({ ...old, sn: e?.target?.value }));
            }}
            value={formFields.sn}
            error={formErrors.sn}
            isRequired={true}
            placeholder={"Enter Serial Number"}
          />

          <TextInputField
            type={"text"}
            style={{
              width: "w-full",
              labelFontSize: "text-[20px]",
              inputFontSize: "text-[18px]",
            }}
            label="Ward"
            onChange={(e) => {
              setFormErrors((old) => ({ ...old, ward: null }));
              setFormFields((old) => ({ ...old, ward: e?.target?.value }));
            }}
            value={formFields.ward}
            error={formErrors.ward}
            isRequired={true}
            placeholder={"Enter Ward"}
          />

          <TextInputField
            type={"text"}
            style={{
              width: "w-full",
              labelFontSize: "text-[20px]",
              inputFontSize: "text-[18px]",
            }}
            label="Gender"
            onChange={(e) => {
              setFormErrors((old) => ({ ...old, gt: null }));
              setFormFields((old) => ({ ...old, gt: e?.target?.value }));
            }}
            value={formFields.gt}
            error={formErrors.gt}
            isRequired={true}
            placeholder={"Enter Gender"}
          />

          <TextInputField
            type={"text"}
            style={{
              width: "w-full",
              labelFontSize: "text-[20px]",
              inputFontSize: "text-[18px]",
            }}
            label="Polling Station Area"
            onChange={(e) => {
              setFormErrors((old) => ({ ...old, psa: null }));
              setFormFields((old) => ({ ...old, psa: e?.target?.value }));
            }}
            value={formFields.psa}
            error={formErrors.psa}
            isRequired={true}
            placeholder={"Enter Polling Station Area"}
          />

          <TextInputField
            type={"text"}
            style={{
              width: "w-full",
              labelFontSize: "text-[20px]",
              inputFontSize: "text-[18px]",
            }}
            label="Block Code Area"
            onChange={(e) => {
              setFormErrors((old) => ({ ...old, bca: null }));
              setFormFields((old) => ({ ...old, bca: e?.target?.value }));
            }}
            value={formFields.bca}
            error={formErrors.bca}
            isRequired={true}
            placeholder={"Enter Block Code Area"}
          />

          <TextInputField
            type={"text"}
            style={{
              width: "w-full",
              labelFontSize: "text-[20px]",
              inputFontSize: "text-[18px]",
            }}
            label="Block Code"
            onChange={(e) => {
              setFormErrors((old) => ({ ...old, bc: null }));
              setFormFields((old) => ({ ...old, bc: e?.target?.value }));
            }}
            value={formFields.bc}
            error={formErrors.bc}
            isRequired={true}
            placeholder={"Enter Block Code"}
          />

          <TextInputField
            type={"text"}
            style={{
              width: "w-full",
              labelFontSize: "text-[20px]",
              inputFontSize: "text-[18px]",
            }}
            label="Polling Station Area Urdu"
            onChange={(e) => {
              setFormErrors((old) => ({ ...old, psau: null }));
              setFormFields((old) => ({ ...old, psau: e?.target?.value }));
            }}
            value={formFields.psau}
            error={formErrors.psau}
            isRequired={true}
            placeholder={"Enter Polling Station Area Urdu"}
          />

          <TextInputField
            type={"text"}
            style={{
              width: "w-full",
              labelFontSize: "text-[20px]",
              inputFontSize: "text-[18px]",
            }}
            label="Male Both"
            onChange={(e) => {
              setFormErrors((old) => ({ ...old, mb: null }));
              setFormFields((old) => ({ ...old, mb: e?.target?.value }));
            }}
            value={formFields.mb}
            error={formErrors.mb}
            isRequired={true}
            placeholder={"Enter Male Both"}
          />

          <TextInputField
            type={"text"}
            style={{
              width: "w-full",
              labelFontSize: "text-[20px]",
              inputFontSize: "text-[18px]",
            }}
            label="Female both"
            onChange={(e) => {
              setFormErrors((old) => ({ ...old, fb: null }));
              setFormFields((old) => ({ ...old, fb: e?.target?.value }));
            }}
            value={formFields.fb}
            error={formErrors.fb}
            isRequired={true}
            placeholder={"Enter Female Both"}
          />

          <TextInputField
            type={"text"}
            style={{
              width: "w-full",
              labelFontSize: "text-[20px]",
              inputFontSize: "text-[18px]",
            }}
            label="Total Both"
            onChange={(e) => {
              setFormErrors((old) => ({ ...old, tb: null }));
              setFormFields((old) => ({ ...old, tb: e?.target?.value }));
            }}
            value={formFields.tb}
            error={formErrors.tb}
            isRequired={true}
            placeholder={"Enter Total Both"}
          />

          <button className="w-[40%] text-[18px] mt-2 h-[45px] float-right bg-slate-400 hover:bg-slate-500 text-white rounded-md">
            Add new
          </button>
          {data.status == "200" ? (
            <div className="text-green-500 font-bold">Your Data was added</div>
          ) : (
            <div className="text-red-500 font-bold">
              Your Data was not added
            </div>
          )}
        </div>
        {message == "" ? null : (
          <div className="text-red-500 font-bold">{message}</div>
        )}
      </form>
    </ListingTemplate>
    // </AuthTemplate>
  );
}

export default AddPollingScheme;
