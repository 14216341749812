import React, { useState } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import ReactLoading from "react-loading";
import ReactToPrint from "react-to-print";

export default function DownloadPdf({
  rootElementId,
  downloadFileName,
  show,
  shows,
  showss,
  showsss,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const handleClick = async () => {
    setIsLoading(true);
    console.log("loading");
    const input = document.getElementById(rootElementId);
    const canvas = await html2canvas(input);
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("l", "pt", "a2");
    pdf.setDisplayMode("10%", "two", "FullScreen");
    await pdf.html(input);
    document.getElementById(show).style.display = "none";
    document.getElementById(shows).style.display = "none";
    document.getElementById(showss).style.display = "none";
    document.getElementById(showsss).style.display = "none";
    // pdf.save(Date.now())
    window.print();
    document.getElementById(show).style.display = "block";
    document.getElementById(shows).style.display = "block";
    document.getElementById(showss).style.display = "block";
    document.getElementById(showsss).style.display = "block";
    setIsLoading(false);
  };
  const downloadFileDocument = () => {
    const input = document.getElementById(rootElementId);
    setIsLoading(true);

    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("l", "pt", "a2");
        pdf.setDisplayMode("10%", "two", "FullScreen");
        // pdf.addImage(imgData,"JPEG",0,0)
        // pdf.save(`${downloadFileName}`)
        pdf.html(document.getElementById(rootElementId)).then(() => {
          document.getElementById(show).style.display = "none";
          document.getElementById(shows).style.display = "none";
          document.getElementById(showss).style.display = "none";
          document.getElementById(showsss).style.display = "none";
          // pdf.save(Date.now())
          window.print();
          document.getElementById(show).style.display = "block";
          document.getElementById(shows).style.display = "block";
          document.getElementById(showss).style.display = "block";
          document.getElementById(showsss).style.display = "block";
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return (
    <div>
      {/* <h1>This is a Download pdf Componenet</h1> */}
      {!isLoading ? (
        <button
          onClick={handleClick}
          className="border-[1px] border-gray-400 rounded-md px-1 hover:bg-slate-200"
        >
          Download PDF
        </button>
      ) : (
        <div className="flex justify-center">
          <ReactLoading
            color={"gray"}
            height={"20px"}
            width={"40px"}
            className="my-1 ml-2"
          />
          <div className="mt-2 ml-2 text-[20px] font-bold text-green-500 ">
            Printing in progress. Please wait for a while ...
          </div>
        </div>
      )}
    </div>
  );
}
