import { useContext } from "react";
import { Navigate, Outlet, useLocation, useParams } from "react-router-dom";
import AuthContext from "../../contexts/AuthContext";
import Custom from "../modules/Auth/Custom";

function PrivateRouteWrapper() {
    // let { sector } = useParams();
    const location = useLocation();
    let sector = localStorage.getItem("sector");
    const authCtx = useContext(AuthContext)
    if(!authCtx.isLoggedIn) {
        if (
          location.pathname == "/" &&
          window.location.host == "www.pppelections.co"
        ) {
          return <Custom />;
        }
        return <Navigate to={`/login/${sector}`} />;
    }
    return <Outlet />;
}

export default PrivateRouteWrapper;