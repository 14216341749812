import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { SettingsContextProvider } from './contexts/SettingsContext';
import { AuthContextProvider } from './contexts/AuthContext';
import ReactModal from 'react-modal';
import DataContext, { DataContextProvider } from './contexts/DataContext';
import { Provider } from "react-redux";
import store from './store';
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
const root = ReactDOM.createRoot(document.getElementById('root'));
const queryClient = new QueryClient();
ReactModal.setAppElement('#root')
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <AuthContextProvider>
      <SettingsContextProvider>
        <DataContextProvider>
          <QueryClientProvider client={queryClient}>
            <App />
          </QueryClientProvider>
          {/* <h1>gel</h1> */}
        </DataContextProvider>
      </SettingsContextProvider>
    </AuthContextProvider>
  </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
