import AppImages from "../../../assets/images";
import Header from "../../common/header/Header";
import Sidebar from "../../common/sidebar/Sidebar";

function ListingTemplate1({
  children,
  title = "",
  contentTitle = null,
  headerSubtitle = null,
  onSearch = null,
  buttonTitle = null,
  onButtonClick = null,
  selectBoxLabel = "",
  selectItems = [],
  onSelect = null,
}) {
  return (
    <div className="bg-white">
      <div className="layout w-screen flex dashboard">
        <div className="flex-[1_1_20%] mr-2">
          <Sidebar />
        </div>

        <div id="dash-container" className="flex-[1_1_80%]">
          <Header title={title} subtitle={headerSubtitle} />

          <div id="column-container">
            <div className="bg-white w-full flex-[1_1_70%] px-5 py-10 mt-3">
              <div className="flex justify-between items-end">
                <div className="">
                  <h2 className="font-semibold text-[20px] text-black">
                    {contentTitle ?? title}
                  </h2>
                </div>
                <div className="flex items-end">
                  <div className="relative ml-[126px]">
                    <input
                      type="text"
                      className="border-[1px] border-black rounded-[6px] pr-3 pl-8 py-2 text-[10px] min-w-[250px]"
                      placeholder="Search"
                      onChange={onSearch}
                    />
                    <span className="absolute left-4 top-[50%] translate-x-[-50%] translate-y-[-50%]">
                      <img src={AppImages.search} className="w-4" />
                    </span>
                  </div>
                  {/* <div className="ml-6">
                    <p className="text-[10px] font-bold">{selectBoxLabel}</p>
                    <select
                      name=""
                      id=""
                      className="py-2 px-2 border-[1px] border-black rounded-[6px] text-[10px] w-[120px]"
                      onChange={onSelect}
                    >
                      {selectItems.map((item, index) => (
                        <option key={index} value={item.value}>
                          {item.text}
                        </option>
                      ))}
                    </select>
                  </div> */}

                  {/* {buttonTitle && (
                    <div className="ml-26">
                      <button
                        className="bg-primary text-white text-[10px] rounded-lg px-4 py-2 hover:bg-black"
                        onClick={onButtonClick}
                      >
                        {buttonTitle}
                      </button>
                    </div>
                  )} */}
                </div>
              </div>
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ListingTemplate1;
