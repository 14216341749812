import { useContext, useRef, useState } from "react";
import AppImages from "../../../assets/images";
import AuthContext from "../../../contexts/AuthContext";
import { useOutsideAlerter } from "../../hooks/useOutsideAlerter";
import HeaderMenu from "./HeaderMenu";

function Header({
    title,
    subtitle,
    beforeNotification = null,
}) {
    const [isShown, setIsShown] = useState(false)
    const handleClickOnAvatar = (e) => {
        console.log('hey')
        setIsShown(!isShown)
    }
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, () => {
        setIsShown(false);
    });
    const authCtx = useContext(AuthContext);
    // console.log("Authcontext", authCtx);
    return (
      <div
        // className=
        className={
          authCtx.user.party === "PMLN"
            ? "flex justify-between items-start bg-white text-gray-500 py-2 h-[10%] px-4"
            : authCtx.user.party === "PTI"
            ? "flex justify-between items-start bg-white text-gray-500 pt-2 h-[10%] px-4"
            : authCtx.user.party === "PPP"
            ? "flex justify-between items-start bg-white text-gray-500 pt-2 h-[10%] px-4"
            : "flex justify-between items-start bg-white text-gray-500 pt-2 h-[10%] px-4"
        }
      >
        {authCtx.user.party === "PTI" ? (
          <div className="flex items-center">
            <div className="relative px-3">
              <img
                className="w-10 h-10 rounded-full cursor-pointer bg-gray-100  shadow-md"
                src={authCtx.user.party_image}
                alt="Rounded avatar"
                onClick={handleClickOnAvatar}
              />
              {isShown && (
                <span ref={wrapperRef}>
                  <HeaderMenu />
                </span>
              )}
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="w-[90%] text-white h-[90%] rounded-2xl text-[16px] font-bold pt-1 "></div>
        {authCtx.user.party === "PTI" ? (
          ""
        ) : (
          <div className="flex items-center">
            <div className="relative px-3">
              {authCtx.user.sector === "PP 160" &&
              authCtx.user.party === "PMLN" ? (
                <img
                  className="w-10 h-10 rounded-full cursor-pointer bg-gray-100  shadow-md"
                  src={AppImages.asad}
                  alt="Rounded avatar"
                  onClick={handleClickOnAvatar}
                />
              ) : (
                <img
                  className="w-10 h-10 rounded-full cursor-pointer bg-gray-100  shadow-md"
                  src={authCtx.user.party_image}
                  alt="Rounded avatar"
                  onClick={handleClickOnAvatar}
                />
              )}
              {isShown && (
                <span ref={wrapperRef}>
                  <HeaderMenu />
                </span>
              )}
            </div>
          </div>
        )}
      </div>
    );
}

export default Header;