import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Api } from "../../../../utils/api";
import { RequestStatus } from "../../../../utils/enums";

const initialState = {
    data: [],
    activeunavailabilityData: null,
    fetchDataRequestStatus: RequestStatus.IDLE,
}

export const unavailabilityThunk = {
    getunavailabilitys: createAsyncThunk('/unavailability/getAllunavailabilitys', async (data, thunkAPI) => {
        const { fulfillWithValue, rejectWithValue } = thunkAPI
        try {
            const res = await Api.get({path: `/admin/unavails/`})
            console.log("Response",res)
            if(res.success) {
                
                return fulfillWithValue({
                    data: res.data.data,
                    activeunavailabilityId: data?.activeunavailabilityId
                })
            } else {
                throw res.message
            }
        } catch(e) {
            return rejectWithValue({
                error: true,
                message: typeof e === 'string' ? e : 'Something went wrong',
                exception: e
            })
        }
    }),
}
const unavailabilitySlice = createSlice({
    name: 'unavailability',
    initialState,
    reducers: {
      addunavailability: (state, action) => {
            const {unavailability} = action.payload 
            if(unavailability) {
                state.data.push(unavailability)   
            }
        },
         deleteunavailability: (state, action) => {
            const { unavailabilityId } = action.payload
            console.log(1)
            const unavailability = state.data.find(item => item.id === unavailabilityId)
            if(unavailability) {
                console.log(22)
                state.data = state.data.filter(item => item.id !== unavailabilityId)
            }
            console.log(3)
        },
          updateunavailability: (state, action) => {
            const { unavailability, unavailabilityId } = action.payload 
            console.log(1)
            if(unavailability) {
                console.log(2)
                const found = state.data.find(item => item.id === unavailabilityId)
                const index = state.data.indexOf(found)
                console.log('index', index)
                state.data[index] = unavailability 
                state.activeunavailabilityData = unavailability
            }
            console.log(3)
        },
    },

    extraReducers: (builder) => builder
        .addCase(unavailabilityThunk.getunavailabilitys.fulfilled, (state, action) => {
            state.data = action.payload.data ?? []
            if(action.payload.activeunavailabilityId) {
                const activeunavailabilityData = state.data.find(item => item.id === action.payload.activeunavailabilityId)
                if(activeunavailabilityData) {
                    state.activeunavailabilityData = activeunavailabilityData
                }
            }
            state.fetchDataRequestStatus = RequestStatus.COMPLETED
        })
        .addCase(unavailabilityThunk.getunavailabilitys.rejected, (state, action) => {
            state.fetchDataRequestStatus = RequestStatus.ERROR
        })
        .addCase(unavailabilityThunk.getunavailabilitys.pending, (state, action) => {
            state.fetchDataRequestStatus = RequestStatus.LOADING
        })

        
})
export const unavailabilityActions = unavailabilitySlice.actions
export default unavailabilitySlice.reducer