import React from 'react'

const NonLogin = () => {
  return (
    <div className=" bg-slate-100 w-full h-[690px] flex ">
      <div className="justify-center rounded-md pt-4 pl-2 bg-slate-300 cursor-pointer border-gray-400 border-[1px] h-[200px] w-[23%] mt-[15%] shadow-xl ml-[41%] text-[20px] font-bold text-green-500">
        Please Enter a correct url !
        <div className="text-[12px] pt-1 text-white">
          Url you entered is incorrect so that's why you don't move on login
          screen. So please check url again.{" "}
          <span className="text-green-500">(Thank You)</span>
        </div>
      </div>
    </div>
  );
}

export default NonLogin