import { createContext, useState } from "react";
import { ModuleData } from "../components/modules/mod.data";

const SettingsContext = createContext({
    activeModuleId: 1,
    setActiveModuleId: (id) => {},
    modules: [],
    setModules: (arr) => {}
})

export const SettingsContextProvider = ({ children }) => {
    const [activeModuleId, setActiveModuleId] = useState(1)
    
    const value = {
        activeModuleId: activeModuleId,
        setActiveModuleId: (id) => {
            setActiveModuleId(id)
        },
        modules: ModuleData
    }
    return (
        <SettingsContext.Provider value={value}>
            {children}
        </SettingsContext.Provider>
    )
}

export default SettingsContext