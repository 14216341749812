import { useContext } from "react";
import { useNavigate, useParams } from "react-router";
import AppImages from "../../../assets/images";
import DataContext from "../../../contexts/DataContext";
import Button from "../../basic/Button";
import SingleViewTemplate from "../../common/SingleViewTemplate";

function ViewRole() {
    const navigate = useNavigate()
    const { id } = useParams()
    const dataCtx = useContext(DataContext)
    const role = dataCtx.roles.data.find(role => role.id === parseInt(id))
    
    return ( 
        <>
            <SingleViewTemplate
                title={role.label}
                avatarUrl={AppImages.user}
                items={[
                    // { name: 'Email', value: 'example@gmail.com' },
                    { name: 'Role', value: role.name },
                    // { name: 'Phone', value: '000-1234-567' },
                    // { name: 'Gender', value: 'Male' },
                ]}
                rightButtons={
                    <div className="relative my-4">
                        <div 
                            className="bg-primary rounded-lg absolute right-10 p-2 cursor-pointer hover:bg-black"
                            onClick={e => {
                                navigate(`/roles/${id}/edit`)
                            }}
                        >
                            <img 
                                src={AppImages.editWhite}
                                alt="close"
                                className="w-[13px] h-[13px]"
                            />
                        </div>
                        <div 
                            className="bg-primary rounded-lg absolute right-0 p-2 cursor-pointer hover:bg-black"
                            onClick={e => {
                                navigate('/roles')
                            }}
                        >
                            <img 
                                src={AppImages.crossWhite}
                                alt="close"
                                className="w-[13px] h-[13px]"
                            />
                        </div>
                    </div>
                }
            >  
            </SingleViewTemplate>
        </>
    );
}

export default ViewRole;