import { configureStore } from '@reduxjs/toolkit'
import customerReducer from "./components/modules/Organization/reducers/customer_slice"
import siteReducer from "./components/modules/Organization/reducers/site_slice"
import employeeReducer from "./components/modules/Organization/reducers/employee_slice"
// import staffReducer from "./components/modules/Staff/staff_slice"
import userRoleReducer from "./components/modules/RolesAndPermissions/user_roles_slice"
import incidentReducer from "./components/modules/Organization/reducers/inident_slice"
import unavailabilityReducer from './components/modules/Organization/reducers/unavailability_slice'
import schedulesReducer from './components/modules/Organization/reducers/schedule_slice'


const store = configureStore({
    reducer: {
        // staff: staffReducer,
        customers: customerReducer,
        sites: siteReducer,
        employees: employeeReducer,
        userRoles: userRoleReducer,
        incidents: incidentReducer,
        unavailability: unavailabilityReducer,
        schedules: schedulesReducer,
    },
})

export default store