import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import AppImages from "../../../assets/images";
import AuthContext from "../../../contexts/AuthContext";
import SettingsContext from "../../../contexts/SettingsContext";
import { ModuleData } from "../../modules/mod.data"; 

export const Sidebar = () => {
  //  let { sector } = useParams();
  let sector = localStorage.getItem("sector");
  const navigate = useNavigate()
  const authCtx = useContext(AuthContext)
  const settings = useContext(SettingsContext)
  const [modules, setModules] = useState([]);
  const [activeSubmenuItemId, setActiveSubmenuItemId] = useState(null)

  const location = useLocation()
  //  console.log("Authctx sidebar",authCtx)
    useEffect(() => {

      // if(authCtx.user.party === 'PAKISTAN') {
        let allmodules = ModuleData.filter(
          (mod) =>
            mod.isAdminOnly === true &&
            (mod.package === -1 ||
              parseInt(authCtx.user.package) >= mod.package)
        );
        if (authCtx.user?.sector === "NA 127" && authCtx.user?.admin === "1") {
          allmodules.splice(
            allmodules.findIndex((item) => item.id == 8),
            0,
            {
              id: 1000,
              sidebar: {
                title: "Premium Voters",
                icon: AppImages.logout,
                inactiveIcon: AppImages.bell,
              },
              isAdminOnly: true,
              route: "/paid/voter/list"
            }
          );
        
        }
        setModules(
          allmodules
        );


        // setModules(ModuleData.filter(item => item.package === 1))
      // } else if (authCtx.user.party === 'NLG') {
      //   setModules(ModuleData.filter((mod) => mod.isCustomerOnly === true));
      // }
    }, [])

    useEffect(() => {
      if(modules.length !== 0) {
        console.log(location.pathname)
        const currentItem = modules.find(mod => mod.route && mod.route === location.pathname)
        if(currentItem) {
          settings.setActiveModuleId(currentItem.id)
        } else {
          const currentSubItem = modules.filter(mod => mod.submenu).find(mod => {
            const r = mod.submenu.find(sub => sub.route === location.pathname)
            if(r) {
              setActiveSubmenuItemId(r.id)
            }
            return r 
          })
          if(currentSubItem) {
            settings.setActiveModuleId(currentSubItem.id)
          }
        }
      }
    }, [location.pathname])

    const handleToggleActive = (id) => {
      settings.setActiveModuleId(id)
    };

    // console.log(activeSubmenuItemId);

  return (
    <aside
      className={
        authCtx.user.party === "PMLN"
          ? "shadow-sm border bg-blue-100 min-h-screen flex-[1_1_20%] h-[100%]"
          : authCtx.user.party === "PTI"
          ? "shadow-sm border bg-[#4f8772] min-h-screen flex-[1_1_20%] h-[100%]"
          : authCtx.user.party === "PPP"
          ? "shadow-sm border bg-slate-100 min-h-screen flex-[1_1_20%] h-[100%]"
          : "shadow-sm border bg-gray-100 min-h-screen flex-[1_1_20%] h-[100%]"
      }
      // className="shadow-sm border bg-gray-100 min-h-screen flex-[1_1_20%] h-[100%]"
      aria-label="Sidebar"
    >
      <div className="flex flex-col items-center space-y-2 py-8 px-4 ">
        <Link to="/">
          {/* {authCtx.user.party === "PPP" ? (
            <div className="w-[72px] h-[72px]">
              <img
                className=" rounded-full bg-gray-400 w-full h-full object-contain"
                src={
                  "https://www.pngkit.com/png/full/813-8134385_pakistan-people-party-logo.png"
                }
                alt="Rounded avatar"
              />
            </div>
          ) : ( */}
          {/* "https://upload.wikimedia.org/wikipedia/en/thumb/0/06/Pakistan_Tehreek-e-Insaf_logo.svg/1200px-Pakistan_Tehreek-e-Insaf_logo.svg.png" */}

          <div className="w-[72px] h-[72px]">
            {authCtx.user.sector === "PP 160" &&
            authCtx.user.party === "PMLN" ? (
              <img
                className=" rounded-full bg-gray-400 w-full h-full object-contain"
                src={AppImages.asad}
                alt="Rounded avatar"
              />
            ) : (
              <img
                className=" rounded-full bg-gray-400 w-full h-full object-contain"
                src={authCtx.user.party_image}
                alt="Rounded avatar"
              />
            )}
          </div>
          {/* )} */}
        </Link>
        <h2 className="font-bold text-sm">{authCtx.user.sector}</h2>
      </div>

      {/* Navigation */}
      <ul className="pt-4">
        {modules.map((item, i) => (
          <li
            onClick={() => {
              console.log("clicked");

              // if(item.id === 10) {
              //   window.open('http://c.plabesk.com.s3-website-eu-west-1.amazonaws.com/bogus.html')
              //   return;
              // }
              if (item.id === 7) {
                window.open(
                  "https://s3.eu-west-1.amazonaws.com/c.plabesk.com/graph+2/graph.html"
                );
                return;
              }
              if (item.id === 22) {
                window.open(
                  "http://c.plabesk.com.s3-website-eu-west-1.amazonaws.com/graph%202/graph2.html"
                );
                return;
              }
              if (item.id === 8) {
                authCtx.logout();
                console.log("Sectorrrr", sector);
                navigate(`/login/${sector}`);
                return;
              }
              if (item.route) {
                navigate(item.route);
              }
              handleToggleActive(item.id);
            }}
            className={
              authCtx.user.party === "PMLN"
                ? `p-2 cursor-pointer ${
                    item.id === settings.activeModuleId &&
                    "bg-gray-300 text-black font-bold"
                  }`
                : authCtx.user.party === "PTI"
                ? `p-2 cursor-pointer ${
                    item.id === settings.activeModuleId &&
                    "bg-white text-black font-bold"
                  }`
                : authCtx.user.party === "PPP"
                ? `p-2 cursor-pointer ${
                    item.id === settings.activeModuleId &&
                    "bg-gray-300 text-black font-bold"
                  }`
                : `p-2 cursor-pointer ${
                    item.id === settings.activeModuleId &&
                    "bg-gray-300 text-black font-bold"
                  }`
            }
            // className={`p-2 cursor-pointer ${
            //   item.id === settings.activeModuleId &&
            //   "bg-gray-300 text-black font-bold"
            // }`}
            key={i}
          >
            <div className="flex items-center">
              <span className="block mr-4">
                <img
                  className="w-6 h-6"
                  src={
                    item.id === settings.activeModuleId
                      ? item.sidebar.inactiveIcon
                      : item.sidebar.inactiveIcon
                      ? item.sidebar.inactiveIcon
                      : item.sidebar.inactiveIcon
                  }
                  alt={item.sidebar.title}
                />
              </span>
              <span
                className={
                  authCtx.user.party === "PTI"
                    ? `text-[14px] flex-1 text-white text-left ${
                        item.id === settings.activeModuleId
                          ? "font-bold text-black"
                          : ""
                      }`
                    : `text-[14px] flex-1 text-left ${
                        item.id === settings.activeModuleId ? "font-bold" : ""
                      }`
                }
              >
                {item.sidebar.title}
              </span>
              {item.submenu && (
                <button
                  className="cursor pointer block"
                  //   onClick={(/) => handleToggleSubmenu(i)}
                >
                  <img
                    className="w-[11px] h-[8px] rotate-[180deg]"
                    src={
                      settings.activeModuleId === item.id && item.submenu
                        ? AppImages.caretUpActive
                        : AppImages.caretUpInactive
                    }
                    alt={"submenu"}
                  />
                </button>
              )}
            </div>

            {item.submenu && settings.activeModuleId === item.id && (
              <ul className="space-y-2 pl-10 mt-2 transition-all">
                {item?.submenu.map((submenu) => (
                  <li
                    key={submenu.id}
                    className="flex px-2 py-2"
                    onClick={(e) => {
                      if (submenu.id === 20 || submenu.id === 21) {
                        window.open(
                          "https://s3.eu-west-1.amazonaws.com/c.plabesk.com/graph+2/graph3+(1).html"
                        );
                        return;
                      }
                      if (submenu.id === 22) {
                        window.open(
                          "https://s3.eu-west-1.amazonaws.com/c.plabesk.com/graph+2/graph4.html"
                        );
                        return;
                      }
                      if (submenu.route) {
                        navigate(submenu.route);
                      }
                    }}
                  >
                    {submenu.icon && (
                      <img
                        width="20"
                        height="20"
                        src={submenu.icon}
                        className="mr-4"
                      />
                    )}
                    <span
                      className={`text-[14px] ${
                        location.pathname == submenu.route
                          ? "font-bold"
                          : "font-normal"
                      }`}
                    >
                      {submenu.title}
                    </span>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </aside>
  );
};

export default Sidebar;
