import axios from "axios";
import { addDoc, USER_LOGIN } from "firebase/firestore";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { Link, useNavigate, useParams } from "react-router-dom";
import { Link, useNavigate, useParams } from "react-router-dom";
import AppImages from "../../../assets/images";
import AuthContext from "../../../contexts/AuthContext";
import { encode, decode } from "base-64";
import { isEmail } from "../../../utils/helpers";

import TextInputField from "../../basic/TextInputField";
// import Shimmer from "react-shimmer-effect";
import { AuthApi } from "./api/AuthApi";

function LoginScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { hostname } = window.location;
  console.log("HostName",hostname)
  let { sector } = useParams();
  console.log("sector", sector);
  const decodedSector = decode(sector);
  if (sector) {
    localStorage.setItem("sector", sector);
  }
  console.log("Decoded Sector:", decodedSector);
  const authCtx = useContext(AuthContext);
  const [formFields, setFormFields] = useState({
    email: "",
    password: "",
  });
  const [formErrors, setFormErrors] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    let hasErrors = false;
    const isValidEmail = isEmail(formFields.email);
    if (typeof isValidEmail === "string") {
      setFormErrors((old) => ({ ...old, email: isValidEmail }));
      hasErrors = true;
    }
    if (formFields.password.toString().trim().length === 0) {
      setFormErrors((old) => ({ ...old, password: "Password is required" }));
      hasErrors = true;
    }
    if (hasErrors) {
      return;
    }
    setShowLoader(true);
    setFormErrors({ email: null, password: null, api: null });
    setError(null);
    AuthApi.login(formFields.email, formFields.password)
      .then((res) => {
        if (res.error) throw res;
        // console.log("User", res.data.user.id);
        // addDoc(res.data.response)

        console.log(res.data);
        console.log("*****************************");
        // return;
        if (!res.data.response) {
          setError("Your Password or email is Incorrect");
        }

        // if (res.data.response?.party === "PAKISTAN") {

        // FirebaseService.addLog({
        //   userId: res.data.response?.id,
        //   tag: "user_login",
        //   platform: "web",
        //   role: "manager"
        // });
        authCtx.login(res.data.response);
        navigate("/");
        // }
      })
      .catch((e) => {
        setFormErrors((old) => ({ ...old, api: e.message }));
        console.log("Error", e);
      })
      .finally(() => {
        setShowLoader(false);
      });
  };
  const [data, setData] = useState("");
  useEffect(() => {
    //   let na = authCtx.user.sector;

    const fetchData = async () => {
      const result = await axios(
        `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/halkaactivation?sector=${decodedSector}&domain=${hostname}`
      );
      console.log(
        `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/halkaactivation?sector=${decodedSector}&domain=${hostname}`
      );
      console.log("Resulttt", result);
      setData(result.data.voters);
    };
    fetchData();
  }, []);

  return (
    <div>
      {data === null ? (
        <div className=" bg-slate-100 w-full h-[690px] flex ">
          <div className="justify-center rounded-md pt-4 pl-2 bg-slate-300 cursor-pointer border-gray-400 border-[1px] h-[200px] w-[23%] mt-[15%] shadow-xl ml-[41%] text-[20px] font-bold text-green-500">
            Please Enter a correct url !
            <div className="text-[12px] pt-1 text-white">
              Url you entered is incorrect so that's why you don't move on login
              screen. So please check url again.{" "}
              <span className="text-green-500">(Thank You)</span>
            </div>
          </div>
        </div>
      ) : (
        <div>
          {data == "" ? (
            <div className="w-full">
              {/* <Shimmer> */}
              <div className="flex flex-row h-screen bg-white w-full">
                <div className="w-[50%] h-screen pt-5 ">
                  {/* <Shimmer>
                    <h1 className="justify-center ml-[320px] mt-9 h-[30px] w-[80px] rounded-md bg-slate-100 mb-2 text-[45px] tracking-tight font-bold font-sans2 text-black">
                  
                    </h1>
                  </Shimmer> */}
                  {/* <Shimmer>
                    <h4 className="text-center ml-[310px] mt-[12px] rounded-md h-[30px] w-[100px] bg-slate-100 mb-2 text-[22px] tracking-tight font-bold font-sans2 text-black"></h4>
                  </Shimmer> */}
                  <form
                    onSubmit={handleSubmit}
                    className="max-w-md mx-auto my-5 gap-2 py-[3%] h-[70vh] justify-between flex flex-col"
                  >
                    <div>
                      <div className="flex flex-col ">
                        {/* <Shimmer>
                          <div className="w-[100px] h-[30px] my-2 bg-slate-100 rounded-md"></div>
                        </Shimmer> */}
                        {/* <Shimmer>
                          <div className="w-[450px] h-[45px]  bg-slate-100 rounded-md"></div>
                        </Shimmer> */}
                      </div>
                      <div className="flex flex-col ">
                        {/* <Shimmer>
                          <div className="w-[100px] h-[30px] my-2 bg-slate-100 rounded-md"></div>
                        </Shimmer>
                        <Shimmer>
                          <div className="w-[450px] h-[45px]  bg-slate-100 rounded-md"></div>
                        </Shimmer> */}
                      </div>
                      {/* <Shimmer>
                        <div className="w-[30%] h-[20%] ml-[310px] bg-slate-100 text-[18px] rounded-md text-white hover:bg-slate-400 mt-4"></div>
                      </Shimmer> */}
                    </div>
                  </form>
                  {/* </AuthTemplate> */}
                </div>
                <div className="w-[50%] h-screen border-[1px] py-[5%] pl-[100px] border-gray-300 bg-gray-300 relative">
                  <div className="absolute top-[50%] flex flex-col left-[50%] translate-x-[-50%] translate-y-[-50%]">
                    {/* <Shimmer>
                      <div className="h-[200px] w-[200px] rounded-full bg-slate-300"></div>
                    </Shimmer>
                    <Shimmer> */}
                    {/* <div className="text-center text-[32px] ml-[47px] mt-4 h-[30px] w-[100px] bg-slate-300 text-white">
                  
                      </div>
                    </Shimmer> */}
                  </div>
                </div>
              </div>
              {/* </Shimmer> */}
            </div>
          ) : (
            <>
              {data?.description === "PPP" || data?.description === "PTI" ? (
                <div
                  // className=" h-screen w-full"
                  className={
                    data?.description === "PMLN"
                      ? "h-screen w-full border-[1px] py-[5%] pl-[100px] border-gray-300 bg-[#14A800] relative"
                      : data?.description === "PTI"
                      ? "w-full h-screen border-[1px] py-[5%] pl-[100px] border-gray-300 bg-gradient-to-r from-[#e3254f]  to-[#5dc43c]  relative"
                      : data?.description === "PPP"
                      ? "w-full h-screen border-[1px] py-[5%] pl-[100px] border-gray-300 bg-gradient-to-r from-[#d42323]  to-[#375d27] relative"
                      : "w-full h-screen border-[1px] py-[5%] pl-[100px] border-gray-300 bg-[#14A800] relative"
                  }
                >
                  <div className=" h-screen pt-5 ">
                    <div className="flex items-center justify-center">
                      <img
                        src={data.party_logo}
                        className="w-[100px] rounded-full"
                      />
                    </div>
                    <h1 className="text-center  mb-2 text-[45px] tracking-tight font-bold font-sans2 text-white">
                      {data.web_title}
                    </h1>
                    <h4 className="text-center mt-1 mb-2 text-[22px] tracking-tight font-bold font-sans2 text-white">
                      {data.candidate_name}
                    </h4>
                    <form
                      onSubmit={handleSubmit}
                      className="max-w-md mx-auto  gap-2   justify-between flex flex-col"
                    >
                      <div>
                        <TextInputField
                          type={"email"}
                          style={{
                            width: "w-full",
                            labelFontSize: "text-[20px]",
                            inputFontSize: "text-[18px]",
                          }}
                          label="Email"
                          onChange={(e) => {
                            // setFormErrors((old) => ({ ...old, email: null }));
                            setFormFields((old) => ({
                              ...old,
                              email: e?.target?.value,
                            }));
                          }}
                          value={formFields.email}
                          error={formErrors.email}
                          isRequired={true}
                          placeholder={"example@gmail.com"}
                        />
                        {formErrors.email && (
                          <p className="my-1 text-sm text-primary pl-4">
                            {error}
                          </p>
                        )}
                        <TextInputField
                          type="password"
                          style={{
                            width: "w-full",
                            labelFontSize: "text-[20px]",
                            inputFontSize: "text-[18px]",
                          }}
                          label="Password"
                          onChange={(e) => {
                            // setFormErrors((old) => ({ ...old, password: null }));
                            setFormFields((old) => ({
                              ...old,
                              password: e?.target.value,
                            }));
                          }}
                          value={formFields.password}
                          error={formErrors.password}
                          isRequired={true}
                          placeholder={"********"}
                        />
                        {/* {formErrors.password && ( */}
                        <p className="my-1 text-sm text-primary pl-4">
                          {error}
                        </p>
                        {/* )} */}
                        <div className="flex items-center justify-center mt-4">
                          {" "}
                          {/* Step 2: Centered button */}
                          <button className="w-[full] bg-[#2faa66] px-6 py-2 text-[18px] rounded-md text-white hover:bg-slate-400">
                            Sign in
                          </button>
                        </div>
                      </div>
                      {data?.description === "PMLN" ? (
                        <div className="text-white text-[50px] font-noori text-center">
                          خود دار خوشحال خود مختار پاکستان
                        </div>
                      ) : (
                        ""
                      )}
                    </form>
                  </div>
                  {/* <div
                // className="w-[50%] h-screen border-[1px] py-[5%] pl-[100px] border-gray-300 bg-[#14A800] relative"
                className={
                  data?.description === "PTI"
                    ? "w-[50%] h-screen border-[1px] py-[5%] pl-[100px] border-gray-300 bg-[#14A800] relative"
                    : data?.description === "PMLN"
                    ? "w-[50%] h-screen border-[1px] py-[5%] pl-[100px] border-gray-300 bg-[#ca4141] relative"
                    : data?.description === "PPP"
                    ? "w-[50%] h-screen border-[1px] py-[5%] pl-[100px] border-gray-300 bg-[#3d573a92] relative"
                    : "w-[50%] h-screen border-[1px] py-[5%] pl-[100px] border-gray-300 bg-[#14A800] relative"
                }
              >
                <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
                  <img src={data.party_logo} className="w-[400px]" />
                  <div className="text-center text-[32px] text-white">
                    {data.web_title}
                  </div>
                </div>
              </div> */}
                </div>
              ) : (
                <div className="flex flex-row h-screen">
                  <div className="w-[50%] h-screen pt-5 ">
                    <h1 className="text-center mt-9 mb-2 text-[45px] tracking-tight font-bold font-sans2 text-black">
                      {data.web_title}
                    </h1>
                    <h4 className="text-center mt-1 mb-2 text-[22px] tracking-tight font-bold font-sans2 text-black">
                      {data.candidate_name}
                    </h4>
                    <form
                      onSubmit={handleSubmit}
                      className="max-w-md mx-auto my-5 gap-2 py-[3%] h-[70vh] justify-between flex flex-col"
                    >
                      <div>
                        <TextInputField
                          type={"email"}
                          style={{
                            width: "w-full",
                            labelFontSize: "text-[20px]",
                            inputFontSize: "text-[18px]",
                          }}
                          label="Email"
                          onChange={(e) => {
                            // setFormErrors((old) => ({ ...old, email: null }));
                            setFormFields((old) => ({
                              ...old,
                              email: e?.target?.value,
                            }));
                          }}
                          value={formFields.email}
                          error={formErrors.email}
                          isRequired={true}
                          placeholder={"example@gmail.com"}
                        />
                        {formErrors.email && (
                          <p className="my-1 text-sm text-primary pl-4">
                            {error}
                          </p>
                        )}
                        <TextInputField
                          type="password"
                          style={{
                            width: "w-full",
                            labelFontSize: "text-[20px]",
                            inputFontSize: "text-[18px]",
                          }}
                          label="Password"
                          onChange={(e) => {
                            // setFormErrors((old) => ({ ...old, password: null }));
                            setFormFields((old) => ({
                              ...old,
                              password: e?.target.value,
                            }));
                          }}
                          value={formFields.password}
                          error={formErrors.password}
                          isRequired={true}
                          placeholder={"********"}
                        />
                        {/* {formErrors.password && ( */}
                        <p className="my-1 text-sm text-primary pl-4">
                          {error}
                        </p>
                        {/* )} */}
                        <button className="w-[30%] h-[20%] ml-[310px] bg-[#14A800] text-[18px] rounded-md text-white hover:bg-slate-400 mt-4">
                          Sign in
                        </button>
                      </div>
                    </form>
                  </div>
                  <div
                    // className="w-[50%] h-screen border-[1px] py-[5%] pl-[100px] border-gray-300 bg-[#14A800] relative"
                    className={
                      data?.description === "PTI"
                        ? "w-[50%] h-screen border-[1px] py-[5%] pl-[100px] border-gray-300 bg-[#14A800] relative"
                        : data?.description === "PMLN"
                        ? "w-[50%] h-screen border-[1px] py-[5%] pl-[100px] border-gray-300 bg-[#41ca7c] relative"
                        : data?.description === "PPP"
                        ? "w-[50%] h-screen border-[1px] py-[5%] pl-[100px] border-gray-300 bg-[#3d573a92] relative"
                        : "w-[50%] h-screen border-[1px] py-[5%] pl-[100px] border-gray-300 bg-[#14A800] relative"
                    }
                  >
                    <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
                      {data?.sector === "PP 160" &&
                      data?.description === "PMLN" ? (
                        <img src={AppImages.asad1} className="w-[400px]" />
                      ) : (
                        <>
                          {/* {data?.sector === "PP 160" &&
                          data?.description === "Haqooq-e-khalq" ? (
                            <img src={AppImages.speakerfirst} className="w-[400px]" />
                          ) : ( */}
                            <img src={data.party_logo} className="w-[400px]" />
                          {/* // )} */}
                        </>
                      )}
                      {data?.sector === "PP 160" &&
                      data?.description === "PMLN" ? (
                        ""
                      ) : (
                        <div className="text-center text-[32px] text-white">
                          {data.web_title}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default LoginScreen;
