import React, { useContext, useEffect, useState } from "react";
import ListingTemplate from "../ListingTemplate";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../../../contexts/AuthContext";
import Graph from "./Graph";
import axios from "axios";

const Search = () => {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  console.log("Authcontext", authCtx);
  const [data, setData] = useState("");
   let na = authCtx.user.sector;
  useEffect(() => {
    let na = authCtx.user.Search;
    const fetchData = async () => {
      const result = await axios(
        `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/Searchs?Search=${na}`
      );

      setData(result.data);
    };

    fetchData();
  }, []);
  console.log("RESData", data);
  return (
    <div className="w-[100%] ">
      <ListingTemplate>
        <div className="flex flex-row">
          {/* <div className="text-[18px] font-bold">Sector</div> */}
          <div className=" text-[15px] ">{na}</div>
          <div className="ml-1 text-[15px] ">/</div>
          {/* <div
            className="ml-1 text-[15px] cursor-pointer"
            onClick={(e) => {
              navigate("/team/list");
            }}
          >
            Team Management
          </div>
          <div className="ml-1 text-[15px] ">/</div> */}
          <div className="ml-1 text-[15px]  text-gray-400">Search</div>
        </div>
        <div className="text-[24px] font-bold">Search Report</div>
        <div className="w-[1000px] mt-1">
          A "search report" can refer to various reports that involve searching
          or finding information.It can search report on behalf of your block
          code , phone number and cnic that you provide
        </div>
        <div className="mt-8 flex flex-col h-[90%] w-[100%]">
          <div className="flex flex-row  h-[50%] px-[5%] justify-between flex-wrap">
            <div
              className="flex flex-col border-2  border-gray-200 w-[30%] h-[50%]  rounded-md shadow-md cursor-pointer hover:shadow-sm"
              onClick={(e) => {
                navigate("/age/search");
              }}
            >
              <div className="h-[50%] border-b-2 bg-[#292929] text-white  border-gray-200 flex justify-center pt-[5%] text-[16px] font-bold">
                BlockCode
              </div>
              <div className="h-[50%] flex justify-center pt-[5%] text-[16px] text-slate-500 font-semibold">
                Search by Age
              </div>
            </div>
            <div
              className="flex flex-col border-2  w-[30%] h-[50%] shadow-md  rounded-md cursor-pointer hover:shadow-sm "
              onClick={(e) => {
                navigate("/blockcode/address/search");
              }}
            >
              <div className="h-[50%] border-b-2 bg-[#292929] text-white  border-gray-200 flex justify-center pt-[5%] text-[16px] font-bold">
                BlockCode
              </div>
              <div className="h-[50%] flex justify-center pt-[5%] text-[16px] text-slate-500 font-semibold">
                Search by Address
              </div>
            </div>
            <div
              className="flex flex-col border-2 border-gray-200 w-[30%] h-[50%] shadow-md   rounded-md cursor-pointer hover:shadow-sm"
              onClick={(e) => {
                navigate("/phone/search");
              }}
            >
              <div className="h-[50%] border-b-2 bg-[#292929] text-white border-gray-200 flex justify-center pt-[5%] text-[16px] font-bold">
                Block Code
              </div>
              <div className="h-[50%] flex justify-center pt-[5%] text-[16px] text-slate-500 font-semibold">
                Search by Phone Number
              </div>
            </div>
            <div
              className="flex flex-col border-2 border-gray-200 mt-[20px] w-[30%] h-[50%]  rounded-md cursor-pointer shadow-md hover:shadow-sm "
              onClick={(e) => {
                navigate("/cnic/search");
              }}
            >
              <div className="h-[50%] border-b-2 bg-[#292929] text-white  border-gray-200 flex justify-center pt-[5%] text-[16px] font-bold">
                Halka ({na})
              </div>
              <div className="h-[50%] flex justify-center pt-[5%] text-[16px] text-slate-500 font-semibold">
                Search by CNIC
              </div>
            </div>
           
            {/* <div className=" flex flex-col border-2 border-gray-200">
              <Graph />
            </div> */}
          </div>
          <div className="flex flex-row justify-center space-x-7 h-[50%] px-[5%] "></div>
        </div>
        {/* <div>
          <Graph/>
        </div> */}
      </ListingTemplate>
    </div>
  );
};

export default Search;
