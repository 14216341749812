import { useContext } from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import AppImages from "../../../../assets/images";
import AuthContext from "../../../../contexts/AuthContext";
import { Api } from "../../../../utils/api";
import { RequestStatus } from "../../../../utils/enums";
import SimpleModal from "../../../basic/SimpleModal";
import ListingTemplate from "../ListingTemplate";
import { QueryClient, QueryCache, useQuery } from "react-query";
import { employeeActions, employeeThunk } from "../reducers/employee_slice";

function BlockCodelisting({ children }) {
  const navigate = useNavigate();
  const ths = [{ title: "Block Code" }, { title: "Count" }, { title: "View" }];
  const tdClasses = "text-[12px] border-b-[1px] border-[#2A2D4314] py-4";

  const dispatch = useDispatch();
  const employeeReducer = useSelector((state) => state.employees);
  // console.log("Employee", employeeReducer.data)
  // const [data, setData] = useState(employeeReducer.data);
  const [showableData, setShowableData] = useState();
  const [deleteableItem, setDeletableItem] = useState(null);
  const [searchQuery, setSearchQuery] = useState(null);
  const [statusFilter, setStatusFilter] = useState("all");
  const authCtx = useContext(AuthContext);
console.log("Authcontext", authCtx);
let na = authCtx.user.sector;
let page = 1;
const { isLoading, error, data } = useQuery("sectorData", () =>
  fetch(
    `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/sectors-block-code?sector=${na}&page_no=${page}`
  ).then((res) => res.json())
);
console.log("Dataia", data);

  // useEffect(() => {
  //     if(employeeReducer.fetchDataRequestStatus === RequestStatus.IDLE) {
  //         dispatch(employeeThunk.getEmployees())
  //     }
  // }, [employeeReducer.data])

  // useEffect(() => {
  //     // console.log(employeeReducer.data)
  //     setData(employeeReducer.data ?? [])
  //     setShowableData(employeeReducer.data ?? [])
  // }, [employeeReducer.data])
  // useEffect(() => {
  //   if (employeeReducer.fetchDataRequestStatus === RequestStatus.IDLE) {
  //     dispatch(employeeThunk.getEmployees());
  //   }

  //   if (employeeReducer.fetchDataRequestStatus === RequestStatus.COMPLETED) {
  //     setData(employeeReducer.data ?? []);
  //     setShowableData(employeeReducer.data ?? []);
  //     console.log("EmplyeeDataLength", employeeReducer.data.length);
  //   }
  // }, [employeeReducer.data, employeeReducer.fetchDataRequestStatus]);

  // /admin/employees/m/2/profile/update
  // const handleClickOnStatusBtn = (item, newStatus) => {
  //     dispatch(employeeActions.updateEmployeeStatus({
  //         id: item.id,
  //         newStatus
  //     }))
  //     console.log("ActiveCustomer", item.id);
  //     Api.put({
  //       path: `/admin/employees/m/${item.id}/profile/update`,
  //       data: {
  //         status: newStatus,
  //       },
  //     }).then((res) => {
  //       console.log(res);
  //     });
  // }
  
  const handleStatusFilter = (e) => {
    setStatusFilter(e.target.value);
    switch (e.target.value) {
      case "active":
        setShowableData(data.filter((d) => d.status === "active"));
        break;
      case "inactive":
        setShowableData(
          data.filter((d) => d.status === "inactive" || !d.status)
        );
        break;
      case "all":
      default:
        setShowableData(data);
    }
  };

  const filterDataByQuery = (val) => {
    let newData = showableData.filter(
      (d) =>
        d.id.toString().startsWith(val) ||
        d.name?.toLowerCase().startsWith(val.toLowerCase()) ||
        d.email?.toLowerCase().startsWith(val.toLowerCase()) ||
        d.gender?.toLowerCase().startsWith(val.toLowerCase()) ||
        d.Location?.city?.toLowerCase().startsWith(val.toLowerCase()) ||
        d.Location?.country?.toLowerCase().startsWith(val.toLowerCase())
    );

    return newData;
  };

  const handleOnSearch = (e) => {
    const val = e.target.value?.trim();
    console.log("should com");
    if (val) {
      setSearchQuery(val);
      setShowableData(filterDataByQuery(val));
    } else if (statusFilter !== "all") {
      setShowableData(data.filter((d) => d.status === statusFilter));
    } else {
      setShowableData(data);
    }
  };

  return (
    <ListingTemplate
      //   title="Employees"
      onSearch={handleOnSearch}
      selectBoxLabel="Select staff:"
      selectItems={[
        { text: "All", value: "all" },
        { text: "Active", value: "active" },
        { text: "Inactive", value: "inactive" },
      ]}
      onSelect={handleStatusFilter}
      //   buttonTitle="Add Employee"
      //   onButtonClick={(e) => {
      //     dispatch(employeeActions.setActiveEmployeeData(null));
      //     navigate("/org/employees/add");
      //   }}
    >
      <SimpleModal
        isModalOpen={deleteableItem !== null}
        onClick={() => {
          console.log(deleteableItem?.id);
          Api.delete({
            path: `/admin/employees/${deleteableItem?.id}`,
          }).then((response) => {
            console.log(response);
            if (response.success) {
              dispatch(
                employeeActions.deleteEmployee({ id: deleteableItem?.id })
              );
            }
          });
          setDeletableItem(null);
        }}
        onClose={() => setDeletableItem(null)}
        text="Are you sure to delete this employee?"
      />
      <div className="flex flex-row">
        {/* <div className="text-[18px] font-bold">Sector</div> */}
        <div className=" text-[15px] ">{na}</div>
        <div className="ml-1 text-[15px] ">/</div>
        {/* <div
          className="ml-1 text-[15px]"
          onClick={(e) => {
            // navigate("/team/list");
          }}
        >
          
        </div>
        <div className="ml-1 text-[15px] ">/</div> */}
        <div className="ml-1 text-[15px]  text-gray-400">Block Code List</div>
      </div>
      <div className="text-[24px] font-bold">Block Code detail</div>
      <div className="w-[1000px] mt-1">
        Voter list details according to block code
      </div>
      <table className="w-full my-6">
        <thead>
          <tr>
            {ths.map((item, index) => (
              <th
                key={index}
                className="text-[12px] font-semibold border-b-[1px] border-[#2A2D4314] py-4 text-left"
              >
                {item.title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.block_code?.map((item, index) => (
            <tr key={index}>
              <td className={tdClasses}>{item.block_code}</td>
              <td className={tdClasses}>{item.count}</td>

              {/* <td className={`${tdClasses} w-[10%]`}>
                {item.status === "active" ? (
                  <span
                    className="bg-[#69BA6C] text-white text-center w-[70px] inline-block py-[1px] rounded-[4px] cursor-pointer"
                    title="Click to inactivate it"
                    onClick={(e) => handleClickOnStatusBtn(item, "inactive")}
                  >
                    Active
                  </span>
                ) : (
                  <span
                    className="bg-primary text-white text-center w-[70px] inline-block py-[1px] rounded-[4px] cursor-pointer"
                    title="Click to activate it"
                    onClick={(e) => handleClickOnStatusBtn(item, "active")}
                  >
                    Inactive
                  </span>
                )}
              </td> */}
              <td className={`${tdClasses} w-[60%]`}>
                <div className="flex items-center">
                  <Link to={`/voter/list/${item.block_code}`}>
                    <div className="bg-gray-300 rounded-lg p-2 mr-2 cursor-pointer hover:bg-black">
                      <img src={AppImages.eyeWhite} className="w-[14px]" />
                    </div>
                  </Link>
                  {/* {authCtx.user.sector === 'electionexpert lc'?( */}
                  <Link to={`/gharana/voter/list/${item.block_code}`}>
                    <div className="bg-gray-300 rounded-lg p-2 mr-2 cursor-pointer text-[12px] font-bold hover:bg-slate-500">
                      Gharana List
                    </div>
                  </Link>
                  <Link to={`/phone/${item.block_code}`}>
                    <div className="bg-gray-300 rounded-lg p-2 mr-2 cursor-pointer text-[12px] font-bold hover:bg-slate-500">
                      Phone List
                    </div>
                  </Link>

                  {/* <Link to={`/khanvoter/list/${item.block_code}`}>
                    <div className="bg-gray-300 rounded-lg p-2 mr-2 cursor-pointer text-[12px] font-bold hover:bg-slate-500">
                      Khan List
                    </div>
                  </Link> */}
                  <Link to={`/minority/list/${item.block_code}`}>
                    <div className="bg-gray-300 rounded-lg p-2 mr-2 cursor-pointer text-[12px] font-bold hover:bg-slate-500">
                      Minority List
                    </div>
                  </Link>
                  {/* ):(null)} */}
                  {authCtx.user.sector === "Halqa NA 120" ||
                  authCtx.user.sector === "PP 151" ? (
                    <Link to={`/parchi120/${item.block_code}`}>
                      <div className="bg-gray-300 rounded-lg p-2 mr-2 cursor-pointer text-[12px] font-bold hover:bg-slate-500">
                        Parchi Img
                      </div>
                    </Link>
                  ) : (
                    <Link to={`/parchi/${item.block_code}`}>
                      <div className="bg-gray-300 rounded-lg p-2 mr-2 cursor-pointer text-[12px] font-bold hover:bg-slate-500">
                        Parchi Img
                      </div>
                    </Link>
                  )}

                  {/* {authCtx.user.party === "PPP" ? null : ( */}
                  <Link to={`/parchi1/${item.block_code}`}>
                    <div className="bg-gray-300 rounded-lg p-2 mr-2 cursor-pointer text-[12px] font-bold hover:bg-slate-500">
                      Parchi
                    </div>
                  </Link>
                  {/* )} */}
                  {/* <div
                    className="bg-primary rounded-lg p-2 mr-2 cursor-pointer hover:bg-black"
                    onClick={() => {
                      dispatch(employeeActions.setActiveEmployeeData(item));
                      navigate(`/org/employees/${item.id}/edit`);
                    }}
                  >
                    <img src={AppImages.editWhiteFilled} className="w-[14px]" />
                  </div>
                  <div
                    className="bg-primary rounded-lg p-2 mr-2 cursor-pointer hover:bg-black"
                    onClick={() => setDeletableItem(item)}
                  >
                    <img src={AppImages.deleteWhite} className="w-[14px]" />
                  </div> */}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </ListingTemplate>
  );
}

export default BlockCodelisting;
