import React from "react";

const Spriteslicing = () => {
  const image =
    "https://res.cloudinary.com/election-experts/image/fetch/c_crop,h_80,x_0,w_4000,y_1063/https%3A%2F%2Ffirebasestorage.googleapis.com%2Fv0%2Fb%2Fone-call-59851.appspot.com%2Fo%2F%252Felection_expert_uploader%252FPP%2520160%252F259040412%252Fna%2520124%2520259040301%2520-2_Page_0413-1705677190706.jpg%3Falt%3Dmedia%26token%3Ddc7a9a68-664a-4937-be70-85b85a5b6750";

  const imageStyle = {
    width: "100%", // Set the initial width of the container
    objectFit: "cover",
    objectPosition: "center", // Center the visible part of the image
    maxWidth: "calc(100% - 150px)", // Reduce the visible width by 150px on both sides
  };

  return (
    <div style={{ maxWidth: "800px", margin: "auto" }}>
      <img src={image} alt="" style={imageStyle} />
    </div>
  );
};

export default Spriteslicing;
