import DashboardTemplate from "./DashboardTemplate";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../basic/Button";
function SingleViewTemplate({ 
    items = [],
    title = "",
    avatarUrl = "",
    children,
    rightButtons
}
) {
    const location = useLocation();
    const navigate = useNavigate();
    const parsedPath = location.pathname.split("/");
    const lastPart = parsedPath[parsedPath.length - 1];
    let action = "view";
    if (lastPart === "edit") {
      action = "edit";
    } else {
      action = "view";
    }
    return (
      <DashboardTemplate>
        <div className="px-5">
          {action == "view" && <div>{rightButtons}</div>}
          <div className="flex items-start justify-between py-2">
            <div className="w-[150px] h-[150px] relative bg-gray-100 rounded-2xl">
              <img
                src={avatarUrl}
                alt={"Avatar Image"}
                className="object-cover	object-center absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] max-w-full max-h-full rounded-2xl"
              />
            </div>
            {children}
          </div>
          <div className="border-b-[1px] border-grey py-2">
            <div className="font-bold py-2 font-normal text-black text-[30px]">
              {title}
            </div>
          </div>
          {items.map((item, index) => (
            <div
              className="border-b-[1px] border-grey py-2 text-black"
              key={index}
            >
              <div className="font-medium py-2">{item.name}</div>
              {action == "view" && <div>{item.value}</div>}
              {action == "edit" && (
                <div>
                  <input type="text" value={item.value} />
                </div>
              )}
            </div>
          ))}
          <div className="relative">
            {action !== "view" && (
              <div className="inline-flex w-full p-0 items-center pl-[250px]">
                <Button
                  text={"Save Changes"}
                  style={{
                    width: "w-[230px]",
                    custom: "py-[10px] text-[16px]",
                  }}
                  // onClick={onAdd}
                  // isLoading={isLoading}
                />
                <div
                  className="ml-10 text-[16px] font-normal"
                  onClick={() => navigate("/org/customers")}
                >
                  Cancel
                </div>
              </div>
            )}
          </div>
        </div>
      </DashboardTemplate>
    );
}

export default SingleViewTemplate;