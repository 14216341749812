import { useRef, useState } from "react";
import { Calendar } from "react-calendar";
import "react-calendar/dist/Calendar.css";
import AppImages from "../../../../assets/images";
import { useOutsideAlerter } from "../../../hooks/useOutsideAlerter";
import { compareDates, Days, getNewDate, Months } from "../../../../utils/date";
import { RequestStatus } from "../../../../utils/enums";
import Button from "../../../basic/Button";
import Dropdown from "../../../basic/Dropdown";
import DashboardTemplate from "../../../common/DashboardTemplate";
import AddSchedule from "./AddSchedule/AddSchedule";
import { siteActions, siteThunk } from "../reducers/site_slice";
import "./calendar.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { customerThunk } from "../reducers/customer_slice";
import { employeeThunk } from "../reducers/employee_slice";
import { scheduleThunk } from "../reducers/schedule_slice";
import { unavailabilityThunk } from "../reducers/unavailability_slice";

const MockedCustomers = [
  { value: "customer-1", text: "Central South Yarra" },
  { value: "customer-2", text: "Second Customer" },
];

const CurrentDay = new Date();

function ScheduleContent() {
  const calendarRef = useRef(null);
  // Sites
  const siteReducer = useSelector((state) => state.sites);
  // Employee
  const employeeReducer = useSelector((state) => state.employees);
  // Customers
  const customerReducer = useSelector((state) => state.customers);
  // Schedules
  const scheduleReducer = useSelector((state) => state.schedules);
  console.log("ScheduleReducer",scheduleReducer)

  // Unavailability
  const unavailabilityReducer = useSelector((state) => state.unavailability);

  const dispatch = useDispatch();
  const [data, setData] = useState({ employees: [], sites: [] });
  const [dropdownValues, setDropdownValues] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [activeCustomerId, setActiveCustomerId] = useState();
  const [selectedDate, setSelectedDate] = useState([CurrentDay, CurrentDay]);
  const [scheduleBy, setScheduleBy] = useState("employees");
  const [weeklyReport, setWeeklyReport] = useState({
    totalShifts: 0,
    filledHours: 0,
    unfilledShifts: 0,
  });

  const [sites, setSites] = useState(
    siteReducer.data.map((item) => ({
      text: item.name,
      value: item.id,
    }))
  );
  const [employees, setEmployees] = useState(
    employeeReducer.data.map((item) => ({
      text: item.name,
      value: item.id,
    }))
  );

  const [unavailabilitys, setUnavailabilitys] = useState(
    siteReducer.data ?? []
  );

  useEffect(() => {
    if (siteReducer.fetchDataRequestStatus === RequestStatus.IDLE) {
      dispatch(siteThunk.getSites());
    }

    if (siteReducer.fetchDataRequestStatus === RequestStatus.COMPLETED) {
      setSites(siteReducer.data ?? []);
      // console.log(siteReducer.data.length);
    }
  }, [siteReducer.data]);

  useEffect(() => {
    const items = scheduleBy === "employees" ? data.employees : data.sites;
    const report = {
      totalShifts: 0,
      filledHours: 0,
      unfilledShifts: 0,
    };
    items.map((item) => {
      [0, 1, 2, 3, 4, 5, 6].map((val) => {
        let totalHours = 0;
        const date = getNewDate(selectedDate[0], val);
        const entry = item.entries.find((entry) =>
          compareDates(entry.date, date)
        );

        if (!entry) {
          report.unfilledShifts = report.unfilledShifts + 1;
        } else {
          report.totalShifts = report.totalShifts + 1;
        }

        entry?.schedule.map((schedule) => {
          const from = new Date(schedule.from).getHours();
          const to = new Date(schedule.to).getHours();
          totalHours = totalHours + (to - from);
        });
        report.filledHours = report.filledHours + totalHours;
      });
    });
    setWeeklyReport(report);
  }, [data, selectedDate, scheduleBy]);

  const calculateDistance = (startDate, endDate) => {
    const differenceInTime =
      new Date(endDate).getTime() - new Date(startDate).getTime();
    var distance = Math.trunc(differenceInTime / (1000 * 3600 * 24));
    return distance;
  };

  useEffect(() => {
    const employees = employeeReducer.data.map((employe) => {
      const unavailableEntries = unavailabilitys.reduce((all, schedule) => {
        if (schedule.employeeId === employe.id) {
          const distance = calculateDistance(
            schedule.startDate,
            schedule.endDate
          );

          for (let index = 0; index <= distance; index++) {
            const date = getNewDate(schedule.startDate, index);
            const value = all.find((entry) =>
              compareDates(entry.date, schedule.startDate)
            );

            if (!value) {
              all.push({
                date: date,
                available: false,
                schedule: [],
              });
            }
          }
        }
        return all;
      }, []);
      const entries = scheduleReducer.data.reduce((all, schedule) => {
        if (schedule.employeeId === employe.id) {
          const index = all.findIndex((entry) =>
            compareDates(entry.date, schedule.startTime)
          );
          if (index !== -1) {
            if (all[index].available) {
              all[index].schedule.push({
                from: schedule.startTime,
                to: schedule.endTime,
                info: schedule.Site.name,
              });
            }
          } else {
            all.push({
              date: schedule.startTime,
              available: true,
              schedule: [
                {
                  from: schedule.startTime,
                  to: schedule.endTime,
                  info: schedule.Site.name,
                },
              ],
            });
          }
        }
        return all;
      }, unavailableEntries);

      return { ...employe, entries };
    });

    const sites = siteReducer.data.map((site) => {
      const entries = scheduleReducer.data.reduce((all, schedule) => {
        if (schedule.siteId === site.id) {
          const index = all.findIndex((entry) =>
            compareDates(entry.date, schedule.startTime)
          );
          if (index !== -1) {
            all[index].schedule.push({
              from: schedule.startTime,
              to: schedule.endTime,
              info: schedule.Employee.name,
            });
          } else {
            all.push({
              date: schedule.startTime,
              available: true,
              schedule: [
                {
                  from: schedule.startTime,
                  to: schedule.endTime,
                  info: schedule.Employee.name,
                },
              ],
            });
          }
        }
        return all;
      }, []);
      return { ...site, entries };
    });
    setData({ sites, employees });
  }, [siteReducer.data, employeeReducer.data, scheduleReducer.data]);

  useEffect(() => {
    if (
      employees.length !== 0 &&
      sites.length !== 0 &&
      dropdownValues.length === 0
    ) {
      setDropdownValues(scheduleBy === "employees" ? employees : sites);
    }
  }, [employees, sites]);

  const getSitesByCustomer = (id) => {
    dispatch(siteThunk.getCustomerSites({ customerId: id }));
  };

  const getEmployeesByCustomer = (id) => {
    dispatch(employeeThunk.getCustomerEmployees({ id }));
  };

  useEffect(() => {
    if (siteReducer.fetchDataRequestStatus === RequestStatus.COMPLETED) {
      setSites(
        siteReducer.data.map((item) => ({
          text: item.name,
          value: item.id,
        }))
      );
    }
  }, [siteReducer.data]);

  useEffect(() => {
    if (employeeReducer.fetchDataRequestStatus === RequestStatus.COMPLETED) {
      setEmployees(
        employeeReducer.data.map((item) => ({
          text: item.name,
          value: item.id,
        }))
      );
    }
  }, [employeeReducer.data]);

  useEffect(() => {
    if (scheduleReducer.fetchDataRequestStatus === RequestStatus.IDLE) {
      dispatch(scheduleThunk.getSchedules());
    }
    if (scheduleReducer.fetchDataRequestStatus === RequestStatus.COMPLETED) {
      setSchedules(
        scheduleReducer.data.map((item) => ({
          text: item.name,
          value: item.id,
        }))
      );
    }
  }, [scheduleReducer.data]);

  useEffect(() => {
    if (activeCustomerId) {
      getSitesByCustomer(activeCustomerId);
      getEmployeesByCustomer(activeCustomerId);
    }
  }, [activeCustomerId]);

  useEffect(() => {
    if (unavailabilityReducer.fetchDataRequestStatus === RequestStatus.IDLE) {
      dispatch(unavailabilityThunk.getunavailabilitys());
    }
    if (
      unavailabilityReducer.fetchDataRequestStatus === RequestStatus.COMPLETED
    ) {
      setUnavailabilitys(unavailabilityReducer.data);
    }
  }, [unavailabilityReducer.data]);

  useEffect(() => {
    if (customerReducer.fetchDataRequestStatus === RequestStatus.IDLE) {
      dispatch(customerThunk.getCustomers());
    }
    if (customerReducer.fetchDataRequestStatus === RequestStatus.COMPLETED) {
      setCustomers(customerReducer.data);
      // setActiveCustomerId(customerReducer.data[0].id);
    }
  }, [customerReducer.data]);

  const [showDatePicker, setShowDatePicker] = useState(false);
  const [scheduleData, setScheduleData] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(
    MockedCustomers[0].value
  );
  const [schedules, setSchedules] = useState(employeeReducer.data);

  useOutsideAlerter(calendarRef, () => setShowDatePicker(false));

  const getValidRange = (dates) => {
    const differenceInTime = dates[1].getTime() - dates[0].getTime();
    var distance = Math.trunc(differenceInTime / (1000 * 3600 * 24));
    if (distance <= 6) {
      return dates;
    }
    const endDate = new Date(dates[0]);
    endDate.setDate(endDate.getDate() + 6);
    return [dates[0], endDate];
  };

  const onDateSelected = (value) => {
    setSelectedDate(getValidRange(value));
    setShowDatePicker(false);
  };

  const goToToday = () => {
    setSelectedDate([new Date(), getNewDate(new Date(), 6)]);
  };

  // + new week check it
  const goToNextWeek = () => {
    const startDate = getNewDate(selectedDate[1], 1);
    const endDate = getNewDate(selectedDate[1], 7);
    setSelectedDate([startDate, endDate]);
  };

  const goToPastWeek = () => {
    const startDate = getNewDate(selectedDate[0], -7);
    const endDate = getNewDate(selectedDate[0], -1);
    setSelectedDate([startDate, endDate]);
  };

  const openAddSchedule = (date, id) => {
    setScheduleData({ id, date });
    setOpenModal(true);
  };

  const closeAddSchedule = () => {
    setOpenModal(false);
  };

  const onEntryTypeChange = (type) => {
    setScheduleBy(type);
    // If schedule by changes, we need to provide a different mocked data.
    setDropdownValues(type === "employees" ? sites : employees);
  };

  const getDateRangeLabel = () => {
    const label = `${selectedDate[0].getDate()} ${
      Months[selectedDate[0].getMonth()]
    }`;
    const isSameDate = compareDates(selectedDate[0], selectedDate[1]);
    return isSameDate
      ? label
      : label +
          ` - ${selectedDate[1].getDate()} ${
            Months[selectedDate[1].getMonth()]
          }`;
  };

  const onScheduleTime = (schedule) => {
    const startTimeDate = new Date(scheduleData.date);
    const endTimeDate = new Date(scheduleData.date);
    const start = schedule.startTime.split(":");
    const end = schedule.endTime.split(":");

    startTimeDate.setHours(start[0]);
    startTimeDate.setMinutes(start[1]);

    endTimeDate.setHours(end[0]);
    endTimeDate.setMinutes(end[1]);

    dispatch(
      scheduleThunk.addSchedule({
        customerId: activeCustomerId,
        employeeId: scheduleBy === "employees" ? scheduleData.id : schedule.id,
        siteId: scheduleBy === "sites" ? scheduleData.id : schedule.id,
        startTime: startTimeDate,
        endTime: endTimeDate,
      })
    );
    setOpenModal(false);
  };

  const getFormateHours = (date) => {
    const newDate = new Date(date);
    const hours = newDate.getHours();
    const minutes = newDate.getMinutes();
    return `${hours < 10 ? `0${hours}` : hours}:${
      minutes < 10 ? `0${minutes}` : minutes
    }`;
  };

  const getTotalHours = (entries) => {
    let totalHours = 0;
    [0, 1, 2, 3, 4, 5, 6].map((val) => {
      const date = getNewDate(selectedDate[0], val);
      const entry = entries.find((entry) => compareDates(entry.date, date));

      entry?.schedule.map((schedule) => {
        const from = new Date(schedule.from).getHours();
        const to = new Date(schedule.to).getHours();
        totalHours = totalHours + (to - from);
      });
    });
    return totalHours;
  };

  return (
    <>
      {openModal && (
        <AddSchedule
          open={openModal}
          dropdownData={dropdownValues}
          dropdownPlaceholder={
            scheduleBy === "employees" ? "Select User" : "Select employee"
          }
          onClose={closeAddSchedule}
          onSchedule={onScheduleTime}
        />
      )}
      <DashboardTemplate style={{ wrapper: "px-0 max-w-[1320px]" }}>
        <div className="w-full flex justify-between mb-12">
          <div className="flex flex-col ml-5 justify-end">
            <div className="flex flex-col mb-3">
              <div className="flex w-full justify-between items-center border p-3 mb-[70px] rounded-md border-black mb-3">
                <div className="flex flex-col">
                  <span className="font-bold mb-1 text-black text-xs">
                    Publish & Notify
                  </span>
                  <span className="text-[10px] text-black">
                    0 shifts Unpublished
                  </span>
                </div>
                <img
                  src={AppImages.caretDown}
                  alt="Settings"
                  className="w-[10px] cursor-pointer"
                />
              </div>
              {/* <div className="flex justify-between">
                <span className="text-xs mb-1">Select Customer: </span>
                <img
                  src={AppImages.settings}
                  alt="Settings"
                  className="w-[10px] cursor-pointer"
                />
              </div> */}
              {/* I'll recommend you to not send an object for the style prop, 
                  just send all the clases as one single string */}
              {/* <Dropdown
                style={{
                  width: 'w-full',
                  text: 'text-[12px] font-bold',
                  padding: 'p-1',
                  radius: 'rounded-md',
                }}
                options={customers?.map(customer => ({text: customer.name, value: customer.id})) ?? []}
                id="customer-dropdown"
                value="sy"
                onChange={e => {
                  setSelectedCustomer(e.currentTarget.value);
                  setActiveCustomerId(parseInt(e.target.value))
                }}
              /> */}
              {/* <select
                name=""
                id=""
                onChange={(e) => {
                  setSelectedCustomer(e.currentTarget.value);
                  setActiveCustomerId(parseInt(e.currentTarget.value));
                }}
              >
                {customerReducer.data.map((customer) => (
                  <option key={customer.id} value={customer.id}>
                    {customer.name}
                  </option>
                ))}
              </select> */}
            </div>
            {/* <div className="flex flex-col">
              <span className="text-xs mb-1">Schedule by: </span>
              <div className="flex">
                <Button
                  style={{
                    backgroundColor:
                      scheduleBy !== "employees" &&
                      `bg-white border text-black border-black hover:text-white`,
                    padding: "py-1",
                    width: "w-[80px]",
                    fontSize: "text-[10px]",
                    rounded: "rounded-[6px]",
                    margin: "ml-0 mt-0",
                  }}
                  text="Employees"
                  onClick={(e) => onEntryTypeChange("employees")}
                />
                <Button
                  style={{
                    backgroundColor:
                      scheduleBy !== "sites" &&
                      `bg-white border text-black border-black hover:text-white`,
                    padding: "py-1",
                    width: "w-[80px]",
                    fontSize: "text-[10px]",
                    rounded: "rounded-[6px]",
                    margin: "ml-0 mt-0",
                  }}
                  text="Sites"
                  onClick={(e) => onEntryTypeChange("sites")}
                />
              </div>
            </div> */}
          </div>
          <div className="w-[350px] relative flex flex-col items-center">
            <div>
              <Button
                style={{ padding: "py-2", fontSize: "text-xs" }}
                text="Today"
                onClick={goToToday}
              />
              <Button
                style={{
                  padding: "py-2 pr-2",
                  fontSize: "text-xs",
                  iconStyle: "w-[10px]",
                }}
                icon={AppImages.calendarArrow}
                onClick={goToPastWeek}
              />
              <Button
                style={{ padding: "py-2", fontSize: "text-xs" }}
                text={getDateRangeLabel()}
                onClick={(e) => {
                  setShowDatePicker(true);
                }}
              />
              <Button
                style={{
                  padding: "py-2 pr-2",
                  fontSize: "text-xs",
                  iconStyle: "w-[10px] rotate-180",
                }}
                icon={AppImages.calendarArrow}
                onClick={goToNextWeek}
              />
            </div>
            {showDatePicker && (
              <Calendar
                minDetail="month"
                inputRef={calendarRef}
                className="absolute top-14 rounded-md py-3 px-9"
                prev2Label={null}
                next2Label={null}
                selectRange
                onChange={onDateSelected}
                value={selectedDate}
              />
            )}
          </div>

          {/* These values are harcoded since they should come from an API */}
          <div className="flex flex-col mr-5">
            <div className="flex flex-col w-[168px] border-black border rounded-lg">
              <span className="text-xs px-3 pt-3 font-bold">Weekly report</span>
              <div className="flex justify-between mt-3 px-3 pt-3 border-t-[1px] border-black">
                <span className="text-[10px]">Total shifts</span>
                <span className="text-[10px]">{weeklyReport.totalShifts}</span>
              </div>
              <div className="flex justify-between mt-3 px-3 pt-3 border-t-[1px] border-black">
                <span className="text-[10px]">Unfilled shifts</span>
                <span className="text-[10px] font-bold">
                  {weeklyReport.unfilledShifts}
                </span>
              </div>
              <div className="flex justify-between mt-3 px-3 border-t-[1px] py-3 border-black">
                <span className="text-[10px]">Filled hours</span>
                <span className="text-[10px] font-bold">
                  {weeklyReport.filledHours}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col w-full bg-slate-50">
          <div className="header flex w-full h-5 bg-black">
            {[0, 1, 2, 3, 4, 5, 6].map((val) => {
              const date = getNewDate(selectedDate[0], val);
              return (
                <span
                  key={val}
                  className="text-white flex-grow text-center text-[10px]"
                >
                  {`${Days[date.getDay()]} ${date.getDate()} ${
                    Months[date.getMonth()]
                  }`}
                </span>
              );
            })}
          </div>
          {data[scheduleBy].map(({ entries, name, id, totalHours }) => (
            <div key={id} className="flex-col w-full">
              <div className="flex items-center justify-between w-full h-5 bg-[#F2385F]">
                <span className="text-white ml-2 text-[10px]">{name}</span>
                <span className="text-white mr-2 text-[10px]">
                  {getTotalHours(entries)} hrs
                </span>
              </div>
              <div className="flex w-full min-h-[80px]">
                {[0, 1, 2, 3, 4, 5, 6].map((val) => {
                  const date = getNewDate(selectedDate[0], val);
                  const entry = entries.find((entry) =>
                    compareDates(entry.date, date)
                  );
                  return (
                    <div
                      key={val}
                      className={`w-[152px] flex flex-grow justify-center ${
                        val % 2 === 0 ? "bg-white" : "bg-gray-100"
                      }`}
                    >
                      {entry && (
                        <div
                          className={`w-full h-full flex flex-col ${
                            entry.available
                              ? "bg-black rounded-md"
                              : "bg-primary justify-center items-center"
                          }`}
                        >
                          {entry.available ? (
                            <>
                              {/* {entry.schedule.map(
                                ({ from, to, info }, index) => (
                                  <div
                                    key={index}
                                    className="flex flex-col pl-2 pt-1"
                                  >
                                    <span className="text-[8px] font-bold text-white">
                                      {getFormateHours(from)} -{" "}
                                      {getFormateHours(to)}
                                    </span>
                                    <span className="text-[8px] font-bold text-white">
                                      {info}
                                    </span>
                                  </div>
                                )
                              )} */}
                              <img
                                onClick={() => openAddSchedule(date, id)}
                                src={AppImages.calendarAdd}
                                alt="Add"
                                className="w-[14px] py-2 self-center cursor-pointer"
                              />
                            </>
                          ) : (
                            <span className="font-bold text-white text-[10px]">
                              Unavailable
                            </span>
                          )}
                        </div>
                      )}
                      {!entry && (
                        <img
                          onClick={() => openAddSchedule(date, id)}
                          src={AppImages.calendarAdd}
                          alt="Add"
                          className="w-[14px] cursor-pointer"
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          ))}
        </div>
      </DashboardTemplate>
    </>
  );
}

export default ScheduleContent;
