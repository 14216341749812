import axios from "axios";
import { useContext } from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import AppImages from "../../../../assets/images";
import AuthContext from "../../../../contexts/AuthContext";
import { Api } from "../../../../utils/api";
import { RequestStatus } from "../../../../utils/enums";
import SimpleModal from "../../../basic/SimpleModal";
import ListingTemplate from "../ListingTemplate";
import { employeeActions, employeeThunk } from "../reducers/employee_slice";

function AddressSearch({ children }) {
  const navigate = useNavigate();
  const ths = [{ title: "Block Code" }, { title: "Count" }, { title: "View" }];
  const tdClasses = "text-[12px] border-b-[1px] border-[#2A2D4314] py-4";
  const [isSearching, setIsSearching] = useState(false);
  const dispatch = useDispatch();
  const authCtx = useContext(AuthContext);
  const employeeReducer = useSelector((state) => state.employees);
  // console.log("Employee", employeeReducer.data)
  const [data, setData] = useState([]);
  const [showableData, setShowableData] = useState(data ?? []);

  const [data1, setData1] = useState("");
  const fields = {
    blockCode: null,
    minage: null,
    maxage: null,
  };
  const errors = {
    blockCode: null,
    minage: null,
    maxage: null,
  };
  const [formFields, setFormFields] = useState(fields);
  const [data2, setData2] = useState("");
  let na = authCtx.user.sector;
  let page = 1;
  const wordsToReplace = [
    "مکان",
    "سال",
    "نمبر",
    "مین",
    "محلہ",
    "روڈ",
    "پارک",
    "،",
    "کالونی ",
    "لاہور ",
    "کی",
  ];
  useEffect(() => {
    const fetchData = async () => {
      const result = await axios(
        `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/sectors-block-code?sector=${na}&page_no=${page}`
      );
      console.log("Resulttt", result);

      setData(result.data.block_code);
    };

    fetchData();
  }, []);
  console.log("Showable dtaa", data);
  const onSubmit = async (e) => {
    let na = authCtx.user.sector;
    // setData2(formFields)
    e.preventDefault();
    console.log("EEE", formFields, na);
    setIsSearching(true);
    const result = await axios(
      `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/search-address?sector=${na}&block_code=${formFields.blockCode}&address=${formFields.minage}`
    ).finally(() => {
      setIsSearching(false);
    });
    console.log("Resultttttttttt", result);
    setData2(result.data);
  };
  console.log("Dataaaaaaa", data2);
  return (
    <ListingTemplate
    //   title="Employees"
    >
      <form
        className={
          authCtx.user.party === "PMLN"
            ? "bg-white invitationForm"
            : authCtx.user.party === "PTI"
            ? "bg-white invitationForm"
            : authCtx.user.party === "PPP"
            ? "bg-white invitationForm"
            : "bg-white invitationForm"
        }
        onSubmit={onSubmit}
      >
        <div className="flex flex-col justify-center items-center mb-6 text-[25px] font-bold">
          <div>Search by Address</div>
        </div>
        <select
          name=""
          id=""
          className="py-2 px-2 border-[1px] border-black rounded-[6px] text-[14px] w-full h-[50px]"
          //   value={formFields.lastName}
          onChange={(e) => {
            setFormFields((old) => ({ ...old, blockCode: e.target.value }));
          }}
        >
          <option>Select Block code</option>
          {data?.map((item, index) => (
            <option key={index} value={item.block_code}>
              {item.block_code}
            </option>
          ))}
        </select>
        <div className="flex flex-row my-2">
          <div className="text-[20px] font-bold mt-2">Address</div>
          <div>
            <input
              type="text"
              placeholder="Enter Address"
              className="my-1 w-[250px] rounded-md py-2 ml-4 px-1 border-2 border-gray-300"
              //   value={formFields.minage}
              onChange={(e) => {
                setFormFields((old) => ({ ...old, minage: e.target.value }));
              }}
            />
          </div>
          <div>
            <button
              className="w-[150px] h-[40px] rounded-md bg-blue-400 text-white font-bold hover:bg-blue-300 ml-[480px] mt-2"
              // isLoading={isPostRequestInProgress}
            >
              Search
            </button>
          </div>
        </div>
        <div className="flex flex-col justify-center items-center mb-6"></div>
      </form>
      {data2 === "" ? (
        <></>
      ) : !isSearching && data2?.error ? (
        <div className="font-bold text-[18px]">No Data Found</div>
      ) : (
        <div className="flex flex-col border-[1px] border-gray-200">
          <div className="px-1">
            <div className="text-[15px] font-bold text-right font-noori">
              نام
            </div>
            <div className="font-noori" dir="rtl">
              {data2?.voters?.name?.length > 44
                ? data2?.voters?.name
                    ?.replace(/[^\W]/gi, "")
                    .replace(/[-*]/g, " ")
                    .replace(new RegExp(wordsToReplace.join("|"), "gi"), "")
                    .substr(0, 44) + ""
                : data2?.voters?.name
                    ?.replace(/[^\W]/gi, "")
                    .replace(/[-*]/g, " ")
                    .replace(new RegExp(wordsToReplace.join("|"), "gi"), "")}
            </div>
          </div>
          <hr />
          <div className="px-1">
            <div className="text-[15px] font-bold text-right font-noori">
              پتہ
            </div>
            <div className="font-noori" dir="rtl">
              {data2.voters?.address}
            </div>
          </div>
          <hr />
          <div className="flex flex-row space-x-7 justify-between">
            <div className="px-1">
              <div className="text-[15px] font-bold font-noori" dir="rtl">
                عمر
              </div>
              <div dir="rtl">{data2.voters?.age}</div>
            </div>
            <div className="px-1">
              <div className="text-[15px] font-bold font-noori" dir="rtl">
                گھرانہ نمبر
              </div>
              <div dir="rtl">
                {/* {data2.voters?.gharana_no} */}
                {data2.voters?.gharana_no === "" ? 1 : data2.voters?.gharana_no}
              </div>
            </div>
            <div className="px-1">
              <div className="text-[15px] font-bold font-noori">سلسلہ نمبر</div>
              <div dir="rtl">
                {data2.voters?.silsila_no === "" ? 1 : data2.voters?.silsila_no}
              </div>
            </div>
          </div>
          <hr />
          {authCtx.user.package !== "1" ? (
            <div className="px-1">
              <div className="text-[15px] font-bold text-right font-noori">
                فون نمبر
              </div>
              {/* {data2.response.voter_phone.map((item, index) => ( */}
              <div className="flex flex-row mr-2" dir="rtl">
                {/* {data2.voters?.voter_phone} */}
                {data2.voters?.voter_phone
                  ? JSON.parse(data2.voters?.voter_phone).map((phone) => (
                      <p>,{phone}</p>
                    ))
                  : "--"}
              </div>
              {/* ))} */}
            </div>
          ) : (
            <div></div>
          )}
          <hr />
          <div className="px-1">
            <div className="text-[15px] font-bold text-right font-noori">
              بلاک کوڈ
            </div>
            <div className="flex flex-row mr-2 text-right" dir="rtl">
              {data2.voters?.block_code}
            </div>
          </div>
          <hr />
          <hr />
          <div className="px-1">
            <div className="text-[15px] font-bold text-right font-noori">
              سیکٹر نمبر
            </div>
            <div className="flex flex-row mr-2 text-right" dir="rtl">
              {data2.voters?.sector}
            </div>
          </div>
          <hr />
          <div className="px-1">
            <div className="text-[15px] font-bold text-right font-noori">
              شناختی کارڈ نمبر
            </div>
            <div className="flex flex-row mr-2" dir="rtl">
              {data2.voters?.cnic}
            </div>
          </div>
          <hr />
          {/* <div className="py-2">
            <Link
              to={`/family/list/${data2.voters.sector}/${data2.voters.block_code}/${data2.voters.cnic}/${data2.voters.gharana_no}`}
            >
              <button className="w-[150px] h-[30px] rounded-md bg-green-500 text-white font-bold hover:bg-green-300">
                Family Tree
              </button>
            </Link>
          </div> */}
        </div>
      )}
    </ListingTemplate>
  );
}

export default AddressSearch;
