import { initializeApp } from "firebase/app";
import { collection, addDoc, getDocs, query, where, getFirestore, serverTimestamp, getCountFromServer, limit, updateDoc, } from "firebase/firestore"; 
const ELECTION_EXPERT_LOGS = "election_expert_logs"
const ELECTION_EXPERT_ERTM = "election_expert_ertm";
const GEO_TRACKING_LOGS = "election_expert_geo_tracks";

export const SEARCH_BY_CNIC = "search_by_cnic"
export const SEARCH_BY_BC_CNIC = "search_by_bc_cnic"
export const SEARCH_BY_PHONE = "search_by_phone"
export const SEARCH_BY_FAMILYTREE = "clicked_on_family_tree";
export const SHARE_VOTER_INFO = "clicked_on_share_voter_info";
export const SHARE_VOTER_CALL_CENTER = "clicked_on_call_now";
export const SHARE_VOTER_FAMILY_TREE = "clicked_on_share_family_tree";
export const GENERATE_VOTER_PARCHI = "clicked_on_share_voter_parchi";
export const SHARE_FAMILY_VOTER_PARCHI = "clicked_on_share_voter_parchi";
export const OPEN_APP = "open_app";
export const USER_LOGIN = "user_login";
export const GEO_TRACKING = "election_expert_geo_tracks";
export const DOOR_TO_DOOR = "door_to_door_submission";

const config = {
    "apiKey"            : "AIzaSyATBfNLiYtyA5CYzl2L-Y9Yc-LUSnGcCQM",
    "authDomain"        : "one-call-59851.firebaseapp.com",
    "databaseURL"       : "https://one-call-59851.firebaseio.com",
    "projectId"         : "one-call-59851",
    "storageBucket"     : "one-call-59851.appspot.com",
    "messagingSenderId" : "962461584827",
    "appId"             : "1:962461584827:android:3a97dc0d54c4e5006e889e",
    "measurementId"     : "G-0LF3SPVK62"
}
const app = initializeApp(config)
const db = getFirestore(app)
const FirebaseService = {
  addLog: async (map = {}) => {
    try {
      const ref = await addDoc(collection(db, "election_expert_logs"), {
        ...map,
        createdAt: serverTimestamp(),
      });
      return ref;
    } catch (e) {
      return null;
    }
  },
  /**
   * [
   * {
   * createdAt: time
   * shared_info: object voter_info
   * location: object: lat, lng
   * user_id: user who shared
   * }
   * ]
   */
  getLogsByTag: async (tag, sector) => {
    try {
      const q = query(
        collection(db, "election_expert_logs"),
        where("tag", "==", tag),
        where("sector", "==", sector)
      );
      return getDocs(q);
    } catch (e) {
      return null;
    }
  },
  getLogsByTagAndUserId: async (tag, userId, sector) => {
    try {
      const q = query(
        collection(db, "election_expert_logs"),
        where("tag", "==", tag),
        where("userId", "==", userId),
        where("sector", "==", sector)
      );
      return getDocs(q);
    } catch (e) {
      return null;
    }
  },
  getCountVoters: async () => {
    try {
      const q = query(
        collection(db, ELECTION_EXPERT_ERTM),
      );
      console.log("QDataaa",q)
      const res = await getDocs(q);
      // const refs = res.forEach((doc) => console.log("Docss",doc.data()));
      return res;
      // return res;
    } catch (e) {
      return 0;
    }
  },
  getCountByTag: async (tag = null, sector) => {
    try {
      if (!tag) throw "Tag not found";
      let op = "==";
      if (Array.isArray(tag)) op = "in";
      const q = query(
        collection(db, ELECTION_EXPERT_LOGS),
        where("tag", op, tag),
        where("sector", "==", sector)
      );
      const res = await getCountFromServer(q);
      return res.data().count;
    } catch (e) {
      return 0;
    }
  },

  getCountByTagAndUserId: async (userId, tag, sector) => {
    try {
      if (!tag) throw "Tag not found";
      if (!userId) throw "User id is required";
      let op = "==";
      if (Array.isArray(tag)) op = "in";
      const q = query(
        collection(db, ELECTION_EXPERT_LOGS),
        where("userId", "==", userId),
        where("tag", op, tag),
        where("sector", "==", sector)
      );
      const res = await getCountFromServer(q);
      return res.data().count;
    } catch (e) {
      return 0;
    }
  },
  getSearchLogs: async (userId, tag) => {
    try {
      if (!tag) throw "Tag not found";
      if (!userId) throw "User id is required";
      // if (!userId) throw "User id is required";
      let op = "==";
      if (Array.isArray(tag)) op = "in";
      const q = query(
        collection(db, ELECTION_EXPERT_LOGS),
        where("userId", "==", userId),
        // where("sector", "==", sector),
        where("tag", op, tag),
        limit(600)
      );
      let snapshots = await getDocs(q);
      return snapshots.docs;
    } catch (e) {
      return null;
    }
  },
  getVoterNotFoundCount: async (userId) => {
    try {
      const tag = SEARCH_BY_CNIC;
      if (!userId) throw "User id is required";
      let op = "==";
      if (Array.isArray(tag)) op = "in";
      const q = query(
        collection(db, ELECTION_EXPERT_LOGS),
        where("userId", "==", userId),
        where("tag", op, tag),
        where("responseStatus", "==", false)
      );
      const res = await getCountFromServer(q);
      return res.data().count;
    } catch (e) {
      return 0;
    }
  },
  test: async () => {
    try {
      const q = query(
        collection(db, ELECTION_EXPERT_LOGS),
        where("tag", "==", "clicked_on_search")
        // limit(5)
      );
      // const res = await getCountFromServer(q);
      // return res.data().count;

      const snapshots = await getDocs(q);
      const refs = snapshots.docs.map((doc) => doc.ref);
      for await (const ref of refs) {
        const res = await updateDoc(ref, {
          tag: SEARCH_BY_CNIC,
        });
        console.log(res);
      }
    } catch (e) {
      return 0;
    }
  },
  getUserActivityStats: async (userId) => {
    try {
      const q = query(
        collection(db, ELECTION_EXPERT_LOGS),
        where("tag", "!=", null),
        where("userId", "==", userId)
      );
      const res = await getCountFromServer(q);
      return res.data().count;
    } catch (e) {
      return [];
    }
  },
  getUserDoorToDoorStats: async (userId) => {
    try {
      const q = query(
        collection(db, ELECTION_EXPERT_LOGS),
        where("tag", "==", DOOR_TO_DOOR),
        where("userId", "==", userId)
      );
      const res = await getCountFromServer(q);
      return res.data().count;
    } catch (e) {
      return [];
    }
  },
  getUserCallStats: async (userId) => {
    try {
      const q = query(
        collection(db, ELECTION_EXPERT_LOGS),
        where("tag", "==", SHARE_VOTER_CALL_CENTER),
        where("userId", "==", userId)
      );
      const res = await getCountFromServer(q);
      return res.data().count;
    } catch (e) {
      return [];
    }
  },
  getGeoTrackLogs: async (userId) => {
    try {
      const q = query(
        collection(db, GEO_TRACKING_LOGS),
        where("userId", "==", userId)
      );
      // const res = await getDocs(q);
      const snapshots = await getDocs(q);
      const refs = snapshots.docs.map((doc) => doc.data());
      return refs;
    } catch (e) {
      return [];
    }
  },
};

export default FirebaseService