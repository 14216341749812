import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Api } from "../../../../utils/api";
import { RequestStatus } from "../../../../utils/enums";

const initialState = {
    data: [],
    activeSiteData: null,
    fetchDataRequestStatus: RequestStatus.IDLE,
}

export const siteThunk = {
    getSites: createAsyncThunk('/sites/getAllSites', async (data, thunkAPI) => {
        const { fulfillWithValue, rejectWithValue } = thunkAPI
        try {
            const res = await Api.get({path: '/admin/sites'})
            if(res.success) {
                return fulfillWithValue({
                    data: res.data.data,
                    activeSiteId: data?.activeSiteId
                })
            } else {
                throw res.message
            }
        } catch(e) {
            return rejectWithValue({
                error: true,
                message: typeof e === 'string' ? e : 'Something went wrong',
                exception: e
            })
        }
    }),

    getCustomerSites: createAsyncThunk('/sites/getCustomerSites', async (data, thunkAPI) => {
        const { fulfillWithValue, rejectWithValue } = thunkAPI
        try {
            const {customerId} = data
            const res = await Api.get({path: `/admin/customers/${customerId}/sites`})
            if(res.success) {
                return fulfillWithValue({
                    data: res.data.data,
                    activeSiteId: data?.activeSiteId
                })
            } else {
                throw res.message
            }
        } catch(e) {
            return rejectWithValue({
                error: true,
                message: typeof e === 'string' ? e : 'Something went wrong',
                exception: e
            })
        }
    })
}

const siteSlice = createSlice({
    name: 'sites',
    initialState,
    reducers: {
        setData: (state, action) => {
            state.data = action.payload
        },
        setActiveSite: (state, action) => {
            const { id } = action.payload 
            state.activeSiteData = state.data.find(item => item.id === id)
        },
        addSite: (state, action) => {
            const {site} = action.payload 
            state.data.push(site)
        },
        updateSiteStatus: (state, action) => {
            const { id, newStatus } = action.payload
            const item = state.data.find(i => i.id === id)

            if(item) {
                item.status = newStatus
                // const employees = item.Employees ?? []
                // if(employees && newStatus === 'inactive') {
                //     const newEmployees = employees.filter(employee => employee.SiteEmployee?.id !== id)
                //     item.Employees = newEmployees
                // }
                // if(newStatus === 'inactive') {
                //     item.Customer = null 
                //     item.customerId = null 
                // }
                state.data[state.data.indexOf(item)] = item 
            }
        },
        assignCustomerToSite: (state, action) => {
            const { siteId, customer } = action.payload 
            const site = state.data.find(site => site.id === siteId) 
            if(site && customer) {
                site.Customer = customer 
                state.activeSiteData = site 
                state.data[state.data.indexOf(site)] = site 
            }
        },
        unassignCustomerFromSite: (state, action) => {
            const { siteId } = action.payload 
            const site = state.data.find(site => site.id === siteId) 
            if(site) {
                site.Customer = null 
                state.activeSiteData = site 
                state.data[state.data.indexOf(site)] = site 
            }
        },
        deleteSite: (state, action) => {
            const { id } = action.payload 
            const item = state.data.find(i => i.id === id)
            if(item) {
                const data = state.data
                data.splice(
                    data.indexOf(item),
                    1
                )
                state.data = data 
            }
        },
        updateSiteDetail: (state, action) => {
            const { id, details } = action.payload
            let site = state.data.find(item => item.id === id)
            if(site) {
                state.data[state.data.indexOf(site)] = details
            }
        },
        addSiteContact: (state, action) => {
            const site = state.data.find(item => item.id === state.activeSiteData?.id)
            const { contact } = action.payload 
            if(site && contact) {
                console.log('gonna dispatch')
                const contacts = site.SiteContacts ?? []
                contacts.push(contact)
                site.SiteContacts = contacts 
                state.activeSiteData = site 
                state.data[state.data.indexOf(site)] = site 
            }
        },
        deleteSiteContact: (state, action) => {
            const { contactId } = action.payload 
            const site = state.data.find(item => item.id === state.activeSiteData?.id)
            if(site) {
                const contact = site.SiteContacts?.find(c => c.id === contactId)
                if(contact) {
                    const contacts = site.SiteContacts ?? []
                    contacts.splice(
                        contacts.indexOf(contact),
                        1
                    )
                    site.SiteContacts = contacts
                    state.activeSiteData = site 
                    state.data[state.data.indexOf(site)] = site 
                }
            }
        },
        updateSiteContact: (state, action) => {
            const site = state.data.find(item => item.id === state.activeSiteData?.id)
            const { contactId, data } = action.payload 
            if(site && contactId) {
                const contact = site.SiteContacts?.find(c => c.id === contactId)
                if(contact) {
                    const contacts = site.SiteContacts ?? []
                    contacts[contacts.indexOf(contact)] = data 
                    site.SiteContacts = contacts
                    state.activeSiteData = site 
                    state.data[state.data.indexOf(site)] = site 
                }
            }
        },
        updateNotes: (state, action) => {
            const site = state.data.find(item => item.id === state.activeSiteData?.id)
            const { notes, shortNotes } = action.payload 
            if(site) {
                const note = state.activeSiteData?.SiteNote ?? {}
                note.notes = notes 
                note.shortNotes = shortNotes
                site.SiteNote = note 
                state.activeSiteData = site 
                state.data[state.data.indexOf(site)] = site 
            }
        },
        addSiteLocation: (state, action) => {
            const site = state.data.find(item => item.id === state.activeSiteData?.id)
            const { location } = action.payload 
            if(site && location) {
                const locations = site.SiteLocations ?? []
                locations.push(location)
                site.SiteLocations = locations 
                state.activeSiteData = site 
                state.data[state.data.indexOf(site)] = site 
            }
        },
        updateSiteLocation: (state, action) => {
            const site = state.data.find(item => item.id === state.activeSiteData?.id)
            const { locationId, data } = action.payload 
            if(site && locationId) {
                const location = site.SiteLocations?.find(c => c.id === locationId)
                if(location) {
                    const locations = site.SiteLocations ?? []
                    locations[locations.indexOf(location)] = data 
                    site.SiteContacts = locations
                    state.activeSiteData = site 
                    state.data[state.data.indexOf(site)] = site 
                }
            }
        },
        deleteSiteLocation: (state, action) => {
            const { locationId } = action.payload 
            const site = state.data.find(item => item.id === state.activeSiteData?.id)
            if(site) {
                const location = site.SiteLocations?.find(c => c.id === locationId)
                if(location) {
                    const locations = site.SiteLocations ?? []
                    locations.splice(
                        locations.indexOf(location),
                        1
                    )
                    site.SiteLocations = locations
                    state.activeSiteData = site 
                    state.data[state.data.indexOf(site)] = site 
                }
            }
        },
        addSiteDoc: (state, action) => {
            const site = state.data.find(item => item.id === state.activeSiteData?.id)
            const { doc } = action.payload 
            if(site && doc) {
                const docs = site.SiteDocuments ?? []
                docs.push(doc)
                site.SiteDocuments = docs 
                state.activeSiteData = site 
                state.data[state.data.indexOf(site)] = site 
            }
        },
        updateSiteDoc: (state, action) => {
            const site = state.data.find(item => item.id === state.activeSiteData?.id)
            const { docId, data } = action.payload 
            if(site && docId) {
                const doc = site.SiteDocuments?.find(c => c.id === docId)
                if(doc) {
                    const docs = site.SiteDocuments ?? []
                    docs[docs.indexOf(doc)] = data 
                    site.SiteDocuments = docs
                    state.activeSiteData = site 
                    state.data[state.data.indexOf(site)] = site 
                }
            }
        },
        deleteSiteDoc: (state, action) => {
            const { docId } = action.payload 
            const site = state.data.find(item => item.id === state.activeSiteData?.id)
            if(site) {
                const doc = site.SiteDocuments?.find(c => c.id === docId)
                if(doc) {
                    const docs = site.SiteDocuments ?? []
                    docs.splice(
                        docs.indexOf(doc),
                        1
                    )
                    site.SiteDocuments = docs 
                    state.activeSiteData = site 
                    state.data[state.data.indexOf(site)] = site 
                }
            }
        },
        assignEmployeeToSite: (state, action) => {
            const { employee, siteId } = action.payload 
            const site = state.data.find(e => e.id === siteId) 
            if(employee && site) {
                const employees = site.Employees ?? []
                const existAlready = employees.find(e => e.id === employee.id)
                if(existAlready) {
                    const index = employees.indexOf(existAlready)
                    employee.SiteEmployee.isPreferred = true 
                    employees[index] = employee

                    site.Employees = employees 
                    state.activeSiteData = site 
                    state.data[state.data.indexOf(site)] = site
                } else {
                    employees.push(employee)
                    site.Employees = employees
                    state.activeSiteData = site 
                    state.data[state.data.indexOf(site)] = site
                }
            }
        },
        unassignEmployeeFromSite: (state, action) => {
            const { employee, siteId } = action.payload 
            const site = state.data.find(e => e.id === siteId) 
            if(employee && site) {
                const employees = site.Employees ?? []
                const existAlready = employees.find(e => e.id === employee.id)
                if(existAlready) {
                    const index = employees.indexOf(existAlready)
                    employee.SiteEmployee.isPreferred = false  
                    employees[index] = employee

                    site.Employees = employees 
                    state.activeSiteData = site 
                    state.data[state.data.indexOf(site)] = site
                } else {
                    employees.push(employee)
                    site.Employees = employees
                    state.activeSiteData = site 
                    state.data[state.data.indexOf(site)] = site
                }
            }
        }
    },
    extraReducers: (builder) => builder
        .addCase(siteThunk.getSites.fulfilled, (state, action) => {
            state.data = action.payload.data ?? []
            if(action.payload.activeSiteId) {
                const activeSiteData = state.data.find(item => item.id === action.payload.activeSiteId)
                if(activeSiteData) {
                    state.activeSiteData = activeSiteData
                }
            }
            state.fetchDataRequestStatus = RequestStatus.COMPLETED
        })
        .addCase(siteThunk.getSites.rejected, (state, action) => {
            state.fetchDataRequestStatus = RequestStatus.ERROR
        })
        .addCase(siteThunk.getSites.pending, (state, action) => {
            state.fetchDataRequestStatus = RequestStatus.LOADING
        })

        // .addCase(siteThunk.getCustomerSites.fulfilled, (state, action) => {
        //     state.data = action.payload.data ?? []
        //     if(action.payload.activeSiteId) {
        //         const activeSiteData = state.data.find(item => item.id === action.payload.activeSiteId)
        //         if(activeSiteData) {
        //             state.activeSiteData = activeSiteData
        //         }
        //     }
        //     state.fetchDataRequestStatus = RequestStatus.COMPLETED
        // })
        // .addCase(siteThunk.getCustomerSites.rejected, (state, action) => {
        //     state.fetchDataRequestStatus = RequestStatus.ERROR
        // })
        // .addCase(siteThunk.getCustomerSites.pending, (state, action) => {
        //     state.fetchDataRequestStatus = RequestStatus.LOADING
        // })
})

export const siteActions = siteSlice.actions
export default siteSlice.reducer