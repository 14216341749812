import bell from "./icons/ic_bell.svg"
import calculator from "./icons/ic_calculator.svg"
import calendar from "./icons/ic_calendar.svg"
import caretDown from "./icons/ic_caret_down.svg"
import caretUp from "./icons/ic_caret_up.svg"
import caretUpActive from "./caretUpActive.svg"
import caretUpInactive from "./caretUpInactive.svg"
import certificate from "./icons/ic_certificate.svg"
import coming from "./icons/Election.png"
import loadingWhiteCircle from "./loading-circle-white.svg"
import lock from "./lock.svg"
import eye from "./eye.svg"
import eyeCross from "./eye-cross.svg"
import pti from "./pngegg.png"
import dashboard from "./dashboard.svg"
import dashboardInactive from "./dashboard-inactive.svg"
import staff from "./staff.svg"
import staffInactive from "./staff-inactive.svg"
import user from "./user.png"
import editWhite from "./edit-white.svg"
import editWhiteFilled from "./edit-white-filled.svg"
import eyeWhite from "./eye-white.svg"
import deleteWhite from "./delete-white.svg"
import userBlackYellow from "./user-black-yellow.svg"
import crossWhite from "./cross-white.svg"
import loading from "./loading-1.svg"
import search from "./search.svg"
import upload from "./upload.svg"
import uploadBox from "./upload-box.svg"
import download from "./download.svg"
import tick from "./tick.svg"
import cross from "./cross.svg"
import vector from "./Vector.svg"
import crossBlack from "./cross-black.svg"
import calendarAdd from './icons/ic_calendar_add.svg';
import calendarArrow from './icons/ic_calendar_arrow.svg';
import settings from './icons/ic_settings.svg';
import Jameel from './icons/Jameel Noori Nastaleeq Regular/Jameel Noori Nastaleeq Regular.ttf'
import area from "./ic_local_library_24px.svg"
import logout from "./ic_exit_to_app_24px.svg"
import polling_station from "./polling_station.svg"
import suspicious from "./suspicious.svg"
import pakistanflag from "./pakistanflag.svg"
import logo from "./logo.png"
import ptidashboard from "./ptidashboard.png"
import ptireport from "./ptireports.png";
import ptiteam from "./ptiteam.png";
import ptiagent from "./ptiagent.png";
import ptifamilyreport from "./ptifamilyreports.png";
import ptimap from "./ptimap.png";
import ptitransport from "./ptitransport.png";
import pticall from "./pticall.png"
import newreport from "./icons8-report-50.png"
import voter from "./icons8-elections-50.png"
import phone from "./icons8-outgoing-call-50.png"
import card from "./icons8-card-50.png"
import manphone from "./icons8-man-on-phone-50.png"
import graph from "./icons8-graph-50.png"
import pppelection from "./pppelection.png"
import asad from "./AsadKhokar.jpeg"
import asad1 from "./Asad.jpeg"
import sher from "./sher_mark-removebg-preview.png"
import pp172 from "./pp172-min (1).jpg"
import speaker from "./speaker.png"
import speakerfirst from "./speakerfirst.PNG"
import ppplogo from "./PPPLogo1.png"
import mor from "./mor.JPG"
import pk82 from "./PK82.PNG"
import coconut from "./coconut.png"
import wallclock from "./wallclock-removebg-preview.png"
import morrr from "./morBackground.png"
// import table from "./table-removebg-preview.png"
import pp161 from "./pp161.jpg"
import na119 from "./NA119.jpg"
import demo from "./demo-removebg-preview.png"
import barcode from "./barcode-removebg-preview.png"
import table from "./pp59.jpg"
const AppImages = {
    // pp59,
    barcode,
    demo,
    na119,
    pp161,
    table,
    morrr,
    wallclock,
    coconut,
    pk82,
    mor,
    ppplogo,
    speakerfirst,
    speaker,
    pp172,
    sher,
    asad1,
    asad,
    pppelection,
    graph,
    manphone,
    card,
    phone,
    voter,
    newreport,
    ptiagent,
    ptidashboard,
    pticall,
    ptireport,
    ptiteam,
    ptitransport,
    ptifamilyreport,
    ptimap,
    settings,
    pti,
    bell,
    calculator,
    calendar,
    calendarAdd,
    calendarArrow,
    pakistanflag,
    caretDown,
    caretUp,
    caretUpActive,
    caretUpInactive,
    certificate,
    coming,
    loadingWhiteCircle,
    lock,
    eye,
    logo,
    eyeCross,
    dashboard,
    dashboardInactive,
    staff,
    staffInactive,
    user,
    editWhite,
    editWhiteFilled,
    eyeWhite,
    deleteWhite,
    userBlackYellow,
    crossWhite,
    loading,
    search,
    upload,
    uploadBox,
    download,
    crossBlack,
    vector,
    tick,
    cross,
    Jameel,
    area,
    logout,
    polling_station,
    suspicious
}
export default AppImages