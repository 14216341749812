import AppImages from "../../assets/images";

function Button({
    text,
    icon = null,
    onClick = null,
    ref = null,
    style = {
        backgroundColor: `bg-primary`,
        iconStyle: '',
        width: '',
        margin: '',
        display: 'inline-block',
        custom: '',
        fontSize: 'text-[16px]',
        padding: ''
    },
    isLoading = false
}) {
    return (
      <button
        onClick={onClick}
        className={`${
          isLoading ? "bg-gray-400" : "bg-[#616461]"
        } hover:bg-gray-400 active:bg-red ${
          style.display
        } text-white my-2 mx-2 px-[12px] py-[10px] rounded-[10px] relative outline-primary ${
          style.fontSize
        } ${style.width} ${style.margin} ${style.custom}`}
        disabled={isLoading}
        title={text}
        ref={ref}
      >
        <span>
          <span className={`${isLoading ? "invisible" : ""}`}>
            {icon && (
              <span>
                <img
                  src={icon}
                  className={`inline-block mr-1 ${style.iconStyle}`}
                />
              </span>
            )}
            <span>{text}</span>
          </span>
          <span
            className={`${
              isLoading ? "inline" : "hidden"
            } absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]`}
          >
            <img
              src={AppImages.loadingWhiteCircle}
              alt=""
              className="w-[20px]"
            />
          </span>
        </span>
      </button>
    );
}

export default Button;