import axios from "axios";
import { useContext } from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import AuthContext from "../../../../../contexts/AuthContext";
import ListingTemplate from "../../ListingTemplate";
import { QueryClient, QueryCache, useQuery } from "react-query";
import ReactLoading from "react-loading";
import { toSentenceCase } from "../../../../../utils/helpers";
import Chart from "react-google-charts";

function ReportBySectorMaleFemale({ children }) {
  const navigate = useNavigate();

  const ths = [
    { title: "Block Code" },
    { title: "Male" },
    { title: "Female" },
  ];

  const authCtx = useContext(AuthContext);
  console.log("Authcontext", authCtx);
  // const [data, setData] = useState("");
  const [graphData, setGraphData] = useState([])
  const { id } = useParams();
  let page = 1;
  let na = authCtx.user.sector;
  const { isLoading, error, data } = useQuery("sectorMaleFemaleData", () =>
    fetch(
      `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/block-code-by-gender?sector=${na}&page_no=${page}`
    ).then((res) => res.json())
  );
  console.log("Dataia", data);

  useEffect(() => {
    let na = authCtx.user.sector;
    let Id = authCtx.user.id;
    let page = 1;

    const fetchData = async () => {
    
      console.log("ID", Id, na,page);
      const result = await axios.get(
        `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/block-code-by-gender?sector=${na}&page_no=${page}`
      );
      //   var url = `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/my-team?sector=${na}&id=${Id}`;
      //   console.log("URLLL", url);
      console.log("RESssData", result);
      // setData(result.data);
      if(result.data.response) {
        const arr = result.data.response.map((item) => {
          return [item.block_code, item.male, item.female]
        })
        setGraphData(arr)
      }

    };

    fetchData();
  }, []);
  console.log("Darta", data);

  return (
    <ListingTemplate
      title={id}
      //   onSearch={handleOnSearch}
      //   onSelect={handleStatusFilter}
    >
      <div className="flex flex-row">
        {/* <div className="text-[24px] font-bold mt-3">Sector</div> */}
        <div className=" text-[15px] font-bold mt-5">{na}</div>
        <div className="ml-1 text-[15px] mt-5">/</div>
        {/* <div
          className="ml-1 mt-5 text-[15px] cursor-pointer"
          onClick={(e) => {
            navigate("/create/newteam");
          }}
        >
          Add Team member
        </div>
        <div className="ml-1 text-[15px] mt-5">/</div> */}

        <div className="ml-1 text-[15px]  text-gray-400 mt-5">
          {toSentenceCase("Constituency report by Male and Female count")}
        </div>
        {/* <button
          className="w-[150px] my-4 h-[40px] ml-[700px] bg-[#14A800] text-[18px] rounded-md text-white hover:bg-slate-400 mt-4"
          onClick={() => navigate("/create/newteam")}
        >
          Add new
        </button> */}

        {/* <Button
          text={"Add new"}
          style={{
            width: "w-[150px]",
            custom: "box-border text-[18px]",
            margin: " ml-[65%]",
          }}
          onClick={() => {
            navigate("/create/newteam");
          }}
        /> */}
      </div>
      <div className="text-[24px] font-bold">
      {toSentenceCase("Constituency report by Male and Female count")}
      </div>
      <div className="w-[1000px] mt-1">
        This information could be obtained through a census or other survey.
        Once the data is collected, it can be organized into a report that
        provides an overview of the number of males and females in the
        constituency
      </div>

      <div>
        {graphData && graphData.length > 0 && 
          <Chart
            chartType="Bar"
            data={[["Block Code", "Male", "Female"], ...graphData]}
            options={{
              // title: "Age wise Voters",
              hAxis: {
                title: "Count",
              },
              vAxis: {
                title: "Age",
              },
            }}
            width={"100%"}
            height={"400px"}
          />
        }
      </div>

      <table className="w-full my-6">
        <thead>
          <tr>
            {ths.map((item, index) => (
              <th
                key={index}
                className="text-[12px] font-semibold border-b-[1px] border-[#2A2D4314] bg-gray-100 px-3 py-4 text-left"
              >
                {item.title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data ? (
            data?.response?.map((item, index) => (
              <tr key={index} className="px-3">
                <td className="text-[12px] border-b-[1px] border-[#2A2D4314] py-4 w-[200px]">
                  {item.block_code}
                </td>
                <td className="text-[12px] border-b-[1px] border-[#2A2D4314] py-4 w-[200px]">
                  {item.male}
                </td>
                <td className="text-[12px] border-b-[1px] border-[#2A2D4314] py-4 w-[200px]">
                  {item.female}
                </td>
              </tr>
            ))
          ) : (
            <div className="mt-[40px] ">
              <ReactLoading
                color={"gray"}
                height={"20px"}
                width={"40px"}
                className="ml-[500px] my-3"
              />
              <div className="font-bold text-[18px] ml-[350px]">
                Data was loading slow due to slow Internet
              </div>
            </div>
          )}
        </tbody>
      </table>
    </ListingTemplate>
  );
}

export default ReportBySectorMaleFemale;
