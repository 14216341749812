import { Api } from "../../../../utils/api"

const ProfileApi = {
    updateProfile: async (fields = {
        countryCode: '',
        phone: '',
        address: '',
        city: '',
        province: '',
        postalCode: '',
        dateOfBirth: '',
        avatarUrl: '',
        fullName: ''
    }, token) => {
        const { 
            countryCode,
            phone,
            address,
            city,
            province,
            postalCode,
            dateOfBirth,
            avatarUrl,
            fullName
        } = fields
        return Api.post({
            path: '/userProfiles/createOrUpdate',
            data: {
                countryCode,
                phone,
                address,
                city,
                province,
                postalCode,
                dateOfBirth,
                avatarUrl,
                fullName
            },
            token: token
        })
    }
}

export default ProfileApi