import { useContext } from "react"
import { useEffect, useState } from "react"
import { Navigate, useLocation, useNavigate } from "react-router-dom"
import AuthContext from "../../../../../contexts/AuthContext"
import FirebaseService, { SEARCH_BY_BC_CNIC, SEARCH_BY_CNIC, SEARCH_BY_PHONE } from "../../../../../services/FirebaseService"
import ListingTemplate from "../../ListingTemplate"


const MemberDetail = () => {
    const location = useLocation()
    const { member } = location.state 

    const [cnicSearchCount, setCnicSearchCount] = useState(0)
    const [phoneSearchCount, setPhoneSearchCount] = useState(0)
const [activity, setActivity] = useState(0);
const [cnicSearchData, setCnicSearchData] = useState(0);
    const [searchLogRequestInProgress, setSearchLogRequestInProgress] = useState(false)
    const [searchLogs, setSearchLogs] = useState(null)

    const thClasses = "text-[12px] font-semibold border-b-[1px] border-[#2A2D4314] py-4 text-left"
    const tdClasses = "text-[12px] border-b-[1px] border-[#2A2D4314] py-4";
    const navigate = useNavigate();
    const authCtx = useContext(AuthContext);
    console.log("Authcontext", authCtx);
    let na = authCtx.user.sector;


    useEffect(() => {
        FirebaseService.getCountByTagAndUserId(member?.id, SEARCH_BY_PHONE,na).then(count => {
          setPhoneSearchCount(count)
        })
        FirebaseService.getLogsByTag(SEARCH_BY_CNIC,na).then(
          (logs) => {
            if(logs.docs.length > 0) {
              setActivity(logs.docs.map(doc => doc.data()))
              
            }
            // console.log(logs.docs);
            // setActivity(count)
          }
        );
        FirebaseService.getLogsByTagAndUserId(SEARCH_BY_CNIC, member?.id, na).then(logs => {
             if(logs.docs.length > 0) {
              setCnicSearchData(logs.docs.map((doc) => doc.data()));
              
            }
            // console.log('countby tag and userid',logs.docs);
            // setActivity(count)
          })
        // })
        FirebaseService.getCountByTagAndUserId(member?.id, [SEARCH_BY_CNIC, SEARCH_BY_BC_CNIC],na).then(count => {
          setCnicSearchCount(count)
        })
        setSearchLogRequestInProgress(true)
        FirebaseService.getSearchLogs(member?.id, [SEARCH_BY_CNIC, SEARCH_BY_BC_CNIC, SEARCH_BY_PHONE]).then(docs => {
            setSearchLogs(docs)
        }).finally(() => {
            setSearchLogRequestInProgress(false)
        })
    }, [])
    console.log("Data",searchLogs)

    if(!member) {
        return (
            <ListingTemplate>
                <div>
                    Not Found
                </div>
            </ListingTemplate>
        )
    }
    
    return (
      <ListingTemplate>
        <div className="flex flex-row">
          {/* <div className="text-[24px] font-bold mt-3">Sector</div> */}
          <div
            className=" text-[15px] cursor-pointer mt-5"
            onClick={(e) => {
              navigate("/team/record");
            }}
          >
            Team Record
          </div>
          <div className="ml-1 text-[15px] mt-5">/</div>
          {/* <div
          className="ml-1 mt-5 text-[15px] cursor-pointer"
          onClick={(e) => {
            navigate("/create/newteam");
          }}
        >
          Add Team member
        </div>
        <div className="ml-1 text-[15px] mt-5">/</div> */}
          <div className="ml-1 text-[15px]  text-gray-400 mt-5">
            {member.name}
          </div>
        </div>
        {/* <div>{member.name}</div> */}
        <div className="flex flex-col">
          <div className="flex flex-row space-x-2 h-[129px] mt-4">
            <div className="w-[33%] border-[1px] border-gray-200 rounded-md py-2 px-3 cursor-pointer">
              <div className="text-[14px] font-semibold mb-3 text-[#111827]">
                Total Searches
              </div>
              <div className="text-[24px] font-bold text-[#111827]">
                {cnicSearchCount + phoneSearchCount}
              </div>
              <div className="text-[14px] text-[#6B7280]">
                Total Searches (i.e. by CNIC, phone etc.)
              </div>
            </div>
            <div className="w-[33%] border-[1px] border-gray-200 rounded-md py-2 px-3">
              <div className="text-[14px] font-semibold mb-3 text-[#111827]">
                Total Searches by CNIC
              </div>
              <div className="text-[24px] font-bold text-[#111827]">
                {cnicSearchCount}
              </div>
              <div className="text-[14px] text-[#6B7280]">
                Total searches by CNIC
              </div>
            </div>
            <div className="w-[33%] border-[1px] border-gray-200 rounded-md py-2 px-3">
              <div className="text-[14px] font-semibold mb-3 text-[#111827] text-[#111827]">
                Total Searches by Phone
              </div>
              <div className="text-[24px] font-bold">{phoneSearchCount}</div>
              <div className="text-[14px] text-[#6B7280]">
                Total searches by phone number
              </div>
            </div>
          </div>
        </div>
        <div className=" text-[20px] font-bold text-gray-400 mt-5">
          {member.name}
        </div>
        <div>
          <table className="w-full my-3">
            <thead>
              <tr>
                <th className={thClasses}>CNIC / Phone</th>
                <th className={thClasses}>Location</th>
                <th className={thClasses}>Time</th>
              </tr>
            </thead>
            <tbody>
              {searchLogRequestInProgress ? (
                <tr>
                  <td colSpan={3} className="text-center py-4 text-[14px]">
                    Loading
                  </td>
                </tr>
              ) : !searchLogs || searchLogs.length === 0 ? (
                <tr>
                  <td colSpan={3} className="text-center py-4 text-[14px]">
                    Not Available
                  </td>
                </tr>
              ) : (
                searchLogs.map((log, index) => {
                  const logData = log.data();

                  return (
                    <tr>
                      <td className={tdClasses}>
                        {logData.cnic ?? logData.phoneNumber ?? "--"}
                      </td>
                      <td className={tdClasses}>{logData.latlng ?? "--"}</td>
                      <td className={tdClasses}>
                        {logData.createdAt?.toDate().toLocaleString()}
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
      </ListingTemplate>
    );
}

export default MemberDetail