import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import AppImages from "../../../assets/images";
import DataContext from "../../../contexts/DataContext";
import { Api } from "../../../utils/api";
import Button from "../../basic/Button";
import CheckboxFieldsGroup from "../../basic/CheckboxFieldsGroup";
import TextInputField from "../../basic/TextInputField";
import DashboardTemplate from "../../common/DashboardTemplate";
import { userRolesActions, userRoleThunk } from "./user_roles_slice";

function EditRole() {
    const navigate = useNavigate()
    const { id } = useParams()
    const dataCtx = useContext(DataContext)
    const getFields = () => {
        return {
            name: null,
            permissions: []
        }
    }
    const params = useParams()
    const dispatch = useDispatch()
    const roleReducer = useSelector(state => state.userRoles)
    const [formFields, setFormFields] = useState(getFields())
    const [fieldErrors, setFieldErrors] = useState(getFields())
    useEffect(() => {
        if(roleReducer.activeRoleData) {
            const fields = getFields()
            const data = roleReducer.activeRoleData
            fields.name = data.name 
            fields.permissions = data.Permissions?.map(p => p.name) ?? []
            console.log(fields)
            setFormFields(fields)
        } else if(roleReducer.data.length === 0) {
          dispatch(userRoleThunk.getAll({ activeRoleId: parseInt(params.id) }))
        } else {
          dispatch(userRolesActions.setActiveRole({ id: parseInt(params.id) }))
        }
    }, [roleReducer.activeRoleData])

    const handleUpdate = e => {
        e.preventDefault()
        if(!formFields.name) {
            setFieldErrors(old => ({...old, name: 'Please write the name of the role'}))
            return;
        }
        if(formFields.permissions.length === 0) {
            setFieldErrors(old => ({...old, permissions: 'Please select permissions!'}))
            return;
        }
        setFieldErrors(getFields())
        Api.put({
            path: `/userRoles/${roleReducer.activeRoleData?.id}`,
            data: formFields
        }).then(response => {
            console.log(response)
            if(response.success) {
                dispatch(userRolesActions.updateRole({ role: response.data.role }))
                navigate('/roles')
            }
        })
    }

    return (  
        <DashboardTemplate>
            <div className="relative my-4">
                <div 
                    className="bg-primary rounded-lg absolute right-0 p-2 cursor-pointer hover:bg-black"
                    onClick={e => {
                        navigate('/roles')
                    }}
                >
                    <img 
                        src={AppImages.crossWhite}
                        alt="close"
                    />
                </div>
            </div>
            <div>
                <div className="w-[100px] h-[100px] bg-gray-100 relative rounded-2xl mb-8">
                    <img 
                        src={AppImages.userBlackYellow}
                        alt={'Avatar Image'}
                        className="object-contain object-center absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] rounded-2xl mx-0 my-auto block"
                    />
                </div>

                <div>
                    <form onSubmit={handleUpdate}>
                        <TextInputField 
                            label="Role Name"
                            placeholder="Manager"
                            style={{
                                width: 'w-[500px]',
                                labelFontSize: 'text-[12px]',
                                inputFontSize: 'text-[10px]',
                                labelFontWeight: 'font-bold'
                            }}
                            value={formFields.name}
                            onChange={e => {
                                setFieldErrors(old => ({...old, name: null}))
                                setFormFields(old => ({...old, name: e.target.value}))
                            }}
                            error={fieldErrors.name}
                        />

                        <div>
                            <div>
                                {/* <CheckboxFieldsGroup
                                    label={'Job Type'}
                                    items={[
                                        { value: 'staff_listing', label: 'Listing of Staff' },
                                        { value: 'staff_add', label: 'Add Staff' },
                                        { value: 'staff_edit', label: 'Edit Staff' },
                                        { value: 'staff_delete', label: 'Delete Staff' },
                                    ]}
                                    checkedItemValues={['staff_add']}
                                    onChange={selectedValues => {
                                        console.log(selectedValues)
                                        setFieldErrors(old => ({...old, permissions: null}))
                                        setFormFields(old => ({
                                            ...old,
                                            permissions: selectedValues ?? []
                                        }))
                                    }}
                                    error={fieldErrors.permissions}
                                /> */}
                            </div>
                        </div>

                        <div className="my-4">
                            <p className="block font-bold my-2 text-black text-[12px]">Job Type</p>
                            {fieldErrors.permissions && <p className="my-1 text-[12px] text-primary pl-4">{fieldErrors.permissions}</p>}
                            {
                                [
                                    { value: 'staff_listing', label: 'Listing of Staff' },
                                    { value: 'staff_add', label: 'Add Staff' },
                                    { value: 'staff_edit', label: 'Edit Staff' },
                                    { value: 'staff_delete', label: 'Delete Staff' },
                                ].map((item, index) => (
                                    <div key={index} className="my-4">
                                        <label className="inline-block ml-4 text-[10px] font-normal checkmark-container">
                                            <input 
                                                type="checkbox" 
                                                className=""    
                                                value={item.value}
                                                checked={formFields.permissions.includes(item.value)}
                                                onChange={e => {
                                                    if(e.target.checked) {
                                                        setFormFields(old => ({
                                                            ...old,
                                                            permissions: [
                                                                ...old.permissions,
                                                                e.target.value
                                                            ]
                                                        }))
                                                    } else {
                                                        const p = formFields.permissions.filter(p => p !== item.value)
                                                        setFormFields(old => ({
                                                            ...old,
                                                            permissions: p
                                                        }))
                                                    }
                                                }}
                                            />
                                            <span className="checkmark"></span>
                                            <span className="mt-[1px] inline-block">{item.label}</span>
                                        </label>
                                    </div>
                                ))
                            }
                        </div>

                        <Button 
                            text={'Save Changes'}
                            style={{
                                fontSize: 'text-[14px]',
                                custom: 'py-2 px-16 mt-20'
                            }}
                        />
                    </form>
                </div>
            </div>
        </DashboardTemplate>
    );
}

export default EditRole;