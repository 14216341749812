import { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import AuthContext from "../../contexts/AuthContext";

function AdminRouteWrapper() {
    const authCtx = useContext(AuthContext)
    console.log("AuthCTX", authCtx.user);
    if(!authCtx.isLoggedIn) {
        return <Navigate to="/login" />
    }

    // if(authCtx.user.party !== 'PAKISTAN') {   
        
    //     return <Navigate to="/login" />
    // }
    // if (authCtx.user.party !== "PTI") {
    //   return <Navigate to="/login" />;
    // }
    
    return <Outlet />;
}

export default AdminRouteWrapper;