import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { useContext, useState } from "react"
import AuthContext from "../../../../contexts/AuthContext"
import { Api } from "../../../../utils/api"
import { RequestStatus } from "../../../../utils/enums"

const initialState = {
    data: [],
    activeEmployeeData: null,
    fetchDataRequestStatus: RequestStatus.IDLE,
}
 
export const employeeThunk = {
    
    getEmployees: createAsyncThunk('/employees/getAllSites', async (data, thunkAPI) => {
        const authCtx = useContext(AuthContext);
        console.log("Authcontext", authCtx);
        let na = authCtx.user.sector;
        const { fulfillWithValue, rejectWithValue } = thunkAPI
        //  let block = 260010105;
         let page = 1;
         try {
           const res = await Api.get({
             path: `/sectors-block-code?sector=${na}&page_no=${page}`,
           });
           console.log("RESSSe", res);
           if (res.success) {
             return fulfillWithValue({
               data: res.data.block_code,
               // activeCustomerId: data?.activeCustomerId
             });
           } else {
             throw res.message;
           }
         } catch (e) {
           return rejectWithValue({
             error: true,
             message: typeof e === "string" ? e : "Something went wrong",
             exception: e,
           });
         }
    }),
     getCustomerEmployees: createAsyncThunk('customers/employess/fetchAll', async (data, thunkAPI) => {
        const { fulfillWithValue, rejectWithValue } = thunkAPI
        // const id = data.id
        console.log(data)
        try {
            const res = await Api.get({path: `/admin/customers/${data.id}/employees`})
            console.log('res',res)
            if(res.success) {
                return fulfillWithValue({
                     data: res.data.data,
                })
            } else {
                throw res.message
            }
        } catch(e) {
            return rejectWithValue({
                error: true,
                message: typeof e === 'string' ? e : 'Something went wrong',
                exception: e
            })
        }
    }),
}

const employeeSlice = createSlice({
    name: 'employees',
    initialState,
    reducers: {
        setData: (state, action) => {
            state.data = action.payload
        },
        setActiveEmployeeData: (state, action) => {
            if(action.payload === null) {
                state.activeEmployeeData = null     
            } else {
                const { id } = action.payload 
                state.activeEmployeeData = state.data.find(item => item.id === id)
            }
        },
        addEmployee: (state, action) => {
            const { employee } = action.payload 
            state.data.push(employee)
        },
        updateEmployeeStatus: (state, action) => {
            const { id, newStatus } = action.payload
            const item = state.data.find(i => i.id === id)

            if(item) {
                item.status = newStatus
                state.data[state.data.indexOf(item)] = item 
            }
        },
        updateEmployee: (state, action) => {
            const { id, employee } = action.payload
            let emp = state.data.find(item => item.id === id)
            if(emp) {
                state.activeEmployeeData = employee 
                state.data[state.data.indexOf(emp)] = employee
            }
        },
        deleteEmployee: (state, action) => {
            const { id } = action.payload 
            const item = state.data.find(i => i.id === id)
            if(item) {
                const data = state.data
                data.splice(
                    data.indexOf(item),
                    1
                )
                state.data = data 
            }
        },
        addEmployeeDoc: (state, action) => {
            const emp = state.data.find(item => item.id === state.activeEmployeeData?.id)
            const { doc } = action.payload 
            if(emp && doc) {
                const docs = emp.EmployeeDocuments ?? []
                docs.push(doc)
                emp.EmployeeDocuments = docs 
                state.activeEmployeeData = emp 
                state.data[state.data.indexOf(emp)] = emp 
            }
        },
        updateEmployeeDoc: (state, action) => {
            const emp = state.data.find(item => item.id === state.activeEmployeeData?.id)
            if(emp) {
                const { id, document } = action.payload
                const docIndex = emp.EmployeeDocuments?.findIndex(item => item.id === id)
                if(docIndex !== -1) {
                    emp.EmployeeDocuments[docIndex] = document
                    state.activeEmployeeData = emp 
                    state.data[state.data.indexOf(emp)] = emp
                }
            }
        },
        createHrDetail: (state, action) => {
            const { detail } = action.payload 
            const emp = state.data.find(item => item.id === state.activeEmployeeData?.id)
            if(emp && detail) {
                emp.EmployeeHrDetail = detail 
                state.activeEmployeeData = emp 
                state.data[state.data.indexOf(emp)] = emp
            }
        },
        updateHrDetail: (state, action) => {
            const { detail } = action.payload 
            const emp = state.data.find(item => item.id === state.activeEmployeeData?.id)
            if(emp && detail) {
                emp.EmployeeHrDetail = detail 
                state.activeEmployeeData = emp 
                state.data[state.data.indexOf(emp)] = emp
            }
        },
        assignEmployeeToSite: (state, action) => {
            const { site } = action.payload 
            const emp = state.data.find(e => e.id === state.activeEmployeeData?.id) 
            if(emp && site) {
                const sites = emp.Sites ?? []
                const existAlready = sites.find(s => s.id === site.id)
                if(existAlready) {
                    const index = sites.indexOf(existAlready)
                    site.SiteEmployee.isPreferred = true 
                    sites[index] = site 

                    emp.Sites = sites 
                    state.activeEmployeeData = emp 
                    state.data[state.data.indexOf(emp)] = emp 
                } else {
                    sites.push(site)
                    emp.Sites = sites 
                    state.activeEmployeeData = emp 
                    state.data[state.data.indexOf(emp)] = emp 
                }
            }
        },
        unassignEmployeeFromSite: (state, action) => {
            const { site } = action.payload 
            const emp = state.data.find(e => e.id === state.activeEmployeeData?.id) 
            if(site && emp) {
                const sites = emp.Sites ?? []
                const existAlready = sites.find(s => s.id === site.id)
                if(existAlready) {
                    const index = sites.indexOf(existAlready)
                    site.SiteEmployee.isPreferred = false 
                    sites[index] = site

                    emp.Sites = sites 
                    state.activeEmployeeData = emp 
                    state.data[state.data.indexOf(emp)] = emp
                } else {
                    sites.push(site)
                    emp.Sites = sites 
                    state.activeEmployeeData = emp 
                    state.data[state.data.indexOf(emp)] = emp
                }
            }
        },
        updateSiteStatus: (state, action) => {
            const { siteId, newStatus } = action.payload
            if(state.data) {
                const data = state.data 
                data.forEach((employee, index) => {
                    let sites = employee.Sites ?? []
                    const site = sites.find(site => site.id === siteId)
                    if(site) {
                        site.status = newStatus
                        sites[sites.indexOf(site)] = site 
                        // if(newStatus === 'active') {
                        // } else {
                        //     sites = sites.filter(s => s.id !== siteId)
                        // }
                    }
                    employee.Sites = sites 
                    data[index] = employee
                })
                state.data = data 
            }
        }
    },
    extraReducers: (builder) => builder
        .addCase(employeeThunk.getEmployees.fulfilled, (state, action) => {
            state.data = action.payload.data ?? []
            if(action.payload.activeEmployeeId) {
                const activeEmployeeData = state.data.find(item => item.id === action.payload.activeEmployeeId)
                if(activeEmployeeData) {
                    state.activeEmployeeData = activeEmployeeData
                }
            }
            state.fetchDataRequestStatus = RequestStatus.COMPLETED
        })
        .addCase(employeeThunk.getEmployees.rejected, (state, action) => {
            state.fetchDataRequestStatus = RequestStatus.ERROR
        })
        .addCase(employeeThunk.getEmployees.pending, (state, action) => {
            state.fetchDataRequestStatus = RequestStatus.LOADING
        })

        .addCase(employeeThunk.getCustomerEmployees.fulfilled, (state, action) => {
            state.data = action.payload.data ?? []
            if(action.payload.activeEmployeeId) {
                const activeEmployeeData = state.data.find(item => item.id === action.payload.activeEmployeeId)
                if(activeEmployeeData) {
                    state.activeEmployeeData = activeEmployeeData
                }
            }
            state.fetchDataRequestStatus = RequestStatus.COMPLETED
        })
        .addCase(employeeThunk.getCustomerEmployees.rejected, (state, action) => {
            state.fetchDataRequestStatus = RequestStatus.ERROR
        })
        .addCase(employeeThunk.getCustomerEmployees.pending, (state, action) => {
            state.fetchDataRequestStatus = RequestStatus.LOADING
        })
})

export const employeeActions = employeeSlice.actions
export default employeeSlice.reducer