import axios from "axios";
import React from "react";
import { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import Chart from "react-google-charts";
import { Link, useNavigate, useParams } from "react-router-dom";
import AuthContext from "../../../../contexts/AuthContext";
import FirebaseService, {
  SEARCH_BY_BC_CNIC,
  SEARCH_BY_CNIC,
  SEARCH_BY_PHONE,
} from "../../../../services/FirebaseService";
import { Api } from "../../../../utils/api";
import ListingTemplate from "../ListingTemplate";
import { QueryClient, QueryCache, useQuery } from "react-query";
import AppImages from "../../../../assets/images";
import ReactModal from "react-modal";
import MultiplePrint from "./MultiplePrint";
import ReactLoading from "react-loading";
const FamilyListCount = () => {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  console.log("Authcontext", authCtx);
  const [data, setData] = useState([]);
  const [data1, setData1] = useState("");
  const [dat, setDat] = useState([]);
  const [susCount, setSusCount] = useState(0);
  const [blockCodeLenght, setLength] = useState(0);
  const [editableItem, setEditableItem] = useState(null);
  const params = useParams();
  let blockcode = params.blockcode;
  //  const [data2, setData2] = useState('');
  // const dataBarChart = data

  const optionsBarChart = {
    title: "Suspicious Voters in Halqa",
    colors: ["#FB7A21", "#ffff00"],
    chartArea: { width: "50%" },
    hAxis: {
      title: "Suspicious Voters",
      minValue: 0,
    },
    vAxis: {
      title: "Block Code",
    },
  };
  const [ageGraphData, setAgeGraphData] = useState(null);
  let na = authCtx.user.sector;
  let na1 = na.toLowerCase();
  // const { isLoading, error, dat } = useQuery("sectordashboardData", () =>
  //   fetch(

  //   ).then((res) => res.json())
  // );
  // console.log("Dataia", dat);
  useEffect(() => {
    const fetchData = async () => {
      const result = await axios(
        `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/familylist?blockcode=${blockcode}`
      );
    //   setSusCount(result.data.query?.reduce((acc, val) => acc + val.count, 0));
    //   setLength(result.data.query?.length);
    console.log("Resultttttttttttttttt",result)
      setData1(result.data.family);
    };
    fetchData();
    console.log("Dataaa1", data1, blockcode);
    const fetchData1 = async () => {
      const result = await axios(
        ` https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/sectors?sector=${na}`
      );
      setDat(result.data);
    };
    fetchData1();
    console.log("Dattttttt", dat);
    console.log("Naaaaaaaa", na, na1);
    axios(
      `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/bogus1?sector=${na}`
    )
      .then((res) => {
        const { data } = res;
        if (data.query && Array.isArray(data.query)) {
          const arr = data.query.map((item) => {
            return [item.block_code, item.count];
          });
          setData(arr);
        }
      })
      .catch((e) => {})
      .finally(() => {});
  }, []);
  console.log("RESDataaaa", data);

  const ths = [
    { title: "Gharana Number" },
    { title: "Total Voters" },
    { title: "" },
    // { title: "VoterList" },
    // { title: "Print" },
  ];
  const ths1 = [{ title: "Product" }, { title: "Sold" }];
  const modalStyle = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      maxHeight: "100vh",
      overflowY: "auto",
    },
    overlay: {
      backgroundColor: "rgba(0,0,0, 0.5)",
    },
  };
  return (
    <div>
      {/* <ReactModal
        isOpen={editableItem !== null}
        onRequestClose={() => setEditableItem(null)}
        style={modalStyle}
      >
        <div className="flex flex-col">
          <div className="mt-1">
            <MultiplePrint
              setIsModalOpen={setEditableItem}
              detail={editableItem}
            />
          </div>
        </div>
      </ReactModal> */}
      <ListingTemplate>
        <div className="flex flex-col">
          {/* <div className="flex flex-row justify-between h-[100px]">
            <div
              className="w-[33%] border-[1px] border-gray-200 rounded-md py-2 px-3"
              onClick={(e) => {
                // navigate("/blockcode/list");
              }}
            >
              <div className="text-[22px] font-bold text-gray-600">
                Total BlockCode
              </div>
              <div className="my-5 text-[20px]">{dat?.total_block_code}</div>
            </div>
            <div className="w-[33%] border-[1px] border-gray-200 rounded-md py-2 px-3">
              <div className="text-[22px] font-bold text-gray-600">
                Total Suspicious BlockCode
              </div>
              <div className="my-5 text-[20px] ">{blockCodeLenght}</div>
            </div>
            <div className="w-[33%] border-[1px] border-gray-200 rounded-md py-2 px-3">
              <div className="text-[22px] font-bold text-gray-600">
                Total Bogus
              </div>
              <div className="my-5 text-[20px] ">{susCount}</div>
            </div>
          </div> */}
          {/* <FBSearchCount /> */}
        </div>
        <div>
          {/* <Barchart/> */}
          {/* <Chart
            chartType="BarChart"
            width="100%"
            height="400px"
            data={[["Block Code", "Voter Count"], ...data]}
            options={optionsBarChart}
          /> */}
        </div>
        <div className="flex flex-row space-x-2 my-2 h-[550px]">
          <div className="w-full rounded-md px-3 py-2">
            <div>
              <div className="text-[20px] font-bold">
                Gharana number with total votes
              </div>
              <table className="w-full my-6">
                <thead>
                  <tr>
                    {ths.map((item, index) => (
                      <th
                        key={index}
                        className="text-[12px] font-semibold border-b-[1px] border-[#2A2D4314] bg-gray-100 px-3 py-4 text-center"
                      >
                        {item.title}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {data1 ? (
                    data1?.map((item, index) => (
                      <tr key={index} className="px-3">
                        <td className="text-[12px] border-b-[1px] border-[#2A2D4314] py-4 text-center">
                          {item.gharana_no}
                        </td>
                        <td className="text-[12px] border-b-[1px] border-[#2A2D4314] py-4 w-[30%] text-center">
                          {item.count}
                        </td>
                        <td>
                          <Link
                            to={`/gharana/voter/list/${blockcode}/${item.gharana_no}`}
                          >
                            <div className="bg-gray-300 rounded-lg p-2 mr-2 w-[100px] cursor-pointer text-[12px] font-bold hover:bg-slate-500">
                              Gharana List
                            </div>
                          </Link>
                        </td>

                        {/* <td>
                          <Link
                            to={`/confidence/blockcode/voters/list/${item.block_code}`}
                          >
                            <div className="bg-gray-500  p-2 cursor-pointer hover:bg-black w-[100px] mx-auto my-0">
                              <img
                                src={AppImages.eyeWhite}
                                className="w-[14px] mx-auto my-0"
                              />
                            </div>
                          </Link>
                        </td>
                        <td>
                          <div
                            className="bg-gray-500  p-2 cursor-pointer hover:bg-black w-[100px] mx-auto my-0"
                            onClick={() => setEditableItem(item)}
                          >
                            <img
                              src={AppImages.eyeWhite}
                              className="w-[14px] mx-auto my-0"
                            />
                          </div>
                        </td> */}
                      </tr>
                    ))
                  ) : (
                    <ReactLoading
                      color={"gray"}
                      height={"20px"}
                      width={"40px"}
                      className="my-2"
                    />
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </ListingTemplate>
    </div>
  );
};
// const FBSearchCount = () => {
//   const [cnicSearchCount, setCnicSearchCount] = useState(0);
//   const [phoneSearchCount, setPhoneSearchCount] = useState(0);
//   const authCtx = useContext(AuthContext);
//   console.log("Authcontext", authCtx);
//   let na = authCtx.user.sector;
//   useEffect(() => {
//     FirebaseService.getCountByTag(SEARCH_BY_PHONE, na).then((count) => {
//       setPhoneSearchCount(count);
//     });
//     FirebaseService.getCountByTag([SEARCH_BY_CNIC, SEARCH_BY_BC_CNIC], na).then(
//       (count) => {
//         setCnicSearchCount(count);
//       }
//     );
//   }, []);
//   return (
//     <div className="flex flex-row space-x-2 h-[129px] my-4">
//       <div className="w-[33%] border-[1px] border-gray-200 rounded-md py-2 px-3">
//         <div className="text-[14px] font-semibold mb-3 text-[#111827]">
//           Total Searches
//         </div>
//         <div className="text-[24px] font-bold text-[#111827]">
//           {cnicSearchCount + phoneSearchCount}
//         </div>
//         <div className="text-[14px] text-[#6B7280]">
//           Total Searches (i.e. by CNIC, phone etc.)
//         </div>
//       </div>
//       <div className="w-[33%] border-[1px] border-gray-200 rounded-md py-2 px-3">
//         <div className="text-[14px] font-semibold mb-3 text-[#111827]">
//           Total Searches by CNIC
//         </div>
//         <div className="text-[24px] font-bold text-[#111827]">
//           {cnicSearchCount}
//         </div>
//         <div className="text-[14px] text-[#6B7280]">Total searches by CNIC</div>
//       </div>
//       <div className="w-[33%] border-[1px] border-gray-200 rounded-md py-2 px-3">
//         <div className="text-[14px] font-semibold mb-3 text-[#111827] text-[#111827]">
//           Total Searches by Phone
//         </div>
//         <div className="text-[24px] font-bold">{phoneSearchCount}</div>
//         <div className="text-[14px] text-[#6B7280]">
//           Total searches by phone number
//         </div>
//       </div>
//     </div>
//   );
// };

export default FamilyListCount;
