import React from 'react'

const BlockAccount = () => {
  return (
    <div className="flex justify-center items-center w-full h-screen ">
      <div className="w-[40%] p-6 bg-slate-500 text-[35px] font-bold text-white text-center rounded-md">
        Your account is Blocked.
        <div>
         For more information please contact your
          account manager..
        </div>
      </div>
    </div>
  );
}

export default BlockAccount