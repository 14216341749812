import { useEffect, useState } from "react";
import ReactModal from "react-modal";
import AppImages from "../../assets/images";

function SimpleModal({
    isModalOpen = false,
    onClick,
    text = "",
    children,
    showCloseBtn = true,
    onClose = null 
}) {
    
    return (  
        <ReactModal
            isOpen={isModalOpen}
            style={{
                content: {
                    width: '345px',
                    height: '216px',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    border: '1px solid #F2385F',
                    borderRadius: '20px',
                    padding: 0
                },
                overlay: {
                    backgroundColor: 'rgba(0,0,0, 0.5)'
                }
            }}
        >
            <div className="relative w-full h-full"> 
                {showCloseBtn && <div>
                    <img 
                        src={AppImages.crossWhite} 
                        className="w-[20px] p-[5px] bg-primary rounded-lg absolute right-[10px] top-[10px] cursor-pointer" 
                        alt="close" 
                        onClick={onClose}
                    />
                </div>}
                {text && <h2 className="absolute w-full text-center top-[20%] left-[50%] translate-x-[-50%] transalte-y-[-50%] font-semibold text-[20px] leading px-12">{text}</h2>}
                <div className="p-2">
                    {children}
                </div>
                <div 
                    className="absolute bottom-0 w-full bg-primary text-center text-white py-4 cursor-pointer hover:bg-black" 
                    onClick={e => {
                        if(typeof onClick === 'function') {
                            onClick()
                        }
                    }}
                >
                    Done 
                </div>
            </div>
        </ReactModal>
    );
}

export default SimpleModal;