// import { Button } from 'bootstrap'
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react'
import { Link } from 'react-router-dom'
import TextInputField from '../../../basic/TextInputField'

const MultiplePrint = ({ setIsModalOpen, detail }) => {
  const [number, setNumber] = useState(0);
  const [block_code, setBlockCode]=useState(null)
  console.log();
  useEffect(() => {
    if (detail) {
      setBlockCode(detail?.block_code)
    }
  }, []);
  console.log("BlockCode",block_code)
  return (
    <div>
      <div className="flex flex-row space-x-3">
        {/* <div></div> */}
        <div>
          <div className="w-[360px] px-3">
            <TextInputField
              type="number"
              id="form_serialage"
              label="Enter Number of Reports You Want to print"
              placeholder="Number"
              style={{
                wrapper: "my-1",
                inputStyle: "w-full rounded-md",
              }}
              value={number}
              onChange={(e) => {
                setNumber(e.target.value);
              }}
            />
          </div>
          <Link to={`/form42/${block_code}/${number}`}>
            <button
              onClick={(e) => {}}
              className="w-[180px] ml-5 rounded-md bg-gray-500 text-[18px] hover:bg-gray-400 font-bold"
            >
              Submit
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MultiplePrint