import axios from "axios";
import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import AppImages from "../../../../assets/images";
import AuthContext from "../../../../contexts/AuthContext";
import ReactLoading from "react-loading";
import "./PrintStyles.css";
import FirebaseService from "../../../../services/FirebaseService";
const UniqueParchi = () => {
  const [data, setData] = useState("");
  const [d, sd] = useState("");
  const [f, sf] = useState("");
  const { id } = useParams();
  let Id = id;
  const authCtx = useContext(AuthContext);
  console.log("Authcontext", authCtx);
  useEffect(() => {
    let na = authCtx.user.sector;

    let page = 1;
    console.log("ID", Id, na);
    const fetchData = async () => {
      const result = await axios(
        `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/voter-list-blockCode?sector=${na}&page_no=${page}&block_code=${Id}`
      );
      console.log("resulttt", result.data);
      setData(result.data.voters);
      if (authCtx.user.sector === "PK 82") {
        setData(result.data);
      } else {
        let filteredData = result.data.sort((a, b) => {
          // If gharana_no is an empty string, move it to the bottom
          if (a.silsila_no === "" || a.silsila_no === null) return 1;
          if (b.silsila_no === "" || b.silsila_no === null) return -1;

          // Otherwise, sort based on gharana_no
          return a.silsila_no - b.silsila_no;
        });
        setData(filteredData);
      }
    };

    fetchData();
    const fetchData1 = async () => {
      const resultt = await axios(
        `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/get-polling-scheme?bc=${Id}&gender=male`
      );
      console.log("resulttt123", resultt.data.data.scheme);
      sd(resultt.data.data.scheme);
    };

    fetchData1();

    const fetchData2 = async () => {
      const resultt = await axios(
        `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/get-polling-scheme?bc=${Id}&gender=female`
      );
      console.log("result000", resultt.data.data.scheme);
      sf(resultt.data.data.scheme);
    };

    fetchData2();
  }, []);
  // const cnicFilter = ["35202-9935036-5", "35202-7605994-3", "35202-3520580-5"];

  // Apply the filter
  // const filteredData = data.filter((item) => cnicFilter.includes(item.cnic));
  console.log("Dataaa", data);
  const dataa = [
    // {
    //   block_code: "259110101",
    //   cnic: "35202-7605994-3",
    //   age: "68",
    //   silsila_no: "271",
    //   gharana_no: "101",
    //   name: "سعادت بدر",
    //   fathername: "رؤف احمد",
    //   address: "نمبر G-2-141 محلہ ماڈل ٹاؤن، لاہور",
    //   polling_station_area_urdu:
    //     "گورنمنٹ پروگریسڈ گرلز ہائی اسکول، 752/بی آر بلاک ماڈل ٹاؤن، لاہور۔ (مرد)",
    //   sector: "NA 127 / PP 161",
    // },
    // {
    //   block_code: "259110101",
    //   cnic: "35202-9935036-5",
    //   age: "38",
    //   silsila_no: "272",
    //   gharana_no: "101",
    //   fathername: "سعادت بدر",
    //   name: "حسن بدر",
    //   address: "نمبر G-2-141 محلہ ماڈل ٹاؤن، لاہور",
    //   polling_station_area_urdu:
    //     "گورنمنٹ پروگریسڈ گرلز ہائی اسکول، 752/بی آر بلاک ماڈل ٹاؤن، لاہور۔ (مرد)",
    //   sector: "NA 127 / PP 161",
    // },
    // {
    //   block_code: "259110101",
    //   cnic: "35202-3520580-5",
    //   age: "34",
    //   silsila_no: "273",
    //   gharana_no: "101",
    //   fathername: "سعادت بدر",
    //   name: "حارث بدر  ",
    //   address: "نمبر G-2-141 محلہ ماڈل ٹاؤن، لاہور",
    //   polling_station_area_urdu:
    //     "گورنمنٹ پروگریسڈ گرلز ہائی اسکول، 752/بی آر بلاک ماڈل ٹاؤن، لاہور۔ (مرد)",
    //   sector: "NA 127 / PP 161",
    // },
    // {
    //   block_code: "259090201",
    //   cnic: "35202-2393375-4",
    //   age: "",
    //   silsila_no: "1108",
    //   gharana_no: "705",
    //   fathername: "سعادت بدر",
    //   name: "ہما سعادت",
    //   address: "    ",
    //   polling_station_area_urdu:
    //     "ڈی پی ایس نرسری ونگز سکول ماڈل ٹاؤن لاہور (مشترکہ)",
    //   sector: "NA 128 / PP 161",
    // },
    // {
    //   block_code: "185011302",
    //   cnic: "35202-4694503-9",
    //   age: "",
    //   silsila_no: "24",
    //   gharana_no: "7",
    //   fathername: " ",
    //   name: " محمد مسکین",
    //   address: "    ",
    //   polling_station_area_urdu:
    //     "گورنمنٹ ماڈل گرلز ہائی سکول کینال ویو لاہور (مرد)",
    //   sector: "NA 126 / PP 167",
    // },
    // {
    //   block_code: "185011302",
    //   cnic: "35202-9482516-9",
    //   age: "",
    //   silsila_no: "25",
    //   gharana_no: "7",
    //   fathername: " محمد مسکین",
    //   name: " اقبال احمد",
    //   address: "    ",
    //   polling_station_area_urdu:
    //     "گورنمنٹ ماڈل گرلز ہائی سکول کینال ویو لاہور (مرد)",
    //   sector: "NA 126 / PP 167",
    // },
    {
      block_code: "185011302",
      cnic: "35202-0346193-5",
      age: "",
      silsila_no: "26",
      gharana_no: "7",
      fathername: " محمد مسکین",
      name: "عمران احمد",
      address: "    ",
      polling_station_area_urdu:
        "گورنمنٹ ماڈل گرلز ہائی سکول کینال ویو لاہور (مرد)",
      sector: "NA 126 / PP 167",
    },

    {
      block_code: "260170201",
      cnic: "35202-4474782-1",
      age: "",
      silsila_no: "1142",
      gharana_no: "535",
      fathername: " محمد مسکین",
      name: " بلال احمد",
      address: "    ",
      polling_station_area_urdu:
        "گورنمنٹ دارالعلوم پرائمری سکول لارچز کالونی (مرد)",
      sector: "NA 121 / PP 149",
    },
    {
      block_code: "179010304",
      cnic: "35202-7109538-0",
      age: "",
      silsila_no: "242",
      gharana_no: "93",
      fathername: "  زوجہ محمد آصف باجوہ",
      name: "  ثنا آصف",
      address: "مکان نمبر 1773، ماسک اسٹریٹ، محلہ ملک کالونی، سیالکوٹ کینٹ، ضلع سیالکوٹ ",
      polling_station_area_urdu:
        "گورنمنٹ جناح ایفیشنسی ہائی سکول کینٹ۔ (خواتین) - I",
      sector: "NA 71 / PP 46",
    },
    // {
    //   block_code: "179010304",
    //   cnic: "34603-6640090-3",
    //   age: "",
    //   silsila_no: "301",
    //   gharana_no: "93",
    //   fathername: "  محمد آصف باجوہ",
    //   name: "مہد باجوہ",
    //   address:
    //     "مکان نمبر 1773، ماسک اسٹریٹ، محلہ ملک کالونی، سیالکوٹ کینٹ، ضلع سیالکوٹ    ",
    //   polling_station_area_urdu:
    //     "گورنمنٹ جناح ایفیشنسی ہائی سکول کینٹ۔ (مرد) - II",
    //   sector: "NA 71 / PP 46",
    // },
  ];
  const handlePrint = async () => {
    window.print();

    let res = await FirebaseService.addLog({
      tag: "ParchiDownload",
      platform: "web",
      role: "manager",
      blockcode: Id,
      sector: authCtx.user.sector,
    });
    console.log("firebase response", res);
  };

  const wordsToReplace = [
    "مکان",
    "سال",
    "نمبر",
    "مین",
    "محلہ",
    "روڈ",
    "پارک",
    "،",
    "کالونی ",
    "لاہور ",
    "کی",
    "ضلع",
    "/",
    "ٹاور",
    "خالصہ",
    "خلاصہ",
  ];
  function isPreviousThan17Jan(date) {
    if (!(date instanceof Date)) {
      return false;
    }
    let date17 = new Date(2024, 0, 17, 0, 0, 0, 0);
    return date < date17;
  }
  function ImageSlice({ item }) {
    var yIncr = 0;
    if (item?.rowY) {
      if (authCtx.user.sector === "PP 172") {
        if (item.meta === "100") {
          yIncr = -10;
        } else {
          if (item?.name === "" || item?.address === "") {
            yIncr = -40;
          } else {
            // if()
            // yIncr = 65;
          }
        }
      } else if (["PP 208", "PP 14"].includes(authCtx.user.sector)) {
        if (item?.name === "" || item?.address === "") {
          yIncr = -40;
        } else {
          yIncr = 65;
        }
      } else if (
        ["PP 207", "NA 146"].includes(authCtx.user.sector) &&
        isPreviousThan17Jan(new Date(item.created_at))
      ) {
        if (item?.name === "" || item?.address === "") {
          yIncr = -30;
        } else {
          yIncr = 70;
        }
      } else if (["NA 120"].includes(authCtx.user.sector)) {
        yIncr = 50;
      } else if (["NA 127"].includes(authCtx.user.sector)) {
        yIncr = -10;
      } else if (["PP 59"].includes(authCtx.user.sector)) {
        yIncr = -20;
      } else {
        yIncr = -10;
      }
    }

    return (
      <>
        {authCtx.user.sector === "PP 152" ? (
          <img
            src={`https://res.cloudinary.com/election-experts/image/fetch/c_crop,h_55,x_0,w_4000,y_${
              parseInt(item?.rowY) + yIncr
            }/${encodeURIComponent(item?.url)}`}
            alt=""
            srcSet=""
            className=" w-full h-[40px]"
          />
        ) : (
          <img
            src={`https://res.cloudinary.com/election-experts/image/fetch/c_crop,h_80,x_0,w_4000,y_${
              parseInt(item?.rowY) + yIncr
            }/${encodeURIComponent(item?.url)}`}
            alt=""
            srcSet=""
            className=" w-full h-[40px]"
          />
        )}
      </>
    );
  }

  return (
    <div>
      <div className="flex flex-row ">
        <Link to={`/download/list`}>
          <div className="rounded-md h-[25px] text-center text-yellow-50 my-1 w-[90px] bg-slate-400 hover:bg-slate-600 text-[18px] ml-3 font-bold cursor-pointer">
            Back
          </div>
        </Link>
        <div
          className="rounded-md h-[25px] text-center text-yellow-50 my-1 w-[90px] bg-slate-400 hover:bg-slate-600 text-[18px] ml-3 font-bold cursor-pointer"
          onClick={handlePrint}
        >
          Print
        </div>
      </div>
      <div className=" flex flex-col w-full mb-[140px] border-[1px] border-dotted border-black "></div>
      {data ? (
        dataa.map((item, index) => (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                // margin: "1px",
              }}
              className="w-[90%] font-noori  ml-[40px] border-[1px] border-black"
              key={index}
            >
              <div className="flex flex-row w-full">
                <div className="w-[20%] flex flex-col items-center justify-center">
                  <div className="text-[17px] font-semibold">{item.sector}</div>
                  <img
                    src={AppImages.sher}
                    alt="image"
                    className=" w-full"
                  />
                </div>

                <div className="w-[80%] flex flex-col">
                  <div
                    style={{
                      direction: "rtl",
                      justifyContent: "space-around",
                      display: "flex",
                    }}
                  >
                    <div className="flex flex-row border-[0.5px] w-[50%] border-gray-500 py-[4px]">
                      <div
                        style={{
                          fontWeight: "bold",
                          paddingRight: "15px",
                          fontSize: 15,
                        }}
                      >
                        نام :
                      </div>
                      <div className=" font-[11px] mr-2">{item.name}</div>
                    </div>
                    <div className="flex flex-row border-[0.5px] w-[50%] border-gray-500 py-[4px]">
                      <div
                        style={{
                          fontWeight: "bold",
                          paddingRight: "15px",
                          fontSize: 15,
                        }}
                      >
                        والد کا نام :
                      </div>
                      <div className="font-[11px] mr-2">{item.fathername}</div>
                    </div>
                  </div>

                  <div
                    style={{
                      direction: "rtl",
                      justifyContent: "space-around",
                      display: "flex",
                    }}
                  >
                    <div className="flex flex-row border-[0.5px] w-[50%] border-gray-500 py-[4px]">
                      <div
                        style={{
                          fontWeight: "bold",
                          paddingRight: "15px",
                          fontSize: 15,
                        }}
                      >
                        شماریاتی کوڈ نمبر :
                      </div>
                      <div className=" font-[11px] mr-2">{item.block_code}</div>
                    </div>
                    <div className="flex flex-row border-[0.5px] w-[50%] border-gray-500 py-[4px]">
                      <div
                        style={{
                          fontWeight: "bold",
                          paddingRight: "15px",
                          fontSize: 15,
                        }}
                      >
                        شناختی کارڈ نمبر :
                      </div>
                      <div className="font-[11px] mr-2">{item.cnic}</div>
                    </div>
                  </div>
                  <div
                    className="flex flex-row w-full  px-[25px] py-[4px] border-[0.5px] border-gray-500"
                    dir="rtl"
                  >
                    <div
                      style={{
                        fontWeight: "bold",
                        paddingRight: "7px",
                        fontSize: 15,
                      }}
                    >
                      پتہ :
                    </div>
                    {authCtx.user.sector === "PP 152" ? (
                      ""
                    ) : (
                      <div className="font-[11px] mr-2">
                        {
                          item?.address?.length > 100
                            ? item?.address
                                .replace(/سال/gi, "")
                                .replace(/^\d{0,25}/, "") // Remove numbers from the start
                                .substr(0, 100) + ".."
                            : item?.address
                                ?.replace(/سال/gi, "")
                                .replace(/^\d{0,25}/, "") // Remove numbers from the start
                        }

                        {/* <img
                        src={`https://res.cloudinary.com/election-experts/image/fetch/c_crop,h_90,x_110,w_805,y_${
                          parseInt(item?.rowY) + 65
                        }/${encodeURIComponent(item?.url)}`}
                        alt=""
                        srcSet=""
                        className=" h-[40px]"
                      /> */}
                      </div>
                    )}
                  </div>

                  <div
                    className="flex flex-row w-full  px-[25px] py-[4px] border-[0.5px] border-gray-500"
                    dir="rtl"
                  >
                    <div
                      style={{
                        fontWeight: "bold",
                        paddingRight: "7px",
                        fontSize: 15,
                      }}
                    >
                      پولنگ سٹیشن :
                    </div>
                    <div className="font-[11px] mr-2">
                      {item.polling_station_area_urdu}
                    </div>
                  </div>
                </div>
              </div>
              {/* {authCtx.user.package !== "1" ? (
                <div
                  className="flex flex-row  w-full  px-[25px] py-[4px] border-[0.5px] border-gray-500"
                  dir="rtl"
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      paddingRight: "7px",
                      fontSize: 15,
                    }}
                  >
                    فون نمبر :
                  </div>
                  <div className=" font-[11px] mr-2 flex flex-row">
                    {item.voter_phone
                      ? JSON.parse(item.voter_phone).map((phone) => (
                          <p> , 0{phone} </p>
                        ))
                      : "--"}
                  </div>
                </div>
              ) : (
                <div></div>
              )} */}
            </div>
            <div className=" flex flex-col w-full my-[14px] border-[1px] border-dotted border-black "></div>
          </>
        ))
      ) : (
        <ReactLoading
          color={"gray"}
          height={"40px"}
          width={"70px"}
          className="my-2 justify-center"
        />
      )}
    </div>
  );
};

export default UniqueParchi;
