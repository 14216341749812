// import { Dialog } from "@material-tailwind/react";
import React, { useContext, useMemo } from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import AppImages from "../../../../../assets/images";
// import { Api } from "../../../../../utils/api";
// import { isValidEmail } from "../../../../../utils/helpers";
// import Button from "../../../../basic/Button";
// import TextInputField from "../../../../basic/TextInputField";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { siteActions } from "../../reducers/site_slice";
// import QrCode from "../../../../basic/QrCode";
// import Map from "../../../../basic/Map";
import QrCode from "../../../basic/QrCode";
import { Api } from "../../../../utils/api";
import Map from "../../../basic/Map";
import { siteActions } from "../reducers/site_slice";
import AppImages from "../../../../assets/images";
import TextInputField from "../../../basic/TextInputField";
import Button from "../../../basic/Button";
import axios from "axios";
import AuthContext from "../../../../contexts/AuthContext";

const EditModal = ({ setIsModalOpen, detail }) => {
  const dispatch = useDispatch();
  const siteReducer = useSelector((state) => state.sites);

  const getFields = () => {
    return {
      name: null,
      gharanaNo: null,
      silsilaNo: null,
      gender: null,
        address: null ,
        age: null
    };
  };

  const [formFields, setFormFields] = useState(getFields());
  const [fieldErrors, setFieldErrors] = useState(getFields());
  const [isPostRequestInProgress, setIsPostRequestInProgress] = useState(false);
  const authCtx = useContext(AuthContext);
  console.log("Authcontext", authCtx);

  useEffect(() => {
    if(detail) {
        const fields = getFields()
        fields.address = detail.address 
        fields.gender = detail.gender; 
       fields.name = detail.name 
        fields.gharanaNo = detail.gharana_no 
        fields.silsilaNo = detail.silsila_no 
        fields.age=detail.age

        setFormFields(fields)
    }
    // if (locationId) {
    //   const site = siteReducer.data.find(
    //     (item) => item.id === siteReducer.activeSiteData?.id
    //   );
    //   if (site) {
    //     const location = site.SiteLocations?.find(
    //       (item) => item.id === locationId
    //     );
    //     if (location) {
    //       const fields = getFields();
    //       fields.name = location.name;
    //       fields.serialId = location.serialId;
    //       fields.latitude = location.latitude;
    //       fields.longitude = location.longitude;
    //       setFormFields(fields);
    //       setMapLat(location.latitude);
    //       setMapLong(location.longitude);
    //     }
    //   }
    // }
  }, []);

  const onSubmit = (e) => {
     
     let na = authCtx.user.sector;
    e.preventDefault();
    setIsPostRequestInProgress(true)
     axios
       .post(
         `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/update-record?sector=${na}&cnic=${detail.cnic}`,
         {
           silsila_no: formFields.silsilaNo,
           gharana_no: formFields.gharanaNo,
           name: formFields.name,
           gender: formFields.gender,
           age: formFields.age,
           address: formFields.address
             
         }
       )
       .then((result) => {
         //  setData(result.data.family_tree);
         console.log("UPdatedData",result)
         if(result.data.isUpdated) {
            toast.success("Updated successfully!")
            window.location.reload(false);
         } else {
            toast.error("Error updating")
         }
       })
       .finally(() => {
         setIsPostRequestInProgress(false);
         
       });
    // if (!formFields.name) {
    //   setFieldErrors((old) => ({ ...old, name: "Name is required" }));
    //   return;
    // }

    // setFieldErrors(getFields());
    // if (siteReducer.activeSiteData?.id) {
    //   setIsPostRequestInProgress(true);
    //   Api.put({
    //     path: `/admin/sites/${siteReducer.activeSiteData?.id}/locations/${locationId}`,
    //     data: formFields,
    //   })
    //     .then((response) => {
    //       console.log(response);
    //       if (response.error) {
    //         if (response.apiResponse?.name === "validationError") {
    //           Object.keys(response.apiResponse?.message).forEach((key) => {
    //             fieldErrors[key] = response.apiResponse?.message[key];
    //           });
    //           setFieldErrors(fieldErrors);
    //           return;
    //         }
    //       }
    //       if (response.success) {
    //         dispatch(
    //           siteActions.updateSiteLocation({
    //             locationId: locationId,
    //             data: response.data.location,
    //           })
    //         );
    //         setIsModalOpen(null);
    //       }
    //     })
    //     .finally(() => {
    //       setIsPostRequestInProgress(false);
    //     });
    // }
  };

  return (
    <form className="invitationForm bg-white" onSubmit={onSubmit}>
      <ToastContainer />
      <div className="">
        <div className="relative">
          <img
            src={AppImages.crossBlack}
            alt="close"
            className="w-[10px] absolute right-0 cursor-pointer"
            onClick={(e) => setIsModalOpen(null)}
          />
        </div>
        <p className="font-bold text-[12px] text-center mb-4">Edit Data</p>

        <div className="flex flex-col justify-center items-center">
          <div className="w-[360px] px-3">
            <TextInputField
              id="form_contact_name"
              label="Name"
              placeholder="Name"
              style={{
                wrapper: "my-1",
                inputStyle: "w-full rounded-md",
              }}
              value={formFields.name}
              onChange={(e) => {
                setFieldErrors((old) => ({ ...old, name: null }));
                setFormFields((old) => ({ ...old, name: e.target.value }));
              }}
              error={fieldErrors.name}
            />
          </div>
          <div className="w-[360px] px-3">
            <TextInputField
              id="form_contacgender"
              label="Gender"
              placeholder="gender"
              style={{
                wrapper: "my-1",
                inputStyle: "w-full rounded-md",
              }}
              value={formFields.gender}
              onChange={(e) => {
                setFieldErrors((old) => ({ ...old, gender: null }));
                setFormFields((old) => ({ ...old, gender: e.target.value }));
              }}
              error={fieldErrors.gender}
            />
          </div>
          <div className="w-[360px] px-3">
            <TextInputField
              type="number"
              id="form_serialage"
              label="Age"
              placeholder="Age"
              style={{
                wrapper: "my-1",
                inputStyle: "w-full rounded-md",
              }}
              value={formFields.age}
              onChange={(e) => {
                setFormFields((old) => ({ ...old, age: e.target.value }));
              }}
              error={fieldErrors.age}
            />
          </div>

          <div className="w-[360px] px-3">
            <TextInputField
              id="form_serialid"
              label="Serial Number"
              placeholder="Serial Number"
              style={{
                wrapper: "my-1",
                inputStyle: "w-full rounded-md",
              }}
              value={formFields.silsilaNo}
              onChange={(e) => {
                setFormFields((old) => ({ ...old, silsilaNo: e.target.value }));
              }}
              error={fieldErrors.silsilaNo}
            />
          </div>
          <div className="w-[360px] px-3">
            <TextInputField
              id="form_seriagha"
              label="Gharana Number"
              placeholder="Gharana Number"
              style={{
                wrapper: "my-1",
                inputStyle: "w-full rounded-md",
              }}
              value={formFields.gharanaNo}
              onChange={(e) => {
                setFormFields((old) => ({ ...old, gharanaNo: e.target.value }));
              }}
              error={fieldErrors.gharanaNo}
            />
          </div>

          {/* <div className="w-[360px] px-3">
            <TextInputField
              id="form_serialaddress"
              label="Address"
              placeholder="Address"
              style={{
                wrapper: "my-1",
                inputStyle: "w-full rounded-md",
              }}
              value={formFields.address}
              onChange={(e) => {
                setFormFields((old) => ({ ...old, address: e.target.value }));
              }}
              error={fieldErrors.address}
            />
          </div> */}
          <div className="w-[360px] px-3">
            <label
              htmlFor="form_notes"
              className={`block my-2 pl-4 text-[12px] font-bold my-[2px] pl-0`}
            >
              Address
            </label>
            <textarea
              id="form_address"
              rows="6"
              className="w-full my-1 border-[1px] rounded-xl border-black text-[10px] p-3 outline-primary"
              placeholder="Address.."
              value={formFields.address}
              onChange={(e) => {
                setFormFields((old) => ({ ...old, address: e.target.value }));
              }}
              error={fieldErrors.address}
            ></textarea>
          </div>
        </div>

        <div className="flex flex-col justify-center items-center mb-8">
          <Button
            text="Update"
            style={{
              width: "w-[200px]",
              fontSize: "text-[12px]",
            }}
            isLoading={isPostRequestInProgress}
          />
        </div>
      </div>
    </form>
  );
};

export default EditModal;
