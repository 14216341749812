import axios from "axios";
import { useContext } from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import AppImages from "../../../../assets/images";
import AuthContext from "../../../../contexts/AuthContext";
import ReactLoading from "react-loading";
import ListingTemplate from "../ListingTemplate";
import Chart from "react-google-charts";
// import ListingTemplate from "../ListingTemplate";
import { customerActions, customerThunk } from "../reducers/customer_slice";
import _ from "lodash"

function PaidVoters({ children }) {
  const navigate = useNavigate();
  const customerReducer = useSelector((state) => state.customers);
  const dispatch = useDispatch();

  const ths1 = [
    { title: "BlockCode" },
    { title: "Id Card" },
    { title: "Sector" },
    { title: "Created At" },
    // { title: "Last LoggedIn" },
    // { title: "Last Location" },
    { title: "Vote" },
   
  ];
  const ths = [
    { title: "Id" },
    { title: "Name" },
    { title: "Email" },
    { title: "Total Voters" },
    { title: "Satisfied Voters" },

    { title: "Non Satisfied Voters" },
    { title: "View Report" },
  ];


  const authCtx = useContext(AuthContext);
  console.log("Authcontext", authCtx);
  const [data, setData] = useState("");
  const [data1, setData1] = useState("");
  const [groupedData, setGroupedData] = useState({})
  const { id } = useParams();
  let page = 1;
  let na = authCtx.user.sector;
  let Id = authCtx.user.id;
  useEffect(() => {
    let na = authCtx.user.sector;
    // let page = 1;

    const fetchData = async () => {
      console.log("ID", Id, na);
      const result = await axios.get(
        `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/get-premium-voter-stats-by-day`
      );
      var url = `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/get-premium-voter-stats-by-day`;
      console.log("URLLL", url);
      console.log("RESData", result);
      
      setData([
        ['Day', 'Count'],
        ...result.data.data.map(item => {
          const date = new Date(item.day)
          const showabledate = `${date.toLocaleDateString()}`
          return [showabledate, item.count]
        })
      ]);
    
    };
      const fetchData1 = async () => {
        console.log("ID", Id, na);
        const result = await axios.get(
          `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/get-premium-voters-stats`
        );
        var url = `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/get-premium-voters-stats`;
        console.log("2URLLL", url);
        console.log("RESData2", result);
        setData1(result.data);
       
      };

    fetchData();
    fetchData1()
  }, []);
  console.log("Darta", data);
  
  // const data2 = [
  //   ["Day", "Total"],
  //   [data?.data.day, 20],
  //   ["Category 2", 35],
  //   ["Category 3", 45],
  //   // Add more data as needed
  // ];

  const options = {
    title: "Day Wise Voters",
    hAxis: { title: "Category", minValue: 0 },
    vAxis: { title: "Value", minValue: 0 },
  };
 
  return (
    <ListingTemplate
      title={id}
      //   onSearch={handleOnSearch}
      selectBoxLabel="Select staff:"
      selectItems={[
        { text: "All", value: "all" },
        { text: "Active", value: "active" },
        { text: "Inactive", value: "inactive" },
      ]}
      //   onSelect={handleStatusFilter}
    >
      <div className="flex flex-row">
        {/* <div className="text-[24px] font-bold mt-3">Sector</div> */}
        <div className=" text-[15px] font-bold mt-5">{na}</div>
        <div className="ml-1 text-[15px] mt-5">/</div>
        {/* <div
          className="ml-1 mt-5 text-[15px] cursor-pointer"
          onClick={(e) => {
            navigate("/create/newteam");
          }}
        >
          Add PaidVoters member
        </div>
        <div className="ml-1 text-[15px] mt-5">/</div> */}

        <div className="ml-1 text-[15px]  text-gray-400 mt-5">
          Premium Voters
        </div>
      </div>
      <div className="text-[24px] font-bold">Premium Voters</div>
      <div className="text-[24px] font-bold">
        Total Premium Voters : {data1?.data?.total_count}
      </div>
      <div className="text-[24px] font-bold">
        Total Non Voters : {data1?.data?.total_no_count}
      </div>
      <div className="text-[24px] font-bold">
        Total Satisfied Voters : {data1?.data?.total_yes_count}
      </div>

      {/* <table>
        <thead>
          <tr>
            <td>User id</td>
            <td>Added users</td>
          </tr>
        </thead>
        <tbody>
          {Object.keys(groupedData).length ? Object.keys(groupedData).map(userid => (
            <tr>
              <td>{userid}</td>
              <td>{groupedData[userid].length ?? 0}</td>
            </tr>
          )) : <tr></tr>}
        </tbody>
      </table> */}
      <div className="w-full">
        {
          data?.length > 0 ? (
          <Chart
          chartType="BarChart"
          data={data}
          options={options}
          graph_id="BarChart"
          width={"100%"}
          height={"400px"}
          legend_toggle
        />
          ):('')
        }
       
      </div>

      <table className="w-full my-6">
        <thead>
          <tr>
            {ths.map((item, index) => (
              <th
                key={index}
                className={
                  authCtx.user.party === "PTI"
                    ? "text-[14px] font-semibold border-b-[1px] border-[#2A2D4314] text-white bg-[#59a23a]  px-3 py-4 text-left"
                    : "text-[12px] font-semibold border-b-[1px] border-[#2A2D4314] bg-gray-100 px-3 py-4 text-left"
                }
              >
                {item.title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data1 ? (
            data1?.data.user_wise_data.map((item, index) => (
              <tr key={index} className="px-3">
                <td className="text-[12px] border-b-[1px] border-[#2A2D4314] py-4 w-[170px]">
                  {item.user_id}
                </td>
                <td className="text-[12px] border-b-[1px] border-[#2A2D4314] py-4 w-[170px]">
                  {item.user_name}
                </td>
                <td className="text-[12px] border-b-[1px] border-[#2A2D4314] py-4 w-[170px]">
                  {item.user_email}
                </td>
                <td className="text-[12px] border-b-[1px] border-[#2A2D4314] py-4 w-[200px]">
                  {item.count}
                </td>
                <td className="text-[12px] border-b-[1px] border-[#2A2D4314] py-4 w-[200px]">
                  {item.yes_count}
                </td>
                <td className="text-[12px] border-b-[1px] border-[#2A2D4314] py-4 w-[200px]">
                  {item.no_count}
                </td>
                <td>
                  {/* <Link to="/team/member/cnicsearch" state={{ member: item }}> */}
                  <div
                    className="bg-gray-500 rounded-lg p-2 mr-2 w-[130px] cursor-pointer hover:bg-black"
                    onClick={() => navigate(`/voter/report/${item.user_id}`)}
                  >
                    <img
                      src={AppImages.eyeWhite}
                      className="w-[14px] mx-auto my-0"
                    />
                  </div>
                  {/* </Link> */}
                </td>
              </tr>
            ))
          ) : (
            <div className="flex flex-row">
              <div className="flex justify-center">
                <ReactLoading
                  color={"gray"}
                  height={"20px"}
                  width={"40px"}
                  className="my-2"
                />
              </div>
            </div>
          )}
        </tbody>
      </table>
    </ListingTemplate>
  );
}

export default PaidVoters;
