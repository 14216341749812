import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { useContext } from "react"
import AuthContext from "../../../../contexts/AuthContext"
import { Api } from "../../../../utils/api"
import { RequestStatus } from "../../../../utils/enums"

const initialState = {
    data: [],
    fetchDataRequestStatus: RequestStatus.IDLE,
    activeCustomerData: null 
}

export const customerThunk = {
    getCustomers: createAsyncThunk('customers/fetchAll', async (data, thunkAPI) => {
        const { fulfillWithValue, rejectWithValue } = thunkAPI
        const authCtx = useContext(AuthContext);
        console.log("Authcontext", authCtx);
        let na = authCtx.user.sector;
        let block = 260010105
        let page = 1
        try {
            const res = await Api.get({
              path: `/sectors-block-code?sector=${na}&page_no=${page}&block_code=${block}`,
            });
            console.log("RESSS",res)
            if(res.success) {
                return fulfillWithValue({
                    data: res.data.voters,
                    // activeCustomerId: data?.activeCustomerId
                })
            } else {
                throw res.message
            }
        } catch(e) {
            return rejectWithValue({
                error: true,
                message: typeof e === 'string' ? e : 'Something went wrong',
                exception: e
            })
        }
    }),
    
    getCustomerByUserId: createAsyncThunk('customers/fetchByUserId', async (data, thunkAPI) => {
        const { fulfillWithValue, rejectWithValue } = thunkAPI
        try {
            const { id } = data
            const res = await Api.get({path: `/admin/customers/${id}/customer-details`})
            console.log(res.data)
            if(res.success) {
                return fulfillWithValue({
                    data: [res.data.customer],
                })
            } else {
                throw res.message
            }
        } catch(e) {
            return rejectWithValue({
                error: true,
                message: typeof e === 'string' ? e : 'Something went wrong',
                exception: e
            })
        }
    }),

}

const customerSlice = createSlice({
    name: 'customers',
    initialState,
    reducers: {
        setData: (state, action) => {
            state.data = action.payload
        },
        updateItemStatus: (state, action) => {
            const { id, newStatus } = action.payload
            const item = state.data.find(i => i.id === id)

            if(item) {
                item.status = newStatus
                state.data[state.data.indexOf(item)] = item 
            }
        },
        setActiveCustomer: (state, action) => {
            const { id } = action.payload 
            state.activeCustomerData = state.data.find(item => item.id === id)
        },
        unsetActiveCustomer: (state, action) => {
            state.activeCustomerData = null 
        },
        updateCustomerDetail: (state, action) => {
            const { id, details } = action.payload
            let customer = state.data.find(item => item.id === id)
            if(customer) {
                customer.notes = details?.notes
                customer.name = details?.name 
                customer.referenceNumber = details?.referenceNumber
                customer.email = details?.email 
                customer.phone = details?.phone 
                customer.Location = {
                    city: details?.city,
                    country: details?.country,
                    postalCode: details?.postalCode,
                    state: details?.state,
                    address: details?.address
                }
                state.data[state.data.indexOf(customer)] = customer
            }
        },
        updateCustomerEventDetails: (state, action) => {
            const customer = state.data.find(item => item.id === state.activeCustomerData?.id)
            if(customer) {
                const { eventDetails } = action.payload.data 
                customer.CustomerEventDetail = eventDetails
                state.activeCustomerData = customer 
                state.data[state.data.indexOf(customer)] = customer
            }
        },
        updateCustomer: (state, action) => {
            const { customer } = action.payload 
            if(customer) {
                state.data[0] = customer 
                state.activeCustomerData = customer
            }
        },
        addSubCustomer: (state, action) => {
            const customer = state.data.find(item => item.id === state.activeCustomerData?.id)
            if(customer) {
                const {subCustomer} = action.payload
                const subCustomers = customer.SubCustomers ? customer.SubCustomers : []
                subCustomers.push(subCustomer)
                customer.SubCustomers = subCustomers
                state.activeCustomerData.SubCustomers = subCustomers
                state.data[state.data.indexOf(customer)] = customer
            }
        },
        deleteSubCustomer: (state, action) => {
            const customer = state.data.find(item => item.id === state.activeCustomerData?.id)
            if(customer) {
                const { id } = action.payload 
                const subcustomer = customer.SubCustomers?.find(item => item.id === id)
                if(subcustomer) {
                    customer.SubCustomers.splice(
                        customer.SubCustomers.indexOf(subcustomer),
                        1
                    )
                    console.log('updating')
                    state.activeCustomerData = customer 
                    state.data[state.data.indexOf(customer)] = customer
                }
            }
        },
        updateSubCustomer: (state, action) => {
            const customer = state.data.find(item => item.id === state.activeCustomerData?.id)
            if(customer) {
                const { id, data } = action.payload 
                const subcustomer = customer.SubCustomers?.find(item => item.id === id)
                if(subcustomer && data) {
                    const { firstName, lastName, phone, email, site, avatar } = data
                    subcustomer.firstName = firstName
                    subcustomer.lastName = lastName
                    subcustomer.email = email 
                    subcustomer.phone = phone 
                    subcustomer.site = site 
                    subcustomer.avatar = avatar

                    const index = customer.SubCustomers?.indexOf(subcustomer)
                    customer.SubCustomers[index] = subcustomer
                    console.log('updating bro')
                    state.activeCustomerData = customer 
                    state.data[state.data.indexOf(customer)] = customer
                }
            }
        },
        addCustomerDoc: (state, action) => {
            const customer = state.data.find(item => item.id === state.activeCustomerData?.id)
            if(customer) {
                const docs = customer.CustomerDocuments ?? []
                const { doc } = action.payload 
                docs.push(doc)
                customer.CustomerDocuments = docs 
                state.activeCustomerData = customer 
                state.data[state.data.indexOf(customer)] = customer
            }
        },
        updateCustomerDoc: (state, action) => {
            const customer = state.data.find(item => item.id === state.activeCustomerData?.id)
            if(customer) {
                const { id, document } = action.payload
                const docIndex = customer.CustomerDocuments?.findIndex(item => item.id === id)
                if(docIndex !== -1) {
                    customer.CustomerDocuments[docIndex] = document
                    state.activeCustomerData = customer 
                    state.data[state.data.indexOf(customer)] = customer
                }
            }
        },
        addCustomer: (state, action) => {
            const {customer} = action.payload 
            if(customer) {
                state.data.push(customer)   
            }
        },
        deleteCustomer: (state, action) => {
            const { customerId } = action.payload
            const customer = state.data.find(item => item.id === customerId)
            if(customer) {
                state.data = state.data.filter(item => item.id !== customerId)
            }
        }
    },
    extraReducers: (builder) => builder
        .addCase(customerThunk.getCustomers.fulfilled, (state, action) => {
            state.data = action.payload.data ?? []
            if(action.payload.activeCustomerId) {
                const activeCustomerData = state.data.find(item => item.id === action.payload.activeCustomerId)
                if(activeCustomerData) {
                    state.activeCustomerData = activeCustomerData
                }
            }
            state.fetchDataRequestStatus = RequestStatus.COMPLETED
        })
        .addCase(customerThunk.getCustomers.rejected, (state, action) => {
            state.fetchDataRequestStatus = RequestStatus.ERROR
        })
        .addCase(customerThunk.getCustomers.pending, (state, action) => {
            state.fetchDataRequestStatus = RequestStatus.LOADING
        })

        .addCase(customerThunk.getCustomerByUserId.fulfilled, (state, action) => {
            state.data = action.payload.data ?? []
            if(state.data.length > 0) {
                const activeCustomerData = state.data[0]
                console.log(state.data)
                if(activeCustomerData) {
                    state.activeCustomerData = activeCustomerData
                }
            }
            state.fetchDataRequestStatus = RequestStatus.COMPLETED
        })
        .addCase(customerThunk.getCustomerByUserId.rejected, (state, action) => {
            state.fetchDataRequestStatus = RequestStatus.ERROR
        })
        .addCase(customerThunk.getCustomerByUserId.pending, (state, action) => {
            state.fetchDataRequestStatus = RequestStatus.LOADING
        })
    
})

export const customerActions = customerSlice.actions
export default customerSlice.reducer