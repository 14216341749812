import { useContext, useEffect, useState } from "react";
import SettingsContext from "../../contexts/SettingsContext";
import Header from "./header/Header";
import Sidebar from "./sidebar/Sidebar";
import { ModuleData } from "../modules/mod.data";
// import Header from "./header/Header";

function DashboardTemplate({ 
    children, 
    header, 
    style={
        wrapper: ''
    } 
}) {
    
    const settings = useContext(SettingsContext)
    const [activeModule, setActiveModule] = useState(ModuleData.find(i => i.id === settings.activeModuleId))

    useEffect(() => {
        setActiveModule(ModuleData.find(i => i.id === settings.activeModuleId))
    }, [settings.activeModuleId])

    return (
        <>
            <div className={`bg-white`}>
                <div className="layout w-screen flex space-x-2">
                    <Sidebar />
                    <div className="flex flex-col flex-[1_1_80%] ">
                        {header ? header : <Header 
                            title={'Welcome to ElectionExpert!'}
                        />}
                    <div className={`flex-1 rounded-2xl px-1 bg-white w-full mt-1 py-8 ${style.wrapper ? style.wrapper : ''}`}>
                        {children}
                        {/* {activeModule && activeModule.contentStyle?.center ? (<div className="flex h-full w-full justify-center items-center">
                            {activeModule.content}
                        </div>) : activeModule.content} */}
                    </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default DashboardTemplate;