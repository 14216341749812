import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import AuthContext from '../../../../contexts/AuthContext';
import ReactLoading from "react-loading";
import AppImages from '../../../../assets/images';

const NewList = () => {
     const authCtx = useContext(AuthContext);
     const [data, setData] = useState("");
      useEffect(() => {
        // let na = authCtx.user.sector;
        let page = 1;
       
        const fetchData = async () => {
          const result = await axios(
            `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/diagram-voter-data`
          );
          console.log("Resultt",result.data.data)

          // let filteredData = result.data.voters.sort(
          //   (a, b) => a.silsila_no - b.silsila_no
          // );
        //   let filteredData = result.data.voters.sort((a, b) => {
        //     if (a.silsila_no === null && b.silsila_no !== null) {
        //       return 1; // Move entries with null silsila_no to the bottom
        //     } else if (a.silsila_no !== null && b.silsila_no === null) {
        //       return -1; // Move entries with non-null silsila_no to the top
        //     } else {
        //       return a.silsila_no - b.silsila_no; // Sort by silsila_no for non-null values
        //     }
        //   });

          // Now, use filteredData as needed in your code

        //   if (ageFilter) {
        //     filteredData = filteredData.filter((item) =>
        //       item.age.toString().includes(ageFilter)
        //     );
        //   }

        //   if (addressFilter) {
        //     filteredData = filteredData.filter((item) =>
        //       item.address.includes(addressFilter)
        //     );
        //   }
        //   console.log("Dataaaa", filteredData);
          setData(result.data.data);
        };

        fetchData();
      }, []);
     const wordsToReplace = [
       "مکان",
       "سال",
       "نمبر",
       "مین",
       "محلہ",
       "روڈ",
       "پارک",
       "،",
       "کالونی ",
       "لاہور ",
       "کی",
       "ضلع",
       "/",
       "ٹاور",
       "خالصہ",
       "خلاصہ",
     ];
     const generateRandomNumber = () => {
       // Generate a random number between 18 and 36
       const randomNumber = Math.floor(Math.random() * (36 - 18 + 1)) + 18;
       return randomNumber;
     };
  return (
    <>
      <div className="ml-3">
        <img
          src={AppImages.barcode}
          alt=""
          srcset=""
          className="h-[40px] ml-6 mt-2"
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          // height: "100vh",
        }}
      >
        {" "}
        <table className="w-[99%] my-1 px-2 ">
          <thead>
            <tr className="font-noori">
              <th
                className="text-[19px] font-semibold border-[5px] border-[black] py-4 text-center px-2"
                dir="rtl"
              >
                ووٹر کا نشان انگھوٹا
              </th>
              <th
                className="text-[19px] font-semibold border-[5px] border-[black] py-4 text-center px-2"
                dir="rtl"
              >
                ووٹر کی تصویر
              </th>

              <th
                className="text-[19px] font-semibold border-[5px] text-center border-[black] py-4 px-2"
                dir="rtl"
              >
                پتہ
              </th>
              <th
                className="text-[19px] font-semibold border-[5px] border-[black] py-4 text-center "
                dir="rtl"
              >
                عمر
              </th>

              <th
                className="text-[19px] font-semibold border-[5px] border-[black] py-4 text-center "
                dir="rtl"
              >
                شناختی کارڈ نمبر
              </th>
              <th
                className="text-[19px] font-semibold border-[5px] border-[black] py-4 w-[235px] text-center"
                dir="rtl"
              >
                والد کا نام
              </th>

              <th
                className="text-[19px] font-semibold border-[5px] border-[black] py-4 w-[190px] text-center"
                dir="rtl"
              >
                نام
              </th>
              <th
                className="text-[19px] font-semibold border-[5px] border-[black] py-4 w-[80px] text-center"
                dir="rtl"
              >
                گھرانہ نمبر
              </th>
              <th
                className="text-[19px] font-semibold border-[5px] border-[black] w-[80px] py-4 text-center"
                dir="rtl"
              >
                سلسلہ نمبر
              </th>
            </tr>
          </thead>
          <tbody>
            {data ? (
              data.map((item, index) => (
                <tr key={index}>
                  <td
                    className="text-[17px] border-[2px] border-[black] px-1 py-4 w-[350px] font-noori"
                    dir="rtl"
                  ></td>
                  <td
                    className="text-[17px] border-[2px] border-[black] items-center justify-center pt-1 w-[130px] font-noori"
                    dir="rtl"
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <img
                        src={AppImages.demo}
                        alt=""
                        className="h-[115px] w-full"
                      />
                    </div>
                  </td>

                  <td
                    className="text-[19px] border-[2px] border-[black] py-4 px-2 w-[650px] font-noori"
                    dir="rtl"
                  >
                    سی ڈی جی بوائز پرائمری سکول نواں پنڈ لاہور
                    {/* {item.address?.length > 100
                    ? item.address?.replace(/سال/gi, "").substr(0, 100) + ".."
                    : item.address?.replace(/سال/gi, "")} */}
                  </td>
                  <td
                    className="text-[18px] border-[2px] px-1 border-[black] py-4 w-[35px] font-noori"
                    dir="rtl"
                  >
                    <div className="flex flex-col w-[35px] items-center justify-center">
                      <div className=''>{generateRandomNumber()}</div>
                      <div className="text-[19px] font-noori">سال</div>
                    </div>
                  </td>

                  <td
                    className="text-[16px] border-[2px] font-semibold border-[black] text-center py-4 w-[185px] "
                    dir="rtl"
                  >
                    {item.cnic}
                  </td>
                  <td
                    className="text-[19px] border-[2px] px-2 border-[black] py-4  font-noori"
                    dir="rtl"
                  >
                    {item.sur_name}
                  </td>

                  <td
                    className="text-[19px] border-[2px] px-2 border-[black] py-4  font-noori"
                    dir="rtl"
                  >
                    {
                      item.name
                      //   ?.length > 44
                      // ? item.name
                      //     ?.replace(/[^\W]/gi, "")
                      //     .replace(/[-*]/g, " ")
                      //     .replace(new RegExp(wordsToReplace.join("|"), "gi"), "")
                      //     .substr(0, 44) + ""
                      // : item.name
                      //     ?.replace(/[^\W]/gi, "")
                      //     .replace(/[-*]/g, " ")
                      //     .replace(
                      //       new RegExp(wordsToReplace.join("|"), "gi"),
                      //       ""
                      //     )
                    }
                  </td>
                  <td
                    className="text-[17px] border-[2px] px-1 border-[black] text-center py-4 w-[50px] "
                    dir="rtl"
                  >
                    {generateRandomNumber()}
                  </td>
                  <td
                    className="text-[17px] border-[2px] px-1 border-[black] text-center py-4 w-[50px] "
                    dir="rtl"
                  >
                    {item.silsila_no}
                  </td>
                </tr>
              ))
            ) : (
              <ReactLoading
                color={"gray"}
                height={"40px"}
                width={"70px"}
                className="my-2 justify-center"
              />
            )}
          </tbody>
        </table>
      </div>
      <div className="flex flex-row  mt-5 font-noori font-bold" dir="rtl">
        <div className="mr-[310px] text-[23px]">لاہور-261010451</div>
        <div className="mr-[260px] text-[23px]">صفحہ نمبر: 14/46</div>
        <div className="mr-[355px] text-[24px]">
          پرنٹنگ کی تاریخ: 01 دسمبر 2023 دستخط رجسٹریشن آفیسر
        </div>
      </div>
    </>
  );
}

export default NewList