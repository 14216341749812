import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Api } from "../../../utils/api";
import { RequestStatus } from "../../../utils/enums";

const initialState = {
    data: [],
    activeRoleData: null,
    fetchDataRequestStatus: RequestStatus.IDLE
}

export const userRoleThunk = {
    getAll: createAsyncThunk('/userRoles/getAll', async (data, thunkAPI) => {
        const { fulfillWithValue, rejectWithValue } = thunkAPI
        try {
            const res = await Api.get({path: '/userRoles/all'})
            console.log(res)
            if(res.success) {
                return fulfillWithValue({
                    data: res.data.data,
                    activeRoleId: data?.activeRoleId 
                })
            } else {
                throw res.message
            }
        } catch(e) {
            return rejectWithValue({
                error: true,
                message: typeof e === 'string' ? e : 'Something went wrong',
                exception: e
            })
        }
    })
}

const userRoleSlice = createSlice({
    name: 'userRoles',
    initialState,
    reducers: {
        setActiveRole: (state, action) => {
            let { role, id } = action.payload 
            if(role) {
                state.activeRoleData = role 
            } else if (id) {
                role = state.data.find(r => r.id === id)
                if(role) {
                    state.activeRoleData = role 
                }
            }
        },
        updateRole: (state, action) => {
            const { role } = action.payload
            const r = state.data.find(i => i.id === state.activeRoleData?.id)
            if(r) {
                state.activeRoleData = role 
                state.data[state.data.indexOf(r)] = role 
            }
        },
        deleteRole: (state, action) => {
            const { roleId } = action.payload
            const r = state.data.find(i => i.id === roleId)
            if(r) {
                state.data = state.data.filter(i => i.id !== roleId)
            }
        },
        addRole: (state, action) => {
            const { role } = action.payload 
            if(role) {
                state.data.push(role)
            }
        }
    },
    extraReducers: (builder) => builder
        .addCase(userRoleThunk.getAll.fulfilled, (state, action) => {
            state.data = action.payload.data ?? []
            if(action.payload.activeRoleId) {
                const activeData = state.data.find(item => item.id === action.payload.activeRoleId)
                if(activeData) {
                    state.activeRoleData = activeData
                }
            }
            state.fetchDataRequestStatus = RequestStatus.COMPLETED
        })
        .addCase(userRoleThunk.getAll.rejected, (state, action) => {
            state.fetchDataRequestStatus = RequestStatus.ERROR
        })
        .addCase(userRoleThunk.getAll.pending, (state, action) => {
            state.fetchDataRequestStatus = RequestStatus.LOADING
        })
})

export const userRolesActions = userRoleSlice.actions
export default userRoleSlice.reducer