import React from 'react'
import { QueryClient, QueryCache, useQuery } from "react-query";
const ReactQuery = () => {
    const queryClient = new QueryClient();
    let sector = 'NA 120'
    let start = Date.now();
     const { isLoading, error, data } = useQuery(
       "repoData",
       () =>
         fetch(
           `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/geo-location-polling-station?sector=${sector}`
         ).then((res) => {
           
           console.log(res);
           return res.json();
         })
          ((res) => console.log(res))
     );
//      const myDat = ()=>{
//          let start = Date.now();
// fetch(
//            `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/geo-location-polling-station?sector=${sector}`
//          ).then((res) => res.json())
//          .then(res => {
//             const end = Date.now()
//             console.log(end-start)
//          })
//      }
//      myDat()
    //  if (isLoading) return "Loading...";
  
    //  if (error) return "An error has occurred: " + error.message;
    //  console.log("Data",data)
 let end = Date.now();
 console.log(end - start);
    // console.log("Daatttaa", queryClient.getQueryData("repoData") ) 
 
       

    //  console.log("CacheDataaa",query)
  return (
    <div>
      ReactQuery
      {/* <h1>{data.name}</h1> */}
      <p>{}</p>
    </div>
  );
}

export default ReactQuery