import axios from "axios";
import { useContext } from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import AuthContext from "../../../../../contexts/AuthContext";
import ListingTemplate from "../../ListingTemplate";
import ReactLoading from "react-loading";
import { toSentenceCase } from "../../../../../utils/helpers";

function ReportByBlockCodeandCaste({ children }) {
  const navigate = useNavigate();

  const ths = [
    { title: "Block Code" },
    { title: "Khan" },
    { title: "Khokhar" },
    { title: "Chaudhary" },
    { title: "Mian" },
    { title: "Masseh" },
    { title: "Sheikh" },
    { title: "Butt" },
    { title: "Rajput" },
    { title: "Kaim Khani" },
    { title: "Gujjar" },
    { title: "Rao" },
    { title: "Jutt" },
  ];

  const authCtx = useContext(AuthContext);
  console.log("Authcontext", authCtx);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false)
  const { id } = useParams();
  let page = 1;
  let na = authCtx.user.sector;
  useEffect(() => {
    let na = authCtx.user.sector;
    let Id = 1;
    let page = 1;

    const fetchData = async () => {
      console.log("ID", Id, na, page);
      setIsLoading(true)
      axios.get(
        `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/block-code-by-cast?sector=${na}`
      ).then(result => {
        setData(result.data?.data);
      })
      .finally(() => {
        setIsLoading(false);
      })
    };

    fetchData();
  }, []);
  console.log("Darta", data);

  return (
    <ListingTemplate
      title={id}
      //   onSearch={handleOnSearch}
      //   onSelect={handleStatusFilter}
    >
      <div className="flex flex-row">
        {/* <div className="text-[24px] font-bold mt-3">Sector</div> */}
        <div className=" text-[15px] font-bold mt-5">{na}</div>
        <div className="ml-1 text-[15px] mt-5">/</div>
        {/* <div
          className="ml-1 mt-5 text-[15px] cursor-pointer"
          onClick={(e) => {
            navigate("/create/newteam");
          }}
        >
          Add Team member
        </div>
        <div className="ml-1 text-[15px] mt-5">/</div> */}

        <div className="ml-1 text-[15px]  text-gray-400 mt-5">
          {toSentenceCase("Constituency report by block code and caste")}
        </div>
        {/* <button
          className="w-[150px] my-4 h-[40px] ml-[700px] bg-[#14A800] text-[18px] rounded-md text-white hover:bg-slate-400 mt-4"
          onClick={() => navigate("/create/newteam")}
        >
          Add new
        </button> */}

        {/* <Button
          text={"Add new"}
          style={{
            width: "w-[150px]",
            custom: "box-border text-[18px]",
            margin: " ml-[65%]",
          }}
          onClick={() => {
            navigate("/create/newteam");
          }}
        /> */}
      </div>
      <div className="text-[24px] font-bold">
        {toSentenceCase("Constituency report by block code and caste")}
      </div>
      <div className="w-[1000px] mt-1">
        This information could be obtained through a census or other survey.
        Once the data is collected, it can be organized into a report that
        provides an overview of the number of castes in the constituency
      </div>

      <table className="w-full my-6">
        <thead>
          <tr>
            {ths.map((item, index) => (
              <th
                key={index}
                className="text-[12px] font-semibold border-b-[1px] border-[#2A2D4314] bg-gray-100 px-3 py-4 "
              >
                {item.title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {!isLoading && data.length > 0 ? (
            data?.map((item, index) => (
              <tr key={index} className="px-3 text-center">
                <td className="text-[12px] border-b-[1px] border-[#2A2D4314] py-4 w-[110px]">
                  {item.block_code}
                </td>
                <td className="text-[12px] border-b-[1px] border-[#2A2D4314] py-4 w-[110px]">
                  {isNaN(
                    (item.khan /
                      (item.butt +
                        item.rao +
                        item.kaimkhani +
                        item.rajput +
                        item.chaudhary +
                        item.gujjar +
                        item.jutt +
                        item.khan +
                        item.khokar +
                        item.mian +
                        item.sheikh +
                        item.masseh)) *
                      100
                  )
                    ? 0
                    : (
                        (item.khan /
                          (item.butt +
                            item.rao +
                            item.kaimkhani +
                            item.rajput +
                            item.chaudhary +
                            item.gujjar +
                            item.jutt +
                            item.khan +
                            item.khokar +
                            item.mian +
                            item.sheikh +
                            item.masseh)) *
                        100
                      ).toFixed(2)}
                  %
                </td>
                <td className="text-[12px] border-b-[1px] border-[#2A2D4314] py-4 w-[110px]">
                  {isNaN(
                    (item.khokar /
                      (item.butt +
                        item.rao +
                        item.kaimkhani +
                        item.rajput +
                        item.chaudhary +
                        item.gujjar +
                        item.jutt +
                        item.khan +
                        item.khokhar +
                        item.mian +
                        item.sheikh +
                        item.masseh)) *
                      100
                  )
                    ? 0
                    : (
                        (item.khokar /
                          (item.butt +
                            item.rao +
                            item.kaimkhani +
                            item.rajput +
                            item.chaudhary +
                            item.gujjar +
                            item.jutt +
                            item.khan +
                            item.khokar +
                            item.mian +
                            item.sheikh +
                            item.masseh)) *
                        100
                      ).toFixed(2)}
                  %
                </td>
                <td className="text-[12px] border-b-[1px] border-[#2A2D4314] py-4 w-[110px]">
                  {isNaN(
                    (item.chaudhary /
                      (item.butt +
                        item.rao +
                        item.kaimkhani +
                        item.rajput +
                        item.chaudhary +
                        item.gujjar +
                        item.jutt +
                        item.khan +
                        item.khokar +
                        item.mian +
                        item.sheikh +
                        item.masseh)) *
                      100
                  )
                    ? 0
                    : (
                        (item.chaudhary /
                          (item.butt +
                            item.rao +
                            item.kaimkhani +
                            item.rajput +
                            item.chaudhary +
                            item.gujjar +
                            item.jutt +
                            item.khan +
                            item.khokar +
                            item.mian +
                            item.sheikh +
                            item.masseh)) *
                        100
                      ).toFixed(2)}
                  %
                </td>
                <td className="text-[12px] border-b-[1px] border-[#2A2D4314] py-4 w-[110px]">
                  {isNaN(
                    (item.mian /
                      (item.butt +
                        item.rao +
                        item.kaimkhani +
                        item.rajput +
                        item.chaudhary +
                        item.gujjar +
                        item.jutt +
                        item.khan +
                        item.khokar +
                        item.mian +
                        item.sheikh +
                        item.masseh)) *
                      100
                  )
                    ? 0
                    : (
                        (item.mian /
                          (item.butt +
                            item.rao +
                            item.kaimkhani +
                            item.rajput +
                            item.chaudhary +
                            item.gujjar +
                            item.jutt +
                            item.khan +
                            item.khokar +
                            item.mian +
                            item.sheikh +
                            item.masseh)) *
                        100
                      ).toFixed(2)}
                  %
                </td>
                <td className="text-[12px] border-b-[1px] border-[#2A2D4314] py-4 w-[110px]">
                  {isNaN(
                    (item.masseh /
                      (item.butt +
                        item.rao +
                        item.kaimkhani +
                        item.rajput +
                        item.chaudhary +
                        item.gujjar +
                        item.jutt +
                        item.khan +
                        item.khokar +
                        item.mian +
                        item.sheikh +
                        item.masseh)) *
                      100
                  )
                    ? 0
                    : (
                        (item.masseh /
                          (item.butt +
                            item.rao +
                            item.kaimkhani +
                            item.rajput +
                            item.chaudhary +
                            item.gujjar +
                            item.jutt +
                            item.khan +
                            item.khokar +
                            item.mian +
                            item.sheikh +
                            item.masseh)) *
                        100
                      ).toFixed(2)}
                  %
                </td>
                <td className="text-[12px] border-b-[1px] border-[#2A2D4314] py-4 w-[110px]">
                  {isNaN(
                    (item.sheikh /
                      (item.butt +
                        item.rao +
                        item.kaimkhani +
                        item.rajput +
                        item.chaudhary +
                        item.gujjar +
                        item.jutt +
                        item.khan +
                        item.khokar +
                        item.mian +
                        item.sheikh +
                        item.masseh)) *
                      100
                  )
                    ? 0
                    : (
                        (item.sheikh /
                          (item.butt +
                            item.rao +
                            item.kaimkhani +
                            item.rajput +
                            item.chaudhary +
                            item.gujjar +
                            item.jutt +
                            item.khan +
                            item.khokar +
                            item.mian +
                            item.sheikh +
                            item.masseh)) *
                        100
                      ).toFixed(2)}
                  %
                </td>
                <td className="text-[12px] border-b-[1px] border-[#2A2D4314] py-4 w-[110px]">
                  {isNaN(
                    (item.butt /
                      (item.butt +
                        item.rao +
                        item.kaimkhani +
                        item.rajput +
                        item.chaudhary +
                        item.gujjar +
                        item.jutt +
                        item.khan +
                        item.khokar +
                        item.mian +
                        item.sheikh +
                        item.masseh)) *
                      100
                  )
                    ? 0
                    : (
                        (item.butt /
                          (item.butt +
                            item.rao +
                            item.kaimkhani +
                            item.rajput +
                            item.chaudhary +
                            item.gujjar +
                            item.jutt +
                            item.khan +
                            item.khokar +
                            item.mian +
                            item.sheikh +
                            item.masseh)) *
                        100
                      ).toFixed(2)}
                  %
                </td>
                <td className="text-[12px] border-b-[1px] border-[#2A2D4314] py-4 w-[110px]">
                  {isNaN(
                    (item.rajput /
                      (item.butt +
                        item.rao +
                        item.kaimkhani +
                        item.rajput +
                        item.chaudhary +
                        item.gujjar +
                        item.jutt +
                        item.khan +
                        item.khokar +
                        item.mian +
                        item.sheikh +
                        item.masseh)) *
                      100
                  )
                    ? 0
                    : (
                        (item.rajput /
                          (item.butt +
                            item.rao +
                            item.kaimkhani +
                            item.rajput +
                            item.chaudhary +
                            item.gujjar +
                            item.jutt +
                            item.khan +
                            item.khokar +
                            item.mian +
                            item.sheikh +
                            item.masseh)) *
                        100
                      ).toFixed(2)}
                  %
                </td>
                <td className="text-[12px] border-b-[1px] border-[#2A2D4314] py-4 w-[110px]">
                  {isNaN(
                    (item.kaimkhani /
                      (item.butt +
                        item.rao +
                        item.kaimkhani +
                        item.rajput +
                        item.chaudhary +
                        item.gujjar +
                        item.jutt +
                        item.khan +
                        item.khokar +
                        item.mian +
                        item.sheikh +
                        item.masseh)) *
                      100
                  )
                    ? 0
                    : (
                        (item.kaimkhani /
                          (item.butt +
                            item.rao +
                            item.kaimkhani +
                            item.rajput +
                            item.chaudhary +
                            item.gujjar +
                            item.jutt +
                            item.khan +
                            item.khokar +
                            item.mian +
                            item.sheikh +
                            item.masseh)) *
                        100
                      ).toFixed(2)}
                  %
                </td>

                <td className="text-[12px] border-b-[1px] border-[#2A2D4314] py-4 w-[110px]">
                  {isNaN(
                    (item.gujjar /
                      (item.butt +
                        item.rao +
                        item.kaimkhani +
                        item.rajput +
                        item.chaudhary +
                        item.gujjar +
                        item.jutt +
                        item.khan +
                        item.khokar +
                        item.mian +
                        item.sheikh +
                        item.masseh)) *
                      100
                  )
                    ? 0
                    : (
                        (item.gujjar /
                          (item.butt +
                            item.rao +
                            item.kaimkhani +
                            item.rajput +
                            item.chaudhary +
                            item.gujjar +
                            item.jutt +
                            item.khan +
                            item.khokar +
                            item.mian +
                            item.sheikh +
                            item.masseh)) *
                        100
                      ).toFixed(2)}
                  %
                </td>
                <td className="text-[12px] border-b-[1px] border-[#2A2D4314] py-4 w-[110px]">
                  {isNaN(
                    (item.rao /
                      (item.butt +
                        item.rao +
                        item.kaimkhani +
                        item.rajput +
                        item.chaudhary +
                        item.gujjar +
                        item.jutt +
                        item.khan +
                        item.khokar +
                        item.mian +
                        item.sheikh +
                        item.masseh)) *
                      100
                  )
                    ? 0
                    : (
                        (item.rao /
                          (item.butt +
                            item.rao +
                            item.kaimkhani +
                            item.rajput +
                            item.chaudhary +
                            item.gujjar +
                            item.jutt +
                            item.khan +
                            item.khokar +
                            item.mian +
                            item.sheikh +
                            item.masseh)) *
                        100
                      ).toFixed(2)}
                  %
                </td>

                <td className="text-[12px] border-b-[1px] border-[#2A2D4314] py-4 w-[110px]">
                  {isNaN(
                    (item.jutt /
                      (item.butt +
                        item.rao +
                        item.kaimkhani +
                        item.rajput +
                        item.chaudhary +
                        item.gujjar +
                        item.jutt +
                        item.khan +
                        item.khokar +
                        item.mian +
                        item.sheikh +
                        item.masseh)) *
                      100
                  )
                    ? 0
                    : (
                        (item.jutt /
                          (item.butt +
                            item.rao +
                            item.kaimkhani +
                            item.rajput +
                            item.chaudhary +
                            item.gujjar +
                            item.jutt +
                            item.khan +
                            item.khokar +
                            item.mian +
                            item.sheikh +
                            item.masseh)) *
                        100
                      ).toFixed(2)}
                  %
                </td>
              </tr>
            ))
          ) : (
            <div className="mt-[40px] ">
              <ReactLoading
                color={"gray"}
                height={"20px"}
                width={"40px"}
                className="ml-[500px] my-3"
              />
              {/* <div className="font-bold text-[15px] ml-[350px]">
                Data was loading slow due to slow Internet
              </div> */}
            </div>
          )}
        </tbody>
      </table>
    </ListingTemplate>
  );
}

export default ReportByBlockCodeandCaste;
