const { createContext, useState, useCallback } = require("react");

const AuthContext = createContext({
    token: '',
    isLoggedIn: false,
    login: (token) => {},
    logout: () => {},
    user: null,
    updateUserFields: (fields) => {},
});

const retrieveStoredToken = () => {
    const storedToken = localStorage.getItem('token');
    return {
      token: storedToken,
    };
  };
  
const retrieveStoredUser = () => {
    let storedUser = localStorage.getItem('user');
    if(storedUser) {
        storedUser = JSON.parse(storedUser)
    }
    return {
        user: storedUser,
    };
};

export const AuthContextProvider = (props) => {
    const tokenData = retrieveStoredToken();
  
    const userData = retrieveStoredUser()
    
    let initialToken;
    if (tokenData) {
      initialToken = tokenData.token;
    }
  
    let initialUser;
    if(userData) {
      initialUser = userData.user 
    }
  
    const [token, setToken] = useState(initialToken);
    const [user, setUser] = useState(initialUser)
  
    const userIsLoggedIn = !!token;
  
    const logoutHandler = useCallback(() => {
      setToken(null);
      setUser(null)
      localStorage.removeItem('token');
      localStorage.removeItem('expirationTime');
      localStorage.removeItem('user')
  
    //   if (logoutTimer) {
    //     clearTimeout(logoutTimer);
    //   }
    }, []);
  
    const loginHandler = (response, expirationTime) => {
      const token = response
      const user = response
      console.log("Authuserr",user)
      setUser(user)
      localStorage.setItem('user', JSON.stringify(user));
  
      setToken(token);
      localStorage.setItem('token', token);
    };
  
    const updateUserFields = (fields) => {
      const { fullName, email, phoneNumber, address, city, province, postalCode } = fields
      
      setUser(old => {
        const profile = old.UserProfile
        const i = {
          ...old,
          fullName,
          UserProfile: {
            ...profile,
            postalCode,
            city,
            province,
            address,
            phone: phoneNumber
          }
        }
        localStorage.setItem('user', JSON.stringify(i))
        return i 
      })
    }
  
    const contextValue = {
      token: token,
      isLoggedIn: userIsLoggedIn,
      login: loginHandler,
      logout: logoutHandler,
      user: user,
      updateUserFields
    };
  
    return (
      <AuthContext.Provider value={contextValue}>
        {props.children}
      </AuthContext.Provider>
    );
};
  
export default AuthContext;