import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Api } from "../../../../utils/api";
import { RequestStatus } from "../../../../utils/enums";

const initialState = {
    data: [],
    activeIncidentData: null,
    fetchDataRequestStatus: RequestStatus.IDLE,
}

export const incidentThunk = {
    getIncidents: createAsyncThunk('/incident/getAllIncidents', async (data, thunkAPI) => {
        const { fulfillWithValue, rejectWithValue } = thunkAPI
        try {
            const res = await Api.get({path: '/admin/incidents'})
            console.log("RES",res)
            if(res.success) {
                return fulfillWithValue({
                    data: res.data.data,
                    // activeIncidentId: data?.activeIncidentId
                })
            } else {
                throw res.message
            }
        } catch(e) {
            return rejectWithValue({
                error: true,
                message: typeof e === 'string' ? e : 'Something went wrong',
                exception: e
            })
        }
    }),
}
const incidentSlice = createSlice({
    name: 'incident',
    initialState,
    reducers: {
      addIncident: (state, action) => {
            const {incident} = action.payload 
            if(incident) {
                state.data.push(incident)   
            }
        },
         deleteIncident: (state, action) => {
            const { incidentId } = action.payload
            const incident = state.data.find(item => item.id === incidentId)
            if(incident) {
                state.data = state.data.filter(item => item.id !== incidentId)
            }
        },
          updateIncident: (state, action) => {
              const { incident, incidentId } = action.payload 
            console.log(1)
            if(incident) {
                console.log(2)
                const found = state.data.find(item => item.id === incidentId)
                const index = state.data.indexOf(found)
                console.log('index', index)
                state.data[index] = incident 
                state.activeIncidentData = incident
            }
            console.log(3)
        },

    },

    extraReducers: (builder) => builder
        .addCase(incidentThunk.getIncidents.fulfilled, (state, action) => {
            state.data = action.payload.data ?? []
            if(action.payload.activeIncidentId) {
                const activeIncidentData = state.data.find(item => item.id === action.payload.activeIncidentId)
                if(activeIncidentData) {
                    state.activeIncidentData = activeIncidentData
                }
            }
            state.fetchDataRequestStatus = RequestStatus.COMPLETED
        })
        .addCase(incidentThunk.getIncidents.rejected, (state, action) => {
            state.fetchDataRequestStatus = RequestStatus.ERROR
        })
        .addCase(incidentThunk.getIncidents.pending, (state, action) => {
            state.fetchDataRequestStatus = RequestStatus.LOADING
        })

        
})
export const incidentActions = incidentSlice.actions
export default incidentSlice.reducer