import axios from "axios";
import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import AppImages from "../../../../assets/images";
import AuthContext from "../../../../contexts/AuthContext";
import ReactLoading from "react-loading";
import "./PrintStyles.css";
import FirebaseService from "../../../../services/FirebaseService";
const Parchilink = () => {
  const [data, setData] = useState("");
  const [d, sd] = useState("");
  const [f, sf] = useState("");
  const { id, sector, cnic, fmnumber } = useParams();
  let Id = id;
  const authCtx = useContext(AuthContext);
  console.log("Authcontext", authCtx);
  let na = sector;
  useEffect(() => {
    // let na = na;
    
    let page = 1;
    console.log("ID", Id, na);
    const fetchData = async () => {
      const result = await axios(
        `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/family-tree?sector=${na}&bc=${Id}&cnic=${cnic}&fno=${fmnumber}`
      );
      console.log("resulttt", result.data.family_tree);
      setData(result.data.family_tree);
      if (na === "PK 82") {
        setData(result.data.family_tree);
      } else {
        let filteredData = result.data.family_tree.sort((a, b) => {
          // If gharana_no is an empty string, move it to the bottom
          if (a.silsila_no === "" || a.silsila_no === null) return 1;
          if (b.silsila_no === "" || b.silsila_no === null) return -1;

          // Otherwise, sort based on gharana_no
          return a.silsila_no - b.silsila_no;
        });
        setData(filteredData);
      }
    };

    fetchData();
    const fetchData1 = async () => {
      const resultt = await axios(
        `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/get-polling-scheme?bc=${Id}&gender=male`
      );
      console.log("resulttt123", resultt.data.data.scheme);
      sd(resultt.data.data.scheme);
    };

    fetchData1();

    const fetchData2 = async () => {
      const resultt = await axios(
        `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/get-polling-scheme?bc=${Id}&gender=female`
      );
      console.log("result000", resultt.data.data.scheme);
      sf(resultt.data.data.scheme);
    };

    fetchData2();
  }, []);
  // const cnicFilter = ["35202-9935036-5", "35202-7605994-3", "35202-3520580-5"];

  // Apply the filter
  // const filteredData = data.filter((item) => cnicFilter.includes(item.cnic));
  console.log("Dataaa", data);

  const handlePrint = async () => {
    window.print();

    let res = await FirebaseService.addLog({
      tag: "ParchilinkDownload",
      platform: "web",
      role: "manager",
      blockcode: Id,
      sector: na,
    });
    console.log("firebase response", res);
  };

  const wordsToReplace = [
    "مکان",
    "سال",
    "نمبر",
    "مین",
    "محلہ",
    "روڈ",
    "پارک",
    "،",
    "کالونی ",
    "لاہور ",
    "کی",
    "ضلع",
    "/",
    "ٹاور",
    "خالصہ",
    "خلاصہ",
  ];
  function isPreviousThan17Jan(date) {
    if (!(date instanceof Date)) {
      return false;
    }
    let date17 = new Date(2024, 0, 17, 0, 0, 0, 0);
    return date < date17;
  }
  function ImageSlice({ item }) {
    var yIncr = 0;
    if (item?.rowY) {
      if (na === "PP 172") {
        if (item.meta === "100") {
          yIncr = -10;
        } else {
          if (item?.name === "" || item?.address === "") {
            yIncr = -40;
          } else {
            // if()
            // yIncr = 65;
          }
        }
      } else if (["PP 208", "PP 14"].includes(na)) {
        if (item?.name === "" || item?.address === "") {
          yIncr = -40;
        } else {
          yIncr = 65;
        }
      } else if (
        ["PP 207", "NA 146"].includes(na) &&
        isPreviousThan17Jan(new Date(item.created_at))
      ) {
        if (item?.name === "" || item?.address === "") {
          yIncr = -30;
        } else {
          yIncr = 70;
        }
      } else if (["NA 120"].includes(na)) {
        yIncr = 50;
      } else if (["NA 127"].includes(na)) {
        yIncr = -10;
      } else if (["PP 59"].includes(na)) {
        yIncr = -20;
      } else {
        yIncr = -10;
      }
    }

    return (
      <>
        {na === "PP 152" ? (
          <img
            src={`https://res.cloudinary.com/election-experts/image/fetch/c_crop,h_55,x_0,w_4000,y_${
              parseInt(item?.rowY) + yIncr
            }/${encodeURIComponent(item?.url)}`}
            alt=""
            srcSet=""
            className=" w-full h-[40px]"
          />
        ) : (
          <img
            src={`https://res.cloudinary.com/election-experts/image/fetch/c_crop,h_80,x_0,w_4000,y_${
              parseInt(item?.rowY) + yIncr
            }/${encodeURIComponent(item?.url)}`}
            alt=""
            srcSet=""
            className=" w-full h-[40px]"
          />
        )}
      </>
    );
  }

  return (
    <div>
      <div className="flex flex-row ">
        {/* <Link to={`/download/list`}>
          <div className="rounded-md h-[25px] text-center text-yellow-50 my-1 w-[90px] bg-slate-400 hover:bg-slate-600 text-[18px] ml-3 font-bold cursor-pointer">
            Back
          </div>
        </Link> */}
        <div
          className="rounded-md h-[25px] text-center text-yellow-50 my-1 w-[90px] bg-slate-400 hover:bg-slate-600 text-[18px] ml-3 font-bold cursor-pointer"
          onClick={handlePrint}
        >
          Print
        </div>
      </div>
      <div className=" flex flex-col w-[1100px] mb-[140px] border-[1px] border-dotted border-black "></div>
      {data ? (
        data.map((item, index) => (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                // margin: "1px",
              }}
              className="w-[1000px] font-noori  ml-[40px] border-[1px] border-black"
              key={index}
            >
              {
            //   na == "NA 127" ||
              na == "PP 171" ? (
                <div
                  className="flex flex-row  w-full  px-[25px] text-[18px] py-[5px] border-[0.5px] border-gray-500"
                  dir="rtl"
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      marginRight: "5px",
                      // fontSize: 15,
                    }}
                  >
                    نام :
                  </div>
                  <div className=" mr-2 ">
                    {item.name?.length > 44
                      ? item.name
                          ?.replace(/[^\W]/gi, "")
                          .replace(/[-*]/g, " ")
                          .substr(0, 44) + ".."
                      : item.name?.replace(/[^\W]/gi, "").replace(/[-*]/g, " ")}
                  </div>
                </div>
              ) : (
                // <ImageSlice item={item} />
                <div
                  style={{
                    direction: "rtl",
                    // justifyContent: "space-around",
                    // display: "flex",
                  }}
                  className="border-gray-500 border-[0.5px] w-full"
                >
                  <ImageSlice item={item} />
                </div>
              )}
              <div className="flex flex-row w-full">
                {/* {authCtx.user.party === "PPP" ? (
                  <div className="w-[20%] flex flex-row justify-center">
                    <img
                      className="h-[100px]"
                      src={
                        "https://www.pngkit.com/png/full/813-8134385_pakistan-people-party-logo.png"
                      }
                      alt="Rounded avatar"
                    />
                  </div>
                ) : ( */}
                {/* <div className="w-[20%] flex flex-row items-center justify-center"> */}
                {na === "PP 160" &&
                authCtx.user.party === "PMLN" ? (
                  <div className="w-[20%] flex flex-row items-center justify-center">
                    <img
                      src={AppImages.asad1}
                      alt="image"
                      className="h-[100px] py-1 rounded-2xl"
                    />
                  </div>
                ) : (
                  <>
                    {na === "PP 172" ? (
                      <div className="w-[20%]  flex flex-row items-center justify-center">
                        <img
                          src={AppImages.pp172}
                          alt="image"
                          className="h-[100px] w-full object-fit"
                        />
                      </div>
                    ) : (
                      <>
                        {(na === "PP 155" ||
                          na === "NA 151" ||
                          na === "PP 152") &&
                        authCtx.user.party === "PMLN" ? (
                          <div className="w-[20%]  flex flex-row items-center justify-center">
                            <img
                              src={AppImages.sher}
                              alt="image"
                              className=" h-[100px] "
                            />
                          </div>
                        ) : (
                          <>
                            {na === "PP 160" &&
                            authCtx.user.party === "Haqooq-e-khalq" ? (
                              <div className="w-[20%]  flex flex-col items-center justify-center">
                                <div className="text-center text-[16px] font-bold">
                                  NA 127 / PP 160
                                </div>
                                <div className="text-center text-[16px] font-bold">
                                  ڈاکٹر عمار علی جان / محمد مزمل کاکڑ
                                </div>
                                <img
                                  src={AppImages.speaker}
                                  alt="image"
                                  className=" w-[60px]"
                                />
                              </div>
                            ) : (
                              <>
                                {na === "NA 127" ||
                                na === "PP 162" ||
                                na === "PP 163" ? (
                                  <div className="w-[20%] flex flex-row items-center justify-center">
                                    <img
                                      src={AppImages.ppplogo}
                                      alt="image"
                                      className="h-[100px] w-[200px]"
                                    />
                                  </div>
                                ) : (
                                  <>
                                    {na === "PP 107" &&
                                    authCtx.user.party === "Peacock" ? (
                                      <div className="w-[20%] flex flex-col items-center justify-center">
                                        {/* <div className="text-center text-[18px] font-bold">
                                          سلطان علی خان
                                        </div> */}
                                        <img
                                          src={AppImages.mor}
                                          alt="image"
                                          className="h-[100px]"
                                        />
                                      </div>
                                    ) : (
                                      <>
                                        {na === "PK 82" ? (
                                          <div className="w-[20%]  flex flex-row items-center justify-center">
                                            <img
                                              src={AppImages.pk82}
                                              alt="image"
                                              className="h-[100px]"
                                            />
                                          </div>
                                        ) : (
                                          <>
                                            {na === "PP 46" ? (
                                              <div className="w-[20%]  flex flex-row items-center justify-center">
                                                <img
                                                  src={AppImages.coconut}
                                                  alt="image"
                                                  className="h-[90px]"
                                                />
                                              </div>
                                            ) : (
                                              <>
                                                {na ===
                                                  "PP 47" &&
                                                authCtx.user.party === "PTI" ? (
                                                  <div className="w-[20%]  flex flex-row items-center justify-center">
                                                    <img
                                                      src={AppImages.wallclock}
                                                      alt="image"
                                                      className="h-[100px]"
                                                    />
                                                  </div>
                                                ) : (
                                                  <>
                                                    {na ===
                                                      "PP 47" &&
                                                    authCtx.user.party ===
                                                      "Peacock" ? (
                                                      <div className="w-[20%]  flex flex-row items-center justify-center">
                                                        <img
                                                          src={AppImages.morrr}
                                                          alt="image"
                                                          className="h-[100px]"
                                                        />
                                                      </div>
                                                    ) : (
                                                      <>
                                                        {na ===
                                                          "PP 59" &&
                                                        authCtx.user.party ===
                                                          "PTI" ? (
                                                          <div className="w-[20%]  flex flex-row items-center justify-center">
                                                            <img
                                                              src={
                                                                AppImages.table
                                                              }
                                                              alt="image"
                                                              className="h-[100px]"
                                                            />
                                                          </div>
                                                        ) : (
                                                          <>
                                                            {authCtx.user
                                                              .sector ===
                                                            "PP 161" ? (
                                                              <div className="w-[20%]  flex flex-row items-center justify-center">
                                                                <img
                                                                  src={
                                                                    AppImages.pp161
                                                                  }
                                                                  alt="image"
                                                                  className="h-[100px]"
                                                                />
                                                              </div>
                                                            ) : (
                                                              <>
                                                                {authCtx.user
                                                                  .sector ===
                                                                "NA 119" ? (
                                                                  <div className="w-[20%]  flex flex-row items-center justify-center">
                                                                    <img
                                                                      src={
                                                                        AppImages.na119
                                                                      }
                                                                      alt="image"
                                                                      className="h-[100px]"
                                                                    />
                                                                  </div>
                                                                ) : (
                                                                  <div className="w-[20%] flex flex-row items-center justify-center">
                                                                    <img
                                                                      src={
                                                                        authCtx
                                                                          .user
                                                                          .party_image
                                                                      }
                                                                      alt="image"
                                                                      className="h-[100px] w-[200px]"
                                                                    />
                                                                  </div>
                                                                )}
                                                              </>
                                                            )}
                                                          </>
                                                        )}
                                                      </>
                                                    )}
                                                  </>
                                                )}
                                              </>
                                            )}
                                          </>
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
                {/* </div> */}
                {/* )} */}
                <div className="w-[80%] flex flex-col">
                  {/* <div className="w-full flex flex-row">
                    <div
                      className="flex flex-row w-[50%]   px-[25px] py-[2px] border-[0.5px] border-gray-500"
                      dir="rtl"
                    >
                      <div
                        style={{
                          fontWeight: "bold",
                          marginRight: "5px",
                         
                        }}
                        className="text-[15px]"
                      >
                        نام :
                      </div>
                      <div className=" mr-2 text-[15px] font-bold">
                        {na === "PP 207" ? (
                          <img
                            src={`https://res.cloudinary.com/election-experts/image/fetch/c_crop,h_60,x_1200,w_195,y_${
                              parseInt(item?.rowY) + 85
                            }/${encodeURIComponent(item?.url)}`}
                            alt=""
                            srcSet=""
                            className="h-[30px]"
                          />
                        ) : (
                          <img
                            src={`https://res.cloudinary.com/election-experts/image/fetch/c_crop,h_60,x_1750,w_265,y_${
                              parseInt(item?.rowY) + 65
                            }/${encodeURIComponent(item?.url)}`}
                            alt=""
                            srcSet=""
                            className="h-[23px]"
                          />
                        )}
                      
                      </div>
                    </div>
                    <div
                      className="flex flex-row w-[50%]   px-[25px] py-[2px] border-[0.5px] border-gray-500"
                      dir="rtl"
                    >
                      <div
                        style={{
                          fontWeight: "bold",
                          marginRight: "5px",
                          // fontSize: 15,
                        }}
                        className="text-[15px]"
                      >
                        والد کا نام :
                      </div>
                      <div className=" mr-2 text-[15px] font-bold">
                        {na === "PP 207" ? (
                          <img
                            src={`https://res.cloudinary.com/election-experts/image/fetch/c_crop,h_60,x_900,w_240,y_${
                              parseInt(item?.rowY) + 85
                            }/${encodeURIComponent(item?.url)}`}
                            alt=""
                            srcSet=""
                            className=" h-[30px]"
                          />
                        ) : (
                          <img
                            src={`https://res.cloudinary.com/election-experts/image/fetch/c_crop,h_60,x_1450,w_240,y_${
                              parseInt(item?.rowY) + 65
                            }/${encodeURIComponent(item?.url)}`}
                            alt=""
                            srcSet=""
                            className="h-[23px]"
                          />
                        )}
                     
                      </div>
                    </div>
                  </div> */}

                  <div
                    style={{
                      direction: "rtl",
                      justifyContent: "space-around",
                      display: "flex",
                    }}
                  >
                    <div className="flex flex-row border-[0.5px] w-[50%] border-gray-500 py-[4px]">
                      <div
                        style={{
                          fontWeight: "bold",
                          paddingRight: "15px",
                          fontSize: 15,
                        }}
                      >
                        شماریاتی کوڈ نمبر :
                      </div>
                      <div className=" font-[11px] mr-2">{item.block_code}</div>
                    </div>
                    <div className="flex flex-row border-[0.5px] w-[50%] border-gray-500 py-[4px]">
                      <div
                        style={{
                          fontWeight: "bold",
                          paddingRight: "15px",
                          fontSize: 15,
                        }}
                      >
                        شناختی کارڈ نمبر :
                      </div>
                      <div className="font-[11px] mr-2">{item.cnic}</div>
                    </div>
                  </div>
                  <div
                    className="flex flex-row w-full  px-[25px] py-[4px] border-[0.5px] border-gray-500"
                    dir="rtl"
                  >
                    <div
                      style={{
                        fontWeight: "bold",
                        paddingRight: "7px",
                        fontSize: 15,
                      }}
                    >
                      پتہ :
                    </div>
                    {na === "PP 152" ? (
                      ""
                    ) : (
                      <div className="font-[11px] mr-2">
                        {
                          item?.address?.length > 100
                            ? item?.address
                                .replace(/سال/gi, "")
                                .replace(/^\d{0,25}/, "") // Remove numbers from the start
                                .substr(0, 100) + ".."
                            : item?.address
                                ?.replace(/سال/gi, "")
                                .replace(/^\d{0,25}/, "") // Remove numbers from the start
                        }

                        {/* <img
                        src={`https://res.cloudinary.com/election-experts/image/fetch/c_crop,h_90,x_110,w_805,y_${
                          parseInt(item?.rowY) + 65
                        }/${encodeURIComponent(item?.url)}`}
                        alt=""
                        srcSet=""
                        className=" h-[40px]"
                      /> */}
                      </div>
                    )}
                  </div>
                  {item.gender === "male" ? (
                    <div
                      className="flex flex-row w-full  px-[25px] py-[4px] border-[0.5px] border-gray-500"
                      dir="rtl"
                    >
                      <div
                        style={{
                          fontWeight: "bold",
                          paddingRight: "7px",
                          fontSize: 15,
                        }}
                      >
                        پولنگ سٹیشن :
                      </div>
                      <div className="font-[11px] mr-2">
                        {d?.polling_station_area_urdu}
                      </div>
                    </div>
                  ) : item.gender === "female" ? (
                    <div
                      className="flex flex-row w-full  px-[25px] py-[4px] border-[0.5px] border-gray-500"
                      dir="rtl"
                    >
                      <div
                        style={{
                          fontWeight: "bold",
                          paddingRight: "7px",
                          fontSize: 15,
                        }}
                      >
                        پولنگ سٹیشن :
                      </div>
                      <div className="font-[11px] mr-2">
                        {f?.polling_station_area_urdu}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {/* {authCtx.user.package !== "1" ? (
                <div
                  className="flex flex-row  w-full  px-[25px] py-[4px] border-[0.5px] border-gray-500"
                  dir="rtl"
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      paddingRight: "7px",
                      fontSize: 15,
                    }}
                  >
                    فون نمبر :
                  </div>
                  <div className=" font-[11px] mr-2 flex flex-row">
                    {item.voter_phone
                      ? JSON.parse(item.voter_phone).map((phone) => (
                          <p> , 0{phone} </p>
                        ))
                      : "--"}
                  </div>
                </div>
              ) : (
                <div></div>
              )} */}
            </div>
            <div className=" flex flex-col w-full my-[14px] border-[1px] border-dotted border-black "></div>
          </>
        ))
      ) : (
        <ReactLoading
          color={"gray"}
          height={"40px"}
          width={"70px"}
          className="my-2 justify-center"
        />
      )}
    </div>
  );
};

export default Parchilink;
