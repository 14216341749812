import axios from "axios";
import { useContext } from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactLoading from "react-loading";
import { Link, useNavigate, useParams } from "react-router-dom";
import AppImages from "../../../../assets/images";
import AuthContext from "../../../../contexts/AuthContext";
import { Api } from "../../../../utils/api";
import { RequestStatus } from "../../../../utils/enums";
import SimpleModal from "../../../basic/SimpleModal";
import ListingTemplate from "../ListingTemplate";
import { employeeActions, employeeThunk } from "../reducers/employee_slice";
import ReactToPrint from "react-to-print";
import DownloadPdf from "./Print";
import { useRef } from "react";
import { forwardRef } from "react";
import Chart from "react-google-charts";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import FirebaseService from "../../../../services/FirebaseService";
import Modal from "./Modal";
// import { encode, decode } from "base-64";
// import ReactToPrint from "react-to-print";

const VoterList = forwardRef(({ children }, ref) => {
  const navigate = useNavigate();
  const ths = [{ title: "Block Code" }, { title: "Age" }, { title: "View" }];
  const tdClasses = "text-[11px] border-b-[1px] border-[#2A2D4314] py-4 px-2";

  const dispatch = useDispatch();
  const authCtx = useContext(AuthContext);
  const employeeReducer = useSelector((state) => state.employees);
  // console.log("Employee", employeeReducer.data)
  const [data, setData] = useState("");
  const [showableData, setShowableData] = useState(data ?? []);

  const fields = {
    blockCode: null,
    minage: null,
    maxage: null,
  };
  const errors = {
    blockCode: null,
    minage: null,
    maxage: null,
  };
  const [formFields, setFormFields] = useState(fields);
  const [data2, setData2] = useState("");
  let na = authCtx.user.sector;
  let { blockcode } = useParams();
  //  let na = authCtx.user.sector;
  // let na = authCtx.user.sector;
  const [page, setPage] = useState(1);
  const [ageFilter, setAgeFilter] = useState("");
  const [addressFilter, setAddressFilter] = useState("");
  // console.log("Pageee",page)
   useEffect(() => {
     let na = authCtx.user.sector;
     let page = 1;
     const fetchData = async () => {
       const result = await axios(
         `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/voter-list-blockCode?sector=${na}&page_no=${page}&block_code=${blockcode}`
       );

        // let filteredData = result.data.voters.sort(
        //   (a, b) => a.silsila_no - b.silsila_no
        // );
       let filteredData = result.data.voters.sort((a, b) => {
         if (a.silsila_no === null && b.silsila_no !== null) {
           return 1; // Move entries with null silsila_no to the bottom
         } else if (a.silsila_no !== null && b.silsila_no === null) {
           return -1; // Move entries with non-null silsila_no to the top
         } else {
           return a.silsila_no - b.silsila_no; // Sort by silsila_no for non-null values
         }
       });

       // Now, use filteredData as needed in your code

       if (ageFilter) {
         filteredData = filteredData.filter((item) =>
           item.age.toString().includes(ageFilter)
         );
       }

       if (addressFilter) {
         filteredData = filteredData.filter((item) =>
           item.address.includes(addressFilter)
         );
       }
       console.log("Dataaaa", filteredData);
       setData(filteredData);
     };

     fetchData();
   }, [ageFilter, addressFilter, blockcode, na]);
  // console.log("dataaaa",data.voters)

  const newData = async () => {
    const result = await axios(
      `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/voter-list-blockCode?sector=${na}&page_no=${page}&block_code=${blockcode}`
    );

    setData(result.data.voters);
  };
  const wordsToReplace = [
    "مکان",
    "سال",
    "نمبر",
    "مین",
    "محلہ",
    "روڈ",
    "پارک",
    "،",
    "کالونی ",
    "لاہور ",
    "کی",
    "ضلع",
    "/",
    "ٹاور",
    "خالصہ",
    "خلاصہ",
  ];
const handleAgeFilterChange = (e) => {
  setAgeFilter(e.target.value);
};

const handleAddressFilterChange = (e) => {
  setAddressFilter(e.target.value);
};
  const [message, showmessage] = useState("");
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  function isPreviousThan17Jan(date) {
    if(!(date instanceof Date)) {
      return false 
    }
    let date17 = new Date(2024, 0, 17, 0, 0, 0, 0);
    return date < date17
  }

  function ImageSlice({item}) {
    var yIncr = 0
    if(item?.rowY) {
      if (authCtx.user.sector === "PP 172") {
        if (
          item.meta === "100"
        ) {
          yIncr = -10;
        } else {
          if (item?.name === "" || item?.address === "") {
            yIncr = -40;
          } else {
            // yIncr = 65;
          }
        }
      } else if (["PP 208", "PP 14", "NA 146"].includes(authCtx.user.sector)) {
        if (item?.name === "" || item?.address === "") {
          yIncr = -40;
        } else {
          yIncr = 65;
        }
      } else if (
        ["PP 207", "NA 146"].includes(authCtx.user.sector) &&
        isPreviousThan17Jan(new Date(item.created_at))
      ) {
        if (item?.name === "" || item?.address === "") {
          yIncr = -30;
        } else {
          yIncr = 70;
        }
      } else if (["NA 120"].includes(authCtx.user.sector)) {
        yIncr = 50;
      } else if (["NA 127"].includes(authCtx.user.sector)) {
        yIncr = -10;
      } else if (["PP 59"].includes(authCtx.user.sector)) {
        yIncr = -20;
      } else {
        yIncr = -10;
      }
    }
     

    return (
      <>
        {authCtx.user.sector === "PP 152" ? (
          <img
            src={`https://res.cloudinary.com/election-experts/image/fetch/c_crop,h_55,x_0,w_4000,y_${
              parseInt(item?.rowY) + yIncr
            }/${encodeURIComponent(item?.url)}`}
            alt=""
            srcSet=""
            className=" w-full h-[40px]"
          />
        ) : (
          <img
            src={`https://res.cloudinary.com/election-experts/image/fetch/c_crop,h_80,x_0,w_4000,y_${
              parseInt(item?.rowY) + yIncr
            }/${encodeURIComponent(item?.url)}`}
            alt=""
            srcSet=""
            className=" w-full h-[40px]"
          />
        )}
      </>
    );
  }

  return (
    <>
      {/* <div> */}
      <ListingTemplate ref={ref}>
        {/* </div> */}
        <div className="flex flex-row" id="nottoshow">
          {/* <div className="text-[18px] font-bold">Sector</div> */}
          <div className=" text-[15px] ">{na}</div>
          <div className="ml-1 text-[15px] ">/</div>
          <div
            className="ml-1 text-[15px]"
            onClick={(e) => {
              // navigate("/team/list");
            }}
          >
            {blockcode}
          </div>
          <div className="ml-1 text-[15px] ">/</div>
          <div className="ml-1 text-[15px]  text-gray-400">Voter List</div>
        </div>
        <div id="nottoshowsss">
          <BlockcodeAgeGraph blockCode={blockcode} />
        </div>

        <div id="nottoshowss">
          {/* {authCtx.user.sector == "PP 14" || "PP 172" ? (
            // <button onClick={openModal}>Open Modal</button>
            <button
              onClick={openModal}
              className="border-[1px] border-gray-400 rounded-md px-1 hover:bg-slate-200"
            >
              Download PDF
            </button>
          ) : ( */}
          <DownloadPdf
            rootElementId={"pagetodownload"}
            show={"nottoshow"}
            shows={"nottoshows"}
            showss={"nottoshowss"}
            showsss={"nottoshowsss"}
            onClick={async () => {
              let res = await FirebaseService.addLog({
                tag: "VoterListDownload",
                platform: "web",
                role: "manager",
                blockcode: blockcode,
                sector: authCtx.user.sector,
              });
              console.log("firebase response", res);
              showmessage(
                "Please wait for a minute We are creating PDF of this data"
              );
            }}
          />
          {/* // )} */}

          {message === "" ? (
            <div></div>
          ) : (
            <div className="text-[18px] text-red-400">{message}</div>
          )}
          <div className="flex flex-row  justify-between">
            <div className="w-[30%]">
              {/* <label htmlFor="ageFilter">Age Filter = </label> */}
              <input
                type="text"
                id="ageFilter"
                value={ageFilter}
                onChange={handleAgeFilterChange}
                className="w-[60%] my-4 px-3 py-2 border border-gray-300 rounded-md"
                placeholder="Search By Age"
              />
            </div>
            <div className="w-[30%]">
              {/* <label htmlFor="addressFilter">Address Filter = </label> */}
              <input
                type="text"
                id="addressFilter"
                value={addressFilter}
                onChange={handleAddressFilterChange}
                className="w-[60%] my-4 px-3 py-2 border border-gray-300 rounded-md"
                placeholder="Search By Address"
              />
            </div>
          </div>
        </div>

        <div id="pagetodownload">
          {authCtx.user.party === "PTI" ? (
            <div className="flex justify-center">
              <img
                className=" rounded-full bg-gray-400 w-[10%] object-contain"
                src={authCtx.user.party_image}
                alt="Rounded avatar"
              />
            </div>
          ) : (
            ""
          )}
          {
          // authCtx.user.sector == "NA 127" ||
          authCtx.user.sector == "PP 171" ? (
            <table className="w-full my-6 px-7">
              <thead>
                <tr className="font-noori">
                  {authCtx.user.package !== "1" ? (
                    <th
                      className="text-[15px] font-semibold border-b-[1px] border-[#2A2D4314] py-4 text-right px-2"
                      dir="rtl"
                    >
                      فون
                    </th>
                  ) : null}
                  <th
                    className="text-[15px] font-semibold border-b-[1px] border-[#2A2D4314] py-4 text-right px-2"
                    dir="rtl"
                  >
                    پتہ
                  </th>
                  <th
                    className="text-[15px] font-semibold border-b-[1px] border-[#2A2D4314] py-4 text-right "
                    dir="rtl"
                  >
                    عمر
                  </th>
                  <th
                    className="text-[15px] font-semibold border-b-[1px] border-[#2A2D4314] py-4 text-right "
                    dir="rtl"
                  >
                    صنف
                  </th>
                  <th
                    className="text-[15px] font-semibold border-b-[1px] border-[#2A2D4314] py-4 text-right "
                    dir="rtl"
                  >
                    شناختی کارڈ نمبر
                  </th>

                  <th
                    className="text-[15px] font-semibold border-b-[1px] border-[#2A2D4314] py-4  text-right"
                    dir="rtl"
                  >
                    نام
                  </th>
                  <th
                    className="text-[15px] font-semibold border-b-[1px] border-[#2A2D4314] py-4 w-[50px] text-right"
                    dir="rtl"
                  >
                    گھرانہ
                  </th>
                  <th
                    className="text-[15px] font-semibold border-b-[1px] border-[#2A2D4314] py-4 text-right"
                    dir="rtl"
                  >
                    سلسلہ
                  </th>
                </tr>
              </thead>
              <tbody>
                {data ? (
                  data.map((item, index) => (
                    <tr key={index}>
                      {authCtx.user.package !== "1" ? (
                        <td
                          className="text-[17px] border-b-[1px] pr-3 border-[#2A2D4314] py-4 w-[150px]"
                          dir="rtl"
                        >
                          {item.voter_phone
                            ? (typeof item.voter_phone === "string" &&
                              item.voter_phone !== "null"
                                ? JSON.parse(item.voter_phone)
                                : Array.isArray(item.voter_phone)
                                ? item.voter_phone
                                : []
                              )
                                .filter(
                                  (item, index, self) =>
                                    self.indexOf(item) === index
                                )
                                .map((phone) => (
                                  <div className="mr-2">0{phone}</div>
                                ))
                            : "--"}
                        </td>
                      ) : null}

                      <td
                        className="text-[17px] border-b-[1px] border-[#2A2D4314] py-4 w-[170px] font-noori"
                        dir="rtl"
                      >
                        {item.address?.length > 100
                          ? item.address?.replace(/سال/gi, "").substr(0, 100) +
                            ".."
                          : item.address?.replace(/سال/gi, "")}
                      </td>
                      <td
                        className="text-[17px] border-b-[1px] px-1 border-[#2A2D4314] py-4 w-[150px] font-noori"
                        dir="rtl"
                      >
                        {item.age}
                      </td>
                      <td
                        className="text-[17px] border-b-[1px] border-[#2A2D4314] px-1 py-4 w-[150px] font-noori"
                        dir="rtl"
                      >
                        {item.gender === "male"
                          ? "مرد"
                          : item.gender === "female"
                          ? "عورت"
                          : ""}
                      </td>
                      <td
                        className="text-[17px] border-b-[1px] px-1 border-[#2A2D4314] py-4 w-[200px] font-noori"
                        dir="rtl"
                      >
                        {item.cnic}
                      </td>

                      <td
                        className="text-[17px] border-b-[1px] px-2 border-[#2A2D4314] py-4 w-[150px] font-noori"
                        dir="rtl"
                      >
                        {item.name?.length > 44
                          ? item.name
                              ?.replace(/[^\W]/gi, "")
                              .replace(/[-*]/g, " ")
                              .replace(
                                new RegExp(wordsToReplace.join("|"), "gi"),
                                ""
                              )
                              .substr(0, 44) + ""
                          : item.name
                              ?.replace(/[^\W]/gi, "")
                              .replace(/[-*]/g, " ")
                              .replace(
                                new RegExp(wordsToReplace.join("|"), "gi"),
                                ""
                              )}
                      </td>
                      <td
                        className="text-[17px] border-b-[1px] px-1 border-[#2A2D4314] py-4 w-[50px] font-noori"
                        dir="rtl"
                      >
                        {item.gharana_no}
                      </td>
                      <td
                        className="text-[17px] border-b-[1px] px-1 border-[#2A2D4314] py-4 w-[50px] font-noori"
                        dir="rtl"
                      >
                        {item.silsila_no}
                      </td>
                    </tr>
                  ))
                ) : (
                  <ReactLoading
                    color={"gray"}
                    height={"40px"}
                    width={"70px"}
                    className="my-2 justify-center"
                  />
                )}
              </tbody>
            </table>
          ) : (
            <table className=" my-6 w-full">
              <thead>
                <tr className="font-noori bg-slate-300">
                  {/* {authCtx.user.package !== "1" ? (
                  <th
                    className="text-[15px] font-semibold border-[4px] border-[#0d0d10fd] py-1 text-center px-2"
                    dir="rtl"
                  >
                    فون
                  </th>
                ) : null} */}
                  {/* <th
                  className="text-[15px] font-semibold  border-[4px] px-2 border-[#0d0d10fd] py-1 text-center "
                  dir="rtl"
                >
                  جنس
                </th> */}
                  <th
                    className="text-[15px] font-semibold border-[4px] border-[#0d0d10fd] py-1 text-center px-2"
                    dir="rtl"
                  >
                    پتہ
                  </th>
                  <th
                    className="text-[15px] font-semibold border-[4px] px-2 border-[#0d0d10fd] py-1 text-center "
                    dir="rtl"
                  >
                    عمر
                  </th>

                  <th
                    className="text-[15px] font-semibold border-[4px] px-2 border-[#0d0d10fd] py-1 text-center "
                    dir="rtl"
                  >
                    شناختی کارڈ نمبر
                  </th>
                  <th
                    className="text-[15px] font-semibold border-[4px] px-2 border-[#0d0d10fd] py-1 text-center "
                    dir="rtl"
                  >
                    والد کا نام
                  </th>

                  <th
                    className="text-[15px] font-semibold border-[4px] px-2 border-[#0d0d10fd] py-1 text-center"
                    dir="rtl"
                  >
                    نام
                    {/* <div className="flex flex-row justify-around">
                    <div>سلسلہ</div>
                    <div>خاندان</div>
                    <div></div>
                    <div className="">والد کا نام</div>
                  </div> */}
                  </th>
                  <th
                    className="text-[15px] font-semibold border-[4px] px-2 border-[#0d0d10fd] py-1 text-center "
                    dir="rtl"
                  >
                    گھرانہ
                  </th>

                  <th
                    className="text-[15px] font-semibold border-[4px] px-2 border-[#0d0d10fd]  py-1 text-center "
                    dir="rtl"
                  >
                    سلسلہ
                  </th>
                </tr>
              </thead>
              <tbody>
                {/* {data1 === null} */}
                {data ? (
                  data.map((item, index) => (
                    <>
                      <tr>
                        <td
                          className="text-[11px] border-t-[1px] border-l-[1px] border-r-[1px] px-1 border-[#0d0d10fd] py-1 w-[70px] font-noori"
                          dir="rtl"
                          colSpan={7}
                        >
                           <>
                          {item?.type === "manual" ? (
                            <div
                              className="px-1 flex flex-row text-[25px]  py-2"
                              dir="rtl"
                            >
                              <div className="font-bold text-right font-noori">
                                نام
                              </div>
                              <div className="font-noori mr-4">
                                {/* {data2?.response?.name} */}
                                {
                                  item?.name?.length > 44
                                    ? item?.name
                                        ?.replace(/[^\W]/gi, "")
                                        .replace(/[-*]/g, " ")
                                        // .replace(
                                        //   new RegExp(
                                        //     wordsToReplace.join("|"),
                                        //     "gi"
                                        //   ),
                                        // ""
                                        // )
                                        .substr(0, 44) + ""
                                    : item?.name
                                        ?.replace(/[^\W]/gi, "")
                                        .replace(/[-*]/g, " ")
                                  // .replace(
                                  //   new RegExp(
                                  //     wordsToReplace.join("|"),
                                  //     "gi"
                                  //   ),
                                  //   ""
                                  // )
                                }
                              </div>
                            </div>
                          ) : (
                          <ImageSlice item={item} />)}</>
                          {/* {authCtx.user.sector === "PP 207" ? (
                          <img
                            src={`https://res.cloudinary.com/election-experts/image/fetch/c_crop,h_80,x_0,w_4000,y_${
                              parseInt(item?.rowY) + 70
                            }/${encodeURIComponent(item?.url)}`}
                            alt=""
                            srcSet=""
                            className=" w-[900px]"
                          />
                        ) : (
                          <>
                            {item?.name === "" || item?.address === "" ? (
                              <img
                                src={`https://res.cloudinary.com/election-experts/image/fetch/c_crop,h_100,x_0,w_4000,y_${
                                  parseInt(item?.rowY) - 40
                                }/${encodeURIComponent(item?.url)}`}
                                alt=""
                                srcSet=""
                                className="w-[900px]"
                              />
                            ) : (
                              <img
                                src={`https://res.cloudinary.com/election-experts/image/fetch/c_crop,h_100,x_0,w_4000,y_${
                                  parseInt(item?.rowY)-30
                                }/${encodeURIComponent(item?.url)}`}
                                alt=""
                                srcSet=""
                                className="w-[900px]"
                              />
                            )}
                          </>
                        )} */}
                        </td>
                      </tr>
                      <tr key={index}>
                        {authCtx.user.package !== "1" ? (
                          <td
                            className="text-[17px] border-b-[1px] border-l-[1px] border-r-[1px] pr-3 border-[#0d0d10fd] p1-4 "
                            dir="rtl"
                            // rowSpan={2}
                            colSpan={7}
                          >
                            <span>
                              <span className="mx-3 text-right">
                                {item?.cnic}
                              </span>
                              {authCtx.user.sector !== "PK 82" ? (
                                <>
                                  <strong> | </strong>
                                  <strong dir="rtl">فون :</strong>
                                  <span>
                                    {item.voter_phone
                                      ? (typeof item.voter_phone === "string" &&
                                        item.voter_phone !== "null"
                                          ? JSON.parse(item.voter_phone)
                                          : Array.isArray(item.voter_phone)
                                          ? item.voter_phone
                                          : []
                                        )
                                          .filter(
                                            (item, index, self) =>
                                              self.indexOf(item) === index
                                          )
                                          .map((phone) => (
                                            <span className="mr-2">
                                              0{phone},
                                            </span>
                                          ))
                                      : "--"}
                                  </span>
                                </>
                              ) : (
                                ""
                              )}
                            </span>
                          </td>
                        ) : null}
                      </tr>
                    </>
                  ))
                ) : (
                  <ReactLoading
                    color={"gray"}
                    height={"40px"}
                    width={"70px"}
                    className="my-2 justify-center"
                  />
                )}
              </tbody>
            </table>
          )}

          <Modal isOpen={isModalOpen} onClose={closeModal}>
            <div className="text-[20px] font-bold">
              You can not download more pdfs
            </div>
            <div className="text-[15px] text-gray-700 font-bold">
              For more information please contact us.
            </div>
          </Modal>
        </div>
      </ListingTemplate>
    </>
  );
});

const BlockcodeAgeGraph = ({ blockCode }) => {
  const authCtx = useContext(AuthContext);
  const [ageGraphData, setAgeGraphData] = useState(null);

  useEffect(() => {
    getDataAgeWise().then((res) => {
      if (res.status === 200) {
        const d = [];
        res.report
          .sort((a, b) => a.age - b.age)
          .map((item) => {
            d.push([item.age, item.count]);
          });
        setAgeGraphData(d);
      }
    });
  }, []);

  const getDataAgeWise = async () => {
    let res = await axios(
      `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/age-report?sector=${authCtx.user.sector}&block_code=${blockCode}`
    );
    return res.data;
  };

  return (
    <div>
      {ageGraphData && (
        <>
          <Chart
            chartType="Bar"
            data={[["Age", "Count"], ...ageGraphData]}
            options={{
              hAxis: {
                title: "Count",
              },
              vAxis: {
                title: "Age",
              },
            }}
            width={"100%"}
            height={"200px"}
          />
        </>
      )}
    </div>
  );
};

export default VoterList;
