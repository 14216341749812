import axios from "axios";
// import { Chart } from "chart.js";
import { useContext } from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import AppImages from "../../../../assets/images";
import AuthContext from "../../../../contexts/AuthContext";
import ReactLoading from "react-loading";
import ListingTemplate from "../ListingTemplate";
// import ListingTemplate from "../ListingTemplate";
import { customerActions, customerThunk } from "../reducers/customer_slice";
import Pagination from "./Pagination";
import Chart from "react-google-charts";

function ConfidenceBlockCodeVoterList({ children }) {
  const navigate = useNavigate();
  const customerReducer = useSelector((state) => state.customers);
  const dispatch = useDispatch();

  const ths = [
    { title: "Serial" },
    { title: "Family" },
    { title: "CNIC" },
    { title: "Name" },
    { title: "Address" },
    { title: "Phone" },
  ];

  //   const [data, setData] = useState(customerReducer.data);
  //   const [showableData, setShowableData] = useState(data ?? []);
  const [deleteableItem, setDeletableItem] = useState(null);
  const [searchQuery, setSearchQuery] = useState(null);
  const [statusFilter, setStatusFilter] = useState("all");

  //   useEffect(() => {
  //     if (customerReducer.fetchDataRequestStatus === RequestStatus.IDLE) {
  //       dispatch(customerThunk.getCustomers());
  //     }
  //   }, [customerReducer.fetchDataRequestStatus]);

  //   useEffect(() => {
  //     console.log(customerReducer.data);
  //     setData(customerReducer.data ?? []);
  //     setShowableData(customerReducer.data ?? []);
  //   }, [customerReducer.data]);
  const [cnic, setCnic] = useState("");
  const authCtx = useContext(AuthContext);
  console.log("Authcontext", authCtx);
  const [data, setData] = useState("");
  const { id } = useParams();
  let page = 1;
  let na = authCtx.user.sector;
  useEffect(() => {
    let na = authCtx.user.sector;
    let Id = id;
    let page = 1;
    console.log("ID", Id, na);
    const fetchData = async () => {
      const result = await axios(
        `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/voter-list-blockCode?sector=${na}&page_no=${page}&block_code=${Id}`
      );

      setData(result.data);
    };

    fetchData();
  }, []);
  console.log("RESData", data);
  const malevoter = async () => {
    let gender = "male";
    const result = await axios(
      `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/filter-by-gender-voter?sector=${na}&page_no=${page}&block_code=${id}&gender=${gender}&pageNo=${page}`
    );

    setData(result.data);
  };

  const femalevoter = async () => {
    let gender = "female";
    const result = await axios(
      `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/filter-by-gender-voter?sector=${na}&page_no=${page}&block_code=${id}&gender=${gender}&pageNo=${page}`
    );

    setData(result.data);
  };
  const [data1, setData1] = useState("");
  const cnicSearch = async () => {
    console.log("cnic", cnic);
    const result = await axios(
      `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/search-cnic?sector=${na}&block_code=${id}&cnic=${cnic}`
    );

    setData1(result.data);
  };

  const tdClasses = "text-[12px] border-b-[1px] border-[#2A2D4314] py-4";

  //   const handleStatusFilter = (e) => {
  //     setStatusFilter(e.target.value);
  //     switch (e.target.value) {
  //       case "active":
  //         setShowableData(data.filter((d) => d.status === "active"));
  //         break;
  //       case "inactive":
  //         setShowableData(
  //           data.filter((d) => d.status === "inactive" || !d.status)
  //         );
  //         break;
  //       case "all":
  //       default:
  //         setShowableData(data);
  //     }
  //   };

  //   const filterDataByQuery = (val) => {
  //     console.log(showableData);
  //     const newData = showableData.filter(
  //       (d) =>
  //         d.id.toString().startsWith(val) ||
  //         d.name?.toLowerCase().startsWith(val.toLowerCase()) ||
  //         d.email?.toLowerCase().startsWith(val.toLowerCase()) ||
  //         d.phone?.toLowerCase().startsWith(val.toLowerCase()) ||
  //         d.Location?.city?.toLowerCase().startsWith(val.toLowerCase()) ||
  //         d.Location?.country?.toLowerCase().startsWith(val.toLowerCase())
  //     );
  //     return newData;
  //   };

  //   const handleOnSearch = (e) => {
  //     const val = e.target.value?.trim();
  //     if (val) {
  //       setSearchQuery(val);
  //       setShowableData(filterDataByQuery(val));
  //     } else if (statusFilter !== "all") {
  //       setShowableData(data.filter((d) => d.status === statusFilter));
  //     } else {
  //       setShowableData(data);
  //     }
  //   };

  return (
    <ListingTemplate>
      <div className="flex flex-row">
        {/* <div className="text-[18px] font-bold">Sector</div> */}
        <div className=" text-[15px] ">{na}</div>
        <div className="ml-1 text-[15px] ">/</div>
        <div
          className="ml-1 text-[15px]"
          onClick={(e) => {
            // navigate("/team/list");
          }}
        >
          {id}
        </div>
        <div className="ml-1 text-[15px] ">/</div>
        <div className="ml-1 text-[15px]  text-gray-400">Voter List</div>
      </div>

      <div className="text-[24px] font-bold">Confidence Voter List</div>
      {/* <BlockcodeAgeGraph blockCode={id} />
      <div className="w-[1000px] mt-1">
        A "voter list" in an election is a list of eligible voters who are
        registered to vote in a specific election. The voter list is used to
        ensure that only eligible voters can participate in the election and to
        help election officials manage the voting process.The voter list is an
        important component of the election process as it helps to ensure that
        the election is fair and transparent, and that the outcome accurately
        reflects the will of the eligible voters. The voter list is usually
        updated periodically to remove any voters who are no longer eligible or
        to add new eligible voters.
      </div>
      <div className="ml-[840px] mt-[-15px] mb-[10px] flex flex-row space-x-5">
        <div
          className="bg-gray-100 text-black w-[80px] h-[35px] rounded-md pt-[5px] pl-5 font-bold cursor-pointer hover:bg-gray-300"
          onClick={() => {
            malevoter();
          }}
        >
          Male
        </div>
        <div
          className="bg-gray-100 text-black w-[80px] h-[35px] rounded-md pt-[5px] pl-3 font-bold cursor-pointer hover:bg-gray-300"
          onClick={() => {
            femalevoter();
          }}
        >
          Female
        </div> */}
        {/* <div className="flex flex-row">
          <TextInputField
            type={"text"}
            style={{
              width: "w-full",
              labelFontSize: "text-[20px]",
              inputFontSize: "text-[18px]",
            }}
            onChange={(e) => {
              setCnic(e.target.value);
            }}
            value={cnic}
            // error={formErrors.name}
            isRequired={true}
            placeholder={"Enter cnic"}
          />
          <div className="bg-gray-400 absolute ml-[150px] h-[10px] w-[15px] mt-8" onClick={()=>{
            cnicSearch()
          }}>h</div>
        </div> */}
      {/* </div> */}

      <table className="w-full my-6 px-7">
        <thead>
          <tr className="font-noori">
            <th
              className="text-[15px] font-semibold border-b-[1px] border-[#2A2D4314] py-4 text-right px-2"
              dir="rtl"
            >
              فون
            </th>
            <th
              className="text-[15px] font-semibold border-b-[1px] border-[#2A2D4314] py-4 text-right px-2"
              dir="rtl"
            >
              پتہ
            </th>
            <th
              className="text-[15px] font-semibold border-b-[1px] border-[#2A2D4314] py-4 text-right "
              dir="rtl"
            >
              صنف
            </th>
            <th
              className="text-[15px] font-semibold border-b-[1px] border-[#2A2D4314] py-4 text-right "
              dir="rtl"
            >
              شناختی کارڈ نمبر
            </th>
            {/* <th className="text-[12px] font-semibold border-b-[1px] border-[#2A2D4314] py-4 text-right">
              Block Code
            </th>
            <th className="text-[12px] font-semibold border-b-[1px] border-[#2A2D4314] py-4 text-right">
              Sector
            </th> */}
            <th
              className="text-[15px] font-semibold border-b-[1px] border-[#2A2D4314] py-4 text-right"
              dir="rtl"
            >
              نام
            </th>
            <th
              className="text-[15px] font-semibold border-b-[1px] border-[#2A2D4314] py-4 text-right "
              dir="rtl"
            >
              خاندان نمبر
            </th>
            {/* <th
              className="text-[12px] font-semibold border-b-[1px] border-[#2A2D4314] py-4 text-right "
              dir="rtl"
            >
              گھرانہ نمبر
            </th> */}
            <th
              className="text-[15px] font-semibold border-b-[1px] border-[#2A2D4314] py-4 text-right "
              dir="rtl"
            >
              سلسلہ نمبر
            </th>
          </tr>
        </thead>
        <tbody>
          {data1 === null}
          {data ? (
            data.voters.map((item, index) => (
              <tr key={index}>
                <td
                  className="text-[12px] border-b-[1px] pr-3 border-[#2A2D4314] py-4 w-[150px]"
                  dir="rtl"
                >
                  {item.voter_phone
                    ? JSON.parse(item.voter_phone).map((phone) => (
                        <p>{phone}</p>
                      ))
                    : "--"}
                </td>

                <td
                  className="text-[12px] border-b-[1px] border-[#2A2D4314] py-4 w-[170px] font-noori"
                  dir="rtl"
                >
                  {item.address.slice(0, 45)}
                </td>
                <td
                  className="text-[12px] border-b-[1px] border-[#2A2D4314] px-1 py-4 w-[150px] font-noori"
                  dir="rtl"
                >
                  {/*  */}
                  {item.gender}
                </td>
                <td
                  className="text-[12px] border-b-[1px] px-1 border-[#2A2D4314] py-4 w-[200px] font-noori"
                  dir="rtl"
                >
                  {item.cnic}
                </td>
                <td
                  className="text-[12px] border-b-[1px] px-2 border-[#2A2D4314] py-4 w-[150px] font-noori"
                  dir="rtl"
                >
                  {item.name.slice(0, 11).toString().replace(/[0-9]/gi, "")}
                </td>
                <td
                  className="text-[12px] border-b-[1px] border-[#2A2D4314] py-4 w-[130px]"
                  dir="rtl"
                >
                  {item.gharana_no}
                </td>
                <td
                  className="text-[12px] border-b-[1px] px-1 border-[#2A2D4314] py-4 w-[150px] font-noori"
                  dir="rtl"
                >
                  {item.silsila_no}
                </td>
              </tr>
            ))
          ) : (
            <ReactLoading
              color={"gray"}
              height={"40px"}
              width={"70px"}
              className="my-2 justify-center"
            />
          )}
        </tbody>
      </table>
      <Pagination />
    </ListingTemplate>
  );
}

const BlockcodeAgeGraph = ({ blockCode }) => {
  const authCtx = useContext(AuthContext);
  const [ageGraphData, setAgeGraphData] = useState(null);

  useEffect(() => {
    getDataAgeWise().then((res) => {
      if (res.status === 200) {
        const d = [];
        res.report
          .sort((a, b) => a.age - b.age)
          .map((item) => {
            d.push([item.age, item.count]);
          });
        setAgeGraphData(d);
      }
    });
  }, []);

  const getDataAgeWise = async () => {
    let res = await axios(
      `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/age-report?sector=${authCtx.user.sector}&block_code=${blockCode}`
    );
    return res.data;
  };

  return (
    <div>
      {ageGraphData && (
        <>
          <Chart
            chartType="Bar"
            data={[["Age", "Count"], ...ageGraphData]}
            options={{
              hAxis: {
                title: "Count",
              },
              vAxis: {
                title: "Age",
              },
            }}
            width={"100%"}
            height={"200px"}
          />
        </>
      )}
    </div>
  );
};

export default ConfidenceBlockCodeVoterList;
