import axios from "axios";
// import { Chart } from "chart.js";
import { useContext } from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import AppImages from "../../../../assets/images";
import AuthContext from "../../../../contexts/AuthContext";
import ReactLoading from "react-loading";
import ListingTemplate from "../ListingTemplate";
// import ListingTemplate from "../ListingTemplate";
import { customerActions, customerThunk } from "../reducers/customer_slice";
import Pagination from "./Pagination";
import Chart from "react-google-charts";
import { QueryClient, QueryCache, useQuery } from "react-query";

function SuspiciousBlockCodeVoterListing({ children }) {
  const navigate = useNavigate();
  const customerReducer = useSelector((state) => state.customers);
  const dispatch = useDispatch();

  const ths = [
    { title: "Serial" },
    { title: "Family" },
    { title: "CNIC" },
    { title: "Name" },
    { title: "Address" },
    { title: "Phone" },
  ];

  //   const [data, setData] = useState(customerReducer.data);
  //   const [showableData, setShowableData] = useState(data ?? []);
  const [deleteableItem, setDeletableItem] = useState(null);
  const [searchQuery, setSearchQuery] = useState(null);
  const [statusFilter, setStatusFilter] = useState("all");

  //   useEffect(() => {
  //     if (customerReducer.fetchDataRequestStatus === RequestStatus.IDLE) {
  //       dispatch(customerThunk.getCustomers());
  //     }
  //   }, [customerReducer.fetchDataRequestStatus]);

  //   useEffect(() => {
  //     console.log(customerReducer.data);
  //     setData(customerReducer.data ?? []);
  //     setShowableData(customerReducer.data ?? []);
  //   }, [customerReducer.data]);
  const [cnic, setCnic] = useState("");
  const authCtx = useContext(AuthContext);
  console.log("Authcontext", authCtx);
  const [data5, setData5] = useState([]);
  const [data2, setData2] = useState("");
    const [data3, setData3] = useState([]);
  const { id } = useParams();
  let page = 1;
  let na = authCtx.user.sector;
  const optionsBarChart = {
    title: `Confidence of ${id} Voters` ,
    colors: ["#FB7A21", "#ffff00"],
    chartArea: { width: "50%" },
    hAxis: {
      title: "Suspicious Voters",
      minValue: 0,
    },
    vAxis: {
      title: "Confidence",
    },
  };
  let Id = id;
   
  // console.log("Dataia", data4);
  useEffect(() => {
    let na = authCtx.user.sector;
    let Id = id;
    let page = 1;
     axios(
       `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/groupbyy-confidence?block_code=${Id}`
     )
       .then((res) => {
         const { data } = res;
         if (data.query && Array.isArray(data.query)) {
           const arr = data.query.map((item) => {
             return [item.confidence, item.count];
           });
           setData5(arr);
         }
       })
       .catch((e) => {})
       .finally(() => {});
  //   console.log("ID", Id, na);
    
    // const gharana = async () => {
    //   const result = await axios(
    //     `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/families-confidence?block_code=${Id}`
    //   );
    //   console.log("Resulto",result)

    //   setData3(result.data.query);
    // };

    // gharana();
    // console.log("Dataaaa3",data3)
  //   const fetchData = async () => {
  //     const result = await axios(
  //       `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/voter-list-blockCode?sector=${na}&page_no=${page}&block_code=${Id}`
  //     );

  //     setData2(result.data);
  //   };

  //   fetchData();
  }, []);
  
  console.log("RESData", data3);
  const malevoter = async () => {
    let gender = "male";
    const result = await axios(
      `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/filter-by-gender-voter?sector=${na}&page_no=${page}&block_code=${id}&gender=${gender}&pageNo=${page}`
    );

    setData2(result.data);
  };

  const femalevoter = async () => {
    let gender = "female";
    const result = await axios(
      `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/filter-by-gender-voter?sector=${na}&page_no=${page}&block_code=${id}&gender=${gender}&pageNo=${page}`
    );

    setData2(result.data);
  };
  // const [data1, setData1] = useState("");
  const cnicSearch = async () => {
    console.log("cnic", cnic);
    const result = await axios(
      `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/search-cnic?sector=${na}&block_code=${id}&cnic=${cnic}`
    );

    // setData1(result.data);
  };

  const tdClasses = "text-[12px] border-b-[1px] border-[#2A2D4314] py-4";
const { isLoading, error, data } = useQuery("pollingStationData", () =>
  fetch(
    `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/familiees-confidence?block_code=${Id}&sector=${authCtx.user.sector}`
  ).then((res) => res.json())
);
console.log("Dartaa", data);

if (error) return "An error has occurred: " + error.message;
console.log(
  `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/familiees-confidence?block_code=${Id}`
);

  return (
    <ListingTemplate>
      <div className="flex flex-row">
        {/* <div className="text-[18px] font-bold">Sector</div> */}
        <div className=" text-[15px] ">{na}</div>
        <div className="ml-1 text-[15px] ">/</div>
        <div
          className="ml-1 text-[15px]"
          onClick={(e) => {
            // navigate("/team/list");
          }}
        >
          {id}
        </div>
        <div className="ml-1 text-[15px] ">/</div>
        <div className="ml-1 text-[15px]  text-gray-400">Confidence Voters</div>
      </div>
      <div className="text-[24px] font-bold">Confidence Voters</div>
      <Chart
        chartType="BarChart"
        // chartType=""
        width="100%"
        height="400px"
        data={[["Confidence", "Voter Count"], ...data5]}
        options={optionsBarChart}
      />
      <div className="w-[1000px] mt-1">
        In a confidence voting system, voters are given the option to cast a
        ballot with an assigned level of confidence or weight. This can be used
        to indicate that the voter has greater certainty or conviction about
        their chosen candidate or option.
      </div>

      <table className="w-[90%] my-6 ">
        <tbody>
          {data ? (
            data?.query.map((item, index) => (
              <tr key={index} className="px-3 ">
                <td className="text-[16px] font-bold border-b-[1px] border-[#2A2D4314] py-4 text-left">
                  Gharana number
                </td>

                <td className="text-[12px] border-b-[1px] border-[#2A2D4314] py-4 w-[30%] text-center">
                  {/* -- */}
                </td>
                {/* <td className="text-[12px] border-b-[1px] border-[#2A2D4314] py-4 w-[30%] text-center"> */}
                {/* -- */}
                {/* </td> */}

                <td className="text-left space-y-2 text-[18px] my-2 hover:text-blue-600 hover:cursor-pointer">
                  <Link
                    to={`/suspicious/blockcode/gharana/familytree/${id}/${item.gharana_no}`}
                  >
                    {item.gharana_no}
                  </Link>
                </td>
                <td className="text-left space-y-2 text-[18px] my-2 ">
                  <Link
                    to={`/suspicious/blockcode/gharana/familytree/${id}/${item.gharana_no}`}
                  >
                    <div className="pl-[30px] border-[1px] border-gray-300 w-[100px] hover:bg-slate-200 hover:cursor-pointer rounded-md ">
                      View
                    </div>
                  </Link>
                </td>
              </tr>
            ))
          ) : (
            <div className="flex flex-row">
              <div className="flex justify-center">
                <ReactLoading
                  color={"gray"}
                  height={"20px"}
                  width={"40px"}
                  className="my-2"
                />
              </div>
            </div>
          )}
        </tbody>
      </table>
    </ListingTemplate>
  );
}

const BlockcodeAgeGraph = ({ blockCode }) => {
  const authCtx = useContext(AuthContext);
  const [ageGraphData, setAgeGraphData] = useState(null);

  useEffect(() => {
    getDataAgeWise().then((res) => {
      if (res.status === 200) {
        const d = [];
        res.report
          .sort((a, b) => a.age - b.age)
          .map((item) => {
            d.push([item.age, item.count]);
          });
        setAgeGraphData(d);
      }
    });
  }, []);

  const getDataAgeWise = async () => {
    let res = await axios(
      `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/age-report?sector=${authCtx.user.sector}&block_code=${blockCode}`
    );
    return res.data;
  };

  return (
    <div>
      {ageGraphData && (
        <>
          <Chart
            chartType="Bar"
            data={[["Age", "Count"], ...ageGraphData]}
            options={{
              hAxis: {
                title: "Count",
              },
              vAxis: {
                title: "Age",
              },
            }}
            width={"100%"}
            height={"200px"}
          />
        </>
      )}
    </div>
  );
};

export default SuspiciousBlockCodeVoterListing;
