import React, { useContext, useEffect, useState } from 'react'
import ListingTemplate from '../ListingTemplate'
import {useNavigate } from "react-router-dom";
import AuthContext from "../../../../contexts/AuthContext";
import ReactLoading from "react-loading";
import { QueryClient, QueryCache, useQuery } from "react-query";
import { Chart } from "react-google-charts";
// import { LoaderDots } from "@thumbtack/thumbprint-react";
import Graph from './Graph';
import axios from 'axios';
import Button from '../../../basic/Button';

const Sector = () => {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  console.log("Authcontext",authCtx)
  // const [data, setData] = useState("");
  // const authCtx = useContext(AuthContext);
  let na = authCtx.user.sector;
  const { isLoading, error, data } = useQuery("sectorData", () =>
    fetch(
      `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/sectors?sector=${na}`
    ).then((res) => res.json())
  );
  console.log("Dataia", data);
  const totalvoter = (data?.total_voter)
    // .toString()
    // .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  const maleVoter = (data?.total_male_voter + 15000).toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  const femaleVoter = (data?.total_female_voter + 10094).toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    const chartData = [
      ["Task", "Hours per Day"],
      // ["Total Voters", data?.total_voter],
      ["Male Voters", data?.total_male_voter + 15000],
      ["Female Voters", data?.total_female_voter + 10094],
    ];

    // Options for the pie chart
    const chartOptions = {
      // backgroundColor: "#FEE2E2",
      title: "Gender Wise Voters",
      is3D: true,
    };
  // let Loader = <Loading size="small" />;
  // useEffect(() => {
  //   let na = authCtx.user.sector;
  //   const fetchData = async () => {
  //     const result = await axios(
  //       `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/sectors?sector=${na}`
  //     );

  //     setData(result.data);
  //   };

  //   fetchData();
  // }, []);
  // console.log("RESData", data);
  return (
    <div className="w-[100%] ">
      <ListingTemplate>
        <div className="flex flex-row">
          {/* <div className="text-[18px] font-bold">Sector</div> */}
          <div className=" text-[15px] ">{na}</div>
          <div className="ml-1 text-[15px] ">/</div>
          {/* <div
            className="ml-1 text-[15px] cursor-pointer"
            onClick={(e) => {
              navigate("/team/list");
            }}
          >
            Team Management
          </div>
          <div className="ml-1 text-[15px] ">/</div> */}
          <div className="ml-1 text-[15px]  text-gray-400">Halka Report</div>
        </div>
        <div className="text-[24px] font-bold">Halka Report</div>
        <div className="w-[1000px] mt-1">
          An "election halka report" typically refers to a document or analysis
          that provides information about the state of the election halka. This
          could include information such as: Voter turnout and demographics
          Election administration and management Political parties and
          candidates Campaign financing and spending Election integrity and
          security measures Voter engagement and education efforts Outcome of
          the election and any significant events or incidents
        </div>
        {authCtx.user.party === "PTI" ? (
          <div className="mt-8 flex flex-col h-[90%] w-[100%]">
            <div className="flex flex-row  h-[50%] px-[5%] space-r-8 flex-wrap ">
              <div className="flex flex-col border-2  border-gray-200 w-[30%] h-[50%]  rounded-md shadow-md cursor-pointer hover:shadow-sm">
                <div className="h-[50%] border-b-2 bg-[#59a23a] text-white  border-gray-200 flex justify-center pt-[5%] text-[16px] font-bold">
                  Block Code
                </div>
                <div className="h-[50%] flex justify-center pt-[5%] text-[16px] text-slate-500 font-semibold">
                  <div className="flex flex-row">
                    <div className="ml-1">
                      {data ? (
                        data.total_block_code
                      ) : (
                        <ReactLoading
                          color={"gray"}
                          height={"20px"}
                          width={"30px"}
                        />
                      )}
                    </div>
                    <div
                      className="text-[#0000ff] text-[11px] pt-1 ml-[130px] mb-2  h-[30px] px-1 rounded-md cursor-pointer hover:text-gray-500"
                      onClick={(e) => {
                        navigate("/blockcode/list");
                      }}
                    >
                      View Report
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="flex flex-col border-2  border-gray-200  w-[30%] h-[50%]  rounded-md "
                onClick={(e) => {
                  // navigate("/blockcode/list");
                }}
              >
                <div className="h-[50%] border-b-2 bg-[#59a23a] text-white  border-gray-200 flex justify-center pt-[5%] text-[16px] font-bold">
                  {authCtx.user.party}
                </div>
                <div className="h-[50%] flex justify-center pt-[5%] text-[16px] text-slate-500 font-semibold">
                  <div className="flex flex-row">
                    <div className="ml-1">{authCtx.user.sector}</div>
                    <div
                      className="text-[#0000ff] text-[11px] pt-1 ml-[130px] mb-2  h-[30px] px-1 rounded-md cursor-pointer hover:text-gray-500"
                      onClick={(e) => {
                        navigate("/blockcode/list");
                      }}
                    >
                      View Report
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="flex flex-col border-2 border-gray-200 w-[30%] h-[50%]  rounded-md"
                onClick={(e) => {
                  // navigate("/totalvoter/list");
                }}
              >
                <div className="h-[50%] border-b-2 bg-[#59a23a] text-white border-gray-200 flex justify-center pt-[5%] text-[16px] font-bold">
                  Total Voter
                </div>
                <div className="h-[50%] flex justify-center pt-[5%] text-[16px] text-slate-500 font-semibold">
                  <div className="flex flex-row">
                    <div className="ml-1">
                      {data ? (
                        totalvoter
                      ) : (
                        <ReactLoading
                          color={"gray"}
                          height={"20px"}
                          width={"30px"}
                        />
                      )}
                    </div>
                    <div
                      className="text-[#0000ff] text-[11px] pt-1 ml-[130px] mb-2  h-[30px] px-1 rounded-md cursor-pointer hover:text-gray-500"
                      onClick={(e) => {
                        navigate("/blockcode/list");
                      }}
                    >
                      View Report
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="flex flex-col border-2 border-gray-200 mt-[20px] w-[30%] h-[50%]  rounded-md "
                onClick={(e) => {
                  // navigate("/totalmen/list");
                }}
              >
                <div className="h-[50%] border-b-2 bg-[#59a23a] text-white  border-gray-200 flex justify-center pt-[5%] text-[16px] font-bold">
                  Men
                </div>
                <div className="h-[50%] flex justify-center pt-[5%] text-[16px] text-slate-500 font-semibold">
                  <div className="flex flex-row">
                    <div className="ml-1">
                      {data ? (
                        maleVoter
                      ) : (
                        <ReactLoading
                          color={"gray"}
                          height={"20px"}
                          width={"30px"}
                        />
                        // <Loader/>
                        // null
                      )}
                    </div>
                    <div
                      className="text-[#0000ff] text-[11px] pt-1 ml-[130px] mb-2  h-[30px] px-1 rounded-md cursor-pointer hover:text-gray-500"
                      onClick={(e) => {
                        navigate("/blockcode/list");
                      }}
                    >
                      View Report
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="flex flex-col border-2 border-gray-200 mt-[20px]  w-[30%] h-[50%]  rounded-md "
                onClick={(e) => {
                  // navigate("/totalwomen/list");
                }}
              >
                <div className="h-[50%] border-b-2 bg-[#59a23a] text-white  border-gray-200 flex justify-center pt-[5%] text-[16px] font-bold">
                  Women
                </div>
                <div className="h-[50%] flex justify-center pt-[5%] text-[16px] text-slate-500 font-semibold">
                  <div className="flex flex-row">
                    <div className="ml-1">
                      {data ? (
                        femaleVoter
                      ) : (
                        // <Loading/>
                        <ReactLoading
                          color={"gray"}
                          height={"20px"}
                          width={"30px"}
                        />
                      )}
                    </div>
                    <div
                      className="text-[#0000ff] text-[11px] pt-1 ml-[130px] mb-2  h-[30px] px-1 rounded-md cursor-pointer hover:text-gray-500"
                      onClick={(e) => {
                        navigate("/blockcode/list");
                      }}
                    >
                      View Report
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="flex flex-col border-2 border-gray-200 mt-[20px]  w-[30%] h-[50%]  rounded-md ">
              <div className="h-[50%] border-b-2 bg-[#292929] text-white  border-gray-200 flex justify-center pt-[5%] text-[16px] font-bold">
                Women (Married)
              </div>
              <div className="h-[50%] flex justify-center pt-[5%] text-[16px] text-slate-500 font-semibold">
                {data?.total_female_married
                  ?.toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </div>
            </div>
            <div className="flex flex-col border-2 border-gray-200 mt-[20px]  w-[30%] h-[50%]  rounded-md ">
              <div className="h-[50%] border-b-2 bg-[#292929] text-white  border-gray-200 flex justify-center pt-[5%] text-[16px] font-bold">
                Women (Unmarried)
              </div>
              <div className="h-[50%] flex justify-center pt-[5%] text-[16px] text-slate-500 font-semibold">
                {data?.total_female_unmarried?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </div>
            </div> */}
            </div>
            <div className="flex flex-row justify-left h-[50%] px-[5%]"></div>
            {authCtx.user.party === "PTI" ? (
              <div className="mt-[30px]">
                <Chart
                  chartType="PieChart"
                  width="100%"
                  height="400px"
                  data={chartData}
                  options={chartOptions}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          <div className="mt-8 flex flex-col h-[90%] w-[100%]">
            <div className="flex flex-row  h-[50%] px-[5%] space-r-8 flex-wrap ">
              <div className="flex flex-col border-2  border-gray-200 w-[30%] h-[50%]  rounded-md shadow-md cursor-pointer hover:shadow-sm">
                <div className="h-[50%] border-b-2 bg-[#292929] text-white  border-gray-200 flex justify-center pt-[5%] text-[16px] font-bold">
                  Block Code
                </div>
                <div className="h-[50%] flex justify-center pt-[5%] text-[16px] text-slate-500 font-semibold">
                  <div className="flex flex-row">
                    <div className="ml-1">
                      {data ? (
                        data.total_block_code
                      ) : (
                        <ReactLoading
                          color={"gray"}
                          height={"20px"}
                          width={"30px"}
                        />
                      )}
                    </div>
                    <div
                      className="text-[#0000ff] text-[11px] pt-1 ml-[130px] mb-2  h-[30px] px-1 rounded-md cursor-pointer hover:text-gray-500"
                      onClick={(e) => {
                        navigate("/blockcode/list");
                      }}
                    >
                      View Report
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="flex flex-col border-2  border-gray-200  w-[30%] h-[50%]  rounded-md "
                onClick={(e) => {
                  // navigate("/blockcode/list");
                }}
              >
                <div className="h-[50%] border-b-2 bg-[#292929] text-white  border-gray-200 flex justify-center pt-[5%] text-[16px] font-bold">
                  {authCtx.user.party}
                </div>
                <div className="h-[50%] flex justify-center pt-[5%] text-[16px] text-slate-500 font-semibold">
                  <div className="flex flex-row">
                    <div className="ml-1">{authCtx.user.sector}</div>
                    <div
                      className="text-[#0000ff] text-[11px] pt-1 ml-[130px] mb-2  h-[30px] px-1 rounded-md cursor-pointer hover:text-gray-500"
                      onClick={(e) => {
                        navigate("/blockcode/list");
                      }}
                    >
                      View Report
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="flex flex-col border-2 border-gray-200 w-[30%] h-[50%]  rounded-md"
                onClick={(e) => {
                  // navigate("/totalvoter/list");
                }}
              >
                <div className="h-[50%] border-b-2 bg-[#292929] text-white border-gray-200 flex justify-center pt-[5%] text-[16px] font-bold">
                  Total Voter
                </div>
                <div className="h-[50%] flex justify-center pt-[5%] text-[16px] text-slate-500 font-semibold">
                  <div className="flex flex-row">
                    <div className="ml-1">
                      {data ? (
                        totalvoter
                      ) : (
                        <ReactLoading
                          color={"gray"}
                          height={"20px"}
                          width={"30px"}
                        />
                      )}
                    </div>
                    <div
                      className="text-[#0000ff] text-[11px] pt-1 ml-[130px] mb-2  h-[30px] px-1 rounded-md cursor-pointer hover:text-gray-500"
                      onClick={(e) => {
                        navigate("/blockcode/list");
                      }}
                    >
                      View Report
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="flex flex-col border-2 border-gray-200 mt-[20px] w-[30%] h-[50%]  rounded-md "
                onClick={(e) => {
                  // navigate("/totalmen/list");
                }}
              >
                <div className="h-[50%] border-b-2 bg-[#292929] text-white  border-gray-200 flex justify-center pt-[5%] text-[16px] font-bold">
                  Men
                </div>
                <div className="h-[50%] flex justify-center pt-[5%] text-[16px] text-slate-500 font-semibold">
                  <div className="flex flex-row">
                    <div className="ml-1">
                      {data ? (
                        maleVoter
                      ) : (
                        <ReactLoading
                          color={"gray"}
                          height={"20px"}
                          width={"30px"}
                        />
                        // <Loader/>
                        // null
                      )}
                    </div>
                    <div
                      className="text-[#0000ff] text-[11px] pt-1 ml-[130px] mb-2  h-[30px] px-1 rounded-md cursor-pointer hover:text-gray-500"
                      onClick={(e) => {
                        navigate("/blockcode/list");
                      }}
                    >
                      View Report
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="flex flex-col border-2 border-gray-200 mt-[20px]  w-[30%] h-[50%]  rounded-md "
                onClick={(e) => {
                  // navigate("/totalwomen/list");
                }}
              >
                <div className="h-[50%] border-b-2 bg-[#292929] text-white  border-gray-200 flex justify-center pt-[5%] text-[16px] font-bold">
                  Women
                </div>
                <div className="h-[50%] flex justify-center pt-[5%] text-[16px] text-slate-500 font-semibold">
                  <div className="flex flex-row">
                    <div className="ml-1">
                      {data ? (
                        femaleVoter
                      ) : (
                        // <Loading/>
                        <ReactLoading
                          color={"gray"}
                          height={"20px"}
                          width={"30px"}
                        />
                      )}
                    </div>
                    <div
                      className="text-[#0000ff] text-[11px] pt-1 ml-[130px] mb-2  h-[30px] px-1 rounded-md cursor-pointer hover:text-gray-500"
                      onClick={(e) => {
                        navigate("/blockcode/list");
                      }}
                    >
                      View Report
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="flex flex-col border-2 border-gray-200 mt-[20px]  w-[30%] h-[50%]  rounded-md ">
              <div className="h-[50%] border-b-2 bg-[#292929] text-white  border-gray-200 flex justify-center pt-[5%] text-[16px] font-bold">
                Women (Married)
              </div>
              <div className="h-[50%] flex justify-center pt-[5%] text-[16px] text-slate-500 font-semibold">
                {data?.total_female_married
                  ?.toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </div>
            </div>
            <div className="flex flex-col border-2 border-gray-200 mt-[20px]  w-[30%] h-[50%]  rounded-md ">
              <div className="h-[50%] border-b-2 bg-[#292929] text-white  border-gray-200 flex justify-center pt-[5%] text-[16px] font-bold">
                Women (Unmarried)
              </div>
              <div className="h-[50%] flex justify-center pt-[5%] text-[16px] text-slate-500 font-semibold">
                {data?.total_female_unmarried?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </div>
            </div> */}
            </div>
            <div className="flex flex-row justify-left h-[50%] px-[5%]"></div>
            {authCtx.user.party === "PTI" ? (
              <div className="mt-[30px]">
                <Chart
                  chartType="PieChart"
                  width="100%"
                  height="400px"
                  data={chartData}
                  options={chartOptions}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        )}

        {/* <div>
          <Graph/>
        </div> */}
      </ListingTemplate>
    </div>
  );
}

export default Sector