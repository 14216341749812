import axios from "axios";
import { useContext } from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import AppImages from "../../../../assets/images";
import AuthContext from "../../../../contexts/AuthContext";
import { Api } from "../../../../utils/api";
import { RequestStatus } from "../../../../utils/enums";
import SimpleModal from "../../../basic/SimpleModal";
import ListingTemplate from "../ListingTemplate";
import { employeeActions, employeeThunk } from "../reducers/employee_slice";

function PhoneDataSearch({ children }) {
  const navigate = useNavigate();
  const ths = [{ title: "Block Code" }, { title: "Count" }, { title: "View" }];
  const tdClasses = "text-[12px] border-b-[1px] border-[#2A2D4314] py-4";

  const dispatch = useDispatch();
  const authCtx = useContext(AuthContext);
  const employeeReducer = useSelector((state) => state.employees);
  // console.log("Employee", employeeReducer.data)
  const [data, setData] = useState([]);
  const [showableData, setShowableData] = useState(data ?? []);

  const [data1, setData1] = useState("");
  const fields = {
    blockCode: null,
    phoneNum: null,
  };
  const errors = {
    blockCode: null,
    phoneNum: null,
  };
  const [formFields, setFormFields] = useState(fields);
  const [data2, setData2] = useState("");
  let na = authCtx.user.sector;
 let page =1;
  useEffect(() => {
    const fetchData = async () => {
      const result = await axios(
        `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/sectors-block-code?sector=${na}&page_no=${page}`
      );
      console.log("Resulttt", result);

      setData(result.data.block_code);
    };

    fetchData();
  }, []);
  console.log("Showable dtaa", data);
  const onSubmit = async (e) => {
    // setData2(formFields)
    e.preventDefault();
    console.log("EEE", formFields);
    let phonenumber = parseInt(formFields?.phoneNum,10)
    console.log("PhoneNumberrrr", phonenumber);
    const result = await axios(
      `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/filter-by-phone?sector=${na}&block_code=${formFields.blockCode}&phone=${phonenumber}`
    );
    console.log("Resultttttttttt", result);
    setData2(result.data.voters);
  };
  console.log("Dataaaaaaa", data2);
  var yIncr = 0;
  if (data2?.rowY) {
    if (authCtx.user.sector === "PP 172") {
      if (
        // item.block_code === 185570302 ||
        // item.block_code === 185560303 ||
        // item.block_code === 185570402
        data2?.meta === "100"
      ) {
        yIncr = -10;
      } else {
        if (data2?.name === "" || data2?.address === "") {
          yIncr = -40;
        } else {
          yIncr = 65;
        }
      }
    } else if (["PP 208", "PP 14"].includes(authCtx.user.sector)) {
      if (data2?.name === "" || data2?.address === "") {
        yIncr = -40;
      } else {
        yIncr = 65;
      }
    }
    // else if (["PP 207"].includes(authCtx.user.sector)) {
    //   if (data2?.name === "" || data2?.address === "") {
    //     yIncr = -30;
    //   } else {
    //     yIncr = 50;
    //   }
    // }
    else if (["NA 120"].includes(authCtx.user.sector)) {
      yIncr = 50;
    } else if (["PP 59"].includes(authCtx.user.sector)) {
      yIncr = -20;
    } else {
      yIncr = -10;
    }
  }
//   const darta = JSON.parse(data2.voters);
//   console.log("Darta",darta)
  return (
    <ListingTemplate
    //   title="Employees"
    >
      <div className="flex flex-col justify-center items-center mb-6 text-[25px] font-bold">
        <div>Search by Phone Number</div>
      </div>
      <form
        className={
          authCtx.user.party === "PMLN"
            ? "bg-white invitationForm"
            : authCtx.user.party === "PTI"
            ? "bg-white invitationForm"
            : authCtx.user.party === "PPP"
            ? "bg-white invitationForm"
            : "bg-white invitationForm"
        }
        onSubmit={onSubmit}
      >
        {/* <select
          name=""
          id=""
          className="py-2 px-2 border-[1px] border-black rounded-[6px] text-[14px] w-full h-[50px]"
          //   value={formFields.lastName}
          onChange={(e) => {
            setFormFields((old) => ({ ...old, blockCode: e.target.value }));
          }}
        >
          <option>Select Block code</option>
          {data?.map((item, index) => (
            <option key={index} value={item.block_code}>
              {item.block_code}
            </option>
          ))}
        </select> */}
        <div className="flex flex-row justify-between">
          <div className="flex flex-row my-4">
            <div className="text-[20px] font-bold mt-2">Enter Phone number</div>
            <div>
              <input
                type="text"
                placeholder="Phone Number"
                className="my-1 w-[350px] rounded-md py-2 ml-4 px-1 border-2 border-gray-300"
                //   value={formFields.minage}
                onChange={(e) => {
                  setFormFields((old) => ({
                    ...old,
                    phoneNum: e.target.value,
                  }));
                }}
              />
            </div>
          </div>

          <div className="flex flex-col mt-6 mr-2">
            <button
              className="w-[150px] h-[40px] rounded-md bg-blue-400 text-white font-bold hover:bg-blue-300"
              // isLoading={isPostRequestInProgress}
            >
              Search
            </button>
          </div>
        </div>
      </form>
      {data2 === "" ? (
        <div></div>
      ) : (
        <div>
          <table className="w-full my-6">
            <thead>
              <tr>
                <th
                  className="text-[12px] font-semibold border-b-[1px] border-[#2A2D4314] py-4 text-right px-2"
                  dir="rtl"
                >
                  نام
                </th>
                <th
                  className="text-[12px] font-semibold border-b-[1px] border-[#2A2D4314] py-4 text-center "
                  dir="rtl"
                >
                  عمر
                </th>
                <th
                  className="text-[12px] font-semibold border-b-[1px] border-[#2A2D4314] py-4 text-center "
                  dir="rtl"
                >
                  پتہ
                </th>
                {/* <th className="text-[12px] font-semibold border-b-[1px] border-[#2A2D4314] py-4 text-left">
              Block Code
            </th>
            <th className="text-[12px] font-semibold border-b-[1px] border-[#2A2D4314] py-4 text-left">
              Sector
            </th> */}
                <th
                  className="text-[12px] font-semibold border-b-[1px] border-[#2A2D4314] py-4 text-right"
                  dir="rtl"
                >
                  خاندانی نمبر
                </th>
                <th
                  className="text-[12px] font-semibold border-b-[1px] border-[#2A2D4314] py-4 text-right "
                  dir="rtl"
                >
                  شناختی کارڈ نمبر
                </th>
                <th
                  className="text-[12px] font-semibold border-b-[1px] border-[#2A2D4314] py-4 text-center "
                  dir="rtl"
                >
                  فون
                </th>

                <th
                  className="text-[12px] ml-4 font-semibold border-b-[1px] border-[#2A2D4314] py-4 text-right "
                  dir="rtl"
                >
                  گھر نمبر
                </th>
              </tr>
            </thead>
            <tbody>
              {data2?.map((item, index) => (
                <tr key={index}>
                  <td className={`${tdClasses} font-noori`} dir="rtl">
                    {item.name}
                  </td>
                  <td className={`${tdClasses} pl-3`}>{item.age}</td>
                  <td className={`${tdClasses} font-noori w-[280px]`} dir="rtl">
                    {item.address}
                  </td>
                  {/* <td className={tdClasses}>{item.block_code}</td> */}
                  {/* <td className={tdClasses}>{item.sector}</td> */}
                  <td className={`${tdClasses} w-[100px] mx-3`} dir="rtl">
                    {item.gharana_no}
                  </td>
                  <td className={`${tdClasses} w-[170px]`} dir="rtl">
                    {item.cnic}
                  </td>
                  <td className={`${tdClasses} pl-3`}>
                    {item.voter_phone
                      ? (typeof item.voter_phone === "string" &&
                        item.voter_phone !== "null"
                          ? JSON.parse(item.voter_phone)
                          : Array.isArray(item.voter_phone)
                          ? item.voter_phone
                          : []
                        )
                          .filter(
                            (item, index, self) => self.indexOf(item) === index
                          )
                          .map((phone) => <div className="mr-2">0{phone}</div>)
                      : "--"}
                  </td>
                  <td className={`${tdClasses} w-[60px]`} dir="rtl">
                    {item.gharana_no}
                  </td>
                  {/* <td className={`${tdClasses} w-[10%]`}>
                {item.status === "active" ? (
                  <span
                    className="bg-[#69BA6C] text-white text-center w-[70px] inline-block py-[1px] rounded-[4px] cursor-pointer"
                    title="Click to inactivate it"
                    onClick={(e) => handleClickOnStatusBtn(item, "inactive")}
                  >
                    Active
                  </span>
                ) : (
                  <span
                    className="bg-primary text-white text-center w-[70px] inline-block py-[1px] rounded-[4px] cursor-pointer"
                    title="Click to activate it"
                    onClick={(e) => handleClickOnStatusBtn(item, "active")}
                  >
                    Inactive
                  </span>
                )}
              </td> */}
                  <td className={`${tdClasses} w-[12%]`}>
                    <div className="flex items-center">
                      {/* <Link to={`/blockcode/voters/list/${item.block_code}`}>
                    <div className="bg-primary rounded-lg p-2 mr-2 cursor-pointer hover:bg-black">
                      <img src={AppImages.eyeWhite} className="w-[14px]" />
                    </div>
                  </Link>
                  <Link to={`/voter/list/${item.block_code}`}>
                    <div className="bg-primary rounded-lg p-2 mr-2 cursor-pointer hover:bg-black">
                      <img src={AppImages.eyeWhite} className="w-[14px]" />
                    </div>
                  </Link> */}
                      {/* <div
                    className="bg-primary rounded-lg p-2 mr-2 cursor-pointer hover:bg-black"
                    onClick={() => {
                      dispatch(employeeActions.setActiveEmployeeData(item));
                      navigate(`/org/employees/${item.id}/edit`);
                    }}
                  >
                    <img src={AppImages.editWhiteFilled} className="w-[14px]" />
                  </div>
                  <div
                    className="bg-primary rounded-lg p-2 mr-2 cursor-pointer hover:bg-black"
                    onClick={() => setDeletableItem(item)}
                  >
                    <img src={AppImages.deleteWhite} className="w-[14px]" />
                  </div> */}
                    </div>
                  </td>
                </tr>
              ))}
              {/* {data2 ? (
                  data2.map((item, index) => (
                    <>
                       </>)))} */}
            </tbody>
          </table>
          {/* <div className="px-1 flex flex-row py-2" dir="rtl">
            <img
              src={`https://res.cloudinary.com/election-experts/image/fetch/c_crop,h_90,x_0,w_4000,y_${
                parseInt(data2?.rowY) + yIncr
              }/${encodeURIComponent(data2?.firebase_url)}`}
              alt=""
              srcSet=""
              className="w-[900px]"
            />
          </div> */}
        </div>
      )}
    </ListingTemplate>
  );
}

export default PhoneDataSearch;
