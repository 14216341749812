import axios from "axios";

import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import AuthContext from "../../../../contexts/AuthContext";
import Button from "../../../basic/Button";
import TextInputField from "../../../basic/TextInputField";
import ListingTemplate from "../ListingTemplate";
// import AppImages from "../../../assets/images";
// import AuthContext from "../../../contexts/AuthContext";
// import { Api } from "../../../utils/api";
// import { isEmail } from "../../../utils/helpers";
// import Button from "../../basic/Button";
// import TextInputField from "../../basic/TextInputField";
// import AuthTemplate from "../../common/AuthTemplate";
// import {
//   customerActions,
//   customerThunk,
// } from "../Organization/reducers/customer_slice";
// import { AuthApi } from "./api/AuthApi";

function UpdatePollingAgent() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //   const authCtx = useContext(AuthContext);
  const item = useParams();
//   const name = useParams();
//   const cnic = useParams();
//   const phone = useParams();
//   const blockcode = useParams();
//   const ht = useParams();
//   const ps = useParams();
//   const pe = useParams();
//   const comment = useParams();
  console.log("Itemssss",item)
  const authCtx = useContext(AuthContext);
  let na = authCtx.user.sector;
  console.log("Authcontext", authCtx);
  const getFields = () => {
    return {
      name: null,
      cnic: null,
      blockcode: null,
      halkatype: null,
      pollingstation: null,
      previousexperience: null,
      comment: null,
      phone: null,
      pollingboth:null,
    };
  };
  const [formFields, setFormFields] = useState({
    name: "",
    cnic: "",
    blockcode: "",
    halkatype: "",
    pollingstation: "",
    previousexperience: "",
    comment: "",
    phone: "",
    pollingboth:"",
  });
  const [formErrors, setFormErrors] = useState({
    name: null,
    cnic: null,
    blockcode: null,
    halkatype: null,
    pollingstation: null,
    previousexperience: null,
    comment: null,
    phone: null,
    pollingboth: null,
  });
  const [showLoader, setShowLoader] = useState(false);
  const [showableData, setShowableData] = useState([]);
  const [data, setData] = useState("");
  useEffect(() => {
    const fields = getFields();
    fields.blockcode = item.blockcode;
    fields.cnic= item.cnic;
    fields.comment= item.comment;
    fields.halkatype= item.ht;
    fields.name= item.name;
    fields.phone= item.phone;
    fields.pollingstation= item.ps;
    fields.previousexperience= item.pe;
    fields.pollingboth= item.pb;
    setFormFields(fields);
    // axios.get(
    //     `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/geo-location-polling-station?sector=${authCtx.user?.sector}`
    //   )
    //   .then((res) => {
    //     console.log(res.data);
    //     if (res.data.status === 200) {
    //       //   setData(res.data.polling_scheme);
    //       setShowableData(res.data.polling_scheme);
    //     }
    //   });
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("FormFields", formFields);
    let na = authCtx.user.sector;
    let id = authCtx.user.id;
    let party = authCtx.user.party;
    let packagee = authCtx.user.package;
    const result = await axios.post(
      `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/update-polling-agent?id=${item.id}&blockcode=${formFields.blockcode}&ps=${formFields.pollingstation}&pb=${formFields.pollingboth}&name=${formFields.name}&cnic=${formFields.cnic}&phone=${formFields.phone}&ht=${formFields.halkatype}&pe=${formFields.previousexperience}&comment=${formFields.comment}`
    );
    const url = `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/update-polling-agent?id=${item.id}&blockcode=${formFields.blockcode}&ps=${formFields.pollingstation}&pb=${formFields.pollingboth}&name=${formFields.name}&cnic=${formFields.cnic}&phone=${formFields.phone}&ht=${formFields.halkatype}&pe=${formFields.previousexperience}&comment=${formFields.comment}`;
    console.log("URL", url);
    console.log("Resultttttttttt", result);
    setData(result.data);
  };
  console.log("Dataaaa", data);
  const selecttype = [
    {
      value: "1",
      text: "User",
    },
    {
      value: "2",
      text: "Worker",
    },
  ];
  return (
    // <AuthTemplate>
    <ListingTemplate>
      <div className="flex flex-row">
        {/* <div className="text-[18px] font-bold">Sector</div> */}
        <div className=" text-[15px] ">{na}</div>
        <div className="ml-1 text-[15px] ">/</div>
        <div
          className="ml-1 text-[15px] cursor-pointer"
          onClick={(e) => {
            navigate("/team/list");
          }}
        >
          Team Management
        </div>
        <div className="ml-1 text-[15px] ">/</div>
        <div className="ml-1 text-[15px]  text-gray-400">
          Update Polling Agent
        </div>
      </div>
      <div className="text-[24px] font-bold">Update Polling Agent</div>
      <div className="w-[1000px] mt-1">
        The functionality that can be Updated to a polling agent team could
        include things like: Updateing Agent Setting roles and permissions for
        members Sharing resources and content within the team, etc. Setting up
        workflows and processes specific to the team
      </div>
      <form
        onSubmit={handleSubmit}
        className="max-w-md mx-auto my-5 gap-2 h-[70vh] justify-between flex flex-col"
      >
        <div>
          <TextInputField
            type={"text"}
            style={{
              width: "w-full",
              labelFontSize: "text-[20px]",
              inputFontSize: "text-[18px]",
            }}
            label="Name"
            onChange={(e) => {
              setFormErrors((old) => ({ ...old, name: null }));
              setFormFields((old) => ({ ...old, name: e?.target?.value }));
            }}
            value={formFields.name}
            error={formErrors.name}
            isRequired={true}
            placeholder={"Enter Name"}
          />
          {formErrors.api && (
            <p className="my-1 text-sm text-primary pl-4">{formErrors.api}</p>
          )}
          <TextInputField
            type={"text"}
            style={{
              width: "w-full",
              labelFontSize: "text-[20px]",
              inputFontSize: "text-[18px]",
            }}
            label="Cnic"
            onChange={(e) => {
              setFormErrors((old) => ({ ...old, cnic: null }));
              setFormFields((old) => ({ ...old, cnic: e?.target?.value }));
            }}
            value={formFields.cnic}
            error={formErrors.cnic}
            isRequired={true}
            placeholder={"Enter CNIC"}
          />
          {formErrors.api && (
            <p className="my-1 text-sm text-primary pl-4">{formErrors.api}</p>
          )}
          <TextInputField
            type={"number"}
            style={{
              width: "w-full",
              labelFontSize: "text-[20px]",
              inputFontSize: "text-[18px]",
            }}
            label="Phone"
            onChange={(e) => {
              setFormErrors((old) => ({ ...old, phone: null }));
              setFormFields((old) => ({ ...old, phone: e?.target?.value }));
            }}
            value={formFields.phone}
            error={formErrors.phone}
            isRequired={true}
            placeholder={"Enter Phone number"}
          />
          {formErrors.api && (
            <p className="my-1 text-sm text-primary pl-4">{formErrors.api}</p>
          )}{" "}
          <TextInputField
            type={"text"}
            style={{
              width: "w-full",
              labelFontSize: "text-[20px]",
              inputFontSize: "text-[18px]",
            }}
            label="BlockCode"
            onChange={(e) => {
              setFormErrors((old) => ({ ...old, blockcode: null }));
              setFormFields((old) => ({ ...old, blockcode: e?.target?.value }));
            }}
            value={formFields.blockcode}
            error={formErrors.blockcode}
            isRequired={true}
            placeholder={"Enter BlockCode"}
          />
          {formErrors.api && (
            <p className="my-1 text-sm text-primary pl-4">{formErrors.api}</p>
          )}
          {/* <TextInputField
            type={"text"}
            style={{
              width: "w-full",
              labelFontSize: "text-[20px]",
              inputFontSize: "text-[18px]",
            }}
            label="HalkaType"
            onChange={(e) => {
              setFormErrors((old) => ({ ...old, halkatype: null }));
              setFormFields((old) => ({ ...old, halkatype: e?.target?.value }));
            }}
            value={formFields.halkatype}
            error={formErrors.halkatype}
            isRequired={true}
            placeholder={"Enter Halka"}
          />
          {formErrors.api && (
            <p className="my-1 text-sm text-primary pl-4">{formErrors.api}</p>
          )} */}
          <TextInputField
            type={"text"}
            style={{
              width: "w-full",
              labelFontSize: "text-[20px]",
              inputFontSize: "text-[18px]",
            }}
            label="PollingStation"
            onChange={(e) => {
              setFormErrors((old) => ({ ...old, pollingstation: null }));
              setFormFields((old) => ({
                ...old,
                pollingstation: e?.target?.value,
              }));
            }}
            value={formFields.pollingstation}
            error={formErrors.pollingstation}
            isRequired={true}
            placeholder={"Enter PollingStation"}
          />
          {formErrors.api && (
            <p className="my-1 text-sm text-primary pl-4">{formErrors.api}</p>
          )}
          <TextInputField
            type={"text"}
            style={{
              width: "w-full",
              labelFontSize: "text-[20px]",
              inputFontSize: "text-[18px]",
            }}
            label="PollingBoth"
            onChange={(e) => {
              setFormErrors((old) => ({ ...old, pollingboth: null }));
              setFormFields((old) => ({
                ...old,
                pollingboth: e?.target?.value,
              }));
            }}
            value={formFields.pollingboth}
            error={formErrors.pollingboth}
            isRequired={true}
            placeholder={"Enter pollingboth"}
          />
          {formErrors.api && (
            <p className="my-1 text-sm text-primary pl-4">{formErrors.api}</p>
          )}
          <TextInputField
            type={"text"}
            style={{
              width: "w-full",
              labelFontSize: "text-[20px]",
              inputFontSize: "text-[18px]",
            }}
            label="PreviousExperience"
            onChange={(e) => {
              setFormErrors((old) => ({ ...old, previousexperience: null }));
              setFormFields((old) => ({
                ...old,
                previousexperience: e?.target?.value,
              }));
            }}
            value={formFields.previousexperience}
            error={formErrors.previousexperience}
            isRequired={true}
            placeholder={"Enter Previous Experience"}
          />
          {formErrors.api && (
            <p className="my-1 text-sm text-primary pl-4">{formErrors.api}</p>
          )}
          <TextInputField
            type={"text"}
            style={{
              width: "w-full",
              labelFontSize: "text-[20px]",
              inputFontSize: "text-[18px]",
            }}
            label="Comment"
            onChange={(e) => {
              setFormErrors((old) => ({ ...old, comment: null }));
              setFormFields((old) => ({ ...old, comment: e?.target?.value }));
            }}
            value={formFields.comment}
            error={formErrors.comment}
            isRequired={true}
            placeholder={"Enter Comment"}
          />
          {formErrors.api && (
            <p className="my-1 text-sm text-primary pl-4">{formErrors.api}</p>
          )}
          {/* <div className="text-[13px] font-bold font-sans2 pb-1">Type</div> */}
          {/* <select
            name=""
            id=""
            className=" px-2  border-[1px] border-black rounded-[6px] text-[14px] w-[100%] h-[40px]"
            onChange={(e) => {
              console.log("Type :", e.target.value);
              setFormFields((old) => ({ ...old, type: e.target.value }));
            }}
          >
            {selecttype.map((item, index) => (
              <option key={index} value={item.text}>
                {item.text}
              </option>
            ))}
          </select>
          {formFields.type === "Worker" ? (
            <div>
              <div className="text-[13px] font-bold font-sans2 pb-1 mt-2">
                Polling Station
              </div>
              <select
                name=""
                id=""
                className=" px-2  border-[1px] mb-2 border-black rounded-[6px] text-[14px] w-[100%] h-[40px]"
                onChange={(e) => {
                  console.log("Pollingstation :", e.target.value);
                  setFormFields((old) => ({
                    ...old,
                    pollingstation: e.target.value,
                  }));
                }}
              >
                {showableData?.map((item, index) => (
                  <option key={index} value={item.text}>
                    {item.polling_station_area_urdu}
                  </option>
                ))}
              </select>
            </div>
          ) : (
            <div></div>
          )} */}
          <button className="w-[40%] text-[18px] mt-2 h-[45px] float-right bg-slate-400 hover:bg-slate-500 text-white rounded-md">
            Update
          </button>
          {data.status == "200" ? (
            navigate("/pollingagent/list")
          ) : (
            <div>Your changes was not done</div>
          )}
        </div>
      </form>
    </ListingTemplate>
    // </AuthTemplate>
  );
}

export default UpdatePollingAgent;
