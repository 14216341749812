import axios from "axios";
import { useContext } from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import AppImages from "../../../../assets/images";
import AuthContext from "../../../../contexts/AuthContext";
import { Api } from "../../../../utils/api";
import { RequestStatus } from "../../../../utils/enums";
import Button from "../../../basic/Button";
import SimpleModal from "../../../basic/SimpleModal";
import ListingTemplate from "../ListingTemplate";
// import ListingTemplate from "../ListingTemplate";
import { customerActions, customerThunk } from "../reducers/customer_slice";

function Teamtype({ children }) {
  const navigate = useNavigate();
  const customerReducer = useSelector((state) => state.customers);
  const dispatch = useDispatch();

  const ths = [
    { title: "Name" },
    { title: "Email" },
    { title: "Phone Number" },
    { title: "Created At" },
    { title: "Last LoggedIn" },
    { title: "Last Location" },
    { title: "Status" },
    { title: "Action" },
  ];

  //   const [data, setData] = useState(customerReducer.data);
  //   const [showableData, setShowableData] = useState(data ?? []);
  const [deleteableItem, setDeletableItem] = useState(null);
  const [searchQuery, setSearchQuery] = useState(null);
  const [statusFilter, setStatusFilter] = useState("all");

  //   useEffect(() => {
  //     if (customerReducer.fetchDataRequestStatus === RequestStatus.IDLE) {
  //       dispatch(customerThunk.getCustomers());
  //     }
  //   }, [customerReducer.fetchDataRequestStatus]);

  //   useEffect(() => {
  //     console.log(customerReducer.data);
  //     setData(customerReducer.data ?? []);
  //     setShowableData(customerReducer.data ?? []);
  //   }, [customerReducer.data]);
  const authCtx = useContext(AuthContext);
  console.log("Authcontext", authCtx);
  const [data, setData] = useState("");
  const { id } = useParams();
  let page = 1;
  let na = authCtx.user.sector;
  useEffect(() => {
    let na = authCtx.user.sector;
    let Id = 1;
    // let page = 1;

    const fetchData = async () => {
      console.log("ID", Id, na);
      const result = await axios.get(
        `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/my-team?sector=${na}&id=${Id}`
      );
      var url = `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/my-team?sector=${na}&id=${Id}`;
      console.log("URLLL", url);
      console.log("RESData", result);
      setData(result.data);
    };

    fetchData();
  }, []);
  console.log("Darta", data);
  const [state, setState] = useState("");

  const tdClasses = "text-[12px] border-b-[1px] border-[#2A2D4314] py-4";
  const handleClickOnStatusBtn = async (item, newStatus) => {
    setState(newStatus);
    const result = await axios.get(
      `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/team-member-status?id=${item.id}&status=${newStatus}`
    );
    console.log("Result", result);
  };
  //   const handleStatusFilter = (e) => {
  //     setStatusFilter(e.target.value);
  //     switch (e.target.value) {
  //       case "active":
  //         setShowableData(data.filter((d) => d.status === "active"));
  //         break;
  //       case "inactive":
  //         setShowableData(
  //           data.filter((d) => d.status === "inactive" || !d.status)
  //         );
  //         break;
  //       case "all":
  //       default:
  //         setShowableData(data);
  //     }
  //   };

  //   const filterDataByQuery = (val) => {
  //     console.log(showableData);
  //     const newData = showableData.filter(
  //       (d) =>
  //         d.id.toString().startsWith(val) ||
  //         d.name?.toLowerCase().startsWith(val.toLowerCase()) ||
  //         d.email?.toLowerCase().startsWith(val.toLowerCase()) ||
  //         d.phone?.toLowerCase().startsWith(val.toLowerCase()) ||
  //         d.Location?.city?.toLowerCase().startsWith(val.toLowerCase()) ||
  //         d.Location?.country?.toLowerCase().startsWith(val.toLowerCase())
  //     );
  //     return newData;
  //   };

  //   const handleOnSearch = (e) => {
  //     const val = e.target.value?.trim();
  //     if (val) {
  //       setSearchQuery(val);
  //       setShowableData(filterDataByQuery(val));
  //     } else if (statusFilter !== "all") {
  //       setShowableData(data.filter((d) => d.status === statusFilter));
  //     } else {
  //       setShowableData(data);
  //     }
  //   };

  return (
    <ListingTemplate
      title={id}
      //   onSearch={handleOnSearch}
      selectBoxLabel="Select staff:"
      selectItems={[
        { text: "All", value: "all" },
        { text: "Active", value: "active" },
        { text: "Inactive", value: "inactive" },
      ]}
      //   onSelect={handleStatusFilter}
    >
      <div className="flex flex-row">
        {/* <div className="text-[24px] font-bold mt-3">Sector</div> */}
        <div className=" text-[15px] font-bold mt-5">{na}</div>
        <div className="ml-1 text-[15px] mt-5">/</div>
        {/* <div
          className="ml-1 mt-5 text-[15px] cursor-pointer"
          onClick={(e) => {
            navigate("/create/newteam");
          }}
        >
          Add Team member
        </div>
        <div className="ml-1 text-[15px] mt-5">/</div> */}

        <div className="ml-1 text-[15px]  text-gray-400 mt-5">
         Election Team Management
        </div>
        <button
          className="w-[150px] my-4 h-[40px] ml-[650px] bg-[#14A800] text-[18px] rounded-md text-white hover:bg-slate-400 mt-4"
          onClick={() => navigate("/form/electionteam")}
        >
          Add new
        </button>

        {/* <Button
          text={"Add new"}
          style={{
            width: "w-[150px]",
            custom: "box-border text-[18px]",
            margin: " ml-[65%]",
          }}
          onClick={() => {
            navigate("/create/newteam");
          }}
        /> */}
      </div>
      <div className="text-[24px] font-bold">Election Team Members</div>
      <div className="w-[1000px] mt-1">
        The functionality that can be added to a team could include things like:
        Adding members Setting roles and permissions for members Sharing
        resources and content within the team, etc. Setting up workflows and
        processes specific to the team
      </div>

      <table className="w-full my-6">
        <thead>
          <tr>
            {ths.map((item, index) => (
              <th
                key={index}
                className="text-[12px] font-semibold border-b-[1px] border-[#2A2D4314] bg-gray-100 px-3 py-4 text-left"
              >
                {item.title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.myteam?.map((item, index) => (
            <tr key={index} className="px-3">
              <td className="text-[12px] border-b-[1px] border-[#2A2D4314] py-4 w-[170px]">
                {item.name}
              </td>
              <td className="text-[12px] border-b-[1px] border-[#2A2D4314] py-4 w-[170px]">
                {item.email}
              </td>
              <td className="text-[12px] border-b-[1px] border-[#2A2D4314] py-4 w-[170px]">
                {item.phone}
              </td>
              <td className="text-[12px] border-b-[1px] border-[#2A2D4314] py-4 w-[200px]">
                {item.created_at}
              </td>
              <td className="text-[12px] border-b-[1px] border-[#2A2D4314] py-4 w-[200px]">
                {item.last_logged}
              </td>
              <td className="text-[12px] border-b-[1px] border-[#2A2D4314] py-4 w-[200px]">
                {item.last_logged_location}
              </td>
              <td className="text-[12px] border-b-[1px] border-[#2A2D4314] py-4 w-[200px]">
                {item.status === "active" ? (
                  <span
                    className=" text-white bg-primary text-center w-[70px] inline-block py-[1px] rounded-[4px] cursor-pointer"
                    title="Click to inactivate it"
                    onClick={(e) => handleClickOnStatusBtn(item, "inactive")}
                  >
                    Inactive
                  </span>
                ) : (
                  <span
                    className="bg-[#69BA6C] text-white text-center w-[70px] inline-block py-[1px] rounded-[4px] cursor-pointer"
                    title="Click to activate it"
                    onClick={(e) => handleClickOnStatusBtn(item, "active")}
                  >
                    Active
                  </span>
                )}
              </td>
              <td>
                <Link to="/team/member" state={{ member: item }}>
                  <div className="bg-gray-500 rounded-lg p-2 mr-2 cursor-pointer hover:bg-black">
                    <img
                      src={AppImages.eyeWhite}
                      className="w-[14px] mx-auto my-0"
                    />
                  </div>
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </ListingTemplate>
  );
}

export default Teamtype;
