import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { useState } from 'react';
import { Api } from '../../../../utils/api';
import { RequestStatus } from '../../../../utils/enums';

const initialState = {
  data: [],
  activeScheduleData: null,
  fetchDataRequestStatus: RequestStatus.IDLE,
};

export const scheduleThunk = {
   getSchedules: createAsyncThunk('/schedules/getAllSchedules', async (data, thunkAPI) => {
        const { fulfillWithValue, rejectWithValue } = thunkAPI
        try {
            const res = await Api.get({path: '/admin/schedules'})
            if(res.success) {
                return fulfillWithValue({
                    data: res.data.data,
                    activeScheduleData: data?.activeScheduleData
                })
            } else {
                throw res.message
            }
        } catch(e) {
            return rejectWithValue({
                error: true,
                message: typeof e === 'string' ? e : 'Something went wrong',
                exception: e
            })
        }
    }),
  addSchedule: createAsyncThunk('/schedules/add', async (data, thunkAPI) => {
    const { fulfillWithValue, rejectWithValue } = thunkAPI;
    try {
      const res = await Api.post({ path: '/admin/schedules/create', data });
      if (res.success) {
        return fulfillWithValue({
          data: res.data.schedule,
          activeScheduleData: data?.activeScheduleData,
        });
      } else {
        throw res.message;
      }
    } catch (e) {
      return rejectWithValue({
        error: true,
        message: typeof e === 'string' ? e : 'Something went wrong',
        exception: e,
      });
    }
  }),
};

const scheduleSlice = createSlice({
  name: 'schedules',
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    updateSchedule: (state, action) => {
              const { schedule, scheduleId } = action.payload 
            console.log(1)
            if(schedule) {
                console.log(2)
                const found = state.data.find(schedule => schedule.id === scheduleId)
                const index = state.data.indexOf(found)
                console.log('index', index)
                state.data[index] = schedule 
                state.activeScheduleData = schedule
            }
            console.log(3)
        },
  },
  extraReducers: builder =>
    builder
      .addCase(scheduleThunk.getSchedules.fulfilled, (state, action) => {
        state.data = action.payload.data ?? [];
        state.fetchDataRequestStatus = RequestStatus.COMPLETED;
      })
      .addCase(scheduleThunk.getSchedules.rejected, (state, action) => {
        state.fetchDataRequestStatus = RequestStatus.ERROR;
      })
      .addCase(scheduleThunk.getSchedules.pending, (state, action) => {
        state.fetchDataRequestStatus = RequestStatus.LOADING;
      })
      .addCase(scheduleThunk.addSchedule.fulfilled, (state, action) => {
        state.data = [...state.data, action.payload.data];
        state.fetchDataRequestStatus = RequestStatus.COMPLETED;
      })
      .addCase(scheduleThunk.addSchedule.rejected, (state, action) => {
        state.fetchDataRequestStatus = RequestStatus.ERROR;
      })
      .addCase(scheduleThunk.addSchedule.pending, (state, action) => {
        state.fetchDataRequestStatus = RequestStatus.LOADING;
      }),
});

export const scheduleActions = scheduleSlice.actions;
export default scheduleSlice.reducer;
