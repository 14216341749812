import axios from "axios";
import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import AppImages from "../../../../assets/images";
import AuthContext from "../../../../contexts/AuthContext";
import ReactLoading from "react-loading";
import "./PrintStyleslandscape.css";
const Parchi120 = () => {
  const [data, setData] = useState("");
  const [d, sd] = useState("");
  const [f, sf] = useState("");
  const [na, sna] = useState("");
  const [pp, spp] = useState("");
  const [nac, snac] = useState("");
  const [ppc, sppc] = useState("");
  const { id } = useParams();
  let Id = id;
  const authCtx = useContext(AuthContext);
  console.log("Authcontext", authCtx);
  useEffect(() => {
    let na = authCtx.user.sector;

    let page = 1;
    console.log("ID", Id, na);
    const fetchData = async () => {
      const result = await axios(
        `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/voter-list-blockCode?sector=${na}&page_no=${page}&block_code=${Id}`
      );
      console.log("resulttt", result.data);
      let filteredData = result.data.voters.sort((a, b) => {
        // If gharana_no is an empty string, move it to the bottom
        if (a.gharana_no === "" || a.gharana_no === null) return 1;
        if (b.gharana_no === "" || b.gharana_no === null) return -1;

        // Otherwise, sort based on gharana_no
        return a.gharana_no - b.gharana_no;
      });
      setData(filteredData);
    };

    fetchData();
    const fetchData1 = async () => {
      const resultt = await axios(
        `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/get-polling-scheme?bc=${Id}&gender=male`
      );
      console.log("resulttt123", resultt.data.data.scheme);
      sd(resultt.data.data.scheme);
    };

    fetchData1();

    const fetchData2 = async () => {
      const resultt = await axios(
        `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/get-polling-scheme?bc=${Id}&gender=female`
      );
      console.log("result000", resultt.data.data.scheme);
      sf(resultt.data.data.scheme);
    };

    fetchData2();

    const fetchData3 = async () => {
      const resultt = await axios(
        `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/bc-setting?block_code=${Id}`
      );
      console.log("BlockCodesectorData", resultt.data.data);
      sna(resultt.data.data);
      // sf(resultt.data.data.scheme);
    };

    fetchData3();
  }, []);
  console.log("Dataaa", data);

  const handlePrint = () => {
    window.print();
  };
    
  const wordsToReplace = [
    "مکان",
    "سال",
    "نمبر",
    "مین",
    "محلہ",
    "روڈ",
    "پارک",
    "،",
    "کالونی ",
    "لاہور ",
    "کی",
    "ضلع",
    "/",
    "ٹاور",
    "خالصہ",
    "خلاصہ",
  ];
  function ImageSlice({ item }) {
    var yIncr = 0;
    if (item?.rowY) {
      if (authCtx.user.sector === "PP 172") {
        if (
          // item.block_code === 185570302 ||
          // item.block_code === 185560303 ||
          // item.block_code === 185570402
          item.meta === "100"
        ) {
          yIncr = -10;
        } else {
          if (item?.name === "" || item?.address === "") {
            yIncr = -40;
          } else {
            yIncr = 65;
          }
        }
      } else if (["PP 208", "PP 14"].includes(authCtx.user.sector)) {
        if (item?.name === "" || item?.address === "") {
          yIncr = -40;
        } else {
          yIncr = 65;
        }
      }
      // else if (["PP 207"].includes(authCtx.user.sector)) {
      //   if (item?.name === "" || item?.address === "") {
      //     yIncr = -30;
      //   } else {
      //     yIncr = 70;
      //   }
      // }
      else if (["NA 120"].includes(authCtx.user.sector)) {
        yIncr = 50;
      } else if (["NA 127"].includes(authCtx.user.sector)) {
        yIncr = -10;
      } else if (["PP 59"].includes(authCtx.user.sector)) {
        yIncr = -20;
      } else {
        yIncr = -10;
      }
    }

    return (
      <>
        {item.block_code === 259160903 ? (
          <img
            src={`https://res.cloudinary.com/election-experts/image/fetch/c_crop,h_80,x_0,w_4000,y_${parseInt(
              item?.rowY
            )},a_2/${encodeURIComponent(item?.url)}`}
            alt=""
            srcSet=""
            className=" w-full h-[40px]"
          />
        ) : (
          <img
            src={`https://res.cloudinary.com/election-experts/image/fetch/c_crop,h_80,x_0,w_4000,y_${
              parseInt(item?.rowY) + yIncr
            }/${encodeURIComponent(item?.url)}`}
            alt=""
            srcSet=""
            className=" w-full mr-[-60px] h-[30px] "
          />
        )}
      </>
    );
  }
  function generateImageUrl(item) {
    const jsonObject = JSON.parse(item?.row);
    let xvalue = parseInt(jsonObject.x1);
    const newXValue = xvalue + 40;
    // console.log("Value of X =",jsonObject.x1,newXValue);
    const newYValue = parseInt(item?.rowY)-10;

    const imageUrl = `https://res.cloudinary.com/election-experts/image/fetch/c_crop,h_80,x_${newXValue},w_400,y_${newYValue}/${encodeURIComponent(
      item?.url
    )}`;

    return imageUrl;
  }
  function generateImageUrl1(item) {
    const jsonObject = JSON.parse(item?.row);
    let xvalue = parseInt(jsonObject.x1);
    const newXValue = xvalue + 440;
    // console.log("Value of X =", jsonObject.x1, newXValue);
    const newYValue = parseInt(item?.rowY) - 13;

    const imageUrl = `https://res.cloudinary.com/election-experts/image/fetch/c_crop,h_80,x_${newXValue},w_340,y_${newYValue}/${encodeURIComponent(
      item?.url
    )}`;

    return imageUrl;
  }

  return (
    <div>
      <div className="flex flex-row ">
        <Link to={`/download/list`}>
          <div className="rounded-md h-[25px] text-center text-yellow-50 my-1 w-[90px] bg-slate-400 hover:bg-slate-600 text-[18px] ml-3 font-bold cursor-pointer">
            Back
          </div>
        </Link>
        <div
          className="rounded-md h-[25px] text-center text-yellow-50 my-1 w-[90px] bg-slate-400 hover:bg-slate-600 text-[18px] ml-3 font-bold cursor-pointer"
          onClick={handlePrint}
        >
          Print
        </div>
      </div>
      <div className=" flex flex-col w-full border-[1px] mb-[190px] border-dotted border-black "></div>
      {data ? (
        data.map((item, index) => (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              // margin: "1px",
            }}
            className="w-[90%] font-noori  my-[22px] ml-[40px] border-[1px] border-black"
            key={index}
          >
            {authCtx.user.sector == "NA 127" ||
            authCtx.user.sector == "PP 161" ? (
              <div
                className="flex flex-row w-full   px-[25px] text-[18px] py-[5px] border-[0.5px] border-gray-500"
                dir="rtl"
              >
                <div
                  style={{
                    fontWeight: "bold",
                    marginRight: "5px",
                    // fontSize: 15,
                  }}
                >
                  نام :
                </div>
                <div className=" mr-2 ">
                  {item.name?.length > 44
                    ? item.name
                        ?.replace(/[^\W]/gi, "")
                        .replace(/[-*]/g, " ")
                        .substr(0, 44) + ".."
                    : item.name?.replace(/[^\W]/gi, "").replace(/[-*]/g, " ")}
                </div>
              </div>
            ) : (
              // <ImageSlice item={item} />
              <div
                style={{
                  direction: "rtl",
                  // justifyContent: "space-around",
                  // display: "flex",
                }}
                className="border-gray-500 border-[0.5px] w-full overflow-hidden"
              >
                {/* <>
                    {item?.type === "manual" ? (
                      <div
                        className="px-1 flex flex-row text-[18px]  py-2"
                        dir="rtl"
                      >
                        <div className="font-bold text-right font-noori">
                          نام
                        </div>
                        <div className="font-noori mr-4">
                          
                          {
                            item?.name?.length > 44
                              ? item?.name
                                  ?.replace(/[^\W]/gi, "")
                                  .replace(/[-*]/g, " ")
                                  
                                  .substr(0, 44) + ""
                              : item?.name
                                  ?.replace(/[^\W]/gi, "")
                                  .replace(/[-*]/g, " ")
                            
                          }
                        </div>
                      </div>
                    ) : (
                      <ImageSlice item={item} />
                    )}
                  </> */}
                {/* {authCtx.user.sector === "PP 207" ? (
                      <img
                        src={`https://res.cloudinary.com/election-experts/image/fetch/c_crop,h_80,x_0,w_4000,y_${
                          parseInt(item?.rowY) + 70
                        }/${encodeURIComponent(item?.url)}`}
                        alt=""
                        srcSet=""
                        className=" w-[800px]"
                      />
                    ) : (
                      <img
                        src={`https://res.cloudinary.com/election-experts/image/fetch/c_crop,h_100,x_0,w_4000,y_${
                          parseInt(item?.rowY) + 50
                        }/${encodeURIComponent(item?.url)}`}
                        alt=""
                        srcSet=""
                        className="w-[800px]"
                      />
                    )} */}
                {/* <div className="flex flex-row border-[0.5px]  w-[25%] border-gray-500 py-[4px] ">
                      <div
                        style={{
                          fontWeight: "bold",
                          paddingRight: "15px",
                          fontSize: 15,
                        }}
                      >
                        سلسلہ نمبر :
                      </div>

                      <div className=" font-[11px] ">
                        {item.silsila_no === "" ? 57 : item.silsila_no}
                      </div>
                    </div>
                    <div className="flex flex-row border-[0.5px]  py-[4px] w-[25%]  border-gray-500  ">
                      <div
                        style={{
                          fontWeight: "bold",
                          paddingRight: "15px",
                          fontSize: 15,
                        }}
                      >
                        گھرانہ نمبر :
                      </div>
                      <div className=" font-[11px] mr-2">
                        {item.gharana_no === "" ? 1 : item.gharana_no}
                      </div>
                    </div>
                    {item.gender === "male" ? (
                      <div className="flex flex-row border-[0.5px]  w-[25%]  border-gray-500 py-[4px] ">
                        <div
                          style={{
                            fontWeight: "bold",
                            paddingRight: "15px",
                            fontSize: 15,
                          }}
                        >
                          بوتھ نمبر :
                        </div>
                        <div className=" font-[11px] mr-2">{d?.ward}</div>
                      </div>
                    ) : item.gender === "female" ? (
                      <div className="flex flex-row border-[0.5px]  w-[25%]  border-gray-500 py-[4px] ">
                        <div
                          style={{
                            fontWeight: "bold",
                            paddingRight: "15px",
                            fontSize: 15,
                          }}
                        >
                          بوتھ نمبر :
                        </div>
                        <div className=" font-[11px] mr-2">{f?.ward}</div>
                      </div>
                    ) : (
                      ""
                    )}
                    {item.gender === "male" ? (
                      <div className="flex flex-row border-[0.5px] w-[25%]   border-gray-500 py-[4px] ">
                        <div
                          style={{
                            fontWeight: "bold",
                            paddingRight: "15px",
                            fontSize: 15,
                          }}
                        >
                          وارڈ نمبر :
                        </div>
                        <div className="font-[11px] mr-2">{d?.male_both}</div>
                      </div>
                    ) : item.gender === "female" ? (
                      <div className="flex flex-row border-[0.5px] w-[25%]   border-gray-500 py-[4px] ">
                        <div
                          style={{
                            fontWeight: "bold",
                            paddingRight: "15px",
                            fontSize: 15,
                          }}
                        >
                          وارڈ نمبر :
                        </div>
                        <div className="font-[11px] mr-2">{f?.female_both}</div>
                      </div>
                    ) : (
                      ""
                    )} */}
              </div>
            )}
            <div className="flex flex-row w-full">
              {/* {authCtx.user.party === "PPP" ? (
                  <div className="w-[20%] flex flex-row justify-center">
                    <img
                      className="h-[100px]"
                      src={
                        "https://www.pngkit.com/png/full/813-8134385_pakistan-people-party-logo.png"
                      }
                      alt="Rounded avatar"
                    />
                  </div>
                ) : ( */}

              <div className="w-[20%] flex flex-row justify-center ">
                {authCtx.user.sector === "Halqa NA 120" ||
                authCtx.user.sector === "PP 151" ? (
                  <div>
                    {na.map((item, index) => (
                      <>
                        <div className="text-center text-[18px] font-bold">
                          {/* <div></div>
                      <div></div> */}
                          PP{item?.pp} / NA{item?.na}
                        </div>
                        <div className="text-center text-[18px] font-bold">
                          <span className="ml-1">{item?.na_candidate}</span> /
                          <span className="mr-1">{item?.pp_candidate}</span> 
                          {/* سردار ایاز صادق/ سہیل شوکت بٹ */}
                        </div>
                      </>
                    ))}
                    <img
                      src={AppImages.sher}
                      alt="image"
                      className=" py-1 rounded-2xl object-contain h-[90px] w-[250px] "
                    />
                  </div>
                ) : (
                  <img
                    src={authCtx.user.party_image}
                    alt="image"
                    className="h-[100px]"
                  />
                )}
              </div>
              {/* )} */}
              <div className="w-[80%] flex flex-col">
                <div className="w-full flex flex-row">
                  <div
                    className="flex flex-row w-[50%]   px-[25px] py-[2px] border-[0.5px] border-gray-500"
                    dir="rtl"
                  >
                    <div
                      style={{
                        fontWeight: "bold",
                        marginRight: "5px",
                        // fontSize: 15,
                      }}
                      className="text-[15px]"
                    >
                      والد کا نام :
                    </div>
                    <div className=" mr-2 text-[15px] font-bold">
                      <img
                        src={generateImageUrl(item)}
                        alt=""
                        srcSet=""
                        className=" h-[35px]"
                        // {`https://res.cloudinary.com/election-experts/image/fetch/c_crop,h_80,x_${
                        //   JSON.parse(item?.row).x1 + 37
                        // } ,w_320,y_${
                        //   parseInt(item?.rowY) - 20
                        // }/${encodeURIComponent(item?.url)}`}
                      />
                    </div>
                  </div>
                  <div
                    className="flex flex-row w-[50%]   px-[25px] py-[2px] border-[0.5px] border-gray-500"
                    dir="rtl"
                  >
                    <div
                      style={{
                        fontWeight: "bold",
                        marginRight: "5px",
                      }}
                      className="text-[15px]"
                    >
                      نام :
                    </div>
                    <div className=" mr-2 text-[15px] font-bold">
                      <img
                        src={generateImageUrl1(item)}
                        alt=""
                        srcSet=""
                        className="h-[35px]"
                      />
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    direction: "rtl",
                    justifyContent: "space-around",
                    display: "flex",
                  }}
                >
                  {/* <div className="flex flex-row border-[0.5px] w-[50%] border-gray-500 py-[4px]">
                      <div
                        style={{
                          fontWeight: "bold",
                          paddingRight: "15px",
                          fontSize: 15,
                        }}
                      >
                        شماریاتی کوڈ نمبر :
                      </div>
                      <div className=" font-[11px] mr-2">{item.block_code}</div>
                    </div> */}
                  <div className="flex flex-row border-[0.5px] w-[50%] border-gray-500 py-[2px]">
                    <div
                      style={{
                        fontWeight: "bold",
                        paddingRight: "15px",
                        fontSize: 15,
                      }}
                    >
                      شناختی کارڈ نمبر :
                    </div>
                    <div className="font-[11px] mr-2">{item.cnic}</div>
                  </div>
                  <div className="flex flex-row border-[0.5px] w-[50%] border-gray-500">
                    <div className="flex flex-row w-[50%]  py-[2px]">
                      <div
                        style={{
                          fontWeight: "bold",
                          paddingRight: "15px",
                          fontSize: 15,
                        }}
                      >
                        سلسلہ نمبر :
                      </div>
                      <div className=" font-[11px] mr-2">{item.silsila_no}</div>
                    </div>
                    <div className="flex flex-row border-gray-500 border-r-[1px]  w-[50%]  py-[2px]">
                      <div
                        style={{
                          fontWeight: "bold",
                          paddingRight: "15px",
                          fontSize: 15,
                        }}
                      >
                        گھرانہ نمبر :
                      </div>
                      <div className=" font-[11px] mr-2">{item.gharana_no}</div>
                    </div>
                  </div>
                </div>
                <div
                  className="flex flex-row w-full  px-[25px] py-[2px] border-[0.5px] border-gray-500"
                  dir="rtl"
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      paddingRight: "7px",
                      fontSize: 15,
                    }}
                  >
                    پتہ :
                  </div>
                  <div className="font-[11px] mr-2">
                    <img
                      src={`https://res.cloudinary.com/election-experts/image/fetch/c_crop,h_90,x_110,w_805,y_${
                        parseInt(item?.rowY) - 30
                      }/${encodeURIComponent(item?.url)}`}
                      alt=""
                      srcSet=""
                      className=" h-[35px]"
                    />
                  </div>
                </div>
                <div className="flex flex-row w-full">
                  <div
                    className="flex flex-row border-[0.5px] w-[50%] font-[15px] border-gray-500 py-[4px]"
                    dir="rtl"
                  >
                    <div
                      style={{
                        fontWeight: "bold",
                        paddingRight: "15px",
                        fontSize: 15,
                      }}
                    >
                      شماریاتی کوڈ نمبر :
                    </div>
                    <div className=" mr-2 font-bold">{item.block_code}</div>
                  </div>
                  <div className="w-[70%]">
                    {item.gender === "male" ? (
                      <div
                        className="flex flex-row w-full font-[15px] px-[25px] py-[4px] border-[0.5px] border-gray-500"
                        dir="rtl"
                      >
                        <div
                          style={{
                            fontWeight: "bold",
                            paddingRight: "7px",
                          }}
                        >
                          پولنگ سٹیشن :
                        </div>
                        <div className=" mr-2 font-bold">
                          {d?.polling_station_area_urdu}
                        </div>
                      </div>
                    ) : item.gender === "female" ? (
                      <div
                        className="flex flex-row w-full font-[15px]  px-[25px] py-[4px] border-[0.5px] border-gray-500"
                        dir="rtl"
                      >
                        <div
                          style={{
                            fontWeight: "bold",
                            paddingRight: "7px",
                          }}
                        >
                          پولنگ سٹیشن :
                        </div>
                        <div className="font-[11px] mr-2 font-bold">
                          {f?.polling_station_area_urdu}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* {authCtx.user.package !== "1" ? (
                <div
                  className="flex flex-row  w-full  px-[25px] py-[4px] border-[0.5px] border-gray-500"
                  dir="rtl"
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      paddingRight: "7px",
                      fontSize: 15,
                    }}
                  >
                    فون نمبر :
                  </div>
                  <div className=" font-[11px] mr-2 flex flex-row">
                    {item.voter_phone
                      ? JSON.parse(item.voter_phone).map((phone) => (
                          <p> , 0{phone} </p>
                        ))
                      : "--"}
                  </div>
                </div>
              ) : (
                <div></div>
              )} */}
          </div>
        ))
      ) : (
        <ReactLoading
          color={"gray"}
          height={"40px"}
          width={"70px"}
          className="my-2 justify-center"
        />
      )}
    </div>
  );
};

export default Parchi120;
