import { Api } from "../../../../utils/api"

export const AuthApi = {
    login: async (email, password) => {
        console.log("Email",email)
        return Api.post({
          path: `/login?email=${email}&password=${password}`,
        });
    },
    sendPinCode: async (email, pinCode) => {
        return Api.post({
            path: '/auth/sendPinCode',
            data: {
                email, pinCode 
            }
        })
    },
    resetPassword: async (email, password) => {
        try {
            let res = await Api.post({
                path: '/auth/resetPassword',
                data: {
                    email, password
                }
            })
            console.log(res)
            if(res.data?.error) {
                let m = res.data?.message[0]['msg']
                throw (m)
            }
            return res 
        } catch(e) {
            return {
                error: true,
                message: typeof e === 'string' ? e : 'Something went wrong',
                exception: e
            }
        }
    },
}