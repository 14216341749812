import axios from "axios";
import { useContext } from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import AppImages from "../../../../assets/images";
import AuthContext from "../../../../contexts/AuthContext";
import { Api } from "../../../../utils/api";
import { RequestStatus } from "../../../../utils/enums";
import SimpleModal from "../../../basic/SimpleModal";
import ListingTemplate from "../ListingTemplate";
import { employeeActions, employeeThunk } from "../reducers/employee_slice";

function DataSearch({ children }) {
  const navigate = useNavigate();
  const ths = [{ title: "Block Code" }, { title: "Count" }, { title: "View" }];
  const tdClasses = "text-[12px] border-b-[1px] border-[#2A2D4314] py-4";
  const [isSearching, setIsSearching ] = useState(false)
  const dispatch = useDispatch();
  const authCtx = useContext(AuthContext);
  const employeeReducer = useSelector((state) => state.employees);
  // console.log("Employee", employeeReducer.data)
  const [data, setData] = useState([]);
  const [showableData, setShowableData] = useState(data ?? []);

  const [data1, setData1]=useState('')
  const fields = {
    blockCode: null,
    minage: null,
    maxage: null,
   
  };
  const errors = {
    blockCode: null,
    minage: null,
    maxage: null,
  };
  const [formFields, setFormFields] = useState(fields);
  const [data2,setData2]=useState('')
let na = authCtx.user.sector;
let page = 1;
  
  useEffect(() => {
    const fetchData = async () => {
      const result = await axios(
        `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/sectors-block-code?sector=${na}&page_no=${page}`
      );
      console.log("Resulttt", result);

      setData(result.data.block_code);
    };

    fetchData();
  }, []);
  console.log("Showable dtaa", data);
const onSubmit = async(e) => {
    
  let na = authCtx.user.sector;
    // setData2(formFields)
  e.preventDefault();
  console.log("EEE",formFields);
  setIsSearching(true)
   const result = await axios(
     `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/age-filter?sector=${na}&block_code=${formFields.blockCode}&minAge=${formFields.minage}&maxAge=${formFields.maxage}`
   ).finally(() => {
    setIsSearching(false)
  })
   console.log("Resultttttttttt",result)
    setData2(result.data);
};
console.log("Dataaaaaaa",data2)
  return (
    <ListingTemplate
    //   title="Employees"
    >
      <form
        className={
          authCtx.user.party === "PMLN"
            ? "bg-white invitationForm"
            : authCtx.user.party === "PTI"
            ? "bg-white invitationForm"
            : authCtx.user.party === "PPP"
            ? "bg-white invitationForm"
            : "bg-white invitationForm"
        }
        onSubmit={onSubmit}
      >
        <div className="flex flex-col justify-center items-center mb-6 text-[25px] font-bold">
          <div>Search by Age</div>
        </div>
        <select
          name=""
          id=""
          className="py-2 px-2 border-[1px] border-black rounded-[6px] text-[14px] w-full h-[50px]"
          //   value={formFields.lastName}
          onChange={(e) => {
            setFormFields((old) => ({ ...old, blockCode: e.target.value }));
          }}
        >
          <option>Select Block code</option>
          {data?.map((item, index) => (
            <option key={index} value={item.block_code}>
              {item.block_code}
            </option>
          ))}
        </select>
        <div className="flex flex-row justify-center space-x-[100px]">
          <div className="flex flex-row my-2">
            <div className="text-[20px] font-bold mt-2">Minimum</div>
            <div>
              <input
                type="text"
                placeholder="Min Age"
                className="my-1 w-[95%] rounded-md py-2 ml-4 px-1 border-2 border-gray-300"
                //   value={formFields.minage}
                onChange={(e) => {
                  setFormFields((old) => ({ ...old, minage: e.target.value }));
                }}
              />
            </div>
          </div>
          <div className="flex flex-row my-2">
            <div className="text-[20px] font-bold mt-2">Maximum</div>
            <div>
              <input
                type="text"
                placeholder="Max Age"
                className="my-1 w-[95%] rounded-md py-2 ml-3 px-1 border-2 border-gray-300"
                //   value={formFields.maxage}
                onChange={(e) => {
                  setFormFields((old) => ({ ...old, maxage: e.target.value }));
                }}
              />
            </div>
          </div>
          <button
            className="w-[150px] h-[40px] rounded-md bg-blue-400 mt-4 text-white font-bold hover:bg-blue-300"
            // isLoading={isPostRequestInProgress}
          >
            Search
          </button>
        </div>
        <div className="flex flex-col justify-center items-center mb-6"></div>
      </form>
      {data2 === "" ? (
        <></>
      ) : !isSearching && data2?.error ? (
        <div className="font-bold text-[18px]">No Data Found</div>
      ) : (
        <div className="flex flex-col border-2  border-gray-200 w-[30%] h-[25%] ml-[35%] mt-[80px]  rounded-md shadow-md cursor-pointer hover:shadow-sm">
          <div className="h-[50%] border-b-2 bg-[#292929] text-white  border-gray-200 flex justify-center pt-[5%] text-[16px] font-bold">
            Block Code Voters
          </div>
          <div className="h-[50%] flex justify-center pt-[5%] text-[16px] text-slate-500 font-semibold">
            {data2.voters}
          </div>
        </div>
      )}
    </ListingTemplate>
  );
}

export default DataSearch;
