import React, { useContext } from "react";
import Chart from "react-google-charts";
import AuthContext from "../../../../contexts/AuthContext";
// import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
const Graph = ({ tvoter, mvoter, fmvoter }) => {
  console.log("Tvoter", tvoter, mvoter, fmvoter);
  let malevoter = (mvoter / tvoter) * 100;
  console.log("malevoter", malevoter);
  let femalevoter = (fmvoter / tvoter) * 100;
  console.log("femalevoter", femalevoter);
  const data = [
    ["Total", '100'],
    ["Male", malevoter],
    ["Female", femalevoter],
  ];
  console.log("DArta", data);

   const authCtx = useContext(AuthContext);
  const options = {
    // title: "My Daily Activities",
    colors: ["#009dff", "#FB7A21"],
    // backgroundColor: "#E67070",
  };
  return (
    <div>
      <Chart
        chartType="PieChart"
        data={data}
        options={options}
        width={"100%"}
        height={"400px"}
      />
    </div>
  );
};

export default Graph;
