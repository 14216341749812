import React from "react";
import ListingTemplate from "../ListingTemplate";
const MapScreen = () => {
  return (
    <ListingTemplate>
      <h3>Election Demarcation 2022, Lahore</h3>
      <iframe 
      className="w-full h-[500px]"
      src="https://s3.eu-west-1.amazonaws.com/c.plabesk.com/map2+(1).html?map=lhr-f&lat=31.5204&lng=74.3587&n=31.643357&s=31.362124&w=74.233744&e=74.425298&expand=0.1"></iframe>
    </ListingTemplate>
  )
}

export default MapScreen