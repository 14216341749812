import axios from "axios";
import { useContext, useRef } from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import AppImages from "../../../../assets/images";
import AuthContext from "../../../../contexts/AuthContext";
import { Api } from "../../../../utils/api";
import { RequestStatus } from "../../../../utils/enums";
import SimpleModal from "../../../basic/SimpleModal";
import ListingTemplate from "../ListingTemplate";
import { employeeActions, employeeThunk } from "../reducers/employee_slice";
import DownloadPdf from "./Print";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import FirebaseService from "../../../../services/FirebaseService";

function CnicDataSearch({ children }) {
  const navigate = useNavigate();
  const ths = [{ title: "Block Code" }, { title: "Count" }, { title: "View" }];
  const tdClasses = "text-[12px] border-b-[1px] border-[#2A2D4314] py-4";

  const dispatch = useDispatch();
  const authCtx = useContext(AuthContext);
  const employeeReducer = useSelector((state) => state.employees);
  // console.log("Employee", employeeReducer.data)
  const [data, setData] = useState(employeeReducer.data);
  const [showableData, setShowableData] = useState(data ?? []);

  const [data1, setData1] = useState("");
  const fields = {
    blockCode: null,
    cnic: null,
  };
  const errors = {
    blockCode: null,
    cnic: null,
  };
  const [formFields, setFormFields] = useState(fields);
  const [data2, setData2] = useState("");
  const [isSearching, setIsSearching ] = useState(false)
  const [loading, setLoading] = useState(false);
  const [ps, setPs] = useState('')
  const [parch, setParch] = useState('')
  const [img, setImg] = useState("");
  const [voter, setVoter] = useState("");
   const [answer, setAnswer] = useState('');
   const [responseData, setResponseData] = useState(null);

   const handleButtonClick = async(value) => {
          try {
            // Data to be sent in the request body
            const requestData = {
                "cnic":data2?.cnic,
                "sector": authCtx.user.sector,
                "pin":"ddc",
                "user_id":authCtx.user.id,
                "block_code": data2?.block_code,
                "will_vote": value
                };

            // Make a POST request using Axios
            const response = await axios.post(
              "https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/insert-cnic-payment-data",
              requestData
            );
            console.log("Responseeee",response)
            // Handle the response
            setResponseData(response.data);
          } catch (error) {
            console.error("Error making API request:", error);
          }
     setAnswer(value);
     console.log("Valueee",value)
   };
  const wordsToReplace = [
    "مکان",
    "سال",
    "نمبر",
    "مین",
    "محلہ",
    "روڈ",
    "پارک",
    "،",
    "کالونی ",
    "لاہور ",
    "کی",
    "ضلع",
    "/",
    "ٹاور",
    "خالصہ",
    "خلاصہ",
  ];
  let na = authCtx.user.sector;
  console.log("NAAAA",na)
  // const setFamilyData = (a,b,c,d) =>{
  //   console.log(a,b,c,d)
  // }
  useEffect(() => {
    // if (employeeReducer.fetchDataRequestStatus === RequestStatus.IDLE) {
    //   dispatch(employeeThunk.getEmployees());
    // }

    // if (employeeReducer.fetchDataRequestStatus === RequestStatus.COMPLETED) {
    //   setData(employeeReducer.data ?? []);
    //   setShowableData(employeeReducer.data ?? []);
      // console.log("EmplyeeDataLength", employeeReducer.data);
    // }
  }, []);
  // console.log("Showable dtaa", showableData);
  const [error , setError]=useState('')
  const onSubmit = async (e) => {
    // setData2(formFields)
    e.preventDefault();
    setLoading(true);
   let res = await FirebaseService.addLog({
          
          tag: "cnic_search_button",
          platform: "web",
          role: "manager",
          cnic: formFields.cnic,
          sector: authCtx.user.sector
        });
    console.log("firebase response", res);
    
    setIsSearching(true)
    setAnswer("")
    setError("")
    setData2("")
    const result = await axios(
      `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/search-by-cnic-in-sector?cnic=${formFields.cnic}&sector=${authCtx.user.sector}`
    ).finally(() => {
      setIsSearching(false);
      setLoading(false);
    });
    if(result.data.error === true){
      setError(result?.data.message)
    }
    console.log("Resultcnic",result)
    // console.log("Resultttttttttt", result);
        const resultt = await axios(
          `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/get-polling-scheme?bc=${result.data.data.block_code}&gender=${result.data.data.gender}`
        );
        // console.log("result000", resultt.data.data.scheme);
        setPs(resultt.data.data.scheme);
    setData2(result?.data.data);
    console.log('-------------------------------------------------')
    console.log(result?.data?.data, resultt)

     axios.get(
         `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/family-bogus-detail?sector=${result.data.data.sector}&bc=${result.data.data.block_code}&cnic=${result.data.data.CnicDataSearch}&fno=${result.data.data.gharana_no}`
       )
       .then((parchi) => {
        // console.log("parchiiiiiii", parchi.data.family_tree[0]);
       setParch(parchi.data.family_tree[0]);
       });
    // const parchi = axios(
    //   `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/family-bogus-detail?sector=${result.data.response.sector}&bc=${result.data.response.block_code}&cnic=${result.data.response.CnicDataSearch}&fno=${result.data.response.gharana_no}`
    // );
setLoading(false);
    
  };
  console.log("Dataaaaaaa", data2);
//   const phone = JSON.parse(data2.response.voter_phone);
if (data2?.response?.gharana_no === ''){
  data2.response.gharana_no = 0
}
const [isModalOpen, setIsModalOpen] = useState(false);
const [phoneNumbers, setPhoneNumbers] = useState([]);

const handleOpenModal = () => {
  setIsModalOpen(true);
};

const handleCloseModal = () => {
  setIsModalOpen(false);
};

const handleAddPhoneNumber = () => {
  const inputElement = document.getElementById("phoneNumberInput");
  const enteredPhoneNumber = inputElement.value.trim();

  if (enteredPhoneNumber !== "") {
    // Split the entered phone numbers by commas
    const numbersArray = enteredPhoneNumber.split(",");
    console.log("enteredphonenumbers",enteredPhoneNumber)

    // Filter out empty strings and add each phone number to the array
    setPhoneNumbers((prevPhoneNumbers) => [
      ...prevPhoneNumbers,
      ...numbersArray.filter((number) => number.trim() !== ""),
    ]);

    inputElement.value = ""; // Clear the input field after adding to the array
  }
};
console.log("PhoneNumberss", phoneNumbers)
if(phoneNumbers.length > 0){
   const apiUrl =
     "https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/add-phone-numbers-by-cnic";

   // Prepare the request body
   const requestBody = {
     cnic: formFields.cnic,
     phone_numbers: phoneNumbers,
   };

   // Make the API call using Axios
   axios.post(apiUrl, requestBody)
     .then((response) => {
       // Handle the response data if needed
       console.log("API response:", response.data);
     })
     .catch((error) => {
       // Handle errors
       console.error("API error:", error);
     });
}



const printStyles = `
    @media print {
      body * {
        visibility: hidden;
      }

      .contentToPrint {
        visibility: visible;
      }
    }
  `;
//  const printRef = useRef(null);
const divToPrintRef = useRef(null);
 // Function to handle printing

 const handlePrint = async () => {
   const divToPrint = divToPrintRef.current;

   if (divToPrint) {
     const canvas = await html2canvas(divToPrint, {
       useCORS: true, // Use this option to handle cross-origin images
     });

     const imgData = canvas.toDataURL("image/png");

     const pdf = new jsPDF();
     const imgWidth = pdf.internal.pageSize.getWidth() - 20;
     const imgHeight = (canvas.height * imgWidth) / canvas.width;

     pdf.addImage(imgData, "PNG", 10, 10, imgWidth, imgHeight); // Centered image

     pdf.save("download.pdf");
   }
 };
 function isPreviousThan17Jan(date) {
   if (!(date instanceof Date)) {
     return false;
   }
   let date17 = new Date(2024, 0, 17, 0, 0, 0, 0);
   return date < date17;
 }

 var yIncr = 0
 if(data2?.rowY) {
   if (authCtx.user.sector === "PP 172") {
    if ( 
      data2?.meta === "100"
    ) {
      yIncr = -10;
    } else {
      if (data2?.name === "" || data2?.address === "") {
        yIncr = -40;
      } 
    }
}
     else if (["PP 208", "PP 14", "NA 146"].includes(authCtx.user.sector)) {
       if (data2?.name === "" || data2?.address === "") {
         yIncr = -40;
       } else {
         yIncr = 65;
       }
     } else if (
       ["PP 207", "NA 146"].includes(authCtx.user.sector) &&
       isPreviousThan17Jan(new Date(data2?.created_at))
     ) {
       if (data2?.name === "" || data2?.address === "") {
         yIncr = -30;
       } else {
         yIncr = 70;
       }
     } else if (["NA 120"].includes(authCtx.user.sector)) {
       yIncr = 50;
     } else if (["PP 59"].includes(authCtx.user.sector)) {
       yIncr = -20;
     } else {
       yIncr = -10;
     }
  
 }

// console.log("JsonDataaa", JSON.parse(data2?.row));
  return (
    <ListingTemplate
    //   title="Employees"
    >
      {/* <style>{printStyles}</style> */}
      {authCtx.user.party === "PTI" ? (
        <form
          // className="invitationForm bg-white"
          className={
            authCtx.user.party === "PMLN"
              ? "bg-white invitationForm"
              : authCtx.user.party === "PTI"
              ? "bg-white invitationForm"
              : authCtx.user.party === "PPP"
              ? "bg-white invitationForm"
              : "bg-white invitationForm"
          }
          onSubmit={onSubmit}
          id="nottoshow"
        >
          {/* <select
          name=""
          id=""
          className="py-2 px-2 border-[1px] border-black rounded-[6px] text-[14px] w-full h-[50px]"
          //   value={formFields.lastName}
          onChange={(e) => {
            setFormFields((old) => ({ ...old, blockCode: e.target.value }));
          }}
        >
          {showableData.map((item, index) => (
            <option key={index} value={item.block_code}>
              {item.block_code}
            </option>
          ))}
        </select> */}
          <div>
            <div className="flex justify-center">
              <img
                className=" rounded-full bg-gray-400 w-[10%] object-contain"
                src={authCtx.user.party_image}
                alt="Rounded avatar"
              />
            </div>
          </div>
          <div className="flex justify-center my-4">
            <div className="flex flex-col w-[70%]">
              <div className="text-[25px] font-bold my-4">
                Search by CNIC Number
              </div>
              <div className="w-full">
                <input
                  type="text"
                  placeholder="Enter CNIC Number"
                  className="my-1 w-[100%] rounded-md py-2 px-2 border-2 border-gray-300"
                  //   value={formFields.minage}
                  onChange={(e) => {
                    setFormFields((old) => ({ ...old, cnic: e.target.value }));
                  }}
                />
              </div>
            </div>
          </div>

          <div className="flex justify-center mb-6 mr-5">
            {loading ? (
              <div className="font-semibold w-[150px] h-[35px] rounded-md bg-gray-200 flex justify-center items-center">
                <div>loading ...</div>
              </div>
            ) : (
              <button
                className="w-[150px] h-[35px] rounded-md bg-[#59a23a]  text-white font-bold hover:bg-green-300"
                // isLoading={isPostRequestInProgress}
              >
                Search
              </button>
            )}
          </div>
        </form>
      ) : (
        <form
          // className="invitationForm bg-white"
          className={
            authCtx.user.party === "PMLN"
              ? "bg-white invitationForm"
              : authCtx.user.party === "PTI"
              ? "bg-white invitationForm"
              : authCtx.user.party === "PPP"
              ? "bg-white invitationForm"
              : "bg-white invitationForm"
          }
          onSubmit={onSubmit}
          id="nottoshow"
        >
          <div className="flex flex-col justify-right my-4">
            <div className="text-[25px] font-bold my-4 ml-[370px]">
              Search by CNIC Number
            </div>
            <div>
              <input
                type="text"
                placeholder="Enter CNIC Number"
                className="my-1 w-[100%] rounded-md py-2 px-4  border-2 border-gray-300"
                //   value={formFields.minage}
                onChange={(e) => {
                  setFormFields((old) => ({ ...old, cnic: e.target.value }));
                }}
              />
            </div>
          </div>

          <div className="flex justify-end mb-6 mr-5">
            {loading ? (
              <div className="font-semibold w-[150px] h-[35px] rounded-md bg-gray-200 flex justify-center items-center">
                <div>loading ...</div>
              </div>
            ) : (
              <button
                className="w-[150px] h-[35px] rounded-md bg-blue-400  text-white font-bold hover:bg-blue-300"
                // isLoading={isPostRequestInProgress}
              >
                Search
              </button>
            )}
          </div>
        </form>
      )}
      {error === "" ? "" : <div>{error}</div>}
      {loading || data2 === "" ? (
        <></>
      ) : !isSearching && data2 === "" && loading === false ? (
        <div className="font-bold text-[18px]">No Data Found</div>
      ) : (
        <div className="flex flex-col items-center justify-center px-3 w-full">
          <div
            className="flex flex-col border-[1px]  px-3 w-[80%] border-gray-200"
            id="nottoshows"
          >
            {authCtx.user.sector == "NA 127" 
            // ||
            // authCtx.user.sector == "PP 161"
             ? (
              <div>
                {data2?.cnic === "35202-8379708-9" ? (
                  <div className="px-1 flex flex-row py-2" dir="rtl">
                    <img
                      src={`https://res.cloudinary.com/election-experts/image/fetch/c_crop,h_90,x_0,w_4000,y_${
                        parseInt(data2?.rowY) + yIncr
                      }/${encodeURIComponent(data2?.firebase_url)}`}
                      alt=""
                      srcSet=""
                      className="w-[1100px] h-[35px]"
                    />
                  </div>
                ) : (
                  <div>
                    <div className="px-1 flex flex-row py-2" dir="rtl">
                      <div className="text-[15px] font-bold text-right font-noori">
                        نام
                      </div>
                      <div className="font-noori mr-4">
                        {/* {data2?.response?.name} */}
                        {data2?.name?.length > 44
                          ? data2?.name
                              ?.replace(/[^\W]/gi, "")
                              .replace(/[-*]/g, " ")
                              .replace(
                                new RegExp(wordsToReplace.join("|"), "gi"),
                                ""
                              )
                              .substr(0, 44) + ""
                              : data2?.name
                          // : data2?.name
                          //     ?.replace(/[^\W]/gi, "")
                          //     .replace(/[-*]/g, " ")
                          //     .replace(
                          //       new RegExp(wordsToReplace.join("|"), "gi"),
                          //       ""
                          //     )
                              }
                      </div>
                    </div>
                    {authCtx.user.sector == "NA 127" ? (
                      <div className="px-1 flex flex-row py-2" dir="rtl">
                        <img
                          src={`https://res.cloudinary.com/election-experts/image/fetch/c_crop,h_90,x_0,w_4000,y_${
                            parseInt(data2?.rowY) + yIncr
                          }/${encodeURIComponent(data2?.firebase_url)}`}
                          alt=""
                          srcSet=""
                          className="w-[1100px] h-[35px]"
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                )}
              </div>
            ) : (
              <>
                {data2?.type === "manual" ? (
                  <div className="px-1 flex flex-row py-2" dir="rtl">
                    <div className="text-[15px] font-bold text-right font-noori">
                      نام
                    </div>
                    <div className="font-noori mr-4">
                      {/* {data2?.response?.name} */}
                      {data2?.name?.length > 44
                        ? data2?.name
                            ?.replace(/[^\W]/gi, "")
                            .replace(/[-*]/g, " ")
                            .replace(
                              new RegExp(wordsToReplace.join("|"), "gi"),
                              ""
                            )
                            .substr(0, 44) + ""
                        : data2?.name
                            // ?.replace(/[^\W]/gi, "")
                            // .replace(/[-*]/g, " ")
                            // .replace(
                            //   new RegExp(wordsToReplace.join("|"), "gi"),
                            //   ""
                            // )
                            }
                    </div>
                  </div>
                ) : (
                  <>
                    {authCtx.user.sector === "PP 152" ? (
                      <div
                        className=" flex flex-row py-2 w-full overflow-hidden"
                        dir="rtl"
                      >
                        <img
                          src={`https://res.cloudinary.com/election-experts/image/fetch/c_crop,h_55,x_0,w_4000,y_${
                            parseInt(data2?.rowY) + yIncr
                          }/${encodeURIComponent(data2?.firebase_url)}`}
                          alt=""
                          srcSet=""
                          className="w-full h-[40px]"
                        />
                      </div>
                    ) : (
                      <div
                        className=" flex flex-row py-2 w-full overflow-hidden"
                        dir="rtl"
                      >
                        <img
                          src={`https://res.cloudinary.com/election-experts/image/fetch/c_crop,h_90,x_0,w_4000,y_${
                            parseInt(data2?.rowY) + yIncr
                          }/${encodeURIComponent(data2?.firebase_url)}`}
                          alt=""
                          srcSet=""
                          className="w-full h-[40px]"
                        />
                      </div>
                    )}
                  </>
                )}
              </>
            )}
            {data2?.type === "manual" ? (
              <div
                className="px-1 flex flex-row py-2 border-gray-200"
                dir="rtl"
              >
                <div className="text-[15px] font-bold text-right font-noori">
                  پتہ
                </div>
                <div className="font-noori mr-4">
                  {/* {data2?.response?.name} */}
                  {data2?.address?.length > 100
                    ? data2?.address?.replace(/سال/gi, "").substr(0, 100) + ".."
                    : data2?.address?.replace(/سال/gi, "")}
                </div>
              </div>
            ) : (
              ""
            )}

            {authCtx.user.sector !== "PK 82" ? (
              <div className="px-1  flex flex-row py-2" dir="rtl">
                <div className="text-[15px] font-bold text-right font-noori">
                  فون نمبر
                </div>
                {/* {data2.response.voter_phone.map((item, index) => ( */}
                <div className="flex flex-row mr-9" dir="rtl">
                  {data2?.voter_phone === null ? (
                    ""
                  ) : (
                    <div>
                      {data2?.voter_phone
                        .split(",")
                        .map((item) => 0 + item.trim())
                        .filter(
                          (item, index, self) => self.indexOf(item) === index
                        )
                        .join(" , ")}
                    </div>
                  )}
                  {/* 0 */}
                  {/* {data2?.response?.voter_phone
                  .split(",")
                  .filter((item, index, self) => self.indexOf(item) === index)} */}
                </div>
              </div>
            ) : (
              <div></div>
            )}
            <hr />
            <div className="px-1 flex flex-row py-2" dir="rtl">
              <div className="text-[15px] font-bold text-right font-noori">
                بلاک کوڈ
              </div>
              <div className="flex flex-row mr-9 text-right" dir="rtl">
                {data2?.block_code}
              </div>
            </div>
            <hr />
            {data2?.gender === "male" ? (
              <div className="px-1 flex flex-row py-2" dir="rtl">
                <div className="text-[15px] font-bold text-right font-noori">
                  پولنگ اسٹیشن
                </div>
                <div className="flex flex-row mr-9 text-right" dir="rtl">
                  {ps?.polling_station_area_urdu}
                </div>
              </div>
            ) : data2?.gender === "female" ? (
              <div className="px-1 flex flex-row py-2" dir="rtl">
                <div className="text-[15px] font-bold text-right font-noori">
                  پولنگ اسٹیشن
                </div>
                <div className="flex flex-row mr-9 text-right" dir="rtl">
                  {ps?.polling_station_area_urdu}
                </div>
              </div>
            ) : (
              ""
            )}

            <hr />
            <hr />
            <div className="px-1 flex flex-row py-2" dir="rtl">
              <div className="text-[15px] font-bold text-right font-noori">
                سیکٹر نمبر
              </div>
              <div className="flex flex-row mr-9 text-right" dir="rtl">
                {data2?.sector}
              </div>
            </div>
            <hr />
            <div className="px-1 flex flex-row py-2" dir="rtl">
              <div className="text-[15px] font-bold text-right font-noori">
                شناختی کارڈ نمبر
              </div>
              <div className="flex flex-row mr-4" dir="rtl">
                {data2?.cnic}
              </div>
            </div>
            <hr />
            <div className="py-2 px-3 flex flex-row justify-around">
              {data2?.gharana_no === "" ? (
                ""
              ) : (
                <div>
                  <Link
                    to={`/family/list/${data2?.sector}/${data2?.block_code}/${data2?.cnic}/${data2?.gharana_no}`}
                  >
                    <button className="w-[150px] h-[30px] rounded-md bg-green-500 text-white font-bold hover:bg-green-300">
                      Family Tree
                    </button>
                  </Link>
                </div>
              )}

              <div>
                <button
                  onClick={handleOpenModal}
                  className="bg-blue-500 text-white  p-2 rounded-md"
                >
                  Add PhoneNumber
                </button>
              </div>
              <div
                className="w-[150px] h-[30px] rounded-md text-center bg-blue-500 cursor-pointer text-white font-bold hover:bg-blue-300"
                onClick={(e) => {
                  setImg(data2?.firebase_url);
                }}
              >
                Voter Parchi
              </div>
              {authCtx.user.admin === "1" &&
              authCtx.user.sector === "NA 127" ? (
                <div
                  className="w-[150px] h-[30px] rounded-md text-center bg-blue-500 cursor-pointer text-white font-bold hover:bg-blue-300"
                  onClick={(e) => {
                    setVoter("OK");
                  }}
                >
                  Confirm Voter
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      )}
      {voter == "" ? (
        <div></div>
      ) : (
        <div className=" w-[95%] font-noori flex flex-col justify-center items-center mb-1 mt-4 border-[1px] border-black">
          <div className="text-[22px] font-semibold font-noori my-2">
            کیا آپ ہمیں ووٹ دیں گے؟
          </div>
          {answer ? (
            ""
          ) : (
            <div className="flex flex-row justify-between w-[50%]">
              <button
                className="bg-green-500 hover:bg-green-700 text-white font-bold my-3 py-2 px-4 rounded mr-2"
                onClick={() => handleButtonClick("Yes")}
              >
                Yes
              </button>
              <button
                className="bg-red-500 hover:bg-red-700 text-white font-bold my-3 py-2 px-4 rounded"
                onClick={() => handleButtonClick("No")}
              >
                No
              </button>
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold my-3 py-2 px-4 rounded"
                onClick={() => navigate("/paid/voter/list")}
              >
                View List
              </button>
            </div>
          )}

          {answer && (
            <>
              {answer === "Yes" ? (
                <p>
                  You clicked: {answer}. It means that {data2?.cnic} is over
                  confirmed voter
                </p>
              ) : (
                <p>
                  You clicked: {answer}. It means that {data2?.cnic} is not our
                  voter
                </p>
              )}

              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold my-3 py-2 px-4 rounded"
                onClick={() => navigate("/paid/voter/list")}
              >
                View List
              </button>
            </>
          )}
        </div>
      )}
      {img == "" ? (
        <div></div>
      ) : (
        <div>
          <div className="mt-8" id="divToPrint" ref={divToPrintRef}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                // margin: "1px",
              }}
              className="contentToPrint w-[95%] font-noori mb-1 mt-4 border-[1px] border-black"
              id="pagetodownload"
              // className=""
              // key={index}
            >
              <div className="flex flex-row w-full">
                <div className="w-full flex flex-col" dir="rtl">
                  {authCtx.user.sector == "NA 127" ?
                  // ||
                  // authCtx.user.sector == "PP 161" ? 
                  (
                    <div
                      className="flex flex-row py-[2px] border-[0.5px] border-gray-500"
                      dir="rtl"
                    >
                      {data2?.cnic === "35202-8379708-9" ? (
                        <div className="px-1 flex flex-row py-2" dir="rtl">
                          <img
                            src={`https://res.cloudinary.com/election-experts/image/fetch/c_crop,h_90,x_0,w_4000,y_${
                              parseInt(data2?.rowY) + yIncr
                            }/${encodeURIComponent(data2?.firebase_url)}`}
                            alt=""
                            srcSet=""
                            className="w-[1100px] h-[35px]"
                          />
                        </div>
                      ) : (
                        <div>
                          {/* <div className="px-1 flex flex-row py-2" dir="rtl">
                            <div className="text-[15px] font-bold text-right font-noori">
                              نام
                            </div>
                            <div className="font-noori mr-4 " data-test={data2.name}>
                              {data2?.name?.length > 44
                                ? data2?.name
                                    ?.replace(/[^\W]/gi, "")
                                    .replace(/[-*]/g, " ")
                                    .replace(
                                      new RegExp(
                                        wordsToReplace.join("|"),
                                        "gi"
                                      ),
                                      ""
                                    )
                                    .substr(0, 44) + ""
                                // : data2?.name
                                : "ssasasa"
                                    // ?.replace(/[^\W]/gi, "")
                                    // .replace(/[-*]/g, " ")
                                    // .replace(
                                    //   new RegExp(
                                    //     wordsToReplace.join("|"),
                                    //     "gi"
                                    //   ),
                                    //   ""
                                    // )
                                  }
                            </div>
                          </div> */}
                          {authCtx.user.sector === "NA 127" ? (
                            <div
                              className="px-1 flex flex-row py-2 overflow-hidden"
                              dir="rtl"
                            >
                              <img
                                src={`https://res.cloudinary.com/election-experts/image/fetch/c_crop,h_90,x_0,w_4000,y_${
                                  parseInt(data2?.rowY) + yIncr
                                }/${encodeURIComponent(data2?.firebase_url)}`}
                                alt=""
                                srcSet=""
                                className="w-full h-[50px]"
                              />
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      )}
                    </div>
                  ) : (
                    <>
                      {data2?.type === "manual" ? (
                        <div
                          className="px-[25px] flex flex-row py-2 text-[18px] "
                          dir="rtl"
                        >
                          <div className="text-[15px] font-bold text-right font-noori">
                            نام
                          </div>
                          <div className="font-noori mr-4">
                            {/* {data2?.response?.name} */}
                            {data2?.name?.length > 44
                              ? data2?.name
                                  ?.replace(/[^\W]/gi, "")
                                  .replace(/[-*]/g, " ")
                                  .replace(
                                    new RegExp(wordsToReplace.join("|"), "gi"),
                                    ""
                                  )
                                  .substr(0, 44) + ""
                              : data2?.name
                                  ?.replace(/[^\W]/gi, "")
                                  .replace(/[-*]/g, " ")
                                  .replace(
                                    new RegExp(wordsToReplace.join("|"), "gi"),
                                    ""
                                  )}
                          </div>
                        </div>
                      ) : (
                        <div
                          className="flex flex-row py-[2px] border-[0.5px] border-gray-500 overflow-hidden"
                          dir="rtl"
                        >
                          {authCtx.user.sector === "PP 152" ? (
                            <img
                              src={`https://res.cloudinary.com/election-experts/image/fetch/c_crop,h_55,x_0,w_4000,y_${
                                parseInt(data2?.rowY) + yIncr
                              }/${encodeURIComponent(data2?.firebase_url)}`}
                              alt=""
                              srcSet=""
                              className="w-full h-[40px]"
                            />
                          ) : (
                            <img
                              src={`https://res.cloudinary.com/election-experts/image/fetch/c_crop,h_90,x_0,w_4000,y_${
                                parseInt(data2?.rowY) + yIncr
                              }/${encodeURIComponent(data2?.firebase_url)}`}
                              alt=""
                              srcSet=""
                              className="w-full h-[40px]"
                            />
                          )}
                        </div>
                      )}
                    </>
                  )}

                  <div
                    style={{
                      direction: "rtl",
                      justifyContent: "space-around",
                      display: "flex",
                    }}
                  >
                    <div className="flex flex-row border-[0.5px] text-[18px]  w-[25%] border-gray-500 py-2 ">
                      <div
                        style={{
                          fontWeight: "bold",
                          paddingRight: "25px",
                          // fontSize: 15,
                        }}
                        className=""
                      >
                        سلسلہ نمبر :
                      </div>

                      <div className=" font-[7px]">
                        {
                          // data2?.response?.silsila_no === ""
                          //   ? 57
                          //   :
                          data2?.silsila_no
                        }
                      </div>
                    </div>
                    <div className="flex flex-row border-[0.5px] text-[18px] py-2 w-[25%]  border-gray-500  ">
                      <div
                        style={{
                          fontWeight: "bold",
                          paddingRight: "12px",
                          // fontSize: 10,
                        }}
                        className=""
                      >
                        گھرانہ نمبر :
                      </div>
                      <div className=" font-[7px] mr-2 ">
                        {
                          // data2?.response?.gharana_no === ""
                          //   ? 57
                          //   :
                          data2?.gharana_no
                        }
                      </div>
                    </div>
                    <div className="flex flex-row border-[0.5px] text-[18px]  w-[25%]  border-gray-500 py-2 ">
                      <div
                        style={{
                          fontWeight: "bold",
                          paddingRight: "12px",
                          // fontSize: 10,
                        }}
                        className=""
                      >
                        شناختی کارڈ:
                      </div>
                      <div className=" font-[7px] mr-2 ">{data2?.cnic}</div>
                    </div>
                    <div className="flex flex-row border-[0.5px] w-[25%] text-[18px]  border-gray-500 py-2 ">
                      <div
                        style={{
                          fontWeight: "bold",
                          paddingRight: "12px",
                          // fontSize: 10,
                        }}
                        className=""
                      >
                        بلاک کوڈ :
                      </div>
                      <div className="font-[7px]  mr-2">
                        {data2?.block_code}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="flex flex-row w-full text-[18px]  px-[25px] py-2 border-[0.5px] border-gray-500"
                dir="rtl"
              >
                <div
                  style={{
                    fontWeight: "bold",
                    // paddingRight: "7px",
                  }}
                  className=""
                >
                  پولنگ سٹیشن نمبر :
                </div>
                <div className="font-[11px]  mr-2">{data2?.block_code}</div>
              </div>
              {data2?.gender === "male" ? (
                <div
                  className="px-1 flex flex-row py-2 text-[18px] w-full mr-3"
                  dir="rtl"
                >
                  <div className="px-[25px] font-bold text-right font-noori">
                    پولنگ اسٹیشن
                  </div>
                  <div className="flex flex-row mr-9 text-right" dir="rtl">
                    {ps?.polling_station_area_urdu}
                  </div>
                </div>
              ) : data2?.gender === "female" ? (
                <div
                  className="flex flex-row px-[25px] py-2 text-[18px] w-full mr-3"
                  dir="rtl"
                >
                  <div className=" font-bold text-right font-noori">
                    پولنگ اسٹیشن
                  </div>
                  <div className="flex flex-row mr-9 text-right" dir="rtl">
                    {ps?.polling_station_area_urdu}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>

          <button
            onClick={handlePrint}
            className="bg-blue-500 text-white  p-2 rounded-md"
          >
            Print
          </button>
        </div>
      )}

      {isModalOpen && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 z-50 flex items-center justify-center">
          <div className="modal-container">
            <div className="modal-content bg-white p-4 rounded-md">
              <div className="flex flex-row justify-between">
                <div className="text-lg font-semibold mb-4">
                  Add Phone Number
                </div>
                <div className="bg-gray-300 rounded-full h-7 w-7 flex justify-center items-center font-bold text-[18px]">
                  <button className="modal-close" onClick={handleCloseModal}>
                    &times;
                  </button>
                </div>
              </div>

              <input
                id="phoneNumberInput"
                type="text"
                placeholder="Enter phone number"
                className="border p-2 mb-2"
              />
              <button
                onClick={handleAddPhoneNumber}
                className="bg-blue-500 text-white p-2 rounded-md"
              >
                Add Phone Number
              </button>
              <div className="mt-4">
                <strong>Added Phone Numbers:</strong>
                <ul>
                  {phoneNumbers.map((number, index) => (
                    <li key={index}>{number}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
    </ListingTemplate>
  );
}

export default CnicDataSearch;
