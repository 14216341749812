import axios from "axios";
import { useContext, useState } from "react";
import { useNavigate } from "react-router";
import AppImages from "../../../assets/images";
import AuthContext from "../../../contexts/AuthContext";
import { range } from "../../../utils/helpers";
import Button from "../../basic/Button";
import Dropdown from "../../basic/Dropdown";
import TextInputField from "../../basic/TextInputField";
import DashboardTemplate from "../../common/DashboardTemplate";
import ProfileApi from "./api/ProfileApi";

function EditProfile() {
    const navigate = useNavigate()
    const authCtx = useContext(AuthContext)
    const [formFields, setFormFields] = useState({
        fullName: {value: authCtx.user.fullName, error: null},
        address: {value: authCtx.user.UserProfile?.address, error: null},
        city: {value: authCtx.user.UserProfile?.city, error: null},
        province: {value: authCtx.user.UserProfile?.province, error: null},
        postalCode: {value: authCtx.user.UserProfile?.postalCode, error: null},
        dobMonth: {value: (new Date(authCtx.user.UserProfile?.dateOfBirth)).getMonth().toString(), error: null},
        dobDay: {value: (new Date(authCtx.user.UserProfile?.dateOfBirth)).getDate().toString(), error: null},
        dobYear: {value: (new Date(authCtx.user.UserProfile?.dateOfBirth)).getFullYear().toString(), error: null},
    })

    const handleSubmit = async (e) => {
        e.preventDefault()
        let hasErrors = false 
        console.log(formFields)
        Object.keys(formFields).forEach((key) => {
            if(formFields[key]['value'].toString().length === 0) {
                hasErrors = true
                setFormFields(old => ({...old, [key]: {...old[key], error: 'This field is required' }})) 
            }
        })
        if(hasErrors) {
            return;
        }

        console.log(authCtx.token)

        // const apiResponse = await ProfileApi.updateProfile({
        //     address: formFields.address.value,
        //     city: formFields.city.value,
        //     province: formFields.province.value,
        //     postalCode: formFields.postalCode.value,
        //     fullName: formFields.fullName.value
        // }, authCtx.token)

        axios
          .post(
            "https://securitylinksapi.herokuapp.com/api/v1/userProfiles/createOrUpdate",
            {
              address: formFields.address.value,
              city: formFields.city.value,
              province: formFields.province.value,
              postalCode: formFields.postalCode.value,
              fullName: formFields.fullName.value,
            },
            {
              headers: {
                Authorization: `Bearer ${authCtx.token}`,
                "Content-Type": "application/json",
                Accept: "*/*",
              },
            }
          )
          .then((res) => {
            console.log("response ====>");
            console.log(res);
            authCtx.updateUserFields({
              address: formFields.address.value,
              city: formFields.city.value,
              province: formFields.province.value,
              postalCode: formFields.postalCode.value,
              fullName: formFields.fullName.value,
            });
          })
          .catch((e) => {
            console.log("error =====>");
            console.log(e);
          });

        // console.log(apiResponse)
    }
    
    return ( 
        <DashboardTemplate>
            <form onSubmit={handleSubmit}>
                <div
                    className="flex flex-col justify-center items-center"
                >
                    <div>
                        <div className="w-[150px] h-[150px] bg-gray-100 relative rounded-2xl mb-8">
                            <img 
                                src={AppImages.user}
                                alt={'Avatar Image'}
                                className="object-contain object-center absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] rounded-2xl mx-0 my-auto block"
                            />
                        </div>
                    </div>
                    <TextInputField 
                        type='text'
                        placeholder="Full Name"
                        style={{
                            width: 'w-[400px]'
                        }}
                        value={formFields.fullName.value}
                        onChange={e => {
                            const val = e.target.value 
                            setFormFields(old => ({...old, fullName: { error: null, value: val }}))
                        }}
                        error={formFields.fullName.error}
                    />
                    <TextInputField 
                        type='text'
                        placeholder="Address"
                        style={{
                            width: 'w-[400px]'
                        }}
                        value={formFields.address.value}
                        onChange={e => {
                            const val = e.target.value 
                            setFormFields(old => ({...old, address: { error: null, value: val }}))
                        }}
                        error={formFields.address.error}
                    />
                    <TextInputField 
                        type='text'
                        placeholder="City"
                        style={{
                            width: 'w-[400px]'
                        }}
                        value={formFields.city.value}
                        onChange={e => {
                            const val = e.target.value 
                            setFormFields(old => ({...old, city: { error: null, value: val }}))
                        }}
                        error={formFields.city.error}
                    />
                    <div className="flex">
                        <div className="flex-1">
                            <TextInputField 
                                type='text'
                                placeholder="Province"
                                style={{
                                    width: 'w-[195px]'
                                }}
                                value={formFields.province.value}
                                onChange={e => {
                                    const val = e.target.value 
                                    setFormFields(old => ({...old, province: { error: null, value: val }}))
                                }}
                                error={formFields.province.error}
                            />
                        </div>
                        <p className="w-[10px]"></p>
                        <div className="flex-1">
                            <TextInputField 
                                type='text'
                                placeholder="Postal Code"
                                style={{
                                    width: 'w-[195px]'
                                }}
                                value={formFields.postalCode.value}
                                onChange={e => {
                                    const val = e.target.value 
                                    setFormFields(old => ({...old, postalCode: { error: null, value: val }}))
                                }}
                                error={formFields.postalCode.error}
                            />
                        </div>
                    </div>
                    <div>
                        <p className="text-[16px] font-semibold mb-2 text-black">Date of Birth</p>
                        <div className="flex w-[400px]">
                            <div >
                                <Dropdown 
                                    placeholder="MM"
                                    style={{
                                        width: 'w-[130px]'
                                    }}
                                    options={[
                                        { text: 'January', value: '0' },
                                        { text: 'February', value: '1' },
                                        { text: 'March', value: '2' },
                                        { text: 'April', value: '3' },
                                        { text: 'May', value: '4' },
                                        { text: 'June', value: '5' },
                                        { text: 'July', value: '6' },
                                        { text: 'August', value: '7' },
                                        { text: 'September', value: '8' },
                                        { text: 'October', value: '9' },
                                        { text: 'November', value: '10' },
                                        { text: 'December', value: '11' },
                                    ]}
                                    value={formFields.dobMonth.value.toString()}
                                    onChange={e => {
                                        const val = e.target.value 
                                        setFormFields(old => ({...old, dobMonth: { error: null, value: val }}))
                                    }}
                                    error={formFields.dobMonth.error}
                                />
                            </div>
                            <p className="w-[5px]"></p>
                            <div>
                                <Dropdown 
                                    placeholder="DD"
                                    style={{
                                        width: 'w-[130px]'
                                    }}
                                    options={range(31, 1).map((item, index) => {
                                        return { text: item, value: item }
                                    })}
                                    value={formFields.dobMonth.value.toString()}
                                    onChange={e => {
                                        const val = e.target.value 
                                        setFormFields(old => ({...old, dobDay: { error: null, value: val }}))
                                    }}
                                    error={formFields.dobDay.error}
                                />
                            </div>
                            <p className="w-[5px]"></p>

                            <div>
                                <Dropdown 
                                    placeholder="YYYY"
                                    style={{
                                        width: 'w-[130px]'
                                    }}
                                    options={range(82, 1940).map((item, index) => {
                                        return { text: item, value: item }
                                    })}
                                    value={formFields.dobYear.value.toString()}
                                    onChange={e => {
                                        const val = e.target.value 
                                        setFormFields(old => ({...old, dobYear: { error: null, value: val }}))
                                    }}
                                    error={formFields.dobYear.error}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center justify-between w-[400px] my-4">
                        <Button 
                            text={'Save Changes'}
                            style={{
                                custom: 'py-2 px-10 basis-[60%]'
                            }}
                        />
                        <p
                            className="basis-[30%] text-black cursor-pointer"
                            onClick={e => {
                                navigate('/profile/view')
                            }}
                        >Cancel</p>
                    </div>
                </div>
            </form>
        </DashboardTemplate>
    );
}

export default EditProfile;