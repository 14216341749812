import { createContext, useState } from "react";

const DataContext = createContext({
    roles: {
        data: [],
        isLoading: false,
        error: null,
        update: (roles) => {},
        deleteOne: (id) => {},
        getOne: (id) => {},
        updateIsLoading: (isLoading) => {},
        updateError: (error) => {}
    },
    
    // Permissions
    permissions: [],
    updatePermissions: (permissions) => {},
    isLoadingPermissions: false,
    updateIsLoadingPermissions: (isLoading) => {},


})

export function DataContextProvider({ children }) {
    const [roles, setRoles] = useState({ data: [], isLoading: false })

    const rolesFunctions = {
        update: roles => setRoles(old => ({...old, data: roles})),
        deleteOne: id => {
            roles.splice(roles.indexOf(roles.find(role => role.id === id)), 1)
            setRoles(old => ({...old, data: roles}))
        },
        updateIsLoading: (isLoading) => setRoles(old => ({...old, isLoading: isLoading}))
    }

    const value = {
        roles: {
            data: roles.data,
            isLoading: roles.isLoading,
            update: rolesFunctions.update,
            deleteOne: rolesFunctions.deleteOne,
            updateIsLoading: rolesFunctions.updateIsLoading
        },
    }
    return ( 
        <DataContext.Provider value={value}>
            {children}
        </DataContext.Provider>
    );
}

export default DataContext;