import React from 'react'

const Notfound = () => {
  return (
    <div>
      <h1>404 Error: Page not found</h1>
    </div>
  );
}

export default Notfound