import axios from "axios"
import { useContext } from "react"
import { useEffect } from "react"
import { useState } from "react"
import AuthContext from "../../../../contexts/AuthContext"
import ReactLoading from "react-loading";
import { QueryClient, QueryCache, useQuery } from "react-query";
import ListingTemplate from "../ListingTemplate"

const PollingStation = () => {
    const [data1, setData] = useState([])
    const [showableData, setShowableData] = useState([])
    const authCtx = useContext(AuthContext)
    const ths = [
        { title: "Block Code " },
        { title: " Block Code Area" },
        { title: "Polling Station Area" },
        { title: "Total Booth", classes: "w-[5%]" },
        { title: "Ward", classes: "w-[5%]" },
    ]
    const tdClasses = "text-[12px] border-b-[1px] border-[#2A2D4314] py-4";
     const { isLoading, error, data } = useQuery("pollingStationData", () =>
       fetch(
         `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/geo-location-polling-station?sector=${authCtx.user?.sector}`
       ).then((res) => res.json())
     );
     console.log('Dartaa',data)
    //  setData(data.polling_scheme)
    //  console.log("Dataaa", data.polling_scheme);

    //  if (isLoading) return "Loading...";

     if (error) return "An error has occurred: " + error.message;
    // useEffect(() => {
    //     axios.get(`https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/geo-location-polling-station?sector=${authCtx.user?.sector}`).then(res => {
    //         console.log(res.data)
    //         if(res.data.status === 200) {
    //             setData(res.data.polling_scheme)
    //             setShowableData(res.data.polling_scheme)
    //         }
    //     })
    // }, [])
    return (
      <ListingTemplate>
        <div className="text-[24px] font-bold">Polling Stations</div>
        <div className="w-[1000px] mt-1">
          Polling stations are set up by the Election Commission of Pakistan
          (ECP) in various areas, cities and towns across the country. The ECP
          ensures that each polling station is accessible, secure, and equipped
          with necessary facilities and resources to facilitate a smooth and
          fair voting process.
        </div>
        <table className="w-full my-6">
          <thead>
            <tr>
              {ths.map((item, index) => (
                <th
                  key={index}
                  className={
                    "text-[12px] font-semibold border-b-[1px] border-[#2A2D4314] py-4 text-left " +
                    (item.classes ? item.classes : "") +
                    (index === 0 ? "w-[15%]" : "")
                  }
                >
                  {item.title}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data?.polling_scheme ? (
              data?.polling_scheme.map((item, index) => (
                <tr key={index}>
                  <td className={tdClasses}>{item.block_code}</td>
                  <td className={tdClasses}>{item.block_code_area?.replace(/["()]/gi, "")}</td>
                  <td className={tdClasses}>
                    <p>{item.polling_station_area?.replace(/["()]/gi, "")}</p>
                    <p className="font-noori">
                      {item.polling_station_area_urdu}
                      <a
                        href={`https://www.google.com/maps/?q=${item.polling_station_area_urdu}`}
                        target="_blank"
                        className="text-blue-400 ml-2"
                      >
                        <span>View</span>
                      </a>
                    </p>
                  </td>
                  <td className={tdClasses}>{item.total_both}</td>
                  <td className={tdClasses}>{item.ward}</td>
                </tr>
              ))
            ) : (
              <div className="flex flex-row">
                <div className="flex justify-center">
                  <ReactLoading
                    color={"gray"}
                    height={"20px"}
                    width={"40px"}
                    className="my-2"
                  />
                </div>
              </div>
            )}
          </tbody>
        </table>
      </ListingTemplate>
    );
}

export default PollingStation