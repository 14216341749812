import axios from "axios";
import { useContext } from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import AuthContext from "../../../../../contexts/AuthContext";
import ListingTemplate from "../../ListingTemplate";
import { QueryClient, QueryCache, useQuery } from "react-query";
import ReactLoading from "react-loading";
import { toSentenceCase } from "../../../../../utils/helpers";
import Chart from "react-google-charts";

function ReportByBlockCodeAgeFemale({ children }) {
  const navigate = useNavigate();
  const [graphData, setGraphData] = useState([])

  const ths = [
    { title: "Block Code" },
    { title: "17-25" },
    { title: "25-35" },
    { title: "35-45" },
    { title: "45+" },
  ];

  const authCtx = useContext(AuthContext);
  console.log("Authcontext", authCtx);
  // const [data, setData] = useState("");
  const { id } = useParams();
  let page = 1;
   let gender = "female";
  let na = authCtx.user.sector;
  const { isLoading, error, data } = useQuery("femaleData", () =>
    fetch(
      `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/block-code-by-age-group?sector=${na}&gender=${gender}`
    ).then((res) => res.json())
  );
  console.log("Dataia", data);
  useEffect(() => {
    let na = authCtx.user.sector;
    let Id = 1;
    // let page = 1;

    const fetchData = async () => {
      let gender = "female";
      console.log("ID", Id, na);
      const result = await axios.get(
        `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/block-code-by-age-group?sector=${na}&gender=${gender}`
      );
      //   var url = `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/my-team?sector=${na}&id=${Id}`;
      //   console.log("URLLL", url);
      console.log("RESData", result);
      // setData(result.data);
      console.log(1)
      if(result.data.response) {
        console.log(2)
        const arr = Object.keys(result.data.response).map((key, index, self) => {
          const d = result.data.response[key]
          return [key, d["17-25"], d["25-35"], d["35-45"], d["45+"]]
        })
        setGraphData(arr)
      }
    };

    fetchData();
  }, []);
  console.log("Darta", data);

  return (
    <ListingTemplate
      title={id}
      //   onSearch={handleOnSearch}
      //   onSelect={handleStatusFilter}
    >
      <div className="flex flex-row">
        {/* <div className="text-[24px] font-bold mt-3">Sector</div> */}
        <div className=" text-[15px] font-bold mt-5">{na}</div>
        <div className="ml-1 text-[15px] mt-5">/</div>
        {/* <div
          className="ml-1 mt-5 text-[15px] cursor-pointer"
          onClick={(e) => {
            navigate("/create/newteam");
          }}
        >
          Add Team member
        </div>
        <div className="ml-1 text-[15px] mt-5">/</div> */}

        <div className="ml-1 text-[15px]  text-gray-400 mt-5">
          {toSentenceCase("Constituency report by block code and age group (Female)")}
        </div>
        {/* <button
          className="w-[150px] my-4 h-[40px] ml-[700px] bg-[#14A800] text-[18px] rounded-md text-white hover:bg-slate-400 mt-4"
          onClick={() => navigate("/create/newteam")}
        >
          Add new
        </button> */}

        {/* <Button
          text={"Add new"}
          style={{
            width: "w-[150px]",
            custom: "box-border text-[18px]",
            margin: " ml-[65%]",
          }}
          onClick={() => {
            navigate("/create/newteam");
          }}
        /> */}
      </div>
      <div className="text-[24px] font-bold">
        {toSentenceCase("Constituency report by block code and age group (Female)")}
      </div>
      <div className="w-[1000px] mt-1">
        This information could include data on the number of Female in each
        block code, as well as their ages. Once you have collected this
        information, you can organize it into a report that provides an overview
        of the demographic makeup of the constituency by block code and age
        group
      </div>

      <div>
        {graphData && graphData.length > 0 && 
          <Chart
            chartType="Bar"
            data={[["Block Code", "17 - 25", "25 - 35", "35 - 45", "45+"], ...graphData]}
            options={{
              // title: "Age wise Voters",
              hAxis: {
                title: "Count",
              },
              vAxis: {
                title: "Age",
              },
            }}
            width={"100%"}
            height={"400px"}
          />
        }
      </div>

      <table className="w-full my-6">
        <thead>
          <tr>
            {ths.map((item, index) => (
              <th
                key={index}
                className="text-[12px] font-semibold border-b-[1px] border-[#2A2D4314] bg-gray-100 px-3 py-4 text-left"
              >
                {item.title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data ? (
            data.response &&
            Object.keys(data.response).map((key, index) => (
              <tr key={index} className="px-3">
                <td className="text-[12px] border-b-[1px] border-[#2A2D4314] py-4 w-[200px]">
                  {key}
                </td>
                <td className="text-[12px] border-b-[1px] border-[#2A2D4314] py-4 w-[200px]">
                  {data.response[key]["17-25"] ?? "--"}
                </td>
                <td className="text-[12px] border-b-[1px] border-[#2A2D4314] py-4 w-[200px]">
                  {data.response[key]["25-35"] ?? "--"}
                </td>
                <td className="text-[12px] border-b-[1px] border-[#2A2D4314] py-4 w-[200px]">
                  {data.response[key]["35-45"] ?? "--"}
                </td>
                <td className="text-[12px] border-b-[1px] border-[#2A2D4314] py-4 w-[200px]">
                  {data.response[key]["45+"] ?? "--"}
                </td>
              </tr>
            ))
          ) : (
            <div className="mt-[40px] ">
              <ReactLoading
                color={"gray"}
                height={"20px"}
                width={"40px"}
                className="ml-[500px] my-3"
              />
              <div className="font-bold text-[18px] ml-[350px]">
                Data was loading slow due to slow Internet
              </div>
            </div>
          )}
        </tbody>
      </table>
    </ListingTemplate>
  );
}

export default ReportByBlockCodeAgeFemale;
