import React from 'react'

const Route404 = () => {
  return (
    <div className='bg-gray-500 text-white justify-center w-full h-[700px]'>
      <div className='text-[58px] text-center pt-[20%]'>404</div>
    <div className='text-[22px] text-center'>look like the page you were looking for is no loger here</div></div>
  )
}

export default Route404