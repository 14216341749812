import { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import AppImages from "../../../assets/images";
import DataContext from "../../../contexts/DataContext";
import { Api } from "../../../utils/api";
import Button from "../../basic/Button";
import CheckboxFieldsGroup from "../../basic/CheckboxFieldsGroup";
import TextInputField from "../../basic/TextInputField";
import DashboardTemplate from "../../common/DashboardTemplate";
import { userRolesActions } from "./user_roles_slice";

function AddRole() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const dataCtx = useContext(DataContext)
    const getFields = () => {
        return {
            name: null,
            permissions: []
        }
    }
    const [formFields, setFormFields] = useState(getFields())
    const [fieldErrors, setFieldErrors] = useState(getFields())

    const handleSubmit = e => {
        e.preventDefault()
        console.log(formFields)
        if(!formFields.name) {
            setFieldErrors(old => ({...old, name: 'Please write the name of the role'}))
            return;
        }
        if(formFields.permissions.length === 0) {
            console.log('adjhshaj')
            setFieldErrors(old => ({...old, permissions: 'Please select permissions!'}))
            return;
        }
        setFieldErrors(getFields())
        Api.post({
            path: `/userRoles/create`,
            data: formFields
        }).then(response => {
            console.log(response)
            if(response.success) {
                dataCtx.roles.update([ ...dataCtx.roles.data, response.data.role ])
                dispatch(userRolesActions.addRole({ role: response.data.role }))
                navigate('/roles')
            }
            if(response.error) {
                let message = 'Something went wrong'
                if(response.apiResponse?.isOperationalError) {
                    message = 'Something went wrong!'
                } else if(response.apiResponse?.name === 'validationError') {
                    message = response.apiResponse?.message[Object.keys(response.apiResponse?.message)[0]][0]
                }
                toast.error(
                    message, {
                    theme: 'colored',
                    hideProgressBar: true,
                })
            }
        })
    }

    return (  
        <DashboardTemplate>
            <ToastContainer />
            <div className="relative my-4">
                <div 
                    className="bg-primary rounded-lg absolute right-0 p-2 cursor-pointer hover:bg-black"
                    onClick={e => {
                        navigate('/roles')
                    }}
                >
                    <img 
                        src={AppImages.crossWhite}
                        alt="close"
                    />
                </div>
            </div>
            <div>
                <div className="w-[100px] h-[100px] bg-gray-100 relative rounded-2xl mb-8">
                    <img 
                        src={AppImages.userBlackYellow}
                        alt={'Avatar Image'}
                        className="object-contain object-center absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] rounded-2xl mx-0 my-auto block"
                    />
                </div>

                <div>
                    <form
                        onSubmit={handleSubmit}
                    >
                        <TextInputField 
                            label="Role Name"
                            placeholder="Manager"
                            style={{
                                width: 'w-[500px]',
                                labelFontSize: 'text-[12px]',
                                inputFontSize: 'text-[10px]',
                                labelFontWeight: 'font-bold'
                            }}
                            value={formFields.name}
                            onChange={e => {
                                setFieldErrors(old => ({...old, name: null}))
                                setFormFields(old => ({...old, name: e.target.value.trim()}))
                            }}
                            error={fieldErrors.name}
                        />

                        <div className="my-4">
                            <p className="block font-bold my-2 text-black text-[12px]">Job Type</p>
                            {fieldErrors.permissions && <p className="my-1 text-[12px] text-primary pl-4">{fieldErrors.permissions}</p>}
                            {
                                [
                                    { value: 'staff_listing', label: 'Listing of Staff' },
                                    { value: 'staff_add', label: 'Add Staff' },
                                    { value: 'staff_edit', label: 'Edit Staff' },
                                    { value: 'staff_delete', label: 'Delete Staff' },
                                ].map((item, index) => (
                                    <div key={index} className="my-4">
                                        <label className="inline-block ml-4 text-[10px] font-normal checkmark-container">
                                            <input 
                                                type="checkbox" 
                                                className=""    
                                                value={item.value}
                                                checked={formFields.permissions.includes(item.value)}
                                                onChange={e => {
                                                    if(e.target.checked) {
                                                        setFormFields(old => ({
                                                            ...old,
                                                            permissions: [
                                                                ...old.permissions,
                                                                e.target.value
                                                            ]
                                                        }))
                                                    } else {
                                                        const p = formFields.permissions.filter(p => p !== item.value)
                                                        setFormFields(old => ({
                                                            ...old,
                                                            permissions: p
                                                        }))
                                                    }
                                                }}
                                            />
                                            <span className="checkmark"></span>
                                            <span className="mt-[1px] inline-block">{item.label}</span>
                                        </label>
                                    </div>
                                ))
                            }
                        </div>

                        <Button 
                            text={'Create'}
                            style={{
                                fontSize: 'text-[14px]',
                                custom: 'py-2 px-16 mt-20'
                            }}
                        />
                    </form>
                </div>
            </div>
        </DashboardTemplate>
    );
}

export default AddRole;