import axios from "axios";
import { useContext } from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import AppImages from "../../../../assets/images";
import AuthContext from "../../../../contexts/AuthContext";
import ReactLoading from "react-loading";
import ListingTemplate from "../ListingTemplate";
// import ListingTemplate from "../ListingTemplate";
import { customerActions, customerThunk } from "../reducers/customer_slice";
import FirebaseService from "../../../../services/FirebaseService";

function UserCallRecord({ children }) {
  const navigate = useNavigate();
  // const customerReducer = useSelector((state) => state.customers);
  // const dispatch = useDispatch();

  const ths = [
    { title: "Name" },
    { title: "Total Calls" },
    { title: "View Report" },
  ];

  //   const [data, setData] = useState(customerReducer.data);
  //   const [showableData, setShowableData] = useState(data ?? []);
  // const [deleteableItem, setDeletableItem] = useState(null);
  // const [searchQuery, setSearchQuery] = useState(null);
  // const [statusFilter, setStatusFilter] = useState("all");
  const [userIds, setUserIds] = useState([]);
  const [fbStats, setFbStats] = useState([]);

  const authCtx = useContext(AuthContext);

  const [data, setData] = useState("");
  const { id } = useParams();

  let na = authCtx.user.sector;
  useEffect(() => {
    let na = authCtx.user.sector;
    let Id = authCtx.user.id;
    const fetchData = async () => {
      const result = await axios.get(
        `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/my-team?sector=${na}&id=${Id}`
      );
      setData(result.data);
      if (result.data.myteam?.length > 0) {
        const ids = result.data.myteam.map((user) => user.id);
        const d = {};
        for await (const id of ids) {
          const stats = await FirebaseService.getUserCallStats(id);
          d[id] = stats;
        //   setFbStats(stats);
        // console.log("Statsss",stats)
        }
console.log(d)
setFbStats(d)
        
      }
    };

    fetchData();
  }, []);

  console.log("Dataaa", fbStats);

  return (
    <ListingTemplate
      title={id}
      //   onSearch={handleOnSearch}
      selectBoxLabel="Select staff:"
      selectItems={[
        { text: "All", value: "all" },
        { text: "Active", value: "active" },
        { text: "Inactive", value: "inactive" },
      ]}
      //   onSelect={handleStatusFilter}
    >
      <div className="flex flex-row">
        {/* <div className="text-[24px] font-bold mt-3">Sector</div> */}
        <div className=" text-[15px] font-bold mt-5">{na}</div>
        <div className="ml-1 text-[15px] mt-5">/</div>
        {/* <div
          className="ml-1 mt-5 text-[15px] cursor-pointer"
          onClick={(e) => {
            navigate("/create/newteam");
          }}
        >
          Add Team member
        </div>
        <div className="ml-1 text-[15px] mt-5">/</div> */}

        <div className="ml-1 text-[15px]  text-gray-400 mt-5">
          Team Managment
        </div>
        <button
          className="w-[150px] my-4 h-[40px] ml-[700px] bg-[#14A800] text-[18px] rounded-md text-white hover:bg-slate-400 mt-4"
          onClick={() => navigate("/create/newteam")}
        >
          Add new
        </button>
      </div>
      <div className="text-[24px] font-bold">Team Members</div>
      <div className="w-[1000px] mt-1">
        The functionality that can be added to a team could include things like:
        Adding members Setting roles and permissions for members Sharing
        resources and content within the team, etc. Setting up workflows and
        processes specific to the team
      </div>

      <table className="w-full my-6">
        <thead>
          <tr>
            {ths.map((item, index) => (
              <th
                key={index}
                className={
                  authCtx.user.party === "PTI"
                    ? "text-[14px] text-white font-semibold border-b-[1px] border-[#2A2D4314] bg-[#59a23a] px-3 py-4 text-center"
                    : "text-[12px] font-semibold border-b-[1px] border-[#2A2D4314] bg-gray-100 px-3 py-4 text-center"
                }
              >
                {item.title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data ? (
            data.myteam?.map((item, index) => (
              <tr key={index} className="px-3">
                <td className="text-[12px] border-b-[1px] border-[#2A2D4314] py-4 text-center">
                  {item.name}
                </td>

                <td className="text-[12px] border-b-[1px] border-[#2A2D4314] py-4 w-[30%] text-center">
                  {fbStats && fbStats[item.id] >= 0 ? (
                    <div>{fbStats[item.id]}</div>
                  ) : (
                    "--"
                  )}
                </td>

                <td>
                  <Link
                    to="/team/member/callinfo"
                    state={{ member: item, path: "CallCenter" }}
                  >
                    <div className="bg-gray-500  p-2 cursor-pointer hover:bg-black w-[100px] mx-auto my-0">
                      <img
                        src={AppImages.eyeWhite}
                        className="w-[14px] mx-auto my-0"
                      />
                    </div>
                  </Link>
                </td>
              </tr>
            ))
          ) : (
            <div className="flex flex-row">
              <div className="flex justify-center">
                <ReactLoading
                  color={"gray"}
                  height={"20px"}
                  width={"40px"}
                  className="my-2"
                />
              </div>
            </div>
          )}
        </tbody>
      </table>
    </ListingTemplate>
  );
}

export default UserCallRecord;
