import axios from "axios";

import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import AuthContext from "../../../../contexts/AuthContext";
import Button from "../../../basic/Button";
import TextInputField from "../../../basic/TextInputField";
import ListingTemplate from "../ListingTemplate";


function AddVoter() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //   const authCtx = useContext(AuthContext);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const authCtx = useContext(AuthContext);
  let na = authCtx.user.sector;
  console.log("Authcontext", authCtx);
  const [formFields, setFormFields] = useState({
    name: "",
    blockCode: "",
    cnic: "",
    silsilaNumber: "",
    gharanaNumber: "",
    age: "",
    address:"",
    gender:"",
  });
  const [formErrors, setFormErrors] = useState({
    name: null,
    blockCode: null,
    cnic: null,
    silsilaNumber: null,
    gharanaNumber: null,
    age: null,
    address:null,
    gender: null,
    api: null,
  });
  const [showLoader, setShowLoader] = useState(false);
  const [showableData, setShowableData] = useState([]);
  const [data, setData] = useState("");
//   const [message, setMessage] = useState("");
  useEffect(() => {
    axios
      .get(
        `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/sectors-block-code?sector=${na}`
      )
      .then((res) => {
        console.log(res.data);
        if (res.data.status === 200) {
          //   setData(res.data.polling_scheme);
          setShowableData(res.data.block_code);
        }
      });
  }, []);
  console.log("ShowableData", showableData)
  const handleSubmit = async (e) => {
    setMessage('');
    setError('');
    e.preventDefault();
    console.log("FormFields", formFields);
    let na = authCtx.user.sector;
    let id = authCtx.user.id;
    let party = authCtx.user.party;
    // let packagee = authCtx.user.package;
    const result = await axios.post(
      `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/insert-new-voter`,
      {
          cnic: formFields.cnic,
          block_code: formFields.blockCode,
          sector:na,
          silsila_no:formFields.silsilaNumber,
          gharana_no: formFields.gharanaNumber,
          age: formFields.age,
          name: formFields.name,
          address: formFields.address,
          gender: formFields.gender
      }
    );
    
    console.log(
      "Resultttt",
      result.data.error,
      result.data.status,
      result.data.message
    );
    if(result.data.status === 400){
        setError(result.data.message)
    }
    else if(result.data.status === 200){
        setMessage("Data Added Successfully")
    }

    // setMessage(result.data.message);
    // console.log("Resultttttttttt", result);
    // setData(result.data);
  };
  console.log("Dataaaa", data);
  const selecttype = [
    {
      value: "1",
      text: "User",
    },
    {
      value: "2",
      text: "Worker",
    },
  ];
  return (
    // <AuthTemplate>
    <ListingTemplate>
      <div className="flex flex-row">
        {/* <div className="text-[18px] font-bold">Sector</div> */}
        <div className=" text-[15px] ">{na}</div>

        <div className="ml-1 text-[15px] ">/</div>
        <div className="ml-1 text-[15px]  text-gray-400">Add Voter</div>
      </div>
      <div className="text-[24px] font-bold">Add Voter</div>

      <form
        onSubmit={handleSubmit}
        className="max-w-md mx-auto my-5 gap-2 h-[70vh] justify-between flex flex-col"
      >
        <div>
          <TextInputField
            type={"text"}
            style={{
              width: "w-full",
              labelFontSize: "text-[20px]",
              inputFontSize: "text-[18px]",
            }}
            label="Name"
            onChange={(e) => {
              setFormErrors((old) => ({ ...old, name: null }));
              setFormFields((old) => ({ ...old, name: e?.target?.value }));
            }}
            value={formFields.name}
            error={formErrors.name}
            isRequired={true}
            placeholder={"Enter Name"}
          />
          {formErrors.api && (
            <p className="my-1 text-sm text-primary pl-4">{formErrors.api}</p>
          )}
          <TextInputField
            type={"text"}
            style={{
              width: "w-full",
              labelFontSize: "text-[20px]",
              inputFontSize: "text-[18px]",
            }}
            label="Address"
            onChange={(e) => {
              setFormErrors((old) => ({ ...old, address: null }));
              setFormFields((old) => ({ ...old, address: e?.target?.value }));
            }}
            value={formFields.address}
            error={formErrors.address}
            isRequired={true}
            placeholder={"Enter Address"}
          />
          {formErrors.api && (
            <p className="my-1 text-sm text-primary pl-4">{formErrors.api}</p>
          )}
          <TextInputField
            type={"text"}
            style={{
              width: "w-full",
              labelFontSize: "text-[20px]",
              inputFontSize: "text-[18px]",
            }}
            label="Cnic"
            onChange={(e) => {
              setFormErrors((old) => ({ ...old, cnic: null }));
              setFormFields((old) => ({ ...old, cnic: e?.target?.value }));
            }}
            value={formFields.cnic}
            error={formErrors.cnic}
            isRequired={true}
            placeholder={"Enter Cnic number"}
          />
          {formErrors.api && (
            <p className="my-1 text-sm text-primary pl-4">{formErrors.api}</p>
          )}
          <TextInputField
            type={"number"}
            style={{
              width: "w-full",
              labelFontSize: "text-[20px]",
              inputFontSize: "text-[18px]",
            }}
            label="BlockCode"
            onChange={(e) => {
              setFormErrors((old) => ({ ...old, blockCode: null }));
              setFormFields((old) => ({ ...old, blockCode: e?.target?.value }));
            }}
            value={formFields.blockCode}
            error={formErrors.blockCode}
            isRequired={true}
            placeholder={"Enter BlockCode number"}
          />
          {formErrors.api && (
            <p className="my-1 text-sm text-primary pl-4">{formErrors.api}</p>
          )}
          <TextInputField
            type={"number"}
            style={{
              width: "w-full",
              labelFontSize: "text-[20px]",
              inputFontSize: "text-[18px]",
            }}
            label="Silsila Number"
            onChange={(e) => {
              setFormErrors((old) => ({ ...old, silsilaNumber: null }));
              setFormFields((old) => ({
                ...old,
                silsilaNumber: e?.target?.value,
              }));
            }}
            value={formFields.silsilaNumber}
            error={formErrors.silsilaNumber}
            isRequired={true}
            placeholder={"Enter Sisila number"}
          />
          {formErrors.api && (
            <p className="my-1 text-sm text-primary pl-4">{formErrors.api}</p>
          )}
          <TextInputField
            type={"number"}
            style={{
              width: "w-full",
              labelFontSize: "text-[20px]",
              inputFontSize: "text-[18px]",
            }}
            label="Gharana Number"
            onChange={(e) => {
              setFormErrors((old) => ({ ...old, gharanaNumber: null }));
              setFormFields((old) => ({
                ...old,
                gharanaNumber: e?.target?.value,
              }));
            }}
            value={formFields.gharanaNumber}
            error={formErrors.gharanaNumber}
            isRequired={true}
            placeholder={"Enter Gharana number"}
          />
          {formErrors.api && (
            <p className="my-1 text-sm text-primary pl-4">{formErrors.api}</p>
          )}
          <TextInputField
            type={"text"}
            style={{
              width: "w-full",
              labelFontSize: "text-[20px]",
              inputFontSize: "text-[18px]",
            }}
            label="Gender"
            onChange={(e) => {
              setFormErrors((old) => ({ ...old, gender: null }));
              setFormFields((old) => ({
                ...old,
                gender: e?.target?.value,
              }));
            }}
            value={formFields.gender}
            error={formErrors.gender}
            isRequired={true}
            placeholder={"Enter gender"}
          />
          {formErrors.api && (
            <p className="my-1 text-sm text-primary pl-4">{formErrors.api}</p>
          )}
          <TextInputField
            type={"number"}
            style={{
              width: "w-full",
              labelFontSize: "text-[20px]",
              inputFontSize: "text-[18px]",
            }}
            label="Age"
            onChange={(e) => {
              setFormErrors((old) => ({ ...old, age: null }));
              setFormFields((old) => ({
                ...old,
                age: e?.target?.value,
              }));
            }}
            value={formFields.age}
            error={formErrors.age}
            isRequired={true}
            placeholder={"Enter Age number"}
          />
          {formErrors.api && (
            <p className="my-1 text-sm text-primary pl-4">{formErrors.api}</p>
          )}

          {/* <div className="text-[13px] font-bold font-sans2 pb-1">BlockCode</div>
          <select
            name=""
            id=""
            className=" px-2  border-[1px] border-black rounded-[6px] text-[14px] w-[100%] h-[40px]"
            onChange={(e) => {
              console.log("Type :", e.target.value);
              setFormFields((old) => ({ ...old, type: e.target.value }));
            }}
          >
            {showableData.map((item, index) => (
              <option key={index} value={item.block_code}>
                {item.block_code}
              </option>
            ))}
          </select> */}

          {error === "" ? (
            <div></div>
          ) : (
            <div className="text-red-500 font-bold">{error}</div>
          )}
          {message === "" ? (
            <div></div>
          ) : (
            <div className="text-green-500 font-bold">{message}</div>
          )}
          <button className="w-[40%] text-[18px] mt-2 h-[45px] float-right bg-slate-400 text-white rounded-md">
            Add new
          </button>
        </div>
      </form>
    </ListingTemplate>
    // </AuthTemplate>
  );
}

export default AddVoter;
