import axios from 'axios';
import React from 'react'
import { useContext } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import Chart from 'react-google-charts';
import { useNavigate } from 'react-router-dom';
import "./blink.css"
import AuthContext from '../../../../contexts/AuthContext';
import FirebaseService, { SEARCH_BY_BC_CNIC, SEARCH_BY_CNIC, SEARCH_BY_PHONE } from '../../../../services/FirebaseService';
import { Api } from '../../../../utils/api';
import { QueryClient, QueryCache, useQuery } from "react-query";
import ListingTemplate from '../ListingTemplate'
import ReactLoading from "react-loading";
import Graph from './Graph';
import Graph1 from './Graph1';
import { convertCompilerOptionsFromJson } from 'typescript';
import AppImages from '../../../../assets/images';


const SectorDashboard = () => {
    const navigate = useNavigate();
    const authCtx = useContext(AuthContext);
    // console.log("Authcontext", authCtx);
    // const [data, setData] = useState('')
    const [ageGraphData, setAgeGraphData] = useState(null)
    const [sevtf, setsevtf] = useState(null);
    const [tstff, settstff] = useState(null);
    const [sixab, setsixa] = useState(null);
    let na = authCtx.user.sector;
    const { isLoading, error, data } = useQuery("dashboardData", () =>
      fetch(
        `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/sectors?sector=${na}`
      ).then((res) => res.json())
    );
    console.log("Dataia",data)
    const chartData = [
      ["Task", "Hours per Day"],
      // ["Total Voters", data?.total_voter],
      ["Male Voters", data?.total_male_voter],
      ["Female Voters", data?.total_female_voter],
    ];

    // Options for the pie chart
    const chartOptions = {
      // backgroundColor: "#FEE2E2",
      titleTextStyle: {
        fontSize: 18, // Adjust the font size as needed
      },
      colors: ["#59a23a", "#cc0000"],
      title: "Gender Ratio",
      is3D: true,
    };

    useEffect(() => {
   

      // fetchData();
       FirebaseService.getLogsByTag({
         tag: "cnic_search_button",
         sector: authCtx.user.sector,
       }).then(res => {console.log("firebase response", res);});
    

      getDataAgeWise().then(res => {
        // console.log(res)
        if(res.status === 200) {
          const d = [];
          const ageMap = new Map();

          res.report.forEach((item) => {
            const numericAge = item.age.match(/\d+/)[0];
            const count = item.count;

            if (ageMap.has(numericAge)) {
              // If entry for this age already exists, update count only if it's higher
              const existingCount = ageMap.get(numericAge);
              if (count > existingCount) {
                ageMap.set(numericAge, count);
              }
            } else {
              // If entry for this age doesn't exist, add it to the map
              ageMap.set(numericAge, count);
            }
          });

          // Convert the map back to the desired array format
          ageMap.forEach((count, age) => {
            d.push([age, count]);
          });

          // Sort the result based on age
          d.sort((a, b) => a[0] - b[0]);

          setAgeGraphData(d);

        }
      })
    }, []);
    // console.log("RESData",data)
    // console.log("AgeDataGraph",ageGraphData)
    const totalvoters = data?.total_voter ;
    const addtv = totalvoters;
    const estimatedVoters = Math.round(addtv * 0.5);
    const totalestimate = Math.round(estimatedVoters * 0.51);
      // ?.toString()
      // .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    const getDataAgeWise = async () => {
      let res = await axios(
        `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/age-report?sector=${authCtx.user.sector}`
      );
      console.log("GetDataAgeWise = ", res.data);
      // Initialize counts for different age groups
      let age17to35Count = 0;
      let age36to55Count = 0;
      let age56AndAboveCount = 0;

      // Iterate through the array
      res.data.report.forEach((item) => {
        const age = parseInt(item.age, 10); // Convert age to integer for numerical comparison

        // Categorize counts based on age ranges
        if (age >= 17 && age <= 35) {
          age17to35Count += item.count;
        } else if (age >= 36 && age <= 55) {
          age36to55Count += item.count;
        } else {
          age56AndAboveCount += item.count;
        }
      });
       setsevtf(age17to35Count);
       settstff(age36to55Count);
       setsixa(age56AndAboveCount);
      // Display the counts for each age group
      console.log("Age 17-35 Count:", age17to35Count);
      console.log("Age 36-55 Count:", age36to55Count);
      console.log("Age 56 and Above Count:", age56AndAboveCount);
      return res.data;
    }
    

   const dataa = [
     ["Total", "Voters"],
     ["TotalVoters", addtv],
     ["Turnout",estimatedVoters ],
     ["TargettedVoters", totalestimate],
    //  ["Houston", 2312717],
    //  ["Phoenix", 1626078],
   ];
    const options = {
      // backgroundColor: "#FEE2E2",
      title: "Total Expected TurnOut",
      is3D: true,
      titleTextStyle: {
        fontSize: 18, // Adjust the font size as needed
      },
      colors: ["#FF5733", "#59a23a", "#33FF57"],
    };
     const Ageoptions = {
       // backgroundColor: "#FEE2E2",
       title: "Age Grouping",
      //  is3D: true,
       titleTextStyle: {
         fontSize: 18, // Adjust the font size as needed
       },
       pieHole: 0.4,
       colors: ["#FF5733", "#59a23a", "#33FF57"],
     };
    const ths = [
      { title: "ID" },
      { title: "Customer Name" },
      { title: "Email" },
      { title: "Phone Number" },
      { title: "City" },
      { title: "Country" },
      { title: "Status" },
      { title: "Actions" },
    ];
     const ths1 = [
       { title: "Product" },
       { title: "Sold" },
     ];
     const agewisedata = [
       ["Voters", "Value", { role: "style" }],
       ["Age 17 - 35", sevtf, "color: cae7ff"], // Adjust the color as needed
       ["Age 36 - 55", tstff, "color: ffd4cf"],
       ["Age 56+", sixab, "color: ffe8a1"], // Adjust the color as needed
     ];

     // Options for the chart
     const agewiseoptions = {
      //  title: "Segmented Bar Chart",
       legend: { position: "none" },
       bars: "horizontal",
       hAxis: {
         format: "",
         minValue: 0,
         maxValue: sevtf+sixab,
       },
     };
     
  return (
    <ListingTemplate>
      <div className="flex flex-col">
        {authCtx.user.party === "PTI" ? (
          <div className="flex flex-row space-x-2 h-[129px]">
            <div
              className="w-[33%] border-[1px] border-gray-200 rounded-md pb-2"
              onClick={(e) => {
                // navigate("/blockcode/list");
              }}
            >
              <div className="text-[16px] font-semibold mb-3 bg-[#59a23a] px-3 py-1 text-white">
                Block Code
              </div>
              <div className="h-[50%] flex pt-[1%] text-[16px] text-slate-500 font-semibold px-3">
                <div className="ml-1 text-[24px] font-bold text-[#111827]">
                  {data ? (
                    data.total_block_code
                  ) : (
                    <ReactLoading
                      color={"gray"}
                      height={"20px"}
                      width={"40px"}
                      className="my-2"
                    />
                  )}
                </div>
              </div>
              <div className="flex flex-row justify-between">
                <div className="ml-1 text-[14px] text-[#6B7280]">
                  Total Block Code
                </div>
                <div
                  className="text-blue-500 mr-3 font-semibold text-[15px]  mb-2  h-[30px] px-1 rounded-md cursor-pointer hover:text-gray-500"
                  onClick={(e) => {
                    navigate("/blockcode/list");
                  }}
                >
                  View Voters List
                </div>
              </div>
            </div>
            <div className="w-[33%] border-[1px] border-gray-200 rounded-md  ">
              <div className="text-[16px] font-semibold mb-3 bg-[#59a23a] px-3 py-1 text-white">
                Total Voters
              </div>
              {/* {authCtx.user.sector === "PP 207" ? (
              <div className="text-[24px] font-bold text-[#111827]">
                258,111
              </div>
            ) : ( */}
              <div className="text-[24px] font-bold text-[#111827] px-3">
                {data ? (
                  addtv.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                ) : (
                  <ReactLoading
                    color={"gray"}
                    height={"20px"}
                    width={"40px"}
                    className="my-2"
                  />
                )}
              </div>
              {/* )} */}
              {/* <div className="text-[24px] font-bold text-[#111827]">
              {data ? (
                addtv.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              ) : (
                <ReactLoading
                  color={"gray"}
                  height={"20px"}
                  width={"40px"}
                  className="my-2"
                />
              )}
            </div> */}
              <div className="text-[14px] text-[#6B7280] px-3">
                Total voters of this Halka
              </div>
              {/* {authCtx.user.sector == "PP 207" ||
            authCtx.user.sector == "PP 208" ? ( */}
              <div className="animate-blink text-[14px] text-green-400 px-3">
                Reconciliation...
              </div>
              {/* ) : (
              ""
            )} */}
            </div>
            <div
              className="w-[33%] border-[1px] border-gray-200 rounded-md "
              onClick={(e) => {
                // navigate("/blockcode/list");
              }}
            >
              <div className="text-[16px] font-semibold mb-3 bg-[#59a23a] px-3 py-1 text-white">
                Gharana
              </div>
              <div className="h-[50%] flex pt-[1%] text-[16px] text-[#111827] px-3 font-bold">
                {authCtx.user.sector}
              </div>
              {authCtx.user.sector === "Mathra" ? (
                <div className="flex flex-row">
                  <div className="ml-1 text-[14px] text-[#6B7280]">
                    Total gharana
                  </div>
                  <div
                    className="text-[#0000ff] text-[12px] ml-[130px] mb-2  h-[30px] px-1 rounded-md cursor-pointer hover:text-gray-500"
                    onClick={(e) => {
                      navigate("/blockcode/list1");
                    }}
                  >
                    View Report
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        ) : (
          <div className="flex flex-row space-x-2 h-[129px]">
            <div
              className="w-[33%] border-[1px] flex flex-row bg-white border-gray-200 rounded-md py-2 px-2"
              onClick={(e) => {
                // navigate("/blockcode/list");
              }}
            >
              <div className="w-[20%] flex justify-center items-center">
                <div className="bg-red-300 rounded-md">
                  <img
                    src={AppImages.dashboard}
                    className="w-[50px] p-2"
                    alt=""
                    srcset=""
                  />
                </div>
              </div>
              <div className="w-[80%]">
                <div className="text-[14px] font-semibold mb-3 text-[#111827]">
                  Block Code
                </div>
                <div className="h-[50%] flex pt-[1%] text-[16px] text-slate-500 font-semibold">
                  <div className="ml-1 text-[24px] font-bold text-[#111827]">
                    {data ? (
                      data.total_block_code
                    ) : (
                      <ReactLoading
                        color={"gray"}
                        height={"20px"}
                        width={"40px"}
                        className="my-2"
                      />
                    )}
                  </div>
                </div>
                <div className="flex flex-row justify-between">
                  <div className="ml-1 text-[14px] text-[#6B7280]">
                    Total Block Code
                  </div>
                  <div
                    className="text-[#0000ff] text-[12px]  mb-2  h-[30px] px-1 rounded-md cursor-pointer hover:text-gray-500"
                    onClick={(e) => {
                      navigate("/blockcode/list");
                    }}
                  >
                    View Report
                  </div>
                </div>
              </div>
            </div>
            <div className="w-[33%] flex flex-row border-[1px] bg-orange-100 border-gray-200 rounded-md py-2 px-3">
              <div className="w-[20%] flex justify-center items-center">
                <div className="bg-orange-300 rounded-md">
                  <img
                    src={AppImages.voter}
                    className="w-[50px] p-2"
                    alt=""
                    srcset=""
                  />
                </div>
              </div>
              <div className="w-[80%]">
                <div className="text-[14px] font-semibold mb-3 text-[#111827]">
                  Total Voters
                </div>
                <div className="text-[24px] font-bold text-[#111827]">
                  {data ? (
                    addtv.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  ) : (
                    <ReactLoading
                      color={"gray"}
                      height={"20px"}
                      width={"40px"}
                      className="my-2"
                    />
                  )}
                </div>

                <div className="text-[14px] text-[#6B7280]">
                  Total voters of this Halka
                </div>

                <div className="animate-blink text-[14px] text-[#276131]">
                  Reconciliation...
                </div>
              </div>
            </div>
            <div
              className="w-[33%] flex flex-row bg-blue-100 border-[1px] border-gray-200 rounded-md py-2 px-3"
              onClick={(e) => {
                // navigate("/blockcode/list");
              }}
            >
              <div className="w-[20%]  flex justify-center items-center">
                <div className="bg-blue-300 rounded-md">
                  <img
                    src={AppImages.newreport}
                    className="w-[50px] p-2"
                    alt=""
                    srcset=""
                  />
                </div>
              </div>
              <div className="w-[80%]">
                <div className="text-[14px] font-semibold mb-3 text-[#111827]">
                  Gharana
                </div>
                <div className="h-[50%] flex pt-[1%] text-[16px] text-[#111827] font-bold">
                  {authCtx.user.sector}
                </div>
                {authCtx.user.sector === "Mathra" ? (
                  <div className="flex flex-row">
                    <div className="ml-1 text-[14px] text-[#6B7280]">
                      Total gharana
                    </div>
                    <div
                      className="text-[#0000ff] text-[12px] ml-[130px] mb-2  h-[30px] px-1 rounded-md cursor-pointer hover:text-gray-500"
                      onClick={(e) => {
                        navigate("/blockcode/list1");
                      }}
                    >
                      View Report
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        )}

        <FBSearchCount />
        {authCtx.user.party === "PTI" ? (
          <>
            <div className="flex flex-row space-x-2 h-[129px] my-3">
              <div className="w-[33%] border-[1px] border-gray-200 rounded-md ">
                {" "}
                <div className="text-[14px] font-semibold bg-[#59a23a] mb-3 rounded-md py-2 px-3 text-white">
                  {" "}
                  Total Voters{" "}
                </div>{" "}
                {/* {authCtx.user.sector === "PP 207" ? ( <div className="text-[24px] font-bold text-[#111827]"> 258,111 </div> ) : ( */}{" "}
                <div className="text-[24px] font-bold px-3 text-[#111827]">
                  {" "}
                  {data ? (
                    addtv.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  ) : (
                    <ReactLoading
                      color={"gray"}
                      height={"20px"}
                      width={"40px"}
                      className="my-2"
                    />
                  )}{" "}
                </div>{" "}
                {/* )} */}{" "}
                <div className="text-[14px] px-3 text-[#6B7280]">
                  {" "}
                  Total voters of this Halka{" "}
                </div>{" "}
              </div>
              <div className="w-[33%] border-[1px] border-gray-200 rounded-md ">
                <div className="flex flex-row justify-between bg-[#59a23a] mb-3 rounded-md py-1 px-3">
                  <div className="text-[14px] font-semibold  text-white">
                    Expected Turnout
                  </div>
                  <div className="text-[13px] font-semibold mb-3 text-white">
                    50% of TotalVoters
                  </div>
                </div>
                <div className="text-[24px] font-bold text-[#111827] px-3">
                  {data ? (
                    Math.round(addtv * 0.5)
                      ?.toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  ) : (
                    <ReactLoading
                      color={"gray"}
                      height={"20px"}
                      width={"40px"}
                      className="my-2"
                    />
                  )}
                </div>
                <div className="text-[14px] text-[#6B7280] px-3">
                  Expected Turnout of this Halka
                </div>
              </div>
              <div className="w-[33%] border-[1px] border-gray-200 rounded-md ">
                <div className="flex flex-row justify-between bg-[#59a23a] mb-3 rounded-md py-1 px-3 ">
                  <div className="text-[14px] font-semibold mb-3 text-white">
                    Targetted Voters
                  </div>
                  <div className="text-[13px] font-semibold mb-3 text-white">
                    51% of expected Turnout
                  </div>
                </div>
                <div className="text-[24px] font-bold text-[#111827] px-3">
                  {data ? (
                    totalestimate
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  ) : (
                    <ReactLoading
                      color={"gray"}
                      height={"20px"}
                      width={"40px"}
                      className="my-2"
                    />
                  )}
                </div>
                <div className="text-[14px] text-[#6B7280] px-3">
                  Targetted voters of this Halka
                </div>
              </div>
            </div>
            <div className="w-full flex flx-row">
              <div className="w-[50%]">
                <Chart
                  chartType="PieChart"
                  width="100%"
                  height="400px"
                  // chartType="BarChart"
                  data={dataa}
                  options={options}
                />
              </div>
              <div className="w-[50%]">
                <Chart
                  chartType="PieChart"
                  width="100%"
                  height="400px"
                  data={chartData}
                  options={chartOptions}
                />
              </div>
            </div>
          </>
        ) : (
          ""
        )}
        {authCtx.user.sector === "electionexpert lc" ? (
          <div className="flex flex-row space-x-2 h-[129px]">
            <div
              className="w-[33%] border-[1px] border-gray-200 rounded-md py-2 px-3"
              onClick={(e) => {
                // navigate("/blockcode/list");
              }}
            >
              <div className="text-[14px] font-semibold mb-3 text-[#111827]">
                App User
              </div>
              <div className="h-[50%] flex pt-[1%] text-[16px] text-slate-500 font-semibold">
                <div className="ml-1 text-[24px] font-bold text-[#111827]">
                  0
                  {/* {data ? (
                  data.total_block_code
                ) : (
                  <ReactLoading
                    color={"gray"}
                    height={"20px"}
                    width={"40px"}
                    className="my-2"
                  />
                )} */}
                </div>
              </div>
              <div className="flex flex-row">
                <div className="ml-1 text-[14px] text-[#6B7280]">
                  Total App User of this sector
                </div>
                <div
                // className="text-[#0000ff] text-[12px] ml-[130px] mb-2  h-[30px] px-1 rounded-md cursor-pointer hover:text-gray-500"
                // onClick={(e) => {
                //   navigate("/blockcode/list");
                // }}
                >
                  {/* View Report */}
                </div>
              </div>
            </div>
            <div className="w-[33%] border-[1px] border-gray-200 rounded-md py-2 px-3">
              <div className="text-[14px] font-semibold mb-3 text-[#111827]">
                Marked Vote
              </div>
              <div className="text-[24px] font-bold text-[#111827]">
                {totalestimate}
                {/* {data ? (
                data.total_voter
                  ?.toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              ) : (
                <ReactLoading
                  color={"gray"}
                  height={"20px"}
                  width={"40px"}
                  className="my-2"
                />
              )} */}
              </div>
              <div className="text-[14px] text-[#6B7280]">
                Total marked vote of this Halka
              </div>
            </div>
            {/* <div
            className="w-[33%] border-[1px] border-gray-200 rounded-md py-2 px-3"
            onClick={(e) => {
              // navigate("/blockcode/list");
            }}
          >
            <div className="text-[14px] font-semibold mb-3 text-[#111827]">
              Gharana
            </div>
            <div className="h-[50%] flex pt-[1%] text-[16px] text-[#111827] font-bold">
              {authCtx.user.sector}
            </div>
            <div className="flex flex-row">
              <div className="ml-1 text-[14px] text-[#6B7280]">
                Total gharana
              </div>
              <div
                className="text-[#0000ff] text-[12px] ml-[130px] mb-2  h-[30px] px-1 rounded-md cursor-pointer hover:text-gray-500"
                onClick={(e) => {
                  navigate("/blockcode/list1");
                }}
              >
                View Report
              </div>
            </div>
          </div> */}
          </div>
        ) : null}

        {/* <div></div> */}

        {authCtx.user.party === "PTI" ? (
          <div className="flex flex-row space-x-2 my-2 h-[550px]">
            <div className="w-full ">
              <div className="flex flex-col text-[28px] font-bold mb-[35px] text-[#111827]">
                Age Segregation
              </div>
              <div>
                <div>
                  {ageGraphData && (
                    <>
                      <Chart
                        chartType="Bar"
                        data={[["Age", "Count"], ...ageGraphData]}
                        options={{
                          // title: "Age wise Voters",
                          hAxis: {
                            title: "Count",
                          },
                          vAxis: {
                            title: "Age",
                          },
                          colors: ["#59a23a"],
                        }}
                        width={"100%"}
                        height={"400px"}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex flex-row space-x-2 my-2 h-[550px]">
            <div className="w-[100%] border-[1px] border-gray-200 bg-orange-200 rounded-md px-2 pt-[20px]">
              <div className="flex flex-row text-[20px] ml-2 font-bold mb-5 text-black">
                <div className="bg-orange-400 rounded-md h-[40px]">
                  <img
                    src={AppImages.graph}
                    alt=""
                    srcset=""
                    className="p-2 h-[40px]"
                  />
                </div>
                <div className="ml-3 mt-2">Age Wise Voters</div>
              </div>
              <div>
                <div>
                  {ageGraphData && (
                    <>
                      <Chart
                        chartType="Bar"
                        data={[["Age", "Count"], ...ageGraphData]}
                        options={{
                          // title: "Age wise Voters",
                          hAxis: {
                            title: "Count",
                          },
                          vAxis: {
                            title: "Age",
                          },
                          colors: ["#ffc637"],
                        }}
                        width={"100%"}
                        height={"400px"}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {authCtx.user.party === "PTI" ? (
          <div>
            <div className="mb-2">
              {ageGraphData && (
                <>
                  <Chart
                    chartType="PieChart"
                    width="100%"
                    height="500px"
                    data={agewisedata}
                    options={Ageoptions}
                  />
                </>
              )}
            </div>
          </div>
        ) : (
          ""
        )}
        {authCtx.user.party === "PTI" ? (
          ""
        ) : (
          <div>
            {" "}
            <div className="flex flex-row space-x-2 h-[129px] my-3">
              <div className="w-[33%] border-[1px] bg-blue-100 border-gray-200 rounded-md ">
                {" "}
                <div className="text-[14px] font-semibold bg-blue-300 mb-3 rounded-md py-2 px-3 text-white">
                  {" "}
                  Total Male Voters :
                </div>{" "}
                {/* {authCtx.user.sector === "PP 207" ? ( <div className="text-[24px] font-bold text-[#111827]"> 258,111 </div> ) : ( */}{" "}
                <div className="text-[24px]  font-bold px-3 text-[#111827]">
                  {" "}
                  {data ? (
                    data?.total_male_voter
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  ) : (
                    <ReactLoading
                      color={"gray"}
                      height={"20px"}
                      width={"40px"}
                      className="my-2"
                    />
                  )}{" "}
                </div>{" "}
                {/* )} */}{" "}
                <div className="text-[14px] px-3 text-[#6B7280]">
                  {" "}
                  Total male voters of this Halka{" "}
                </div>{" "}
              </div>
              <div className="w-[33%] border-[1px] bg-white border-gray-200 rounded-md ">
                <div className="flex flex-row justify-between bg-red-400 mb-3 rounded-md py-1 px-3">
                  <div className="text-[14px] font-semibold  text-white">
                    Total Female Voters :
                  </div>
                </div>
                <div className="text-[24px] font-bold text-[#111827] px-3">
                  {data ? (
                    data?.total_female_voter
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  ) : (
                    <ReactLoading
                      color={"gray"}
                      height={"20px"}
                      width={"40px"}
                      className="my-2"
                    />
                  )}
                </div>
                <div className="text-[14px] text-[#6B7280] px-3">
                  Total female voters of this Halka
                </div>
              </div>
              <div className="w-[33%] border-[1px] bg-orange-100 border-gray-200 rounded-md ">
                <div className="flex flex-row justify-between bg-orange-300 mb-3 rounded-md py-1 px-3 ">
                  <div className="text-[14px] font-semibold mb-3 text-white">
                    Total Voters
                  </div>
                  {/* <div className="text-[13px] font-semibold mb-3 text-white">
                51% of expected Turnout
              </div> */}
                </div>
                <div className="text-[24px] font-bold text-[#111827] px-3">
                  {data ? (
                    // sixab.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    data?.total_voter
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  ) : (
                    <ReactLoading
                      color={"gray"}
                      height={"20px"}
                      width={"40px"}
                      className="my-2"
                    />
                  )}
                </div>
                <div className="text-[14px] text-[#6B7280] px-3">
                  Total voters of this Halka
                </div>
              </div>
            </div>
            <div className="w-full flex flex-row">
              <div className="w-[60%] border-[1px] border-gray-200 bg-blue-100 rounded-md px-2 pt-[20px]">
                <div className="flex flex-row text-[20px] ml-2 font-bold mb-5 text-black">
                  <div className="bg-blue-300 rounded-md h-[40px]">
                    <img
                      src={AppImages.graph}
                      alt=""
                      srcset=""
                      className="p-2 h-[40px]"
                    />
                  </div>
                  <div className="ml-3 mt-2">Age Wise Voters</div>
                </div>
                <div>
                  <div className="mb-2">
                    {ageGraphData && (
                      <>
                        <Chart
                          chartType="BarChart"
                          width="100%"
                          height="300px"
                          data={agewisedata}
                          options={agewiseoptions}
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="w-[40%] flex flex-col px-3">
                {/* <div className="flex flex-row space-x-2 h-[129px] my-3"> */}
                <div className=" border-[1px] bg-blue-100 mb-2 h-[120px] border-gray-200 rounded-md ">
                  {" "}
                  <div className="text-[14px] font-semibold bg-blue-300 mb-3 rounded-md py-2 px-3 text-white">
                    {" "}
                    Voters Age (17 - 35){" "}
                  </div>{" "}
                  {/* {authCtx.user.sector === "PP 207" ? ( <div className="text-[24px] font-bold text-[#111827]"> 258,111 </div> ) : ( */}{" "}
                  <div className="text-[24px]  font-bold px-3 text-[#111827]">
                    {" "}
                    {data ? (
                      sevtf
                    ) : (
                      <ReactLoading
                        color={"gray"}
                        height={"20px"}
                        width={"40px"}
                        className="my-2"
                      />
                    )}{" "}
                  </div>{" "}
                  {/* )} */}{" "}
                  <div className="text-[14px] px-3 text-[#6B7280]">
                    {" "}
                    Total voters of this Halka{" "}
                  </div>{" "}
                </div>
                <div className=" border-[1px] bg-white mb-2 h-[120px] border-gray-200 rounded-md ">
                  <div className="flex flex-row justify-between bg-red-400 mb-3 rounded-md py-1 px-3">
                    <div className="text-[14px] font-semibold  text-white">
                      Voters Age (36 - 55)
                    </div>
                  </div>
                  <div className="text-[24px] font-bold text-[#111827] px-3">
                    {data ? (
                      tstff
                    ) : (
                      <ReactLoading
                        color={"gray"}
                        height={"20px"}
                        width={"40px"}
                        className="my-2"
                      />
                    )}
                  </div>
                  <div className="text-[14px] text-[#6B7280] px-3">
                    Total voters of this Halka
                  </div>
                </div>
                <div className=" border-[1px] bg-orange-100 h-[120px] border-gray-200 rounded-md ">
                  <div className="flex flex-row justify-between bg-orange-300 mb-3 rounded-md py-1 px-3 ">
                    <div className="text-[14px] font-semibold mb-3 text-white">
                      Voters Age (55 +)
                    </div>
                    {/* <div className="text-[13px] font-semibold mb-3 text-white">
                51% of expected Turnout
              </div> */}
                  </div>
                  <div className="text-[24px] font-bold text-[#111827] px-3">
                    {data ? (
                      sixab
                    ) : (
                      <ReactLoading
                        color={"gray"}
                        height={"20px"}
                        width={"40px"}
                        className="my-2"
                      />
                    )}
                  </div>
                  <div className="text-[14px] text-[#6B7280] px-3">
                    Total voters of this Halka
                  </div>
                </div>
                {/* </div> */}
              </div>
            </div>
          </div>
        )}

        {authCtx.user.sector === "electionexpert lc" ? (
          <div className="w-full px-7 my-3 font-noori font-bold" dir="rtl">
            <div className="w-full flex flex-row">
              <div className="w-[40%] border-[1px] px-4 border-black bg-slate-200">
                گھرانہ ووٹرو کا خلاصہ
              </div>
              <div className="w-[15%] border-[1px] px-4 border-black bg-slate-200">
                گھرانہ
              </div>
              <div className="w-[15%] border-[1px] px-4 border-black bg-slate-200">
                مرد
              </div>
              <div className="w-[15%] border-[1px] px-4 border-black bg-slate-200">
                خواتین
              </div>
              <div className="w-[15%] border-[1px] px-4 border-black bg-slate-200">
                کل
              </div>
            </div>
            <div className="w-full flex flex-row">
              <div className="w-[40%] border-[1px] px-4 border-black">
                دس افراد یا اس سے زیادہ
              </div>
              {/* {data1.map((item, index) => (console.log("itemmmmmmmmm",item)))} */}
              <div className="w-[15%] border-[1px] px-4 border-black">5</div>
              <div className="w-[15%] border-[1px] px-4 border-black ">28</div>
              <div className="w-[15%] border-[1px] px-4 border-black ">27</div>
              <div className="w-[15%] border-[1px] px-4 border-black ">55</div>
            </div>
            <div className="w-full flex flex-row">
              <div className="w-[40%] border-[1px] px-4 border-black">
                5 سے 9 افراد
              </div>
              <div className="w-[15%] border-[1px] px-4 border-black">12</div>
              <div className="w-[15%] border-[1px] px-4 border-black ">44</div>
              <div className="w-[15%] border-[1px] px-4 border-black ">36</div>
              <div className="w-[15%] border-[1px] px-4 border-black ">80</div>
            </div>
            <div className="w-full flex flex-row">
              <div className="w-[40%] border-[1px] px-4 border-black">
                1 سے 4 افراد
              </div>
              <div className="w-[15%] border-[1px] px-4 border-black">192</div>
              <div className="w-[15%] border-[1px] px-4 border-black ">183</div>
              <div className="w-[15%] border-[1px] px-4 border-black ">170</div>
              <div className="w-[15%] border-[1px] px-4 border-black ">353</div>
            </div>
            <div className="w-full flex flex-row">
              <div className="w-[40%] border-[1px] px-4 border-black">کل</div>
              <div className="w-[15%] border-[1px] px-4 border-black">209</div>
              <div className="w-[15%] border-[1px] px-4 border-black ">241</div>
              <div className="w-[15%] border-[1px] px-4 border-black ">247</div>
              <div className="w-[15%] border-[1px] px-4 border-black ">488</div>
            </div>
          </div>
        ) : null}
        {authCtx.user.party === "PTI" ? (
          ""
        ) : (
          <>
            <div className="w-[100%] border-[1px] border-gray-200 bg-blue-200 rounded-md px-2 mt-2 pt-[20px]">
              <div className="flex flex-row text-[20px] ml-2 font-bold mb-5 text-black">
                <div className="bg-blue-500 rounded-md h-[40px]">
                  <img
                    src={AppImages.graph}
                    alt=""
                    srcset=""
                    className="p-2 h-[40px]"
                  />
                </div>
                <div className="ml-3 mt-2">Voters Expected Turnout</div>
              </div>
              <div className="mb-4">
                <Chart
                  width={"100%"}
                  height={"300px"}
                  chartType="BarChart"
                  data={dataa}
                  options={options}
                />
              </div>
            </div>
            <div className="flex flex-row space-x-2 h-[129px] my-3">
              <div className="w-[33%] border-[1px] bg-blue-100 border-gray-200 rounded-md ">
                {" "}
                <div className="text-[14px] font-semibold bg-blue-300 mb-3 rounded-md py-2 px-3 text-white">
                  {" "}
                  Total Voters{" "}
                </div>{" "}
                {/* {authCtx.user.sector === "PP 207" ? ( <div className="text-[24px] font-bold text-[#111827]"> 258,111 </div> ) : ( */}{" "}
                <div className="text-[24px]  font-bold px-3 text-[#111827]">
                  {" "}
                  {data ? (
                    addtv.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  ) : (
                    <ReactLoading
                      color={"gray"}
                      height={"20px"}
                      width={"40px"}
                      className="my-2"
                    />
                  )}{" "}
                </div>{" "}
                {/* )} */}{" "}
                <div className="text-[14px] px-3 text-[#6B7280]">
                  {" "}
                  Total voters of this Halka{" "}
                </div>{" "}
              </div>
              <div className="w-[33%] border-[1px] bg-white border-gray-200 rounded-md ">
                <div className="flex flex-row justify-between bg-red-400 mb-3 rounded-md py-1 px-3">
                  <div className="text-[14px] font-semibold  text-white">
                    Expected Turnout
                  </div>
                  <div className="text-[13px] font-semibold mb-3 text-white">
                    50% of TotalVoters
                  </div>
                </div>
                <div className="text-[24px] font-bold text-[#111827] px-3">
                  {data ? (
                    Math.round(addtv * 0.5)
                      ?.toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  ) : (
                    <ReactLoading
                      color={"gray"}
                      height={"20px"}
                      width={"40px"}
                      className="my-2"
                    />
                  )}
                </div>
                <div className="text-[14px] text-[#6B7280] px-3">
                  Expected Turnout of this Halka
                </div>
              </div>
              <div className="w-[33%] border-[1px] bg-orange-100 border-gray-200 rounded-md ">
                <div className="flex flex-row justify-between bg-orange-300 mb-3 rounded-md py-1 px-3 ">
                  <div className="text-[14px] font-semibold mb-3 text-white">
                    Targetted Voters
                  </div>
                  <div className="text-[13px] font-semibold mb-3 text-white">
                    51% of expected Turnout
                  </div>
                </div>
                <div className="text-[24px] font-bold text-[#111827] px-3">
                  {data ? (
                    totalestimate
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  ) : (
                    <ReactLoading
                      color={"gray"}
                      height={"20px"}
                      width={"40px"}
                      className="my-2"
                    />
                  )}
                </div>
                <div className="text-[14px] text-[#6B7280] px-3">
                  Targetted voters of this Halka
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </ListingTemplate>
  );
}
const FBSearchCount = () => {
  const [cnicSearchCount, setCnicSearchCount] = useState(0);
  const [phoneSearchCount, setPhoneSearchCount] = useState(0);
  const authCtx = useContext(AuthContext);
  let na = authCtx.user.sector;
  useEffect(() => {
    FirebaseService.getCountVoters().then((count) => {
      // console.log(
      const dataa =  count.forEach((doc) => console.log("Docss", doc.data()))
      return dataa;
      // );
    });
    FirebaseService.getCountByTag(SEARCH_BY_PHONE,na).then((count) => {
      setPhoneSearchCount(count);
    });
    FirebaseService.getCountByTag([SEARCH_BY_CNIC, SEARCH_BY_BC_CNIC],na).then(
      (count) => {
        setCnicSearchCount(count);
      }
    );
  }, []);
  return (
    <>
      {authCtx.user.party === "PTI" ? (
        <div className="flex flex-row space-x-2 h-[129px] my-4">
          <div className="w-[33%] border-[1px] border-gray-400 rounded-md ">
            <div className="text-[14px] font-semibold mb-3 text-white py-2 px-3 rounded-md bg-[#59a23a]">
              Total searches by phone
            </div>
            <div className="text-[24px] font-bold px-3 text-[#111827]">
              {cnicSearchCount + phoneSearchCount}
            </div>
            <div className="text-[14px] px-3 text-[#111827]">
              Total Searches (i.e. by CNIC, phone etc.)
            </div>
          </div>
          <div className="w-[33%] border-[1px] border-gray-400 rounded-md ">
            <div className="text-[14px] font-semibold mb-3 text-white py-2 px-3 rounded-md bg-[#59a23a]">
              Total Searches by CNIC
            </div>
            <div className="text-[24px] font-bold text-[#111827] px-3">
              {cnicSearchCount}
            </div>
            <div className="text-[14px] text-[#111827] px-3">
              Total searches by CNIC
            </div>
          </div>
          <div className="w-[33%] border-[1px] border-gray-400 rounded-md ">
            <div className="text-[14px] font-semibold mb-3 text-white py-2 px-3 rounded-md bg-[#59a23a]">
              Total Searches by Phone
            </div>
            <div className="text-[24px] font-bold px-3">{phoneSearchCount}</div>
            <div className="text-[14px] text-[#111827] px-3">
              Total searches by phone number
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-row space-x-2 h-[129px] my-4">
          <div className="w-[33%] flex flex-row border-[1px] bg-orange-100 border-gray-200 rounded-md py-2 px-3">
            <div className="w-[20%] flex justify-center items-center">
              <div className="bg-orange-300 rounded-md">
                <img
                  src={AppImages.phone}
                  className="w-[50px] p-2"
                  alt=""
                  srcset=""
                />
              </div>
            </div>
            <div className="w-[80%]">
              <div className="text-[14px] font-semibold mb-3 text-[#111827]">
                Total searches by phone
              </div>
              <div className="text-[24px] font-bold text-[#111827]">
                {cnicSearchCount + phoneSearchCount}
              </div>
              <div className="text-[14px] text-[#6B7280]">
                Total Searches (i.e. by CNIC, phone etc.)
              </div>
            </div>
          </div>
          <div className="w-[33%] flex flex-row border-[1px] bg-blue-100 border-gray-200 rounded-md py-2 px-3">
            <div className="w-[20%] flex justify-center items-center">
              <div className="bg-blue-300 rounded-md">
                <img
                  src={AppImages.card}
                  className="w-[50px] p-2"
                  alt=""
                  srcset=""
                />
              </div>
            </div>
            <div className="w-[80%]">
              <div className="text-[14px] font-semibold mb-3 text-[#111827]">
                Total Searches by CNIC
              </div>
              <div className="text-[24px] font-bold text-[#111827]">
                {cnicSearchCount}
              </div>
              <div className="text-[14px] text-[#6B7280]">
                Total searches by CNIC
              </div>
            </div>
          </div>
          <div className="w-[33%] flex flex-row border-[1px] bg-white border-gray-100 rounded-md py-2 px-3">
            <div className="w-[20%] flex justify-center items-center">
              <div className="bg-red-300 rounded-md">
                <img
                  src={AppImages.manphone}
                  className="w-[50px] p-1"
                  alt=""
                  srcset=""
                />
              </div>
            </div>
            <div className="w-[80%]">
              <div className="text-[14px] font-semibold mb-3 text-[#111827]">
                Total Searches by Phone
              </div>
              <div className="text-[24px] font-bold">{phoneSearchCount}</div>
              <div className="text-[14px] text-[#6B7280]">
                Total searches by phone number
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SectorDashboard