import axios from "axios";

import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import AuthContext from "../../../../contexts/AuthContext";
import Button from "../../../basic/Button";
import TextInputField from "../../../basic/TextInputField";
import ListingTemplate from "../ListingTemplate";
// import AppImages from "../../../assets/images";
// import AuthContext from "../../../contexts/AuthContext";
// import { Api } from "../../../utils/api";
// import { isEmail } from "../../../utils/helpers";
// import Button from "../../basic/Button";
// import TextInputField from "../../basic/TextInputField";
// import AuthTemplate from "../../common/AuthTemplate";
// import {
//   customerActions,
//   customerThunk,
// } from "../Organization/reducers/customer_slice";
// import { AuthApi } from "./api/AuthApi";

function PollingDayWorkForce() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //   const authCtx = useContext(AuthContext);
  const authCtx = useContext(AuthContext);
  let na = authCtx.user.sector;
  console.log("Authcontext", authCtx);
  const [formFields, setFormFields] = useState({
    name: "",
    email: "",
    phone: "",
    password: "",
    type: "",
  });
  const [formErrors, setFormErrors] = useState({
    name: null,
    email: null,
    phone: null,
    password: null,
    type: null,
    api: null,
  });
  const [showableData, setShowableData] = useState([]);
  const [data, setData] = useState("");
  useEffect(() => {
    axios
      .get(
        `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/geo-location-polling-station?sector=${authCtx.user?.sector}`
      )
      .then((res) => {
        console.log(res.data);
        if (res.data.status === 200) {
        //   setData(res.data.polling_scheme);
          setShowableData(res.data.polling_scheme);
        }
      });
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("FormFields", formFields);
    let na = authCtx.user.sector;
    let id = 1;
    let party = "PTI";
    const result = await axios.post(
      `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/create-team-member?sector=${na}&id=${id}&party=${party}`,
      {
        name: formFields.name,
        email: formFields.email,
        phone: formFields.phone,
        password: formFields.password,
        type: formFields.type,
      }
    );
    const url = `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/create-team-member?sector=${na}&id=${id}&party=${party}`;
    console.log("URL", url);
    console.log("Resultttttttttt", result);
    setData(result.data);
  };
  console.log("Dataaaa", data);

  const selectUnits = [
    {
      value: "1",
      text: "Allocation of voter mobilize",
    },
    {
      value: "2",
      text: " Allocation of agent for Polling Station",
    },
    {
      value: "3",
      text: " Kanal",
    },
  ];
  return (
    // <AuthTemplate>
    <ListingTemplate>
      <div className="flex flex-row">
        {/* <div className="text-[18px] font-bold">Sector</div> */}
        <div className=" text-[15px] ">{na}</div>
        <div className="ml-1 text-[15px] ">/</div>
        <div
          className="ml-1 text-[15px] cursor-pointer"
          onClick={(e) => {
            navigate("/pollingdayworkforce/list");
          }}
        >
          Polling Day Workforce list
        </div>
        <div className="ml-1 text-[15px] ">/</div>
        <div className="ml-1 text-[15px]  text-gray-400">
          Polling Day Workforce
        </div>
      </div>
      <div className="text-[24px] font-bold">
        Create Your Polling Day Workforce
      </div>
      <div className="w-[1000px] mt-1">
        The functionality that can be added to a team could include things like:
        Adding members Setting roles and permissions for members Sharing
        resources and content within the team, etc. Setting up workflows and
        processes specific to the team
      </div>
      <form
        onSubmit={handleSubmit}
        className="max-w-md mx-auto my-5 gap-2 h-[70vh] justify-between flex flex-col"
      >
        <div>
          <TextInputField
            type={"email"}
            style={{
              width: "w-full",
              labelFontSize: "text-[20px]",
              inputFontSize: "text-[18px]",
            }}
            label="Email"
            onChange={(e) => {
              setFormErrors((old) => ({ ...old, email: null }));
              setFormFields((old) => ({ ...old, email: e?.target?.value }));
            }}
            value={formFields.email}
            error={formErrors.email}
            isRequired={true}
            placeholder={"example@gmail.com"}
          />
          {formErrors.api && (
            <p className="my-1 text-sm text-primary pl-4">{formErrors.api}</p>
          )}
          <TextInputField
            type="password"
            style={{
              width: "w-full",
              labelFontSize: "text-[20px]",
              inputFontSize: "text-[18px]",
            }}
            label="Password"
            onChange={(e) => {
              setFormErrors((old) => ({ ...old, password: null }));
              setFormFields((old) => ({ ...old, password: e?.target.value }));
            }}
            value={formFields.password}
            error={formErrors.password}
            isRequired={true}
            placeholder={"********"}
          />
          <TextInputField
            type={"text"}
            style={{
              width: "w-full",
              labelFontSize: "text-[20px]",
              inputFontSize: "text-[18px]",
            }}
            label="Name"
            onChange={(e) => {
              setFormErrors((old) => ({ ...old, name: null }));
              setFormFields((old) => ({ ...old, name: e?.target?.value }));
            }}
            value={formFields.name}
            error={formErrors.name}
            isRequired={true}
            placeholder={"Enter Name"}
          />
          {formErrors.api && (
            <p className="my-1 text-sm text-primary pl-4">{formErrors.api}</p>
          )}
          <div className="text-[13px] font-bold font-sans2 pb-1">
            Polling Station
          </div>
          <select
            name=""
            id=""
            className=" px-2  border-[1px] border-black rounded-[6px] text-[14px] w-[100%] h-[40px]"
            onChange={(e) => {
              console.log("Unit :", e.target.value);
              setFormFields((old) => ({ ...old, unit: e.target.value }));
            }}
          >
            {showableData?.map((item, index) => (
              <option key={index} value={item.text}>
                {item.polling_station_area_urdu}
              </option>
            ))}
          </select>
          <TextInputField
            type={"number"}
            style={{
              width: "w-full",
              labelFontSize: "text-[20px]",
              inputFontSize: "text-[18px]",
            }}
            label="Phone"
            onChange={(e) => {
              setFormErrors((old) => ({ ...old, phone: null }));
              setFormFields((old) => ({ ...old, phone: e?.target?.value }));
            }}
            value={formFields.phone}
            error={formErrors.phone}
            isRequired={true}
            placeholder={"Enter Phone number"}
          />
          {formErrors.api && (
            <p className="my-1 text-sm text-primary pl-4">{formErrors.api}</p>
          )}
          <button className="w-[40%] text-[18px] h-[45px] float-right bg-slate-400 text-white rounded-md">
            Add new
          </button>
          {/* <Button
            text={"Add new"}
            style={{
              width: "w-[40%]",
              custom: "box-border text-[18px]",
              margin: "mt-[5px]",
            }}
            isLoading={showLoader}
          /> */}
        </div>
      </form>
    </ListingTemplate>
    // </AuthTemplate>
  );
}

export default PollingDayWorkForce;
