import axios from "axios";
import { useContext } from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import AppImages from "../../../../assets/images";
import AuthContext from "../../../../contexts/AuthContext";
import ReactLoading from "react-loading";
import ListingTemplate from "../ListingTemplate";
// import ListingTemplate from "../ListingTemplate";
import { customerActions, customerThunk } from "../reducers/customer_slice";
import FirebaseService from "../../../../services/FirebaseService";
import { id } from "date-fns/locale";
import DownloadPdf from "./Print";

function SystemGeneratedReport({ children }) {
  const navigate = useNavigate();
  // const customerReducer = useSelector((state) => state.customers);
  // const dispatch = useDispatch();

  const ths = [
    { title: "Name" },
    { title: "Total Calls" },
    { title: "View Report" },
  ];

  
  const [userIds, setUserIds] = useState([]);
  const [fbStats, setFbStats] = useState([]);

  const authCtx = useContext(AuthContext);

  const [data, setData] = useState([]);
  const [dataabove, setDataabove] = useState([]);
  const [databelow , setDatabelow] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  const { blockcode } = useParams();
const { fmnumber } = useParams();
const { cnic } = useParams();
  let na = authCtx.user.sector;
  useEffect(() => {
    let na = authCtx.user.sector;
    let Id = 1;
    const fetchData = async () => {
       setIsLoading(true);
       axios.get(
           `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/family-bogus-detail1?sector=${na}&bc=${blockcode}&cnic=${cnic}&fno=${fmnumber}`
         )
         .then((result) => {
          console.log("Resulttt",result)
           setData(result.data.family_tree);
           setDataabove(
             result.data.family_tree?.filter((item) => item.confidence >= 90)
           );
           setDatabelow(
             result.data.family_tree?.filter((item) => item.confidence < 56)
           );
         })
         .finally(() => {
           setIsLoading(false);
         });
      
    };

    fetchData();
  }, []);

    console.log("Dataaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa", data);
//   data?.filter((item)=>{
//     if (item.confidence > 56){
//         setDataabove(item)
//     }
//     else{
//         setDatabelow(item)
//     }
//   })
  console.log("Dataabove",dataabove)
  const today = new Date();
  const date = today.getDate();
  const month = today.getMonth() + 1; // Add 1 to the month because the month index starts at 0 (January)
  const year = today.getFullYear();

  return (
    <ListingTemplate
      //   title={id}
      //   onSearch={handleOnSearch}
      selectBoxLabel="Select staff:"
      selectItems={[
        { text: "All", value: "all" },
        { text: "Active", value: "active" },
        { text: "Inactive", value: "inactive" },
      ]}
      //   onSelect={handleStatusFilter}
    >
      <div id="nottoshowss">
        <DownloadPdf
          rootElementId={"pagetodownload"}
          show={"nottoshow"}
          shows={"nottoshows"}
          showss={"nottoshowss"}
        />
      </div>
      <div id="pagetodownload">
        <div className="flex flex-row">
          {/* <div className="text-[24px] font-bold mt-3">Sector</div> */}
          <div className=" text-[15px] font-bold mt-5">{na}</div>
          <div className="ml-1 text-[15px] mt-5">/</div>
          <div className="ml-1 text-[15px]  text-gray-400 mt-5">
            System Detected Verified Vote(s)
          </div>
          <div className="w-[150px] text-black my-4 h-[40px] ml-[610px] text-[15px] mt-4">
            {date + "/" + month + "/" + year}
          </div>
        </div>
        <div className="text-[24px] font-bold">
          System Detected Verified Vote(s)
        </div>
        <div className="flex flex-row my-3 text-[15px]">
          <div>Family no.</div>
          <div className="ml-2">{fmnumber}</div>
        </div>
        <div className="flex flex-row my-1 text-[15px]">
          <div>BlockCode:</div>
          <div className="ml-2">{blockcode}</div>
        </div>
        <div className="flex flex-row my-1 text-[15px]">
          <div>Constituency:</div>
          <div className="ml-2">{na}</div>
        </div>
        <div className="flex flex-row my-1 text-[15px]">
          <div>Accuracy: </div>
          <div className="ml-2">97 %</div>
        </div>
        <div className="my-2 text-[15px]">
          This information is compiled using an algorithm (System lexical
          relationship methodology) that considers the relationship between
          words and their meaning, as well as how often they appear in voter
          data. Afterwards, manual verification may be performed if confidence
          level is 100. Any confidence number less than 100 is a system
          calibrated number.
        </div>

        <table className="w-[100%] border-[1px] border-collapse font-noori">
          <thead>
            <tr>
              <th className="text-right border-[1px] border-[#000] p-[5px]">
                اعتماد
              </th>
              <th className="text-right border-[1px] border-[#000] p-[5px]">
                پتہ
              </th>

              <th className="text-right border-[1px] border-[#000] p-[5px]">
                شناختی کارڈ
              </th>
              <th className="text-right border-[1px] border-[#000] p-[5px]">
                صنف
              </th>
              <th className="text-right border-[1px] border-[#000] p-[5px]">
                عمر
              </th>
              <th className="text-right border-[1px] border-[#000] p-[5px]">
                نام
              </th>
              <th className="text-right border-[1px] border-[#000] p-[5px]">
                سیریل نمبر
              </th>
            </tr>
          </thead>

          <tbody>
            {!isLoading && dataabove ? (
              dataabove.map((item, index) => (
                <tr>
                  <td className="text-right p-[5px]">{item.confidence}</td>
                  <td className="text-right p-[5px]">{item.address}</td>
                  <td className="text-right p-[5px]">{item.cnic}</td>
                  <td className="text-right p-[5px]">{item.gender}</td>
                  <td className="text-right p-[5px]">{item.age}</td>
                  <td className="text-right p-[5px]">
                    {item.name.slice(0, 9).toString().replace(/[0-9]/gi, "")}
                  </td>
                  <td className="text-right p-[5px]">{item.silsila_no}</td>
                </tr>
              ))
            ) : (
              <div className="flex flex-row">
                <div className="flex justify-center">
                  <ReactLoading
                    color={"gray"}
                    height={"20px"}
                    width={"40px"}
                    className="my-1 ml-2"
                  />
                  <div className="mt-2 ml-2 font-bold ">
                    Data is Loading ...
                  </div>
                </div>
              </div>
            )}
          </tbody>
        </table>

        <div className="text-[24px] font-bold my-6">
          System Detected Suspicious Vote(s)
        </div>
        <table
          className="w-[100%] border-[1px] border-collapse font-noori"
          // style="width: 100%;border:1px solid;border-collapse: collapse"
        >
          <thead>
            <tr>
              <th className="text-right border-[1px] border-[#000] p-[5px]">
                اعتماد
              </th>
              <th className="text-right border-[1px] border-[#000] p-[5px]">
                پتہ
              </th>
              <th className="text-right border-[1px] border-[#000] p-[5px]">
                شناختی کارڈ
              </th>
              <th className="text-right border-[1px] border-[#000] p-[5px]">
                صنف
              </th>
              <th className="text-right border-[1px] border-[#000] p-[5px]">
                عمر
              </th>
              <th className="text-right border-[1px] border-[#000] p-[5px]">
                نام
              </th>
              <th className="text-right border-[1px] border-[#000] p-[5px]">
                سیریل نمبر
              </th>
            </tr>
          </thead>
          {
            // data
          }
          <tbody>
            {!isLoading && databelow ? (
              databelow.map((item, index) => (
                <tr>
                  <td className="text-right p-[5px]">{item.confidence}</td>
                  <td className="text-right p-[5px]">{item.address}</td>
                  <td className="text-right p-[5px]">{item.cnic}</td>
                  <td className="text-right p-[5px]">{item.gender}</td>
                  <td className="text-right p-[5px]">{item.age}</td>
                  <td className="text-right p-[5px]">
                    {item.name.slice(0, 9).toString().replace(/[0-9]/gi, "")}
                  </td>
                  <td className="text-right p-[5px]">{item.silsila_no}</td>
                </tr>
              ))
            ) : (
              <div className="flex flex-row">
                <div className="flex justify-center">
                  <ReactLoading
                    color={"gray"}
                    height={"20px"}
                    width={"40px"}
                    className="my-1 ml-2"
                  />
                  <div className="mt-2 ml-2 font-bold ">
                    Data is Loading ...
                  </div>
                </div>
              </div>
            )}
            {/* nee */}
          </tbody>
        </table>

        <div className="my-2 text-[15px]">
          The "Voter Family Verification" is a system-generated document that
          does not require further verification than is intended. If there is a
          discrepancy, please contact your account manager and assign a data
          verification agent to resolve the issue. This document is generated
          for the purpose of verifying that the individual voter family member
          is registered to vote as provided voter list by the candidate from
          Election Commission of Pakistan. System does not
          add/alter/modify/delete any information from the provided data on its
          own.
        </div>
      </div>
    </ListingTemplate>
  );
}

export default SystemGeneratedReport;
