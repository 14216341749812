function Dropdown({
    options = [],
    name = "",
    onChange,
    id,
    value = "",
    placeholder = "",
    style = {
        width: 'w-[500px]',
        selectStyle: '',
        labelStyle: '',
        wrapper: ''
    },
    label = null,
    isRequired = false,
    showAsRequired = false,
    error = null 
}) {
    // console.log('-------')
    // console.log(value)
    // console.log('-------')
    return ( 
        <div className={`${style.wrapper}`}>
            {label && <label htmlFor={id} className={`block my-2 pl-4 text-[12px] font-bold my-[2px] pl-0 ${style.labelStyle}`}>{label} {(isRequired && showAsRequired ? <span className="text-primary">*</span> : '')}</label>}
            <select 
                name={name} 
                id={id} 
                defaultValue={value?.toString()} 
                onChange={onChange}
                className={`p-3 border-[2px] border-solid border-black rounded-xl outline-primary text-black ${style.width} ${style.selectStyle} ${error ? 'border-primary' : ''}`}
            >
                {placeholder && <option>{placeholder}</option>}
                {options && options.map((option, index) => (
                    <option value={option.value.toString()} key={index}>{option.text}</option>
                ))}
            </select>
            {error && <p className="my-1 text-[12px] text-primary pl-4">{error}</p>}
        </div>
    );
}

export default Dropdown;