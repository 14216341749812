import AppImages from "../../assets/images";

export const ModuleData = [
  {
    id: 1,
    sidebar: {
      title: "Dashboard",
      icon: AppImages.ptidashboard,
      inactiveIcon: AppImages.ptidashboard,
    },
    route: "/",
    isAdminOnly: true,
    package: -1,
  },
  {
    id: 2,
    sidebar: {
      title: "Cnic Search",
      icon: AppImages.search,
      inactiveIcon: AppImages.search,
    },
    route: "/cnic/search",
    isAdminOnly: true,
    package: -1,
  },
  {
    id: 3,
    sidebar: {
      title: "Team Report",
      icon: AppImages.ptiteam,
      inactiveIcon: AppImages.ptiteam,
    },
    route: "/team/list",
    isAdminOnly: true,
    package: -1,
  },
  {
    id: 4,
    sidebar: {
      title: "Halka Report",
      icon: AppImages.ptireport,
      inactiveIcon: AppImages.ptireport,
    },
    route: "/sector",
    isAdminOnly: true,
    package: -1,
  },
  {
    id: 5,
    sidebar: {
      title: "Download",
      icon: AppImages.logout,
      inactiveIcon: AppImages.logout,
    },
    route: "/download/list",
    isAdminOnly: true,
    package: -1,
  },
  {
    id: 13,
    sidebar: {
      title: "Polling Team",
      icon: AppImages.ptiagent,
      inactiveIcon: AppImages.ptiagent,
    },
    route: "/pollingagent/list",
    isAdminOnly: true,
    package: -1,
  },
  {
    id: 14,
    sidebar: {
      title: "Call Report",
      icon: AppImages.pticall,
      inactiveIcon: AppImages.pticall,
    },
    route: "/team/call/record",
    isAdminOnly: true,
    package: -1,
  },

  // {
  //   id: 14,
  //   sidebar: {
  //     title: "DoorToDoor Records",
  //     icon: AppImages.user,
  //     inactiveIcon: AppImages.user,
  //   },
  //   route: "/team/doortodoor",
  //   isAdminOnly: true,
  //   package: -1,
  // },
  // {
  //   id: 97,
  //   sidebar: {
  //     title: "ERTM",
  //     icon: AppImages.polling_station,
  //     inactiveIcon: AppImages.polling_station,
  //   },
  //   submenu: [
  //     {
  //       id: 20,
  //       title: "Overview",
  //       icon: AppImages.polling_station,
  //       // route: "/blockcode/age/male",
  //     },
  //     {
  //       id: 21,
  //       title: "NA Results",
  //       icon: AppImages.polling_station,
  //       // route: "/blockcode/age/male",
  //     },
  //     {
  //       id: 22,
  //       title: "PA Results",
  //       icon: AppImages.polling_station,
  //       // route: "/blockcode/age/male",
  //     },
  //   ],
  //   isAdminOnly: true,
  //   package: -1,
  // },
  {
    id: 90,
    sidebar: {
      title: "Voters Reports",
      icon: AppImages.ptifamilyreport,
      inactiveIcon: AppImages.ptifamilyreport,
    },
    isAdminOnly: true,
    submenu: [
      //   {
      //     id: 90.1,
      //     title: "Age (Male)",
      //     icon: AppImages.polling_station,
      //     route: "/blockcode/age/male",
      //   },
      //   {
      //     id: 90.2,
      //     title: "Age (Female)",
      //     icon: AppImages.polling_station,
      //     route: "/blockcode/age/female",
      //   },
      {
        id: 90.1,
        title: "Block Code (Gender)",
        icon: AppImages.polling_station,
        route: "/male/female/report",
      },
      {
        id: 90.2,
        title: "Caste",
        icon: AppImages.polling_station,
        route: "/caste/report",
      },
      {
        id: 90.3,
        title: "Caste (Age Wise)",
        icon: AppImages.polling_station,
        route: "/castage/report",
      },
    ],
    package: -1,
  },
  {
    id: 9,
    sidebar: {
      title: "Male Voters",
      icon: AppImages.ptifamilyreport,
      inactiveIcon: AppImages.ptifamilyreport,
    },
    route: "/blockcode/age/male",
    // route: "/suspicious/votes",
    isAdminOnly: true,
    package: -1,
  },
  {
    id: 10,
    sidebar: {
      title: "Female Voters",
      icon: AppImages.ptifamilyreport,
      inactiveIcon: AppImages.ptifamilyreport,
    },
    route: "/blockcode/age/female",
    // route: "/suspicious/votes",
    isAdminOnly: true,
    package: -1,
  },
  // {
  //   id: 9,
  //   sidebar: {
  //     title: "Polling Station",
  //     icon: AppImages.polling_station,
  //     inactiveIcon: AppImages.polling_station,
  //   },
  //   route: "/polling-stations",
  //   // route: "/suspicious/votes",
  //   isAdminOnly: true,
  //   package: -1,
  // },
  // {
  //   id: 10,
  //   sidebar: {
  //     title: "Polling Day Dashboard",
  //     icon: AppImages.polling_station,
  //     inactiveIcon: AppImages.polling_station,
  //   },
  //   route: "/polling/dashboard",
  //   // route: "/suspicious/votes",
  //   isAdminOnly: true,
  //   package: -1,
  // },
  // {
  //   id: 12,
  //   sidebar: {
  //     title: "Compaign Mangment",
  //     icon: AppImages.polling_station,
  //     inactiveIcon: AppImages.polling_station,
  //   },
  //   route: "/campaign/managment",
  //   // route: "/suspicious/votes",
  //   isAdminOnly: true,
  //   // package: -1,
  // },

  {
    id: 17,
    sidebar: {
      title: "Transport Team",
      icon: AppImages.ptitransport,
      inactiveIcon: AppImages.ptitransport,
    },
    route: "/transportmanagment/list",
    // route: "/suspicious/votes",
    isAdminOnly: true,
    package: -1,
  },

  {
    id: 11,
    sidebar: {
      title: "Suspicious Votes",
      icon: AppImages.suspicious,
      inactiveIcon: AppImages.suspicious,
    },
    route: "/suspicious/votes",
    isAdminOnly: true,
    package: -1,
  },
  // {
  //   id: 98,
  //   sidebar: {
  //     title: "Map",
  //     icon: AppImages.ptimap,
  //     inactiveIcon: AppImages.ptimap,
  //   },
  //   route: "/map",
  //   isAdminOnly: true,
  //   package: -1,
  // },

  {
    id: 8,
    sidebar: {
      title: "Log out",
      icon: AppImages.logout,
      inactiveIcon: AppImages.logout,
    },
    // route: "/search/data",
    isAdminOnly: true,
    package: -1,
  },
];
