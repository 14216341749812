import axios from "axios";
import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import AppImages from "../../../../assets/images";
import AuthContext from "../../../../contexts/AuthContext";
import ReactLoading from "react-loading";
const Parchi1 = () => {
  const [data, setData] = useState("");
   const [d, sd] = useState("");
   const [f, sf] = useState("");
  const { id } = useParams();
  let Id = id;
  const authCtx = useContext(AuthContext);
  console.log("Authcontext", authCtx);
  useEffect(() => {
    let na = authCtx.user.sector;

    let page = 1;
    console.log("ID", Id, na);
    const fetchData = async () => {
      const result = await axios(
        `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/voter-list-blockCode?sector=${na}&page_no=${page}&block_code=${Id}`
      );
      console.log("resulttt", result.data);
      let filteredData = result.data.voters.sort((a, b) => {
        // If gharana_no is an empty string, move it to the bottom
        if (a.gharana_no === "" || a.gharana_no === null) return 1;
        if (b.gharana_no === "" || b.gharana_no === null) return -1;

        // Otherwise, sort based on gharana_no
        return a.gharana_no - b.gharana_no;
      });
      setData(filteredData);
    };

    fetchData();
    const fetchData1 = async () => {
      const resultt = await axios(
        `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/get-polling-scheme?bc=${Id}&gender=male`
      );
      console.log("resulttt123", resultt.data.data.scheme);
      sd(resultt.data.data.scheme);
    };

    fetchData1();

    const fetchData2 = async () => {
      const resultt = await axios(
        `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/get-polling-scheme?bc=${Id}&gender=female`
      );
      console.log("result000", resultt.data.data.scheme);
      sf(resultt.data.data.scheme);
    };

    fetchData2();
  }, []);
  console.log("Dataaa", data);

  const handlePrint = () => {
    window.print();
  };
    function ImageSlice({ item }) {
      var yIncr = 0;
      if (item?.rowY) {
          if (authCtx.user.sector === "PP 172") {
            if (
              // item.block_code === 185570302 ||
              // item.block_code === 185560303 ||
              // item.block_code === 185570402
              item.meta === "100"
            ) {
              yIncr = -10;
            } else {
              if (item?.name === "" || item?.address === "") {
                yIncr = -40;
              } else {
                yIncr = 65;
              }
            }
          } else if (["PP 208", "PP 14"].includes(authCtx.user.sector)) {
            if (item?.name === "" || item?.address === "") {
              yIncr = -40;
            } else {
              yIncr = 65;
            }
          } 
          // else if (["PP 207"].includes(authCtx.user.sector)) {
          //   if (item?.name === "" || item?.address === "") {
          //     yIncr = -30;
          //   } else {
          //     yIncr = 70;
          //   }
          // }
           else if (["NA 120"].includes(authCtx.user.sector)) {
            yIncr = 50;
          } else if (["NA 127"].includes(authCtx.user.sector)) {
            yIncr = -10;
          } else if (["PP 59"].includes(authCtx.user.sector)) {
            yIncr = -20;
          } else {
            yIncr = -10;
          }
      }

      return (
        <>
          {authCtx.user.sector === "PP 152" ? (
            <img
              src={`https://res.cloudinary.com/election-experts/image/fetch/c_crop,h_55,x_0,w_4000,y_${
                parseInt(item?.rowY) + yIncr
              }/${encodeURIComponent(item?.url)}`}
              alt=""
              srcSet=""
              className=" w-full h-[40px]"
            />
          ) : (
            <img
              src={`https://res.cloudinary.com/election-experts/image/fetch/c_crop,h_80,x_0,w_4000,y_${
                parseInt(item?.rowY) + yIncr
              }/${encodeURIComponent(item?.url)}`}
              alt=""
              srcSet=""
              className=" w-full h-[40px]"
            />
          )}
        </>
      );
    }
  // let url =
  //   "https://res.cloudinary.com/election-experts/image/fetch/c_crop,h_160,x_0,w_4000,y_957/https%3A%2F%2Ffirebasestorage.googleapis.com%2Fv0%2Fb%2Fone-call-59851.appspot.com%2Fo%2FElections%252Fpunjab%252F111010102%252Fmale_first_file%252F1703017495796.jpg%3Falt%3Dmedia%26token%3D19569247-d28c-40b2-820e-4f20d3343e68";
  return (
    <div>
      <div className="flex flex-row mb-[70px] ">
        <Link to={`/download/list`}>
          <div className="rounded-md h-[25px] text-center text-yellow-50 my-1 w-[90px] bg-slate-400 hover:bg-slate-600 text-[18px] ml-3 font-bold cursor-pointer">
            Back
          </div>
        </Link>
        <div
          className="rounded-md h-[25px] text-center text-yellow-50 my-1 w-[90px] bg-slate-400 hover:bg-slate-600 text-[18px] ml-3 font-bold cursor-pointer"
          onClick={handlePrint}
        >
          Print
        </div>
      </div>
      {/* <div className=" flex flex-col w-full border-[1px] border-dotted border-black mb-[60px] "></div> */}
      {/* <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          // margin: "1px",
        }}
        className="w-[85%] font-noori my-2 ml-[60px] border-[3px] border-black mb-[20px] bg-gray-200"
      >
        <div
          className=" flex flex-row w-full border-b-[3px] border-gray-500"
          dir="rtl"
        >
          <div
            className="w-[25%] border-l-[0.5px] border-gray-500"
            style={{
              fontWeight: "bold",
              marginRight: "5px",
              fontSize: 15,
            }}
          >
            حلقہ
          </div>
          <div
            style={{
              // fontWeight: "bold",
              marginRight: "5px",
              // fontSize: 15,
            }}
          >
            {authCtx.user.sector}
          </div>
        </div>
        <div className=" flex flex-row w-full" dir="rtl">
          <div
            className="w-[25%] border-l-[1px] border-gray-500"
            style={{
              fontWeight: "bold",
              marginRight: "5px",
              fontSize: 15,
            }}
          >
            بلاک کوڈ
          </div>
          <div
            style={{
              // fontWeight: "bold",
              marginRight: "5px",
              // fontSize: 15,
            }}
          >
            {Id}
          </div>
        </div>
      </div> */}
      {data ? (
        data?.map((item, index) => (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              // margin: "1px",
            }}
            className="w-[85%] h-[138px] font-noori my-[6px] ml-[60px] border-[1px] border-black"
            key={index}
          >
            <div className="flex flex-row w-full">
              {/* <div className="w-[20%] flex flex-row justify-center">
                <img src={AppImages.logo} alt="image" className="h-[100px]" />
              </div> */}
              <div className="w-full flex flex-col" dir="rtl">
                {/* <div className="w-full flex flex-row">
                  <div
                    className="flex flex-row w-[50%]   px-[25px] py-[2px] border-[0.5px] border-gray-500"
                    dir="rtl"
                  >
                    <div
                      style={{
                        fontWeight: "bold",
                        marginRight: "5px",
                       
                      }}
                      className="text-[15px]"
                    >
                      نام :
                    </div>
                    <div className=" mr-2 text-[15px] font-bold">
                      {authCtx.user.sector === "PP 207" ? (
                        <img
                          src={`https://res.cloudinary.com/election-experts/image/fetch/c_crop,h_60,x_1200,w_195,y_${
                            parseInt(item?.rowY) + 85
                          }/${encodeURIComponent(item?.url)}`}
                          alt=""
                          srcSet=""
                          className="h-[30px]"
                        />
                      ) : (
                        <img
                          src={`https://res.cloudinary.com/election-experts/image/fetch/c_crop,h_60,x_1750,w_265,y_${
                            parseInt(item?.rowY) + 65
                          }/${encodeURIComponent(item?.url)}`}
                          alt=""
                          srcSet=""
                          className="h-[23px]"
                        />
                      )}
                      
                    </div>
                  </div>
                  <div
                    className="flex flex-row w-[50%]   px-[25px] py-[2px] border-[0.5px] border-gray-500"
                    dir="rtl"
                  >
                    <div
                      style={{
                        fontWeight: "bold",
                        marginRight: "5px",
                       
                      }}
                      className="text-[15px]"
                    >
                      والد کا نام :
                    </div>
                    <div className=" mr-2 text-[15px] font-bold">
                      {authCtx.user.sector === "PP 207" ? (
                        <img
                          src={`https://res.cloudinary.com/election-experts/image/fetch/c_crop,h_60,x_900,w_240,y_${
                            parseInt(item?.rowY) + 85
                          }/${encodeURIComponent(item?.url)}`}
                          alt=""
                          srcSet=""
                          className=" h-[30px]"
                        />
                      ) : (
                        <img
                          src={`https://res.cloudinary.com/election-experts/image/fetch/c_crop,h_60,x_1450,w_240,y_${
                            parseInt(item?.rowY) + 65
                          }/${encodeURIComponent(item?.url)}`}
                          alt=""
                          srcSet=""
                          className="h-[23px]"
                        />
                      )}
                     
                    </div>
                  </div>
                </div> */}
                {/* authCtx.user.sector == "NA 127" || */}
                {authCtx.user.sector == "PP 161" ? (
                  <div
                    className="flex flex-row text-[15px] w-full  px-[25px] py-[5px] border-[0.5px] border-gray-500"
                    dir="rtl"
                  >
                    <div
                      style={{
                        fontWeight: "bold",
                        marginRight: "5px",
                        fontSize: 15,
                      }}
                    >
                      نام :
                    </div>
                    <div className=" mr-2">
                      {item.name?.length > 44
                        ? item.name
                            ?.replace(/[^\W]/gi, "")
                            .replace(/[-*]/g, " ")
                            .substr(0, 44) + ".."
                        : item.name
                            ?.replace(/[^\W]/gi, "")
                            .replace(/[-*]/g, " ")}
                    </div>
                  </div>
                ) : (
                  <div
                    className="flex flex-row py-[2px] border-[0.5px] border-gray-500 overflow-hidden"
                    dir="rtl"
                  >
                    <>
                      {item?.type === "manual" ? (
                        <div
                          className="px-1 flex flex-row text-[18px]  py-2"
                          dir="rtl"
                        >
                          <div className="font-bold text-right font-noori">
                            نام
                          </div>
                          <div className="font-noori mr-4">
                            {/* {data2?.response?.name} */}
                            {
                              item?.name?.length > 44
                                ? item?.name
                                    ?.replace(/[^\W]/gi, "")
                                    .replace(/[-*]/g, " ")
                                    // .replace(
                                    //   new RegExp(
                                    //     wordsToReplace.join("|"),
                                    //     "gi"
                                    //   ),
                                    // ""
                                    // )
                                    .substr(0, 44) + ""
                                : item?.name
                                    ?.replace(/[^\W]/gi, "")
                                    .replace(/[-*]/g, " ")
                              // .replace(
                              //   new RegExp(
                              //     wordsToReplace.join("|"),
                              //     "gi"
                              //   ),
                              //   ""
                              // )
                            }
                          </div>
                        </div>
                      ) : (
                        <ImageSlice item={item} />
                      )}
                    </>
                    {/* {authCtx.user.sector === "PP 207" ? (
                    <img
                      src={`https://res.cloudinary.com/election-experts/image/fetch/c_crop,h_80,x_0,w_4000,y_${
                        parseInt(item?.rowY) + 70
                      }/${encodeURIComponent(item?.url)}`}
                      alt=""
                      srcSet=""
                      className=" w-[1000px]"
                    />
                  ) : (
                    <img
                      src={`https://res.cloudinary.com/election-experts/image/fetch/c_crop,h_100,x_0,w_4000,y_${
                        parseInt(item?.rowY) + 50
                      }/${encodeURIComponent(item?.url)}`}
                      alt=""
                      srcSet=""
                      className="w-[1000px]"
                    />
                  )} */}
                  </div>
                )}
                <div
                  style={{
                    direction: "rtl",
                    justifyContent: "space-around",
                    display: "flex",
                  }}
                >
                  <div className="flex flex-row border-[0.5px]  w-[25%] border-gray-500 py-[2px] ">
                    <div
                      style={{
                        fontWeight: "bold",
                        paddingRight: "25px",
                        // fontSize: 15,
                      }}
                      className="text-[15px]"
                    >
                      سلسلہ نمبر :
                    </div>

                    <div className=" text-[15px]  font-[7px]">
                      {item.silsila_no}
                    </div>
                  </div>
                  <div className="flex flex-row border-[0.5px]  py-[2px] w-[25%]  border-gray-500  ">
                    <div
                      style={{
                        fontWeight: "bold",
                        paddingRight: "12px",
                        // fontSize: 10,
                      }}
                      className="text-[15px]"
                    >
                      گھرانہ نمبر :
                    </div>
                    <div className=" font-[7px] mr-2 text-[15px]">
                      {item.gharana_no}
                    </div>
                  </div>
                  <div className="flex flex-row border-[0.5px]  w-[25%]  border-gray-500 py-[2px] ">
                    <div
                      style={{
                        fontWeight: "bold",
                        paddingRight: "12px",
                        // fontSize: 10,
                      }}
                      className="text-[15px]"
                    >
                      شناختی کارڈ:
                    </div>
                    <div className=" font-[7px] mr-2 text-[15px]">
                      {item.cnic}
                    </div>
                  </div>
                  <div className="flex flex-row border-[0.5px] w-[25%]   border-gray-500 py-[2px] ">
                    <div
                      style={{
                        fontWeight: "bold",
                        paddingRight: "12px",
                        // fontSize: 10,
                      }}
                      className="text-[15px]"
                    >
                      بلاک کوڈ :
                    </div>
                    <div className="font-[7px] text-[15px] mr-2">
                      {item.block_code}
                    </div>
                  </div>
                </div>
                {/* <div
                  style={{
                    direction: "rtl",
                    justifyContent: "space-around",
                    display: "flex",
                  }}
                > */}
                {/* <div className="flex flex-row border-[0.5px] w-[50%] border-gray-500 py-[2px]">
                    <div
                      style={{
                        fontWeight: "bold",
                        paddingRight: "15px",
                        fontSize: 15,
                      }}
                    >
                      شماریاتی کوڈ نمبر :
                    </div>
                    <div className=" font-[11px] mr-2">{item.block_code}</div>
                  </div>
                  <div className="flex flex-row border-[0.5px] w-[50%] border-gray-500 py-[2px]">
                    <div
                      style={{
                        fontWeight: "bold",
                        paddingRight: "15px",
                        fontSize: 15,
                      }}
                    >
                      شناختی کارڈ نمبر :
                    </div>
                    <div className="font-[11px] mr-2">{item.cnic}</div>
                  </div> */}
              </div>
            </div>
            {/* </div> */}
            {/* {authCtx.user.package !== "1" ? (
              <div
                className="flex flex-row  w-full  px-[25px] py-[2px] border-[0.5px] border-gray-500"
                dir="rtl"
              >
                <div
                  style={{
                    fontWeight: "bold",
                    // paddingRight: "7px",
                    fontSize: 15,
                  }}
                  className="text-[14px]"
                >
                  پتہ :
                </div>
                <div className=" font-[11px] text-[12px] mr-2 flex flex-row">
                  {item.address?.length > 100
                    ? item.address?.replace(/سال/gi, "").substr(0, 100) + ".."
                    : item.address?.replace(/سال/gi, "")}
               
                </div>
              </div>
            ) : (
              <div></div>
            )} */}
            {/* <div
              className="flex flex-row w-full  px-[25px] py-[2px] border-[0.5px] border-gray-500"
              dir="rtl"
            >
              <div
                style={{
                  fontWeight: "bold",
                  // paddingRight: "7px",
                  fontSize: 15,
                }}
                className="text-[14px]"
              >
                فون نمبر :
              </div>
              <div className="font-[11px] text-[14px] mr-2 flex flex-row">
                {item.voter_phone
                  ? JSON.parse(item.voter_phone)
                      .filter(
                        (item, index, self) => self.indexOf(item) === index
                      )
                      .map((phone) => (
                        <div className=" flex flex-row"> , 0{phone} </div>
                      ))
                  : "--"}
              </div>
            </div> */}
            <div
              className="flex flex-row w-full  px-[25px] py-[4px] border-[0.5px] border-gray-500"
              dir="rtl"
            >
              <div
                style={{
                  fontWeight: "bold",
                  // paddingRight: "7px",
                  fontSize: 15,
                }}
                className="text-[14px]"
              >
                پولنگ سٹیشن نمبر :
              </div>
              <div className="font-[11px] text-[14px] mr-2">
                {item.block_code}
              </div>
            </div>
            {item.gender === "male" ? (
              <div
                className="flex flex-row w-full  px-[25px] py-[4px] border-[0.5px] border-gray-500"
                dir="rtl"
              >
                <div
                  style={{
                    fontWeight: "bold",
                    paddingRight: "7px",
                    fontSize: 15,
                  }}
                >
                  پولنگ سٹیشن :
                </div>
                <div className="font-[11px] mr-2">
                  {d?.polling_station_area_urdu}
                </div>
              </div>
            ) : item.gender === "female" ? (
              <div
                className="flex flex-row w-full  px-[25px] py-[4px] border-[0.5px] border-gray-500"
                dir="rtl"
              >
                <div
                  style={{
                    fontWeight: "bold",
                    paddingRight: "7px",
                    fontSize: 15,
                  }}
                >
                  پولنگ سٹیشن :
                </div>
                <div className="font-[11px] mr-2">
                  {f?.polling_station_area_urdu}
                </div>
              </div>
            ) : (
              ""
            )}
            {/* <div
              className="flex flex-row w-full  px-[25px] py-[2px] border-[0.5px] border-gray-500"
              dir="rtl"
            >
              <div
                style={{
                  fontWeight: "bold",
                  // paddingRight: "7px",
                  fontSize: 15,
                }}
                className="text-[14px]"
              >
                پولنگ سٹیشن :
              </div>
              <div className="font-[11px] text-[14px] mr-2">
                {item?.polling_station.polling_station_area_urdu}
              </div>
            </div> */}
          </div>
        ))
      ) : (
        <ReactLoading
          color={"gray"}
          height={"40px"}
          width={"70px"}
          className="my-2 justify-center"
        />
      )}
    </div>
  );
};

export default Parchi1;
