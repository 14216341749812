import axios from "axios";
import { useContext } from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import AppImages from "../../../../assets/images";
import AuthContext from "../../../../contexts/AuthContext";
import ReactLoading from "react-loading";
import ListingTemplate from "../ListingTemplate";
// import ListingTemplate from "../ListingTemplate";
import { customerActions, customerThunk } from "../reducers/customer_slice";

function GharanoFamilyTree({ children }) {
  const navigate = useNavigate();
  const customerReducer = useSelector((state) => state.customers);
  const dispatch = useDispatch();

  const ths = [
    { title: "BlockCode" },
    { title: "Cnic" },
    { title: "Sector" },
    { title: "Confidence" },
    { title: "Tasdeeq" },
  ];

 
  const authCtx = useContext(AuthContext);
  console.log("Authcontext", authCtx);
  const [data, setData] = useState([]);
  const { id } = useParams();
  const {gharanaNo} = useParams()
  let page = 1;
  let na = authCtx.user.sector;
  useEffect(() => {
    let na = authCtx.user.sector;
    let Id = 1;
    // let page = 1;

    const fetchData = async () => {
      console.log("ID", Id, na);
      const result = await axios.get(
        `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/family/detaill?block_code=${id}&gharana_no=${gharanaNo}&sector=${na}`
      );
     
      console.log("RESData", result);
      setData(result.data.query);
      setCnic(result.data.query[0].cnic);
      // return [result.data.query]
    };

    fetchData();
  }, []);
  console.log("Darta", data);
  const [state, setState] = useState("");
  const [cnic,setCnic]=useState("")

  const tdClasses = "text-[12px] border-b-[1px] border-[#2A2D4314] py-4";
  const handleClickOnStatusBtn = async (item, newStatus) => {
    setState(newStatus);
    const result = await axios.get(
      `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/team-member-status?id=${item.id}&status=${newStatus}`
    );
    console.log("Result", result);
  };

  return (
    <ListingTemplate
      title={id}
      //   onSearch={handleOnSearch}
      selectBoxLabel="Select staff:"
      selectItems={[
        { text: "All", value: "all" },
        { text: "Active", value: "active" },
        { text: "Inactive", value: "inactive" },
      ]}
      //   onSelect={handleStatusFilter}
    >
      <div className="flex flex-row">
        {/* <div className="text-[24px] font-bold mt-3">Sector</div> */}
        <div className=" text-[15px] font-bold mt-5">{na}</div>
        <div className="ml-1 text-[15px] mt-5">/</div>
        {/* <div
          className="ml-1 mt-5 text-[15px] cursor-pointer"
          onClick={(e) => {
            navigate("/create/newteam");
          }}
        >
          Add Team member
        </div>
        <div className="ml-1 text-[15px] mt-5">/</div> */}

        <div className="ml-1 text-[15px]  text-gray-400 mt-5">
          Gharna Detail
        </div>

        {/* <Button
          text={"Add new"}
          style={{
            width: "w-[150px]",
            custom: "box-border text-[18px]",
            margin: " ml-[65%]",
          }}
          onClick={() => {
            navigate("/create/newteam");
          }}
        /> */}
      </div>
      <div className="text-[24px] font-bold"> Gharna Detail</div>
      <div className="w-[1000px] mt-1">
        The gharan detail is a detail of voter family number by which we can see
        the family tree of that voter or check that whether this voter is
        suspiciou or not
      </div>
      {/* <Link to={`/system/generated/report/${id}/${cnic}/${gharanaNo}`}>
        <div className="h-[30px] my-2 bg-slate-100 text-[12px] w-[200px] px-1 bg-slate-400 font-bold rounded-md py-1 pl-2 hover:bg-slate-200 hover:cursor-pointer hover:text-white">
          View System Generated Report
        </div>
      </Link> */}
      <table className="w-full my-6">
        <thead>
          <tr>
            {ths.map((item, index) => (
              <th
                key={index}
                className="text-[12px] font-semibold border-b-[1px] border-[#2A2D4314] bg-gray-100 px-3 py-4 text-left"
              >
                {item.title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data ? (
            data?.map((item, index) => (
              <tr key={index} className="px-3">
                <td className="text-[12px] border-b-[1px] border-[#2A2D4314] py-4 w-[170px]">
                  {item.block_code}
                </td>
                <td className="text-[12px] border-b-[1px] border-[#2A2D4314] py-4 w-[170px]">
                  {item.cnic}
                </td>
                <td className="text-[12px] border-b-[1px] border-[#2A2D4314] py-4 w-[170px]">
                  {item.sector}
                </td>
                <td className="text-[12px] border-b-[1px] border-[#2A2D4314] py-4 w-[200px]">
                  {item.confidence}
                </td>
                {/* <td className="text-left space-y-2 text-[18px] my-2 ">
                  
                </td> */}
                <td className="text-left space-y-2 text-[18px] my-2 ">
                  <Link
                    to={`/such/ghalt/tasdeeq/${id}/${item.cnic}/${gharanaNo}`}
                  >
                    <div className="h-[30px] bg-slate-100 text-[12px] w-[70px] rounded-md font-bold pt-1 pl-2 hover:bg-slate-400 hover:cursor-pointer hover:text-white">
                      Tasdeeq
                    </div>
                  </Link>
                </td>
                <td className="text-[12px] py-4 w-[200px]">
                  {/* {item.confidence} */}
                </td>
              </tr>
            ))
          ) : (
            <div className="flex flex-row">
              <div className="flex justify-center">
                <ReactLoading
                  color={"gray"}
                  height={"20px"}
                  width={"40px"}
                  className="my-2"
                />
              </div>
            </div>
          )}
        </tbody>
      </table>
    </ListingTemplate>
  );
}

export default GharanoFamilyTree;
