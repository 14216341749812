import { useEffect, useState } from 'react';
import AppImages from '../../../../../assets/images';
import Button from '../../../../basic/Button';
import Dropdown from '../../../../basic/Dropdown';

// Mocked values for start and end time.
const MockedTimeValues = [
  {
    value: '00:00',
    text: '00:00',
  },
  { value: '00:30', text: '00:30' },
  { value: '01:00', text: '01:00' },
  { value: '01:30', text: '01:30' },
  { value: '02:00', text: '02:00' },
  { value: '02:30', text: '02:30' },
  { value: '03:00', text: '03:00' },
  { value: '03:30', text: '03:30' },
  { value: '04:00', text: '04:00' },
  { value: '04:30', text: '04:30' },
  { value: '05:00', text: '05:00' },
  { value: '05:30', text: '05:30' },
  { value: '06:00', text: '06:00' },
  { value: '06:30', text: '06:30' },
  { value: '07:00', text: '07:00' },
  { value: '07:30', text: '07:30' },
  { value: '08:00', text: '08:00' },
  { value: '08:30', text: '08:30' },
  { value: '09:00', text: '09:00' },
  { value: '09:30', text: '09:30' },
  { value: '10:00', text: '10:00' },
  { value: '10:30', text: '10:30' },
  { value: '11:00', text: '11:00' },
  { value: '11:30', text: '11:30' },
  { value: '12:00', text: '12:00' },
  { value: '12:30', text: '12:30' },
  { value: '13:00', text: '13:00' },
  { value: '13:30', text: '13:30' },
  { value: '14:00', text: '14:00' },
  { value: '14:30', text: '14:30' },
  { value: '15:00', text: '15:00' },
  { value: '15:30', text: '15:30' },
  { value: '16:00', text: '16:00' },
  { value: '16:30', text: '16:30' },
  { value: '17:00', text: '17:00' },
  { value: '17:30', text: '17:30' },
  { value: '18:00', text: '18:00' },
  { value: '18:30', text: '18:30' },
  { value: '19:00', text: '19:00' },
  { value: '19:30', text: '19:30' },
  { value: '20:00', text: '20:00' },
  { value: '20:30', text: '20:30' },
  { value: '21:00', text: '21:00' },
  { value: '21:30', text: '21:30' },
  { value: '22:00', text: '22:00' },
  { value: '22:30', text: '22:30' },
  { value: '23:00', text: '23:00' },
  { value: '23:30', text: '23:30' },
];

const AddSchedule = ({
  open = false,
  onClose,
  dropdownPlaceholder,
  dropdownData,
  onSchedule,
}) => {
  const [selectedId, setSelectedId] = useState();
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();

  const toggleScrollBehavior = toggle => {
    const body = document.querySelector('body');
    if (toggle) {
      body.style.overflow = 'hidden';
    } else {
      body.style.overflow = 'auto';
    }
  };

  // Disable scroll when modal is open
  useEffect(() => {
    toggleScrollBehavior(open);
  }, [open]);

  const onScheduleTime = () => {
    const selectedValue = dropdownData.find(data => data.value === +selectedId);
    if (!selectedValue || !startTime || !endTime) {
      return;
    }
    toggleScrollBehavior(false);
    onSchedule({
      id: selectedValue.value,
      startTime,
      endTime,
    });
  };

  const onHandleClose = () => {
    toggleScrollBehavior(false);
    onClose();
  };

  return (
    <>
      <div
        className="bg-black opacity-50 fixed w-full h-full top-0 left-0 z-30"
        onClick={onHandleClose}
      />
      <div className="w-[600px] h-[360px] flex justify-center fixed z-40 left-0 right-0 top-0 bottom-0 m-auto bg-white rounded-3xl">
        <img
          onClick={onHandleClose}
          src={AppImages.crossBlack}
          alt="Close"
          className="w-[14px] absolute top-6 right-3 cursor-pointer"
        />
        <div className="w-[350px] flex flex-col justify-center">
          <h1 className="text-center font-bold">Add Schedule</h1>
          <div className="mt-7 flex flex-col justify-center">
            <span className="text-[10px] mb-1 font-bold">
              {dropdownPlaceholder}
            </span>
            <Dropdown
              style={{
                width: 'w-full',
                text: 'text-[10px]',
                margin: 'mb-5',
                radius: 'rounded-md',
              }}
              placeholder={dropdownPlaceholder}
              options={dropdownData}
              id="employee-dropdown"
              value={selectedId}
              onChange={e => setSelectedId(e.target.value)}
            />
            <span className="text-[10px] mb-1 font-bold">Select time</span>
            <div className="flex justify-between">
              <Dropdown
                style={{
                  width: 'w-[172px]',
                  text: 'text-[10px]',
                  radius: 'rounded-md',
                }}
                placeholder="Start time"
                options={MockedTimeValues}
                id="start-dropdown"
                value={startTime}
                onChange={e => setStartTime(e.target.value)}
              />
              <Dropdown
                style={{
                  width: 'w-[172px]',
                  text: 'text-[10px]',
                  radius: 'rounded-md',
                }}
                placeholder="End time"
                options={MockedTimeValues}
                id="end-dropdown"
                value={endTime}
                onChange={e => setEndTime(e.target.value)}
              />
            </div>
            <Button
              text="Add Schedule"
              style={{
                width: 'w-[250px]',
                margin: 'mt-12',
                padding: 'py-3',
                fontSize: 'text-xs',
                display: 'self-center',
                rounded: 'rounded-md',
              }}
              onClick={onScheduleTime}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AddSchedule;
