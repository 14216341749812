import React from 'react'
import AppImages from '../../../assets/images';

const Custom = () => {
  return (
    <div className="h-screen w-full flex flex-col items-center justify-center bg-gradient-to-r from-[#d42323]  to-[#375d27]">
      <div>
        <img src={AppImages.pppelection} alt="" className="w-[400px]" />
      </div>
      <a href="https://www.pppelections.co/login/TkEgMTI3">
        <div className="text-black text-[25px] mt-7 font-semibold cursor-pointer hover:text-gray-400">
          Please Click Here to move on Portal
        </div>
      </a>
    </div>
  );
}

export default Custom