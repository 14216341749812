import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import LoginScreen from "./components/modules/Auth/Login";
import Parchi from "./components/modules/Organization/Sector/Parchi";

import PrivateRouteWrapper from "./components/common/PrivateRouteWrapper";
import AdminRouteWrapper from "./components/common/AdminRouteWrapper";
import PublicRouteWrapper from "./components/common/PublicRouteWrapper";
import EditProfile from "./components/modules/Profile/EditProfile";
import ViewProfile from "./components/modules/Profile/ViewProfile";
import ListRoles from "./components/modules/RolesAndPermissions/ListRoles";
import AddRole from "./components/modules/RolesAndPermissions/AddRole";
import EditRole from "./components/modules/RolesAndPermissions/EditRoles";
import ViewRole from "./components/modules/RolesAndPermissions/ViewRole";

import ScheduleContent from "./components/modules/Organization/Schedule/Schedule";
import Sector from "./components/modules/Organization/Sector/Sector";
import Sectorlisting from "./components/modules/Organization/Sector/Sectorlisting";
import Totalvoterlisting from "./components/modules/Organization/Sector/Totalvoterlisting";
import Totalmenlisting from "./components/modules/Organization/Sector/Totalmenlisting";
import Totalwomenlisting from "./components/modules/Organization/Sector/Totalwomenlisting";
import Graph from "./components/modules/Organization/Sector/Graph";
import SectorDashboard from "./components/modules/Organization/Sector/SectorDashboard";
import BlockCodelisting from "./components/modules/Organization/Sector/BlockCodelisting";
import BlockCodeVoterslisting from "./components/modules/Organization/Sector/BlockCodeVoterslisting";
import DataSearch from "./components/modules/Organization/Sector/DataSearch";
import CnicDataSearch from "./components/modules/Organization/Sector/CnicDataSearch";
import PhoneDataSearch from "./components/modules/Organization/Sector/PhoneDataSearch";
import FamilyListing from "./components/modules/Organization/Sector/FamilyListing";
import VoterList, {
  VoterListWrapper,
} from "./components/modules/Organization/Sector/VoterList";

import CreateNewTeam from "./components/modules/Organization/Sector/CreateNewTeam";
import Team from "./components/modules/Organization/Sector/Team";
import Search from "./components/modules/Organization/Sector/Search";
import BlockCodeCnicSearch from "./components/modules/Organization/Sector/BlockCodeCnicSearch";
import MemberDetail from "./components/modules/Organization/Sector/Team/MemberDetail";
import PollingStation from "./components/modules/Organization/Sector/PollingStation";
import Suspiciousvotes from "./components/modules/Organization/Sector/Suspiciousvotes";
import ReportByBlockCodeAgeFemale from "./components/modules/Organization/Sector/Report/ReportByBlockCodeAgeFemale";
import ReportByBlockCodeAgeMale from "./components/modules/Organization/Sector/Report/ReportByBlockCodeAgeMale";
import ReportBySectorMaleFemale from "./components/modules/Organization/Sector/Report/ReportBySectorMaleFemale";
import ReportByBlockCodeandCaste from "./components/modules/Organization/Sector/Report/ReportByBlockCodeandCaste";
import Electionteam from "./components/modules/Organization/Sector/Electionteam";
import Teamtype from "./components/modules/Organization/Sector/Teamtype";
import PollingDayWorkForce from "./components/modules/Organization/Sector/PollingDayWorkForce";
import PollingDayWorkForceList from "./components/modules/Organization/Sector/PollingDayWorkForceList";
import TransportManagment from "./components/modules/Organization/Sector/TransportManagement";
import TransportManagmentList from "./components/modules/Organization/Sector/TransportManagmentList";
import CastsAgeReport from "./components/modules/Organization/Sector/CastsAgeReport";
import MemberCnicSearch from "./components/modules/Organization/Sector/Team/MemberCnicSearch";
import MemberFamilyTreeSearch from "./components/modules/Organization/Sector/Team/MemberFamilyTreeSearch";
import MemberShareVoterInfo from "./components/modules/Organization/Sector/Team/MemberShareVoterInfo";
import MemberShareVoterCallCenter from "./components/modules/Organization/Sector/Team/MemberShareVoterCallCenter";
import TeamMemberRecord from "./components/modules/Organization/Sector/TeamMemberRecord";
import MemberGenerateVoterInfo from "./components/modules/Organization/Sector/Team/MemberGenerateVoterInfo";
import MemberShareFamilyParchi from "./components/modules/Organization/Sector/Team/MemberShareFamilyParchi";
import PersonalRecord from "./components/modules/Organization/Sector/PersonalRecord";
import MapScreen from "./components/modules/Organization/Sector/MapScreen";
import MemberAppOpen from "./components/modules/Organization/Sector/Team/MemberAppOpen";
import MemberWebLogin from "./components/modules/Organization/Sector/Team/MemberWebLogin";
import PollingDayDashboard from "./components/modules/Organization/Sector/PollingDayDashboard";
import GeoTracking from "./components/modules/Organization/Sector/Team/GeoTracking";
import UserCallRecord from "./components/modules/Organization/Sector/UserCallRecord";
import Googlemap from "./components/modules/Organization/Sector/Googlemap";
import ReactQuery from "./components/modules/Organization/Sector/ReactQuery";
import SuspiciousBlockCodeVoterListing from "./components/modules/Organization/Sector/SuspiciousBlockCodeVoterListing";
import GharanoFamilyTree from "./components/modules/Organization/Sector/GharananoFamilyTree";
import SystemGenratedReport from "./components/modules/Organization/Sector/SystemGenratedReport";
import SuchGhaltkiTasdeeq from "./components/modules/Organization/Sector/SuchGhaltkiTasdeeq";
import ConfidenceBlockCodeVoterList from "./components/modules/Organization/Sector/ConfidenceBlockCodeVoterList";
import Form42 from "./components/modules/Organization/Sector/Form42";
import MemberPhoneSearch from "./components/modules/Organization/Sector/MemberPhoneSearch";
import Notfound from "./components/modules/Auth/Notfound";
import NonLogin from "./components/modules/Auth/NonLogin";
import { useContext } from "react";
import AuthContext from "./contexts/AuthContext";
import Package4Route from "./components/common/Package4RouteWrapper";
import Package4RouteWrapper from "./components/common/Package4RouteWrapper";
import Package3RouteWrapper from "./components/common/Package3RouteWrapper";
import Route404 from "./components/modules/Organization/Sector/Route404";
import MemberDoorToDoor from "./components/modules/Organization/Sector/Team/MemberDoorToDoor";
import UserDoorToDoorRecord from "./components/modules/Organization/Sector/UserDoorToDoorRecord";
import FamilyListCount from "./components/modules/Organization/Sector/FamilyListCount";
import BlockCodelisting1 from "./components/modules/Organization/Sector/BlockCodelisting1";
import Parchi1 from "./components/modules/Organization/Sector/Parchi1";
import AddressSearch from "./components/modules/Organization/Sector/AddressSearch";
import GharanaVoterList from "./components/modules/Organization/Sector/GharanaVoterList";
import DownloadListing from "./components/modules/Organization/Sector/DownloadListing";
import AddPollingAgent from "./components/modules/Organization/Sector/AddPollingAgent";
import PollingAgent from "./components/modules/Organization/Sector/PollingAgent";
import UpdatePollingAgent from "./components/modules/Organization/Sector/UpdatePollingAgent";
import AddPollingScheme from "./components/modules/Organization/Sector/AddPollingScheme";
import PhoneNumberVoterList from "./components/modules/Organization/Sector/PhoneNumberVoterList";
import KhanVoterList from "./components/modules/Organization/Sector/KhanVoterList";
import MinorityVoterList from "./components/modules/Organization/Sector/MinorityVoterList";
// import ExampleVoterList from "./components/modules/Organization/Sector/ExampleVoterList";
import ReactGA from "react-ga"
import AddVoter from "./components/modules/Organization/Sector/AddVoter";
import Spriteslicing from "./components/modules/Organization/Sector/Spriteslicing";
import Parchi120 from "./components/modules/Organization/Sector/Parchi120";
import PaidVoters from "./components/modules/Organization/Sector/PaidVoters";
import VoterReport from "./components/modules/Organization/Sector/VoterReport";
import NewList from "./components/modules/Organization/Sector/NewList";
import Parchilink from "./components/modules/Organization/Sector/Parchilink";
import UniqueParchi from "./components/modules/Organization/Sector/UniqueParchi";

const TRACKING_ID = "279104382";
ReactGA.initialize(TRACKING_ID)
function App() {
  const authCtx = useContext(AuthContext);
  console.log("Authcontext", authCtx);
  return (
    <BrowserRouter basename="/">
      <Routes>
        {/* <Route element={<PublicRouteWrapper />}> */}
        <Route component={<Notfound />} />
        <Route exact path="/login/:sector" element={<LoginScreen />} />
        <Route exact path="/login" element={<NonLogin />} />
        <Route
          exact
          path="/parchilink/:sector/:id/:cnic/:fmnumber"
          element={<Parchilink />}
        />

        <Route element={<PrivateRouteWrapper />}>
          <Route exact path="/parchi/:id" element={<Parchi />} />
          <Route exact path="/parchi1/:id" element={<Parchi1 />} />
          <Route exact path="/parchi120/:id" element={<Parchi120 />} />
          <Route exact path="/unique/:id" element={<UniqueParchi />} />

          <Route element={<AdminRouteWrapper />}>
            <Route exact path="/" element={<SectorDashboard />} />
            <Route exact path="/404" element={<Route404 />} />
            <Route exact path="/graph" element={<Graph />} />
            <Route exact path="/list" element={<NewList />} />
            <Route exact path="/ss" element={<Spriteslicing />} />
            <Route exact path="/profile/view" element={<ViewProfile />} />
            <Route exact path="/profile/edit" element={<EditProfile />} />
            <Route exact path="/form/electionteam" element={<Electionteam />} />

            <Route exact path="/electionteam/list" element={<Teamtype />} />
            <Route exact path="/react/query" element={<ReactQuery />} />
            <Route
              exact
              path="/form/pollingdayworkforce"
              element={<PollingDayWorkForce />}
            />
            <Route
              exact
              path="/pollingdayworkforce/list"
              element={<PollingDayWorkForceList />}
            />
            <Route
              exact
              path="/form/transportmanagment"
              element={<TransportManagment />}
            />
            <Route
              exact
              path="/transportmanagment/list"
              element={<TransportManagmentList />}
            />
            <Route exact path="/sector" element={<Sector />} />
            <Route exact path="/cnic/search" element={<CnicDataSearch />} />
            <Route exact path="/phone/search" element={<PhoneDataSearch />} />

            <Route exact path="/sector/list" element={<Sectorlisting />} />

            {/* <Route element={<Package3RouteWrapper />}> */}
            <Route
              exact
              path="/blockcode/age/male"
              element={<ReportByBlockCodeAgeMale />}
            />
            <Route
              exact
              path="/blockcode/age/female"
              element={<ReportByBlockCodeAgeFemale />}
            />
            <Route
              exact
              path="/male/female/report"
              element={<ReportBySectorMaleFemale />}
            />
            <Route
              exact
              path="/add/pollingagent"
              element={<AddPollingAgent />}
            />
            <Route
              exact
              path="/caste/report"
              element={<ReportByBlockCodeandCaste />}
            />
            <Route exact path="/castage/report" element={<CastsAgeReport />} />
            {/* </Route> */}
            <Route
              exact
              path="/family/list/:sector/:blockcode/:cnic/:fmnumber"
              element={<FamilyListing />}
            />
            <Route
              exact
              path="/polling-stations"
              element={<PollingStation />}
            />
            <Route exact path="/map" element={<MapScreen />} />
            <Route
              exact
              path="/voter/list/:blockcode"
              element={<VoterList />}
            />
            <Route
              exact
              path="/khanvoter/list/:blockcode"
              element={<KhanVoterList />}
            />
            <Route
              exact
              path="/minority/list/:blockcode"
              element={<MinorityVoterList />}
            />
            <Route
              exact
              path="/gharana/voter/list/:blockcode"
              element={<GharanaVoterList />}
            />
            <Route
              exact
              path="/phone/:blockcode"
              element={<PhoneNumberVoterList />}
            />
            {/* <Route
              exact
              path="/example/:blockcode"
              element={<ExampleVoterList />}
            /> */}
            <Route exact path="/age/search" element={<DataSearch />} />
            <Route
              exact
              path="/blockcode/cnic/search"
              element={<BlockCodeCnicSearch />}
            />
            <Route
              exact
              path="/blockcode/address/search"
              element={<AddressSearch />}
            />
            <Route exact path="/search" element={<Search />} />
            <Route
              exact
              path="/totalfamily/:blockcode"
              element={<FamilyListCount />}
            />
            {/* <Route element={<Package4RouteWrapper />}> */}
            <Route
              exact
              path="/suspicious/votes"
              element={<Suspiciousvotes />}
            />
            {/* </Route> */}

            <Route
              exact
              path="/blockcode/list"
              element={<BlockCodelisting />}
            />
            <Route exact path="/download/list" element={<DownloadListing />} />
            <Route
              exact
              path="/blockcode/list1"
              element={<BlockCodelisting1 />}
            />
            <Route
              exact
              path="/form42/:blockCode/:reportsNumber"
              element={<Form42 />}
            />
            <Route exact path="/create/newteam" element={<CreateNewTeam />} />
            <Route exact path="/add/voter" element={<AddVoter />} />
            <Route
              exact
              path="/add/pollingscheme"
              element={<AddPollingScheme />}
            />
            <Route
              exact
              path="/update/pollingagent/:id/:name/:cnic/:phone/:blockcode/:ht/:ps/:pb/:pe/:comment"
              element={<UpdatePollingAgent />}
            />
            <Route exact path="/pollingagent" element={<AddPollingAgent />} />
            <Route exact path="/pollingagent/list" element={<PollingAgent />} />
            <Route exact path="/paid/voter/list" element={<PaidVoters />} />
            <Route
              exact
              path="/voter/report/:userId"
              element={<VoterReport />}
            />
            <Route exact path="/team/list" element={<Team />} />
            <Route exact path="/team/record" element={<TeamMemberRecord />} />
            <Route
              exact
              path="/team/call/record"
              element={<UserCallRecord />}
            />
            <Route exact path="/team/member" element={<MemberDetail />} />
            <Route exact path="/member/record" element={<PersonalRecord />} />
            <Route exact path="/member/openapp" element={<MemberAppOpen />} />
            <Route
              exact
              path="/polling/dashboard"
              element={<PollingDayDashboard />}
            />

            <Route
              exact
              path="/team/member/cnicsearch"
              element={<MemberCnicSearch />}
            />
            <Route
              exact
              path="/team/member/doortodoor"
              element={<MemberDoorToDoor />}
            />
            <Route
              exact
              path="/team/doortodoor"
              element={<UserDoorToDoorRecord />}
            />
            <Route
              exact
              path="/team/member/phonesearch"
              element={<MemberPhoneSearch />}
            />
            <Route
              exact
              path="/team/member/familytree"
              element={<MemberFamilyTreeSearch />}
            />
            <Route
              exact
              path="/team/member/voterinfo"
              element={<MemberShareVoterInfo />}
            />
            <Route
              exact
              path="/team/member/callinfo"
              element={<MemberShareVoterCallCenter />}
            />
            <Route
              exact
              path="/team/member/weblogin"
              element={<MemberWebLogin />}
            />
            <Route
              exact
              path="/team/member/generatevoterparchi"
              element={<MemberGenerateVoterInfo />}
            />
            <Route
              exact
              path="/team/member/sharefamilyparchi"
              element={<MemberShareFamilyParchi />}
            />
            <Route
              exact
              path="/team/member/geotracking"
              element={<GeoTracking />}
            />
            <Route exact path="/google/map" element={<Googlemap />} />
            <Route
              exact
              path="/blockcode/voters/list/:id"
              element={<BlockCodeVoterslisting />}
            />
            <Route
              exact
              path="/confidence/blockcode/voters/list/:id"
              element={<ConfidenceBlockCodeVoterList />}
            />
            <Route
              exact
              path="/suspicious/blockcode/voters/list/:id"
              element={<SuspiciousBlockCodeVoterListing />}
            />
            <Route
              exact
              path="/system/generated/report/:blockcode/:cnic/:fmnumber"
              element={<SystemGenratedReport />}
            />
            <Route
              exact
              path="/such/ghalt/tasdeeq/:blockcode/:cnic/:fmnumber"
              element={<SuchGhaltkiTasdeeq />}
            />
            <Route
              exact
              path="/suspicious/blockcode/gharana/familytree/:id/:gharanaNo"
              element={<GharanoFamilyTree />}
            />
            <Route
              exact
              path="/totalvoter/list"
              element={<Totalvoterlisting />}
            />
            <Route exact path="/totalmen/list" element={<Totalmenlisting />} />
            <Route
              exact
              path="/totalwomen/list"
              element={<Totalwomenlisting />}
            />

            <Route
              exact
              path="/campaign/managment"
              element={<ScheduleContent />}
            />
            <Route exact path="/roles" element={<ListRoles />} />
            <Route exact path="/roles/add" element={<AddRole />} />
            <Route exact path="/roles/:id/edit" element={<EditRole />} />
            <Route exact path="/roles/:id/view" element={<ViewRole />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
