import axios from "axios";
import { useContext } from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import AppImages from "../../../../assets/images";
import AuthContext from "../../../../contexts/AuthContext";
import ReactLoading from "react-loading";
import ListingTemplate from "../ListingTemplate";
// import ListingTemplate from "../ListingTemplate";
import { customerActions, customerThunk } from "../reducers/customer_slice";

function PollingAgent({ children }) {
  const navigate = useNavigate();
  const customerReducer = useSelector((state) => state.customers);
  const dispatch = useDispatch();

  const ths = [
    { title: "Name" },
    { title: "Cnic" },
    { title: "Block Code" },
    { title: "Phone" },
    { title: "Halka" },
    { title: "Polling Station" },
    { title: "Previous Experience" },
    { title: "" },
    { title: "" },
  ];

  //   const [data, setData] = useState(customerReducer.data);
  //   const [showableData, setShowableData] = useState(data ?? []);
  const [deleteableItem, setDeletableItem] = useState(null);
  const [searchQuery, setSearchQuery] = useState(null);
  const [statusFilter, setStatusFilter] = useState("all");

  //   useEffect(() => {
  //     if (customerReducer.fetchDataRequestStatus === RequestStatus.IDLE) {
  //       dispatch(customerThunk.getCustomers());
  //     }
  //   }, [customerReducer.fetchDataRequestStatus]);

  //   useEffect(() => {
  //     console.log(customerReducer.data);
  //     setData(customerReducer.data ?? []);
  //     setShowableData(customerReducer.data ?? []);
  //   }, [customerReducer.data]);
  const authCtx = useContext(AuthContext);
  console.log("Authcontext", authCtx);
  const [data, setData] = useState([]);
  const { id } = useParams();
  let page = 1;
  let na = authCtx.user.sector;
  let Id = authCtx.user.id;
  useEffect(() => {
    let na = authCtx.user.sector;
    // let page = 1;

    const fetchData = async () => {
      console.log("ID", Id, na);
      let halka = 'electionexpert lc'
      const result = await axios.get(
        `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/pollingagents?ht=${na}`
      );
      var url = `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/pollingagents?ht=${na}`;
      console.log("URLLL", url);
      console.log("RESData", result);
      setData(result.data.data.sort((a, b) => b.id - a.id));
      // result.data.voters.sort((a, b) => a.silsila_no - b.silsila_no);
    };

    fetchData();
  }, []);
  console.log("Darta", data);

  const deleteItem = async(item)=>{
    console.log("Itemxxxxxxxx",item.id, item.block_code)
    const result = await axios.get(
      `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/delete-polling-agent?blockcode=${item.block_code}&id=${item.id}`
    );
    console.log(
      "Queryyyyyy",
      `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/delete-polling-agent?blockcode=${item.block_code}&id=${item.id}`
    );
    console.log("Result", result);
      const result1 = await axios.get(
        `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/pollingagents?ht=${na}`
      );
      setData(result1.data.data);
  }
  const [searchInput, setSearchInput] = useState("");

  const tdClasses = "text-[12px] border-b-[1px] border-[#2A2D4314] py-4";
  // const handleClickOnStatusBtn = async (item, newStatus) => {
  //   setState(newStatus);
  //   const result = await axios.get(
  //     `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/team-member-status?id=${item.id}&status=${newStatus}`
  //   );
  //   console.log("Result", result);
  // };
  //   const handleStatusFilter = (e) => {
  //     setStatusFilter(e.target.value);
  //     switch (e.target.value) {
  //       case "active":
  //         setShowableData(data.filter((d) => d.status === "active"));
  //         break;
  //       case "inactive":
  //         setShowableData(
  //           data.filter((d) => d.status === "inactive" || !d.status)
  //         );
  //         break;
  //       case "all":
  //       default:
  //         setShowableData(data);
  //     }
  //   };

  //   const filterDataByQuery = (val) => {
  //     console.log(showableData);
  //     const newData = showableData.filter(
  //       (d) =>
  //         d.id.toString().startsWith(val) ||
  //         d.name?.toLowerCase().startsWith(val.toLowerCase()) ||
  //         d.email?.toLowerCase().startsWith(val.toLowerCase()) ||
  //         d.phone?.toLowerCase().startsWith(val.toLowerCase()) ||
  //         d.Location?.city?.toLowerCase().startsWith(val.toLowerCase()) ||
  //         d.Location?.country?.toLowerCase().startsWith(val.toLowerCase())
  //     );
  //     return newData;
  //   };

  //   const handleOnSearch = (e) => {
  //     const val = e.target.value?.trim();
  //     if (val) {
  //       setSearchQuery(val);
  //       setShowableData(filterDataByQuery(val));
  //     } else if (statusFilter !== "all") {
  //       setShowableData(data.filter((d) => d.status === statusFilter));
  //     } else {
  //       setShowableData(data);
  //     }
  //   };
  const fiteredData=data.filter((item) => {
    const searchString = searchInput.toLowerCase();
    return (
      item.name.toLowerCase().includes(searchString) ||
      item.cnic.toLowerCase().includes(searchString) ||
      item.block_code.toLowerCase().includes(searchString) ||
      item.phone.toLowerCase().includes(searchString) ||
      item.halka_type.toLowerCase().includes(searchString) ||
      item.polling_station.toLowerCase().includes(searchString) ||
      item.previous_experience.toLowerCase().includes(searchString)
    );
  });
  return (
    <ListingTemplate
      title={id}
      //   onSearch={handleOnSearch}
      selectBoxLabel="Select staff:"
      selectItems={[
        { text: "All", value: "all" },
        { text: "Active", value: "active" },
        { text: "Inactive", value: "inactive" },
      ]}
      //   onSelect={handleStatusFilter}
    >
      <div className="flex flex-row">
        <div className=" text-[15px] font-bold mt-5">{na}</div>

        <button
          className="w-[150px] my-4 h-[40px] ml-[700px] bg-[#14A800] text-[18px] rounded-md text-white hover:bg-slate-400 mt-4"
          onClick={() => navigate("/add/pollingagent")}
        >
          Add new
        </button>
      </div>
      <div className="text-[24px] font-bold">Polling Agents</div>
      <div className="w-[1000px] mt-1">
        The functionality that can be added to a polling agent could include
        things like: Adding polling agent Setting roles and permissions for
        members Sharing resources and content within the team, etc. Setting up
        workflows and processes specific to the team
      </div>
      <input
        type="text"
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
        className="w-[40%] my-4 px-3 py-2 border border-gray-300 rounded-md"
        placeholder="Search..."
      />
      <table className="w-full my-6">
        <thead>
          <tr>
            {ths.map((item, index) => (
              <th
                key={index}
                className={
                  authCtx.user.party === "PTI"
                    ? "text-[14px] font-semibold border-b-[1px] border-[#2A2D4314] bg-[#59a23a]  text-white px-3 py-4 text-left"
                    : "text-[12px] font-semibold border-b-[1px] border-[#2A2D4314] bg-gray-100 px-3 py-4 text-left"
                }
              >
                {item.title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data ? (
            fiteredData?.map((item, index) => (
              <tr key={index} className="px-3">
                <td className="text-[12px] border-b-[1px] border-[#2A2D4314] py-4 w-[170px]">
                  {item.name}
                </td>
                <td className="text-[12px] border-b-[1px] border-[#2A2D4314] py-4 w-[170px]">
                  {item.cnic}
                </td>
                <td className="text-[12px] border-b-[1px] border-[#2A2D4314] py-4 w-[170px]">
                  {item.block_code}
                </td>
                <td className="text-[12px] border-b-[1px] border-[#2A2D4314] py-4 w-[200px]">
                  {item.phone}
                </td>
                <td className="text-[12px] border-b-[1px] border-[#2A2D4314] py-4 w-[200px]">
                  {item.halka_type}
                </td>
                <td className="text-[12px] border-b-[1px] border-[#2A2D4314] py-4 w-[200px]">
                  {item.polling_station}
                </td>
                <td className="text-[12px] border-b-[1px] border-[#2A2D4314] py-4 w-[200px]">
                  {item.previous_experience}
                </td>
                <td>
                  <div
                    className="bg-primary w-[30px] rounded-lg mr-2 cursor-pointer hover:bg-black"
                    onClick={() => deleteItem(item)}
                  >
                    <img src={AppImages.deleteWhite} className="w-[30px] p-2" />
                  </div>
                </td>
                <td>
                  <Link
                    to={`/update/pollingagent/${item.id}/${item.name}/${item.cnic}/${item.phone}/${item.block_code}/${item.halka_type}/${item.polling_station}/${item.polling_both}/${item.previous_experience}/${item.comments}`}
                  >
                    <div className="bg-primary w-[30px] rounded-lg mr-2 cursor-pointer hover:bg-black">
                      <img src={AppImages.editWhite} className="w-[30px] p-2" />
                    </div>
                  </Link>
                </td>
              </tr>
            ))
          ) : (
            <div className="flex flex-row">
              <div className="flex justify-center">
                <ReactLoading
                  color={"gray"}
                  height={"20px"}
                  width={"40px"}
                  className="my-2"
                />
              </div>
            </div>
          )}
        </tbody>
      </table>
    </ListingTemplate>
  );
}

export default PollingAgent;
