import axios from "axios";
import { useContext } from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import AppImages from "../../../../assets/images";
import AuthContext from "../../../../contexts/AuthContext";
import Chart from "react-google-charts";
import ReactLoading from "react-loading";
import ListingTemplate from "../ListingTemplate";
// import ListingTemplate from "../ListingTemplate";
import { customerActions, customerThunk } from "../reducers/customer_slice";
import _ from "lodash";

function VoterReport({ children }) {
  const navigate = useNavigate();
  const [showableData, setShowableData] = useState([])
  const customerReducer = useSelector((state) => state.customers);
  const dispatch = useDispatch();
  const { userId } = useParams();
  const ths1 = [
    { title: "Id Card" },
    { title: "Sector" },
    { title: "BlockCode" },
    { title: "Vote" },
    { title: "Created At" },
    // { title: "Last LoggedIn" },
    // { title: "Last Location" },
  ];
  const ths = [
    { title: "Id" },
    { title: "Name" },
    { title: "Email" },
    { title: "Total Voters" },
    { title: "Satisfied Voters" },
    // { title: "Last Location" },
    { title: "Non Satisfied Voters" },
  ];

  const authCtx = useContext(AuthContext);
  console.log("Authcontext", authCtx);
  const [data, setData] = useState("");
  const [data1, setData1] = useState("");
  const [groupedData, setGroupedData] = useState({});
  const { id } = useParams();
  let page = 1;
  let na = authCtx.user.sector;
  let Id = authCtx.user.id;
  useEffect(() => {
    let na = authCtx.user.sector;
    // let page = 1;

    // const fetchData = async () => {
    //   console.log("ID", Id, na);
    //   const result = await axios.get(
    //     `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/get-premium-voter-stats-by-day`
    //   );
    //   var url = `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/get-premium-voter-stats-by-day`;
    //   console.log("URLLL", url);
    //   console.log("RESData", result);

    //   setData(result.data?.data?.map(item => {
    //     let d = new Date(item.day)
    //     item.showable_date = d.toLocaleDateString()
    //     return item 
    //   }));
    // };
    const fetchData1 = async () => {
      console.log("ID", Id, na);
      const result = await axios.get(
        `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/get-premium-voters-stats-by-userid?user_id=${userId}`
      );
      var url = `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/get-premium-voters-stats-by-userid?user_id=704`;
      console.log("2URLLL", url);
      console.log("RESData2", result);
      let tempdata = result.data 
        let userdata = tempdata.data?.user_data 
        userdata = userdata.map(item => {
            const dateString = "2/2/2024, 6:21:59 PM";
            const formattedDateString = dateString.replace(",", ""); // Remove the comma
            const jsDateObject = new Date(formattedDateString);
            item.date = jsDateObject
            return item 
        })
        tempdata.data.user_data = userdata 
      setShowableData(tempdata);
      setData1(tempdata)
    };

    // fetchData();
    fetchData1();
  }, []);
  console.log("Darta", data);
  const chartData = [
    ["Total Voters", data1?.data?.total_count],
    ["Satisfied Voters", data1?.data?.yes_count],
    ["Non Satisfied Voters", data1?.data?.no_count],
  ];
  const chartOptions = {
    // title: "My Pie Chart",
    colors: ['green', "red", "black"], // Specify custom colors for each category
  };

  function handleDayChange(e) {
    console.log(e.target.value)
    console.log(data1)
    const dates = data1.data.user_data.filter(item => item.date == e.target.value)
    console.log(dates)
  }

  return (
    <ListingTemplate
      title={id}
      //   onSearch={handleOnSearch}
      selectBoxLabel="Select staff:"
      selectItems={[
        { text: "All", value: "all" },
        { text: "Active", value: "active" },
        { text: "Inactive", value: "inactive" },
      ]}
      //   onSelect={handleStatusFilter}
    >
      <div className="flex flex-row">
        {/* <div className="text-[24px] font-bold mt-3">Sector</div> */}
        <div className=" text-[15px] font-bold mt-5">{na}</div>
        <div className="ml-1 text-[15px] mt-5">/</div>
        {/* <div
          className="ml-1 mt-5 text-[15px] cursor-pointer"
          onClick={(e) => {
            navigate("/create/newteam");
          }}
        >
          Add VoterReport member
        </div>
        <div className="ml-1 text-[15px] mt-5">/</div> */}

        <div className="ml-1 text-[15px]  text-gray-400 mt-5">
          Premium Voters
        </div>
      </div>
      <div className="text-[24px] font-bold">Premium Voters</div>
      <div className="flex flex-row justify-between w-full">
        <div>
          <div className="text-[24px] font-bold">
            Todays Count : {data1?.data?.todays_count}
          </div>
          <div className="text-[24px] font-bold">
            Total Premium Voters : {data1?.data?.total_count}
          </div>
          <div className="text-[24px] font-bold">
            Total Non Voters : {data1?.data?.no_count}
          </div>
          <div className="text-[24px] font-bold">
            Total Satisfied Voters : {data1?.data?.yes_count}
          </div>
        </div>
        <div>
          <Chart
            width={"600px"}
            height={"500px"}
            chartType="PieChart"
            loader={<div>Loading Chart</div>}
            data={[["Category", "Value"], ...chartData]}
            options={chartOptions}
            rootProps={{ "data-testid": "1" }}
          />
        </div>
      </div>

      {/* <table>
        <thead>
          <tr>
            <td>User id</td>
            <td>Added users</td>
          </tr>
        </thead>
        <tbody>
          {Object.keys(groupedData).length ? Object.keys(groupedData).map(userid => (
            <tr>
              <td>{userid}</td>
              <td>{groupedData[userid].length ?? 0}</td>
            </tr>
          )) : <tr></tr>}
        </tbody>
      </table> */}
      {/* <div>
        <label htmlFor="dayDropdown">Select Day:</label>
        <select id="dayDropdown" onChange={(e) => handleDayChange(e)}>
          <option value="-1">All time</option>
          {data?.length &&
            data?.map((item, index) => (
              <option key={index} value={item.day}>
                {item.showable_date}
              </option>
            ))}
        </select>
      </div> */}

      <table className="w-full my-6">
        <thead>
          <tr>
            {ths1.map((item, index) => (
              <th
                key={index}
                className={
                  authCtx.user.party === "PTI"
                    ? "text-[14px] font-semibold border-b-[1px] border-[#2A2D4314] text-white bg-[#59a23a]  px-3 py-4 text-left"
                    : "text-[12px] font-semibold border-b-[1px] border-[#2A2D4314] bg-gray-100 px-3 py-4 text-left"
                }
              >
                {item.title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {showableData?.data?.user_data ? (
            showableData?.data?.user_data?.map((item, index) => (
              <tr key={index} className="px-3">
                <td className="text-[12px] border-b-[1px] border-[#2A2D4314] py-4 w-[170px]">
                  {item.id_card}
                </td>
                <td className="text-[12px] border-b-[1px] border-[#2A2D4314] py-4 w-[170px]">
                  {item.sector}
                </td>
                <td className="text-[12px] border-b-[1px] border-[#2A2D4314] py-4 w-[170px]">
                  {item.block_code}
                </td>
                <td className="text-[12px] border-b-[1px] border-[#2A2D4314] py-4 w-[200px]">
                  {item.will_vote}
                </td>
                <td className="text-[12px] border-b-[1px] border-[#2A2D4314] py-4 w-[200px]">
                  {item.created_at}
                </td>
                {/* <td className="text-[12px] border-b-[1px] border-[#2A2D4314] py-4 w-[200px]">
                  {item.no_count}
                </td> */}
              </tr>
            ))
          ) : (
            <div className="flex flex-row">
              <div className="flex justify-center">
                <ReactLoading
                  color={"gray"}
                  height={"20px"}
                  width={"40px"}
                  className="my-2"
                />
              </div>
            </div>
          )}
        </tbody>
      </table>
    </ListingTemplate>
  );
}

export default VoterReport;
