import axios from "axios";
import { useContext } from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import AppImages from "../../../../assets/images";
import ReactModal from "react-modal";
import AuthContext from "../../../../contexts/AuthContext";
import ReactLoading from "react-loading";
import ListingTemplate from "../ListingTemplate";
// import ListingTemplate from "../ListingTemplate";
import { customerActions, customerThunk } from "../reducers/customer_slice";
import FirebaseService from "../../../../services/FirebaseService";
import { id } from "date-fns/locale";
import EditModal from "./EditModal";

function SuchGhaltkiTasdeeq({ children }) {
  const navigate = useNavigate();
  // const customerReducer = useSelector((state) => state.customers);
  // const dispatch = useDispatch();

  const ths = [
    { title: "Name" },
    { title: "Total Calls" },
    { title: "View Report" },
  ];

  //   const [data, setData] = useState(customerReducer.data);
  //   const [showableData, setShowableData] = useState(data ?? []);
  // const [deleteableItem, setDeletableItem] = useState(null);
  // const [searchQuery, setSearchQuery] = useState(null);
  // const [statusFilter, setStatusFilter] = useState("all");
  const [userIds, setUserIds] = useState([]);
  const [fbStats, setFbStats] = useState([]);
  
  const authCtx = useContext(AuthContext);

  const [data, setData] = useState([]);
  const [confidence, setConfidence] = useState('')
  const [warn , setWarn] = useState('')
  const [dataabove, setDataabove] = useState([]);
  const [databelow, setDatabelow] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [editableItem, setEditableItem] = useState(null);
  const { blockcode } = useParams();
  const { fmnumber } = useParams();
  const { cnic } = useParams();
  let na = authCtx.user.sector;
  useEffect(() => {
    let na = authCtx.user.sector;
    let Id = 1;
    const fetchData = async () => {
       setIsLoading(true);
       axios.get(
           `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/family-bogus-detail?sector=${na}&bc=${blockcode}&cnic=${cnic}&fno=${fmnumber}`
         )
         .then((result) => {
           setData(result.data.family_tree);
         
         })
         .finally(() => {
           setIsLoading(false);
         });
      // const result = await axios.get(
      //   `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/family-bogus-detail?sector=${na}&bc=${blockcode}&cnic=${cnic}&fno=${fmnumber}`
      // );
      // setData(result.data.family_tree);
      
      
    };
       console.log(
         "Apiiii",
         `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/family-bogus-detail?sector=${na}&bc=${blockcode}&cnic=${cnic}&fno=${fmnumber}`
       );
    fetchData();
  }, []);

  console.log("Dataaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa", data);
  //   data?.filter((item)=>{
  //     if (item.confidence > 56){
  //         setDataabove(item)
  //     }
  //     else{
  //         setDatabelow(item)
  //     }
  //   })
  // console.log("Dataabove", dataabove);
  const today = new Date();
  const date = today.getDate();
  const month = today.getMonth() + 1; // Add 1 to the month because the month index starts at 0 (January)
  const year = today.getFullYear();
const modalStyle = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxHeight: "100vh",
    overflowY: "auto",
  },
  overlay: {
    backgroundColor: "rgba(0,0,0, 0.5)",
  },
};
  return (
    <div>
      <ReactModal
        isOpen={editableItem !== null}
        onRequestClose={() => setEditableItem(null)}
        style={modalStyle}
      >
        <div className="flex flex-col">
          <div className="mt-1">
            <EditModal setIsModalOpen={setEditableItem} detail={editableItem} />
          </div>
        </div>
      </ReactModal>
      <ListingTemplate
        //   title={id}
        //   onSearch={handleOnSearch}
        selectBoxLabel="Select staff:"
        selectItems={[
          { text: "All", value: "all" },
          { text: "Active", value: "active" },
          { text: "Inactive", value: "inactive" },
        ]}
        //   onSelect={handleStatusFilter}
      >
        <div className="flex flex-row">
          {/* <div className="text-[24px] font-bold mt-3">Sector</div> */}
          <div className=" text-[15px] font-bold mt-5">{na}</div>
          <div className="ml-1 text-[15px] mt-5">/</div>
          <div className="ml-1 text-[15px]  text-gray-400 mt-5">
            SuchGhaltTasdeeq
          </div>
          <div className="w-[150px] text-black my-4 h-[40px] ml-[610px] text-[15px] mt-4">
            {date + "/" + month + "/" + year}
          </div>
        </div>
        {warn === "" ? (
          <div></div>
        ) : (
          <div className="text-red text-18px my-5 ml-4">
            <img src={warn} className="w-[95%] h-[30%]" />
          </div>
        )}
        <table
          className="w-[100%] border-[1px] border-collapse font-noori"
          // style="width: 100%;border:1px solid;border-collapse: collapse"
        >
          <thead>
            <tr>
              <th className="text-right border-[1px] border-[#000] p-[5px]">
                تبدیلی
              </th>
              <th className="text-right border-[1px] border-[#000] p-[5px]">
                تصدیق غلط
              </th>
              <th className="text-right border-[1px] border-[#000] p-[5px]">
                تصدیق درست
              </th>

              <th className="text-right border-[1px] border-[#000] p-[5px]">
                بوگس
              </th>
              <th className="text-right border-[1px] border-[#000] p-[5px]">
                اعتماد
              </th>
              <th className="text-right border-[1px] border-[#000] p-[5px]">
                قطار کا ٹکرا
              </th>
              <th className="text-right border-[1px] border-[#000] p-[5px]">
                مکمل صفحہ
              </th>
              <th className="text-right border-[1px] border-[#000] p-[5px]">
                پتہ
              </th>
              <th className="text-right border-[1px] border-[#000] p-[5px]">
                عمر
              </th>
              <th className="text-right border-[1px] border-[#000] p-[5px]">
                شناختی کارڈ
              </th>
              <th className="text-right border-[1px] border-[#000] p-[5px]">
                نام
              </th>
              <th className="text-right border-[1px] border-[#000] p-[5px]">
                خاندان نمبر
              </th>
              <th className="text-right border-[1px] border-[#000] p-[5px]">
                سیریل نمبر
              </th>
            </tr>
          </thead>
          {
            // data
          }
          <tbody>
            {!isLoading && data.length > 0 ? (
              data?.map((item, index) => (
                <tr data-id={item.id}>
                  <td className="text-center mr-2 p-[5px]">
                    {/* <td className="text-right p-[5px]"></td> */}
                    <div
                      className="bg-primary rounded-lg p-2 mr-2 cursor-pointer hover:bg-black"
                      onClick={() => setEditableItem(item)}
                    >
                      <img
                        src={AppImages.editWhiteFilled}
                        className="w-[14px]"
                      />
                    </div>
                  </td>
                  <td className="text-center mr-2 p-[5px]">
                    <div
                      className="w-[40px] bg-red-400 text-white p-1 rounded-md cursor-pointer"
                      onClick={async (e) => {
                        let confidence = 0;
                        // setConfidence(0);
                        // setWarn('Confidence is update to 0')
                        setData(
                          data.map((d) => {
                            if (d.id === item.id) {
                              d.confidence = confidence;
                            }
                            return d;
                          })
                        );
                        const result = await axios.get(
                          `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/update-confidencee?cnic=${item.cnic}&confidence=${confidence}`
                        );

                        console.log("Result1", result);
                      }}
                    >
                      غلط
                    </div>
                  </td>
                  <td className="text-center p-[5px] ">
                    <div
                      className="w-[40px] bg-green-400 text-white p-1 rounded-md cursor-pointer"
                      onClick={async (e) => {
                        let confidence = 90;
                        // setConfidence(90);

                        // setWarn("Confidence is update to 90");
                        setData(
                          data.map((d) => {
                            if (d.id === item.id) {
                              d.confidence = confidence;
                            }
                            return d;
                          })
                        );
                        const result = await axios.get(
                          `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/update-confidencee?cnic=${item.cnic}&confidence=${confidence}`
                        );

                        console.log("Result2", result.data);
                      }}
                    >
                      درست
                    </div>
                  </td>
                  <td className="text-center p-[2px]">
                    {item.bogus === 1 ? (
                      <div
                        className="w-[55px] bg-red-400 text-white p-1 rounded-md cursor-pointer"
                        onClick={async (e) => {
                          let bogus = 0;
                          // setConfidence(0);
                          // setWarn('Confidence is update to 0')
                          setData(
                            data.map((d) => {
                              if (d.id === item.id) {
                                d.bogus = bogus;
                              }
                              return d;
                            })
                          );
                          const result = await axios.get(
                            `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/update-bogus?cnic=${item.cnic}&bogus=${bogus}`
                          );

                          console.log("Result1", result);
                        }}
                      >
                        Bogus
                      </div>
                    ) : (
                      <div
                        className="w-[55px] bg-green-400 text-white p-1 rounded-md cursor-pointer"
                        onClick={async (e) => {
                          let bogus = 1;
                          // setConfidence(0);
                          // setWarn('Confidence is update to 0')
                          setData(
                            data.map((d) => {
                              if (d.id === item.id) {
                                d.bogus = bogus;
                              }
                              return d;
                            })
                          );
                          const result = await axios.get(
                            `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/update-bogus?cnic=${item.cnic}&bogus=${bogus}`
                          );

                          console.log("Result1", result);
                        }}
                      >
                        Bogus
                      </div>
                    )}
                  </td>
                  {/* <td className="text-right p-[5px]"></td> */}
                  <td className="text-right p-[5px]">{item.confidence}</td>
                  <td className="text-right p-[5px] text-gray-400 hover:text-blue-600 cursor-pointer">
                    {/* <a
                      target="_blank"
                      rel="noreferrer"
                      href={`https://res.cloudinary.com/election-experts/image/fetch/c_crop,h_160,x_0,w_4000,y_${
                        item.rowY
                      }/${encodeURIComponent(item.page_url)}`}
                    >
                      View
                    </a> */}
                    <div
                      onClick={(e) => {
                        setWarn(
                          `https://res.cloudinary.com/election-experts/image/fetch/c_crop,h_160,x_0,w_4000,y_${
                            item.rowY
                          }/${encodeURIComponent(item.page_url)}`
                        );
                      }}
                    >
                      View
                    </div>
                  </td>

                  <td className="text-right p-[5px] text-gray-400 hover:text-blue-600">
                    <div
                      onClick={(e) => {
                        setWarn(item.page_url);
                      }}
                    >
                      View
                    </div>
                    {/* <a href={item.page_url} target="_blank">
                      View
                    </a> */}
                  </td>
                  <td className="text-right p-[5px]">{item.address}</td>
                  <td className="text-right p-[5px]">{item.age}</td>
                  <td className="text-right p-[5px]">{item.cnic}</td>
                  <td className="text-right p-[5px]">
                    {item.name.slice(0, 11).toString().replace(/[0-9]/gi, "")}
                  </td>
                  <td className="text-right p-[5px]">{item.gharana_no}</td>
                  <td className="text-right p-[5px]">{item.silsila_no}</td>
                </tr>
              ))
            ) : (
              <div className="flex flex-row">
                <div className="flex justify-center">
                  <ReactLoading
                    color={"gray"}
                    height={"20px"}
                    width={"40px"}
                    className="my-1"
                  />
                  <div className="mt-2 ml-2 font-bold ">
                    Data is Loading ...
                  </div>
                </div>
              </div>
            )}
            {/* nee */}
          </tbody>
        </table>
      </ListingTemplate>
    </div>
  );
}

export default SuchGhaltkiTasdeeq;
