// Modal.js
import React, { useState } from "react";

const Modal = ({ isOpen, onClose, children }) => {
  const modalStyle = {
    display: isOpen ? "block" : "none",
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#33C4FF",
    borderRedius:"40px",
    padding: "20px",
    zIndex: 1000,
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  };

  return (
    <div style={modalStyle}>
      {children}
      <button
        onClick={onClose}
        className="border-[1px] border-gray-400 text-white mt-6 bg-gray-500 p-2 rounded-md hover:bg-slate-200"
      >
        Close Message
      </button>
    </div>
  );
};

export default Modal;
