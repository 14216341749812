import { useContext } from "react";
import { useNavigate } from "react-router";
import AppImages from "../../../assets/images";
import AuthContext from "../../../contexts/AuthContext";
import Button from "../../basic/Button";
import DashboardTemplate from "../../common/DashboardTemplate";
import SingleViewTemplate from "../../common/SingleViewTemplate";

function ViewProfile() {
    const authCtx = useContext(AuthContext)
    const navigate = useNavigate()
    return (
      <SingleViewTemplate
        title={authCtx.user.party}
        avatarUrl={
          authCtx.user.party_image
        }
        items={[
          { name: "Email", value: authCtx.user.email },
          { name: "Phone", value: authCtx.user.phone },
          { name: "Sector", value: authCtx.user.sector },
          { name: "Party", value: authCtx.user.party },
        ]}
      >
        {/* <Button
                text={"Edit Profile"}
                icon={AppImages.editWhite}
                style={{
                    fontSize: 'text-[14px]',
                    custom: 'py-2 px-2'
                }}
                onClick={e => {
                    navigate('/profile/edit')
                }}
            /> */}
      </SingleViewTemplate>
    );
}

export default ViewProfile;