import { useContext, useEffect, useState } from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
// import FirebaseService from "../../../../services/FirebaseService";
import ListingTemplate from "../ListingTemplate";
import { Chart } from "react-google-charts";
import FirebaseService, {
  SEARCH_BY_BC_CNIC,
  SEARCH_BY_CNIC,
  SEARCH_BY_PHONE,
  SHARE_VOTER_CALL_CENTER,
  SHARE_VOTER_INFO,
  GENERATE_VOTER_PARCHI,
  SEARCH_BY_FAMILYTREE,
  OPEN_APP,
  USER_LOGIN,
  DOOR_TO_DOOR,
  GEO_TRACKING
} from "../../../../services/FirebaseService";
import ViewDetailBtn from "../../../common/ViewDetailBtn";
import { toSentenceCase } from "../../../../utils/helpers";
import AuthContext from "../../../../contexts/AuthContext";
// import ListingTemplate from "../../ListingTemplate";

const PersonalRecord = () => {
  const location = useLocation();
  const { member } = location.state;

  const [cnicSearchCount, setCnicSearchCount] = useState(0);
  const [doorTodoorCount, setDoorToDoorCount] = useState(0);
  const [callCount, setCallCount] = useState(0);
  const [datanotFound, setDatanotFound] = useState(0);
  const [activity, setActivity] = useState(0);
  const [cnicSearchData, setCnicSearchData] = useState(0);
   const [openapp, setOpenapp] = useState(0);
    const [loginweb, setLoginweb] = useState(0);
     const [tracking, setTracking] = useState(0);
  const [searchLogRequestInProgress, setSearchLogRequestInProgress] =useState(false);
  const [familytreesearch,setSearchFamilyTree]=useState(0)
     const [voterInfo, setVoterInfo] = useState(null);
     const [phonecount, setPhoneSearchCount] = useState(null);
     const [voterparchi, setVoterParchiCount] = useState(null);
  const [searchLogs, setSearchLogs] = useState(null);
  const [searchLogs1, setSearchLogs1] = useState(null);
 const [searchLogs2, setSearchLogs2] = useState(null);
 const [searchLogs3, setSearchLogs3] = useState(null);
 const [searchLogs4, setSearchLogs4] = useState(null);
  const thClasses =
    "text-[12px] font-semibold border-b-[1px] border-[#2A2D4314] py-4 text-left";
  const tdClasses = "text-[12px] border-b-[1px] border-[#2A2D4314] py-4";
  const navigate = useNavigate();
const authCtx = useContext(AuthContext);
console.log("Authcontext", authCtx);
let na = authCtx.user.sector;
  useEffect(() => {
    FirebaseService.getCountByTagAndUserId(member?.id, [
      SHARE_VOTER_CALL_CENTER,
      //   SEARCH_BY_BC_CNIC,
    ],na).then((count) => {
      setCallCount(count);
    });
    setSearchLogRequestInProgress(true);
    FirebaseService.getSearchLogs(member?.id, SHARE_VOTER_CALL_CENTER, na).then(
      (docs) => {
        setSearchLogs1(docs);
      }
    );
    FirebaseService.getCountByTagAndUserId(member?.id, [
      SEARCH_BY_CNIC,
      SEARCH_BY_BC_CNIC,
    ],na).then((count) => {
      setCnicSearchCount(count);
    });
    setSearchLogRequestInProgress(true);
    // FirebaseService.getSearchLogs(member?.id, [
    //   SEARCH_BY_CNIC,
    //   SEARCH_BY_BC_CNIC,
    //   //   SEARCH_BY_PHONE,
    // ]);
    FirebaseService.getCountByTagAndUserId(member?.id, [
      SHARE_VOTER_INFO,
      //   SEARCH_BY_BC_CNIC,
    ],na).then((count) => {
      setVoterInfo(count);
    });
    // setSearchLogRequestInProgress(true);
    // FirebaseService.getSearchLogs(member?.id, SHARE_VOTER_INFO).then((docs) => {
    //   setSearchLogs2(docs);
    // });
      FirebaseService.getCountByTagAndUserId(member?.id, [
        SEARCH_BY_CNIC,
        SEARCH_BY_BC_CNIC,
      ],na).then((count) => {
        setCnicSearchCount(count);
      });
       FirebaseService.getCountByTagAndUserId(member?.id,
         [DOOR_TO_DOOR],
         na
       ).then((count) => {
         setDoorToDoorCount(count);
       });
      // setSearchLogRequestInProgress(true);
      FirebaseService.getSearchLogs(member?.id, [
        SEARCH_BY_CNIC,
        SEARCH_BY_BC_CNIC,
        SEARCH_BY_PHONE,
      ]).then((docs) => {
        setSearchLogs3(docs);
      });
       FirebaseService.getCountByTagAndUserId(member?.id, SEARCH_BY_PHONE,na).then(
         (count) => {
           setPhoneSearchCount(count);
         }
       );
       FirebaseService.getCountByTagAndUserId(member?.id, [
         GENERATE_VOTER_PARCHI,
         //   SEARCH_BY_BC_CNIC,
       ],na).then((count) => {
         setVoterParchiCount(count);
       });
       setSearchLogRequestInProgress(true);
       FirebaseService.getSearchLogs(member?.id, GENERATE_VOTER_PARCHI).then(
         (docs) => {
           setSearchLogs4(docs);
         }
       );
       FirebaseService.getCountByTagAndUserId(member?.id, [
         SEARCH_BY_FAMILYTREE,
         //   SEARCH_BY_BC_CNIC,
       ],na).then((count) => {
         setSearchFamilyTree(count);
       });

        FirebaseService.getCountByTagAndUserId(member?.id, [
          OPEN_APP,
          //   SEARCH_BY_BC_CNIC,
        ],na).then((count) => {
          setOpenapp(count);
        });

        FirebaseService.getCountByTagAndUserId(member?.id, [
          USER_LOGIN
          //   SEARCH_BY_BC_CNIC,
        ],na).then((count) => {
          setLoginweb(count);
        });
         FirebaseService.getGeoTrackLogs(member?.id).then((docs) => {
           console.log("docs", docs);
           setTracking(docs.length);
          //  setSearchLogs(docs);
         });
       
  }, []);
  console.log("Data", cnicSearchData);

  if (!member) {
    return (
      <ListingTemplate>
        <div>Not Found</div>
      </ListingTemplate>
    );
  }
 const data = [
   ["User Activities",
    "Family Tree",
     "Call Center",
     "Share Voter",
     "CNIC Searches",
     "Phone Searches",
     "Voter Parchi",
     "Tracking Report",
     "Data Not Found",
   ],
   ['Report',familytreesearch,callCount, voterInfo, cnicSearchCount, phonecount, voterparchi, tracking,datanotFound],
 
 ];
  const options = {
   chart: {
     title: "Performance",
    //  subtitle: "",
   },
 };
  return (
    <ListingTemplate>
      <div>
        <div className="flex flex-row">
          {/* <div className="text-[24px] font-bold mt-3">Sector</div> */}
          <div
            className=" text-[15px] cursor-pointer mt-5"
            onClick={(e) => {
              navigate("/team/record");
            }}
          >
            Team Record
          </div>
          <div className="ml-1 text-[15px] mt-5">/</div>
          {/* <div
          className="ml-1 mt-5 text-[15px] cursor-pointer"
          onClick={(e) => {
            navigate("/create/newteam");
          }}
        >
          Add Team member
        </div>
        <div className="ml-1 text-[15px] mt-5">/</div> */}
          <div className="ml-1 text-[15px]  text-gray-400 mt-5">
            {member.name}
          </div>
        </div>
        {/* <div>{member.name}</div> */}
        {/* <div className="flex flex-col"> */}
        <div className="flex flex-row space-x-2 h-[129px] mt-4">
          <div className="w-[33%] border-[1px] border-gray-200 rounded-md py-2 px-3 cursor-pointer">
            <Link to="/team/member/callinfo" state={{ member: member }}>
              <div>
                <div className="text-[14px] font-semibold mb-3 text-[#111827]">
                  Call Center
                </div>
                <div className="text-[24px] font-bold text-[#111827]">
                  {callCount}
                </div>
                <div className="text-[14px] text-[#6B7280]">
                  Total Call Count
                </div>
                <ViewDetailBtn />
              </div>
            </Link>
          </div>
          <div className="w-[33%] border-[1px] border-gray-200 rounded-md py-2 px-3 cursor-pointer">
            <Link to="/team/member/voterinfo" state={{ member: member }}>
              <div>
                <div className="text-[14px] font-semibold mb-3 text-[#111827]">
                  Total Share Voter Info
                </div>
                <div className="text-[24px] font-bold text-[#111827]">
                  {voterInfo}
                </div>
                <div className="text-[14px] text-[#6B7280]">
                  No. of times voter parchis are shared
                </div>
                <ViewDetailBtn />
              </div>
            </Link>
          </div>
          <div className="w-[33%] border-[1px] border-gray-200 rounded-md py-2 px-3">
            <Link to="/team/member/cnicsearch" state={{ member: member }}>
              <div>
                <div className="text-[14px] font-semibold mb-3 text-[#111827]">
                  Total Searches by CNIC
                </div>
                <div className="text-[24px] font-bold text-[#111827]">
                  {cnicSearchCount}
                </div>
                <div className="text-[14px] text-[#6B7280]">
                  Total searches by CNIC
                </div>
                <ViewDetailBtn />
              </div>
            </Link>
          </div>
        </div>
        <div className="flex flex-row space-x-2 h-[129px] mt-4">
          {/* <div className="w-[33%] border-[1px] border-gray-200 rounded-md py-2 px-3">
            <Link to="/team/member/cnicsearch" state={{ member: member }}>
              <div>
                <div className="text-[14px] font-semibold mb-3 text-[#111827] text-[#111827]">
                  {toSentenceCase("Total Searches not found")}
                </div>
                <div className="text-[24px] font-bold">{datanotFound}</div>
                <div className="text-[14px] text-[#6B7280]">
                  Total searches not found by CNIC
                </div>
                <ViewDetailBtn />
              </div>
            </Link>
          </div> */}
          <div className="w-[33%] border-[1px] border-gray-200 rounded-md py-2 px-3">
            <Link to="/team/member/phonesearch" state={{ member: member }}>
              <div>
                <div className="text-[14px] font-semibold mb-3 text-[#111827] text-[#111827]">
                  Total Searches by Phone
                </div>
                <div className="text-[24px] font-bold">{phonecount}</div>
                <div className="text-[14px] text-[#6B7280]">
                  Total searches by phone
                </div>
                <ViewDetailBtn />
              </div>
            </Link>
          </div>

          <div className="w-[33%] border-[1px] border-gray-200 rounded-md py-2 px-3 cursor-pointer">
            <Link
              to="/team/member/generatevoterparchi"
              state={{ member: member }}
            >
              <div>
                <div className="text-[14px] font-semibold mb-3 text-[#111827]">
                  Total Voter Parchis
                </div>
                <div className="text-[24px] font-bold text-[#111827]">
                  {voterparchi}
                </div>
                <div className="text-[14px] text-[#6B7280]">
                  No. of voter parchis generated
                </div>
                <ViewDetailBtn />
              </div>
            </Link>
          </div>
          <div className="w-[33%] border-[1px] border-gray-200 rounded-md py-2 px-3">
            <Link to="/team/member/familytree" state={{ member: member }}>
              <div>
                <div className="text-[14px] font-semibold mb-3 text-[#111827] text-[#111827]">
                  Activity Report View Familytree
                </div>
                <div className="text-[24px] font-bold">{familytreesearch}</div>
                <div className="text-[14px] text-[#6B7280]">
                  Total activity report view family tree
                </div>
                <ViewDetailBtn />
              </div>
            </Link>
          </div>
        </div>
        <div className="flex flex-row space-x-2 h-[129px] mt-4">
          <div className="w-[33%] border-[1px] border-gray-200 rounded-md py-2 px-3">
            <Link to="/member/openapp" state={{ member: member }}>
              <div>
                <div className="text-[14px] font-semibold mb-3 text-[#111827] text-[#111827]">
                  Open app
                </div>
                <div className="text-[24px] font-bold">{openapp}</div>
                <div className="text-[14px] text-[#6B7280]">
                  Total app usage
                </div>
                <ViewDetailBtn />
              </div>
            </Link>
          </div>

          <div className="w-[33%] border-[1px] border-gray-200 rounded-md py-2 px-3 cursor-pointer">
            <Link to="/team/member/weblogin" state={{ member: member }}>
              <div>
                <div className="text-[14px] font-semibold mb-3 text-[#111827]">
                  User Web Login
                </div>
                <div className="text-[24px] font-bold text-[#111827]">
                  {loginweb}
                </div>
                <div className="text-[14px] text-[#6B7280]">
                  Number of login by web
                </div>
                <ViewDetailBtn />
              </div>
            </Link>
          </div>
          <div className="w-[33%] border-[1px] border-gray-200 rounded-md py-2 px-3">
            <Link to="/team/member/geotracking" state={{ member: member }}>
              <div>
                <div className="text-[14px] font-semibold mb-3 text-[#111827] text-[#111827]">
                  Tracking Report
                </div>
                <div className="text-[24px] font-bold">{tracking}</div>
                <div className="text-[14px] text-[#6B7280]">
                  Total tracking Report
                </div>
                <ViewDetailBtn />
              </div>
            </Link>
          </div>
        </div>
        <div className="flex flex-row space-x-2 h-[129px] mt-4">
          <div className="w-[33%] border-[1px] border-gray-200 rounded-md py-2 px-3">
            <Link to="/team/member/doortodoor" state={{ member: member }}>
              <div>
                <div className="text-[14px] font-semibold mb-3 text-[#111827]">
                  Door To Door Compaign
                </div>
                <div className="text-[24px] font-bold text-[#111827]">
                  {cnicSearchCount}
                </div>
                <div className="text-[14px] text-[#6B7280]">
                  Door To Door Compaign
                </div>
                <ViewDetailBtn />
              </div>
            </Link>
          </div>
        </div>
        <div className=" text-[20px] font-bold text-gray-400 mt-5">
          {member.name}
        </div>
        <Chart
          chartType="Bar"
          width="100%"
          height="400px"
          data={data}
          options={options}
        />
      </div>
    </ListingTemplate>
  );
};

export default PersonalRecord;
