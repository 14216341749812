import React from 'react'
import AppImages from '../../../../assets/images';
import ListingTemplate from '../ListingTemplate';
// import ListingTemplate1 from '../ListingTemplate1'

const PollingDayDashboard = () => {
  return (
    <ListingTemplate>
      <div className="flex flex-col items-center absolute top-[30%] left-[60%] translate-x-[-50%] translate-y-[-50%]">
        {/* <img
          className="max-w-[32em] h-[80%] pt-[40%]"
          src={AppImages.coming}
          alt={"Coming Soon"}
        /> */}
        <div className="text-[40px] max-w-[32em] h-[80%] pt-[45%] font-bold">
          Polling Day Dashboard
        </div>
        <div className="text-[24px]">Coming Soon . . .</div>
      </div>
    </ListingTemplate>
  );
}

export default PollingDayDashboard