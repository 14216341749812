import axios from "axios";
import React, { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import AuthContext from "../../../../contexts/AuthContext";
import ListingTemplate from "../ListingTemplate";

const FamilyListing = (a, b, c, d) => {
  console.log("ComingData", a, b, c, d);
  const { sector, blockcode, cnic, fmnumber} = useParams();
  console.log("Coming Data", sector);
  const [data, setData] = useState("");
  const tdClasses = "text-[12px] border-b-[1px] border-[#2A2D4314] py-4";
  const ths = [
    { title: "ID" },
    { title: "Age" },
    { title: "Gharna Number" },
    { title: "SilSila Number" },
    { title: "Sector" },
    { title: "Status" },
    { title: "Url Id" },
    { title: "CNIC" },
  ];
  if(fmnumber === ''){
    fmnumber = 1
  }
  const authCtx = useContext(AuthContext);
  useEffect(() => {
    //   let na = authCtx.user.sector;

    const fetchData = async () => {
      const result = await axios(
        `https://7gxwzm6f2vdpe5562dvm7bg7re0aqtsh.lambda-url.eu-west-1.on.aws/family-tree?sector=${sector}&bc=${blockcode}&cnic=${cnic}&fno=${fmnumber}`
      );

      setData(result.data);
    };

    fetchData();
  }, []);
  console.log("Dataaaa", data);
  function ImageSlice({ item }) {
    var yIncr = 0;
    if (item?.rowY) {
        if (authCtx.user.sector === "PP 172") {
          if (
            // item.block_code === 185570302 ||
            // item.block_code === 185560303 ||
            // item.block_code === 185570402
            item.meta === "100"
          ) {
            yIncr = -10;
          } else {
            if (item?.name === "" || item?.address === "") {
              yIncr = -40;
            } else {
              yIncr = 65;
            }
          }
        } else if (["PP 208", "PP 14"].includes(authCtx.user.sector)) {
          if (item?.name === "" || item?.address === "") {
            yIncr = -40;
          } else {
            yIncr = 65;
          }
        } else if (["PP 207"].includes(authCtx.user.sector)) {
          if (item?.name === "" || item?.address === "") {
            yIncr = -30;
          } else {
            yIncr = 70;
          }
        } else if (["NA 120"].includes(authCtx.user.sector)) {
          yIncr = 50;
        } else if (["NA 127"].includes(authCtx.user.sector)) {
          yIncr = -10;
        } else if (["PP 59"].includes(authCtx.user.sector)) {
          yIncr = -20;
        } else {
          yIncr = -10;
        }
    }

    return (
      <img
        src={`https://res.cloudinary.com/election-experts/image/fetch/c_crop,h_80,x_0,w_4000,y_${
          parseInt(item?.rowY) + yIncr
        }/${encodeURIComponent(item?.url)}`}
        alt=""
        srcSet=""
        className=" w-[900px]"
      />
    );
  }
  return (
    <ListingTemplate>
      <div>
        <Link to={`/cnic/search`}>
          <button className="w-[150px] h-[30px] rounded-md bg-green-500 text-white font-bold hover:bg-green-300">
            Back
          </button>
        </Link>
        {authCtx.user.sector == "NA 127" ? (
          <table className="w-full my-6 ml-3" id="my_form">
            <thead>
              <tr>
                {authCtx.user.package !== "1" ? (
                  <th
                    className="text-[12px] font-semibold border-b-[1px] border-[#2A2D4314] py-4 text-right min-w-[150px] "
                    dir="rtl"
                  >
                    فون
                  </th>
                ) : null}
                <th
                  className="text-[12px] font-semibold border-b-[1px] border-[#2A2D4314] py-4 text-center px-2 min-w-[150px]"
                  dir="rtl"
                >
                  پتہ
                </th>

                <th
                  className="text-[12px] font-semibold border-b-[1px] border-[#2A2D4314] py-4 text-center min-w-[150px]"
                  dir="rtl"
                >
                  شناختی کارڈ نمبر
                </th>
                {/* <th className="text-[12px] font-semibold border-b-[1px] border-[#2A2D4314] py-4 text-left">
              Block Code
            </th>
            <th className="text-[12px] font-semibold border-b-[1px] border-[#2A2D4314] py-4 text-left">
              Sector
            </th> */}
                <th
                  className="text-[12px] font-semibold border-b-[1px] border-[#2A2D4314] py-4 text-right min-w-[150px]"
                  dir="rtl"
                >
                  نام
                </th>
                {/* <th
                className="text-[12px] font-semibold border-b-[1px] border-[#2A2D4314] py-4 text-right min-w-[150px] "
                dir="rtl"
              >
                خاندانی نمبر
              </th> */}
                <th
                  className="text-[12px] font-semibold border-b-[1px] border-[#2A2D4314] py-4 text-center min-w-[150px] "
                  dir="rtl"
                >
                  گھرانہ نمبر
                </th>

                <th
                  className="text-[12px] ml-4 font-semibold border-b-[1px] border-[#2A2D4314] py-4 text-right min-w-[150px]"
                  dir="rtl"
                >
                  سلسلہ نمبر
                </th>
              </tr>
            </thead>
            <tbody className="ml-[50px]">
              {!data.family_tree?.length ? (
                <div className="text-center text-[18px] font-bold">
                  No Family tree
                </div>
              ) : (
                data.family_tree?.map((item, index) => (
                  <tr key={index}>
                    {authCtx.user.package !== "1" ? (
                      <td
                        className={`${tdClasses} font-noori min-w-[150px] text-center`}
                        dir="rtl"
                      >
                        {item.voter_phone
                          ? (typeof item.voter_phone === "string" &&
                            item.voter_phone !== "null"
                              ? JSON.parse(item.voter_phone)
                              : Array.isArray(item.voter_phone)
                              ? item.voter_phone
                              : []
                            )
                              .filter(
                                (item, index, self) =>
                                  self.indexOf(item) === index
                              )
                              .map((phone) => (
                                <div className="mr-2">0{phone}</div>
                              ))
                          : "--"}
                      </td>
                    ) : null}

                    <td
                      className={`${tdClasses} font-noori min-w-[150px] `}
                      dir="rtl"
                    >
                      {item.address?.length > 100
                        ? item.address?.replace(/سال/gi, "").substr(0, 100) +
                          ".."
                        : item.address?.replace(/سال/gi, "")}
                    </td>
                    <td
                      className={`${tdClasses} font-noori min-w-[150px] text-center`}
                      dir="rtl"
                    >
                      {item.cnic}
                    </td>
                    {/* <td className={tdClasses}>{item.block_code}</td> */}
                    {/* <td className={tdClasses}>{item.sector}</td> */}
                    <td
                      className={`${tdClasses} min-w-[150px] mx-3 text-center`}
                      dir="rtl"
                    >
                      {item.name?.length > 44
                        ? item.name
                            ?.replace(/[^\W]/gi, "")
                            .replace(/[-*]/g, " ")
                            .substr(0, 44) + ".."
                        : item.name
                            ?.replace(/[^\W]/gi, "")
                            .replace(/[-*]/g, " ")}
                    </td>
                    <td
                      className={`${tdClasses} min-w-[150px] text-center`}
                      dir="rtl"
                    >
                      {item.gharana_no}
                    </td>
                    <td
                      className={`${tdClasses} font-noori min-w-[150px] text-center`}
                      dir="rtl"
                    >
                      {" "}
                      {item.silsila_no}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        ) : (
          <table className="w-full my-6 ml-3" id="my_form">
            <thead>
              <tr>
                {authCtx.user.sector !== "PK 82" ? (
                  <th
                    className="text-[12px] font-semibold border-b-[1px] border-[#2A2D4314] py-4 text-right min-w-[150px] "
                    dir="rtl"
                  >
                    فون
                  </th>
                ) : null}
                <th
                  className="text-[12px] font-semibold border-b-[1px] border-[#2A2D4314] py-4 text-center px-2 min-w-[150px]"
                  dir="rtl"
                >
                  پتہ
                </th>

                <th
                  className="text-[12px] font-semibold border-b-[1px] border-[#2A2D4314] py-4 text-center min-w-[150px]"
                  dir="rtl"
                >
                  شناختی کارڈ نمبر
                </th>
                {/* <th className="text-[12px] font-semibold border-b-[1px] border-[#2A2D4314] py-4 text-left">
              Block Code
            </th>
            <th className="text-[12px] font-semibold border-b-[1px] border-[#2A2D4314] py-4 text-left">
              Sector
            </th> */}
                <th
                  className="text-[12px] font-semibold border-b-[1px] border-[#2A2D4314] py-4 text-right min-w-[150px]"
                  dir="rtl"
                >
                  نام
                </th>
                {/* <th
                className="text-[12px] font-semibold border-b-[1px] border-[#2A2D4314] py-4 text-right min-w-[150px] "
                dir="rtl"
              >
                خاندانی نمبر
              </th> */}
                <th
                  className="text-[12px] font-semibold border-b-[1px] border-[#2A2D4314] py-4 text-center min-w-[150px] "
                  dir="rtl"
                >
                  گھرانہ نمبر
                </th>

                <th
                  className="text-[12px] ml-4 font-semibold border-b-[1px] border-[#2A2D4314] py-4 text-right min-w-[150px]"
                  dir="rtl"
                >
                  سلسلہ نمبر
                </th>
              </tr>
            </thead>
            <tbody className="ml-[50px]">
              {!data.family_tree?.length ? (
                <div className="text-center text-[18px] font-bold">
                  No Family tree
                </div>
              ) : (
                data.family_tree?.map((item, index) => (
                  <>
                    <tr>
                      <td
                        className="text-[11px] border-t-[1px] border-l-[1px] border-r-[1px] px-1 border-[#0d0d10fd] py-1 w-[70px] font-noori"
                        dir="rtl"
                        colSpan={7}
                      >
                        <>
                          {item?.type === "manual" ? (
                            <div
                              className="px-1 flex flex-row text-[25px]  py-2"
                              dir="rtl"
                            >
                              <div className="font-bold text-right font-noori">
                                نام
                              </div>
                              <div className="font-noori mr-4">
                                {/* {data2?.response?.name} */}
                                {
                                  item?.name?.length > 44
                                    ? item?.name
                                        ?.replace(/[^\W]/gi, "")
                                        .replace(/[-*]/g, " ")
                                        // .replace(
                                        //   new RegExp(
                                        //     wordsToReplace.join("|"),
                                        //     "gi"
                                        //   ),
                                        // ""
                                        // )
                                        .substr(0, 44) + ""
                                    : item?.name
                                        ?.replace(/[^\W]/gi, "")
                                        .replace(/[-*]/g, " ")
                                  // .replace(
                                  //   new RegExp(
                                  //     wordsToReplace.join("|"),
                                  //     "gi"
                                  //   ),
                                  //   ""
                                  // )
                                }
                              </div>
                            </div>
                          ) : (
                            <ImageSlice item={item} />
                          )}
                        </>
                        {/* {authCtx.user.sector === "PP 207" ? (
                          <img
                            src={`https://res.cloudinary.com/election-experts/image/fetch/c_crop,h_80,x_0,w_4000,y_${
                              parseInt(item?.rowY) + 70
                            }/${encodeURIComponent(item?.url)}`}
                            alt=""
                            srcSet=""
                            className=" w-[900px]"
                          />
                        ) : (
                          <>
                            {item?.name === "" || item?.address === "" ? (
                              <img
                                src={`https://res.cloudinary.com/election-experts/image/fetch/c_crop,h_100,x_0,w_4000,y_${
                                  parseInt(item?.rowY) - 40
                                }/${encodeURIComponent(item?.url)}`}
                                alt=""
                                srcSet=""
                                className="w-[900px]"
                              />
                            ) : (
                              <img
                                src={`https://res.cloudinary.com/election-experts/image/fetch/c_crop,h_100,x_0,w_4000,y_${
                                  parseInt(item?.rowY)-30
                                }/${encodeURIComponent(item?.url)}`}
                                alt=""
                                srcSet=""
                                className="w-[900px]"
                              />
                            )}
                          </>
                        )} */}
                      </td>
                    </tr>
                    <tr key={index}>
                      {authCtx.user.package !== "1" ? (
                        <td
                          className="text-[12px] border-b-[1px] border-l-[1px] border-r-[1px] pr-3 border-[#0d0d10fd] p1-4 "
                          dir="rtl"
                          // rowSpan={2}
                          colSpan={7}
                        >
                          <span>
                            <span className="mx-3 text-right">
                              {item?.cnic}
                            </span>
                            {authCtx.user.sector !== "PK 82" ? (
                              <>
                                <strong> | </strong>
                                <strong dir="rtl">فون :</strong>
                                <span>
                                  {item.voter_phone
                                    ? (typeof item.voter_phone === "string" &&
                                      item.voter_phone !== "null"
                                        ? JSON.parse(item.voter_phone)
                                        : Array.isArray(item.voter_phone)
                                        ? item.voter_phone
                                        : []
                                      )
                                        .filter(
                                          (item, index, self) =>
                                            self.indexOf(item) === index
                                        )
                                        .map((phone) => (
                                          <span className="mr-2">
                                            0{phone},
                                          </span>
                                        ))
                                    : "--"}
                                </span>
                              </>
                            ) : (
                              ""
                            )}
                          </span>
                        </td>
                      ) : null}
                    </tr>
                  </>
                ))
                // data.family_tree?.map((item, index) => (
                //   <tr key={index}>
                //     {authCtx.user.package !== "1" ? (
                //       <td
                //         className={`${tdClasses} font-noori min-w-[150px] text-center`}
                //         dir="rtl"
                //       >
                //         {item.voter_phone
                //           ? JSON.parse(item.voter_phone).map((phone) => (
                //               <p>{phone}</p>
                //             ))
                //           : "--"}
                //       </td>
                //     ) : null}

                //     <td
                //       className={`${tdClasses} font-noori min-w-[150px] `}
                //       dir="rtl"
                //     >
                //       {item.address}
                //     </td>
                //     <td
                //       className={`${tdClasses} font-noori min-w-[150px] text-center`}
                //       dir="rtl"
                //     >
                //       {item.cnic}
                //     </td>
                //     {/* <td className={tdClasses}>{item.block_code}</td> */}
                //     {/* <td className={tdClasses}>{item.sector}</td> */}
                //     <td
                //       className={`${tdClasses} min-w-[150px] mx-3 text-center`}
                //       dir="rtl"
                //     >
                //       {item.name?.length > 44
                //         ? item.name
                //             ?.replace(/[^\W]/gi, "")
                //             .replace(/[-*]/g, " ")
                //             .substr(0, 44) + ".."
                //         : item.name?.replace(/[^\W]/gi, "").replace(/[-*]/g, " ")}
                //     </td>
                //     <td
                //       className={`${tdClasses} min-w-[150px] text-center`}
                //       dir="rtl"
                //     >
                //       {item.gharana_no}
                //     </td>
                //     <td
                //       className={`${tdClasses} font-noori min-w-[150px] text-center`}
                //       dir="rtl"
                //     >
                //       {" "}
                //       {item.silsila_no}
                //     </td>
                //   </tr>
                // ))
              )}
            </tbody>
          </table>
        )}
      </div>
    </ListingTemplate>
  );
};

export default FamilyListing;
