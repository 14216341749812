import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import AppImages from "../../../../assets/images";
import { Api } from "../../../../utils/api";
import { RequestStatus } from "../../../../utils/enums";
import SimpleModal from "../../../basic/SimpleModal";
import ListingTemplate1 from "../ListingTemplate1";
// import ListingTemplate1 from "../ListingTemplate1";
import { customerActions, customerThunk } from "../reducers/customer_slice";

function Sectorlisting({ children }) {
  const navigate = useNavigate();
  const customerReducer = useSelector((state) => state.customers);
  const dispatch = useDispatch();

  const ths = [
    { title: "ID" },
    { title: "Age" },
    { title: "Gharna Number" },
    { title: "SilSila Number" },
    { title: "Sector" },
    { title: "Status" },
    { title: "Url Id" },
    { title: "CNIC" },
  ];

  const [data, setData] = useState(customerReducer.data);
  const [showableData, setShowableData] = useState(data ?? []);
  const [deleteableItem, setDeletableItem] = useState(null);
  const [searchQuery, setSearchQuery] = useState(null);
  const [statusFilter, setStatusFilter] = useState("all");

  useEffect(() => {
    if (customerReducer.fetchDataRequestStatus === RequestStatus.IDLE) {
      dispatch(customerThunk.getCustomers());
    }
  }, [customerReducer.fetchDataRequestStatus]);

  useEffect(() => {
    console.log(customerReducer.data);
    setData(customerReducer.data ?? []);
    setShowableData(customerReducer.data ?? []);
  }, [customerReducer.data]);

  const tdClasses = "text-[12px] border-b-[1px] border-[#2A2D4314] py-4";


  const handleStatusFilter = (e) => {
    setStatusFilter(e.target.value);
    switch (e.target.value) {
      case "active":
        setShowableData(data.filter((d) => d.status === "active"));
        break;
      case "inactive":
        setShowableData(
          data.filter((d) => d.status === "inactive" || !d.status)
        );
        break;
      case "all":
      default:
        setShowableData(data);
    }
  };

  const filterDataByQuery = (val) => {
    console.log(showableData);
    const newData = showableData.filter(
      (d) =>
        d.id.toString().startsWith(val) ||
        d.name?.toLowerCase().startsWith(val.toLowerCase()) ||
        d.email?.toLowerCase().startsWith(val.toLowerCase()) ||
        d.phone?.toLowerCase().startsWith(val.toLowerCase()) ||
        d.Location?.city?.toLowerCase().startsWith(val.toLowerCase()) ||
        d.Location?.country?.toLowerCase().startsWith(val.toLowerCase())
    );
    return newData;
  };

  const handleOnSearch = (e) => {
    const val = e.target.value?.trim();
    if (val) {
      setSearchQuery(val);
      setShowableData(filterDataByQuery(val));
    } else if (statusFilter !== "all") {
      setShowableData(data.filter((d) => d.status === statusFilter));
    } else {
      setShowableData(data);
    }
  };

  return (
    <ListingTemplate1
      title="Sector"
      onSearch={handleOnSearch}
      selectBoxLabel="Select staff:"
      selectItems={[
        { text: "All", value: "all" },
        { text: "Active", value: "active" },
        { text: "Inactive", value: "inactive" },
      ]}
      onSelect={handleStatusFilter}
      
    >

      <table className="w-full my-6">
        <thead>
          <tr>
            {ths.map((item, index) => (
              <th
                key={index}
                className="text-[12px] font-semibold border-b-[1px] border-[#2A2D4314] py-4 text-left"
              >
                {item.title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {showableData.map((item, index) => (
            <tr key={index}>
              <td className={tdClasses}>{item.id}</td>
              <td className={tdClasses}>{item.age}</td>
              <td className={tdClasses}>{item.gharana_no}</td>
              <td className={tdClasses}>{item.silsila_no}</td>
              <td className={tdClasses}>{item.sector}</td>
              <td className={tdClasses}>{item.status}</td>
              <td className={tdClasses}>{item.url_id}</td>
              <td className={tdClasses}>{item.cnic}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </ListingTemplate1>
  );
}

export default Sectorlisting;
