import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthContext from "../../../contexts/AuthContext";

function HeaderMenu() {

    const navigate = useNavigate()
    const authCtx = useContext(AuthContext)
    const isCustomer = authCtx.user?.UserRole?.name === 'customer'
    const data = [
        {
            id: 1,
            title: 'Profile',
            route: isCustomer ? '/customer/profile/view' : '/profile/view'
        },
        {
            id: 2,
            title: 'Log out',
            onClick: (e) => {
                authCtx.logout()
                navigate('/')
            }
        },

    ]

    return ( 
        <div className="absolute bg-white py-0 rounded-lg right-[50%] border-2 w-[150px] z-50">
            <ul>
                {data.map((item) => (
                    <li className="border-b-[1px] border-[#f2f2f2]" key={item.id}>
                        <div 
                            className="py-2 px-2 text-sm hover:text-primary cursor-pointer"
                            onClick={e => {
                                if(item.route) {
                                    navigate(item.route)
                                } else if(item.onClick && typeof item.onClick === 'function') {
                                    item.onClick(e)
                                }
                            }}
                        >
                            {item.title}
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default HeaderMenu;